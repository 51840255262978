import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import IconButton from '@mui/material/IconButton';
import classNames from 'classnames';
import Icon from 'components/ui-components-v2/Icon';
import Translation from 'components/data/Translation';
import Button from 'components/ui-components-v2/Button';
import Tooltip from 'components/ui-components-v2/Tooltip';
import Inputs from './components/inputs';
import './styles/main.scss';

interface Props {
    /**
     * Custom class name of the repeater.
     */
    className?: string;
    /**
     * Value of the repeater.
     */
    value?: unknown[];
    /**
     * Function to be called when the value of the repeater changes.
     * @param value - New value of the repeater.
     * @returns void
     */
    onChange: (value: unknown[]) => void;
    /**
     * Function to be called when the repeater loses focus.
     * @returns void
     */
    onBlur?: () => void;
    /**
     * Type of the input.
     */
    inputType?: 'text' | 'number';
    /**
     * Props to be passed to the input.
     */
    inputProps?: object;
    /**
     * Maximum number of items in the repeater.
     */
    max?: number;
    /**
     * Label of the add button.
     */
    addLabel?: string;
    /**
     * Whether the repeater should be full width.
     */
    fullWidth?: boolean;
    /**
     * Index of the item to be disabled.
     */
    disabledIndex?: number;
    /**
     * Tooltip to be shown when the disabled index is hovered.
     */
    disabledIndexTooltip?: string;
}

/**
 * The Repeater component is used to create a list of inputs that can be repeated.
 *
 * - [Repeater documentation](https://bycape.atlassian.net/wiki/spaces/DD/pages/659292161/Repeater)
 */
const Repeater = ({
    className,
    value = [null],
    onChange,
    onBlur,
    inputType = 'text',
    inputProps = {},
    max = 0,
    addLabel = Translation.get('actions.add', 'common'),
    fullWidth,
    disabledIndex,
    disabledIndexTooltip
}: Props) => {
    /**
     * Get reference array.
     * @param value - Value of the repeater.
     * @returns Reference array.
     */
    const getReferenceArray = (value: unknown) => {
        const array: unknown[] = [];
        if (Array.isArray(value)) value.forEach(() => array.push(uuidv4()));
        return array;
    };

    const [referenceArray, setReferenceArray] = useState<unknown[]>(getReferenceArray(value));
    const Input = Inputs[inputType];

    /**
     * Add an item to the array.
     */
    const addItem = () => {
        value.push(null);
        setReferenceArray((prevState) => [...prevState, uuidv4()]);
        onChange(value);
    };

    /**
     * Handle change of an item in the array.
     * @param index - Index of the item in the array.
     * @param itemValue - New value of the item.
     */
    const handleItemChange = (index: number, itemValue: unknown) => {
        value[index] = itemValue;
        onChange(value);
    };

    /**
     * Remove an item from the array.
     * @param index - Index of the item to be removed.
     */
    const removeItem = (index: number) => {
        value.splice(index, 1);
        setReferenceArray((prevState) => prevState.filter((_, i) => i !== index));
        onChange(value);
    };

    return (
        <div className={classNames('ui-v2-cape-repeater', { 'ui-v2-cape-repeater--full-width': fullWidth }, className)}>
            {value.map((itemValue, index) => (
                <div key={`${inputType}-${referenceArray[index]}`} onBlur={onBlur ? onBlur : undefined} className="ui-v2-cape-repeater__item">
                    <Tooltip
                        title={disabledIndex !== undefined && disabledIndexTooltip && disabledIndex === index ? disabledIndexTooltip : ''}
                        disableInteractive>
                        <div>
                            <Input
                                value={itemValue}
                                {...inputProps}
                                fullWidth={fullWidth}
                                disabled={disabledIndex === index}
                                onChange={(event) => handleItemChange(index, event.target.value)}
                                type={inputType}
                            />
                        </div>
                    </Tooltip>
                    <IconButton
                        style={{ opacity: index > 0 ? 1 : 0, pointerEvents: index > 0 ? 'auto' : 'none' }}
                        onClick={() => removeItem(index)}
                        size="small"
                        disabled={disabledIndex === index}
                        className="ui-v2-cape-repeater__item__button">
                        <Icon>close</Icon>
                    </IconButton>
                </div>
            ))}

            {(max === 0 || value.length < max) && (
                <Button
                    onClick={addItem}
                    variant="outlined"
                    className={classNames('ui-v2-cape-repeater__add-button', { 'input__repeater__add-button--full-width': fullWidth })}
                    startIcon={<Icon>add</Icon>}
                    color="primary">
                    {addLabel}
                </Button>
            )}
        </div>
    );
};

export default Repeater;
