import React from 'react';
import PropTypes from 'prop-types';
import './../styles/main.scss';
import Icon from 'components/ui-components-v2/Icon';

class GoogleTextAd extends React.Component {
    static propTypes = {
        adType: PropTypes.string,
        headline_one: PropTypes.string,
        headline_two: PropTypes.string,
        headline_three: PropTypes.string,
        description_one: PropTypes.string,
        description_two: PropTypes.string,
        base_url: PropTypes.string,
        path_one: PropTypes.string,
        path_two: PropTypes.string
    };

    static defaultProps = {
        adType: 'textAd',
        headline_one: '',
        headline_two: '',
        headline_three: '',
        description_one: '',
        description_two: '',
        base_url: '',
        path_one: '',
        path_two: ''
    };

    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        const { headline_one, headline_two, headline_three, description_one, description_two, base_url, path_one, path_two } = this.props;

        return (
            <div className="google-text-ad">
                <div className="top-section">
                    <div className="headline-wrapper">
                        {headline_one !== '' && <span>{headline_one}</span>}
                        {headline_two !== '' && <span> | {headline_two}</span>}
                        {headline_three !== '' && <span> | {headline_three}</span>}
                    </div>
                    <div className="icon-wrapper">
                        <Icon fontSize="small" className="info-icon">
                            info
                        </Icon>
                    </div>
                </div>

                <div className="url-wrapper">
                    <span className="adv-badge">Adv.</span>
                    <span className="adv-link">
                        {base_url !== '' && <span>{base_url}</span>}
                        {path_one !== '' && <span>/{path_one}</span>}
                        {path_two !== '' && <span>/{path_two}</span>}
                    </span>
                </div>

                <hr className="line-break" />

                <div className="description-wrapper">
                    {description_one !== '' && <span>{description_one}</span>}
                    {description_two !== '' && <span> {description_two}</span>}
                </div>
            </div>
        );
    }
}

export default GoogleTextAd;
