import { CreativeV2 } from 'components/creatives-v2/components/creative-editor/types/creativeV2.type';
import { Brick, BrickSetup } from '../types/brick.type';
import BrickHelpers from './brick.helpers';
import { BricksObject } from '../types/bricksComponentStore.type';
import BricksComponentStoreHelper from './bricks-component-store.helper';
import { MODEL_CREATIVES } from '../constants';

/**
 * Helper class to handle custom bricks
 */
class CustomBrickHelpers {
    static filterActiveFormats = (creative: CreativeV2, formatsToDelete?: string[]): string[] => {
        const activeFormats: string[] = creative.data.settings.activeFormats;
        return activeFormats.filter((format) => !formatsToDelete?.includes(format));
    };

    /**
     * Deactivate a custom bricks.
     */
    static handleCustomBrickDelete = (brickId: string, formatsToDelete?: string[]): void => {
        const brick = BrickHelpers.getBrickById(brickId);

        if (!brick) return;

        const creative = brick.data?.creatives[0];

        creative.data.settings.activeFormats = this.filterActiveFormats(creative, formatsToDelete); // Remove the current format from the active formats.

        BricksComponentStoreHelper.setBrickModel(brickId, MODEL_CREATIVES, brick.data?.creatives); // Update the brick with the new active formats.
    };

    /**
     * Deactivate multiple custom bricks.
     */
    static handleMultiCustomBricksDelete = (brickIds: string[]): void => {
        const bricks = brickIds.map((brickId) => BrickHelpers.getBrickById(brickId));
        const bricksToDelete: string[] = [];

        // We only need to delete the formats of the parent brick.
        bricks.forEach((brick) => {
            const parentId = brick?.parentId;
            if (parentId && !bricksToDelete.includes(parentId)) {
                bricksToDelete.push(parentId);
            }
        });

        // Filter out the formats that needs to be deleted.
        for (const brickId of bricksToDelete) {
            const formatsToDelete = bricks.filter((brick) => brick?.parentId === brickId && !!brick?.data?.format).map((brick) => brick?.data?.format ?? '');
            this.handleCustomBrickDelete(brickId, formatsToDelete);
        }
    };

    /**
     * Filter out custom bricks from the bricks object.
     */
    static filterOutCustomBricks = (bricksObject: BricksObject): BricksObject => {
        const filteredBricksObject: BricksObject = {};

        Object.keys(bricksObject).forEach((key) => {
            const brick = bricksObject[key];

            if (!this.isCustomBrick(brick)) {
                filteredBricksObject[key] = brick;
            }
        });

        return filteredBricksObject;
    };

    static isCustomBrick = (brick: Brick): boolean => {
        const brickSetup: BrickSetup | undefined = BrickHelpers.getBrickData(brick.subType, 'setup');
        return brickSetup?.isCustomBrick || false;
    };
}

export default CustomBrickHelpers;
