import { IEditorBlock } from 'components/bricks/types/editorBlock.type';
import { MODEL_PUBLISH_METADATA } from 'components/bricks/constants';

const settings: IEditorBlock[] = [
    {
        title: 'General',
        itemType: 'block',
        type: 'multiInput',
        opened: true,
        items: [
            {
                type: 'text',
                identifier: 'meta_campaign-title',
                model: 'title',
                itemType: 'input',
                label: 'Title',
                defaultValue: 'Meta campaign',
                maxLength: 150,
                fixedModel: true,
                useValueObject: false
            },
            {
                type: 'select',
                model: 'status',
                label: 'Status',
                setFirstOption: true,
                identifier: 'meta_campaign-status',
                tooltip: 'Status',
                options: {
                    PAUSED: 'PAUSED',
                    ACTIVE: 'ACTIVE'
                }
            },
            {
                type: 'metadataInput',
                model: 'adAccountId',
                label: 'Ad Account',
                identifier: 'meta_campaign-ad_account_id',
                metadataType: 'facebookAdAccount',
                config: {
                    fields: 'name'
                },
                useValueObject: false
            },
            {
                type: 'dynamicData',
                title: 'Child title',
                identifier: 'meta_campaign-child_title',
                condition: `additionalVars.brick.type==='subItem' && !additionalVars.multiSocial_campaign`,
                model: 'childTitle',
                itemType: 'input',
                label: 'Child title',
                maxLength: 150,
                useValueObject: false
            },
            {
                type: 'dynamicData',
                title: 'Pointer',
                identifier: 'meta_campaign-pointer',
                condition: `additionalVars.brick.type==="subItem" && !additionalVars.multiSocial_campaign`,
                model: 'pointer',
                itemType: 'input',
                label: 'Pointer',
                maxLength: 150,
                useValueObject: false
            }
        ]
    },
    {
        title: 'Special ad categories',
        itemType: 'block',
        type: 'multiInput',
        opened: true,
        items: [
            {
                type: 'autocomplete',
                identifier: 'meta_campaign-special_ad_categories',
                model: 'special_ad_categories',
                label: 'Special ad category',
                tooltip:
                    'Declare if your ads are related to credit, employment or housing, or about social issues, elections or politics. Requirements differ by country.',
                options: {
                    HOUSING: 'Housing',
                    EMPLOYMENT: 'Employment',
                    CREDIT: 'Credit',
                    ISSUES_ELECTIONS_POLITICS: 'Social Issues, elections or politics',
                    ONLINE_GAMBLING_AND_GAMING: 'Online gambling and gaming'
                }
            },
            {
                type: 'metadataInput',
                identifier: 'meta_campaign-special_ad_category_country',
                model: 'special_ad_category_country',
                label: 'Countries',
                condition: `additionalVars.brick.data.settings?.special_ad_categories?.length`,
                tooltip:
                    'Select where you want to run this campaign. If there are additional requirements to run your ads in those locations, your advertising options will be adjusted.',
                metadataType: 'facebookGeographic',
                config: {
                    location_types: 'country'
                },
                useValueObject: false
            }
        ]
    },
    {
        title: 'Campaign details',
        itemType: 'block',
        type: 'multiInput',
        opened: true,
        items: [
            {
                type: 'select',
                model: 'buying_type',
                identifier: 'meta_campaign-buying_type',
                label: 'Buying type',
                tooltip:
                    'This field will help Facebook make optimizations to delivery, pricing, and limits. All ad sets in this campaign must match the buying type.',

                options: {
                    AUCTION: 'Auction',
                    RESERVED: 'Reach and frequency'
                }
            },
            {
                type: 'select',
                model: 'objective',
                identifier: 'meta_campaign-objective',
                label: 'Objective',
                tooltip: 'The ultimate goal of your marketing campaign.',
                defaultValue: 'OUTCOME_AWARENESS',
                setFirstOption: true,
                options: {
                    OUTCOME_AWARENESS: 'Awareness',
                    OUTCOME_TRAFFIC: 'Traffic',
                    OUTCOME_ENGAGEMENT: 'Engagement',
                    OUTCOME_SALES: 'Sales',
                    OUTCOME_APP_PROMOTION: 'App promotion',
                    OUTCOME_LEADS: 'Leads'
                }
            },
            {
                type: 'number',
                startAdornment: '€',
                identifier: 'meta_campaign-spend_cap',
                tooltip: 'A spend cap for the campaign, such that it will not spend more than this cap.',
                model: 'spend_cap',
                label: 'Spend cap'
            }

            // TODO: To be discussed
            // {
            //     type: 'number',
            //     tooltip: 'Used if a campaign has been copied. The ID from the original campaign that was copied.',
            //     model: 'source_campaign_id',
            //     label: 'Source campaign id'
            // },
            // {
            //     type: 'number',
            //     tooltip: 'ToplineID',
            //     model: 'topline_id',
            //     label: 'Topline id'
            // }
        ]
    },
    {
        title: 'Advantage campaign budget',
        itemType: 'block',
        type: 'multiInput',
        opened: true,
        items: [
            {
                type: 'buttonGroup',
                model: 'budget_optimization_on',
                identifier: 'meta_campaign-budget_optimization_on',
                label: 'Advantage+ Campaign Budget',
                tooltip: 'Advantage+ Campaign Budget',
                options: {
                    false: 'OFF',
                    true: 'ON'
                }
            },

            {
                type: 'buttonGroup',
                model: 'budget_mode',
                identifier: 'meta_campaign-budget_mode',
                label: 'Budget mode',
                startAdornment: '€',
                condition: `blockData.budget_optimization_on==='true'`,
                tooltip: 'Type of the budget',
                defaultValue: 'DAILY',
                options: {
                    DAILY: 'DAILY',
                    LIFETIME: 'LIFETIME'
                }
            },
            {
                type: 'number',
                tooltip: 'Daily campaign budget',
                identifier: 'meta_campaign-budget',
                startAdornment: '€',
                condition: `blockData.budget_optimization_on==='true'`,
                model: 'budget',
                label: 'Budget'
            },
            {
                type: 'select',
                model: 'bid_strategy',
                condition: `blockData.budget_optimization_on==='true' && additionalVars.brick.data.publish.data.objective ==='OUTCOME_AWARENESS'`,
                label: 'Bid strategy',
                identifier: 'meta_campaign-bid_strategy',
                tooltip: 'Bid strategy for this campaign',
                options: {
                    '': '',
                    LOWEST_COST_WITH_BID_CAP: 'Bid cap',
                    LOWEST_COST_WITHOUT_CAP: 'Highest volume'
                }
            },
            {
                type: 'select',
                model: 'bid_strategy',
                condition: `blockData.budget_optimization_on==='true' && additionalVars.brick.${MODEL_PUBLISH_METADATA}.objective && additionalVars.brick.${MODEL_PUBLISH_METADATA}.objective!=='' && additionalVars.brick.${MODEL_PUBLISH_METADATA}.objective!=='OUTCOME_AWARENESS'`,
                label: 'Bid strategy',
                identifier: 'meta_campaign-bid_strategy',
                tooltip: 'Bid strategy for this campaign',
                options: {
                    '': '',
                    LOWEST_COST_WITH_BID_CAP: 'Bid cap',
                    LOWEST_COST_WITHOUT_CAP: 'Highest volume',
                    COST_CAP: 'Cost per result goal'
                }
            },
            {
                type: 'checkboxList',
                model: 'pacing_type',
                identifier: 'meta_campaign-pacing_type',
                condition: `blockData.budget_optimization_on==='true' && blockData.bid_strategy==='LOWEST_COST_WITH_BID_CAP'`,
                label: 'Delivery',
                options: {
                    no_pacing: 'Accelerated'
                }
            }
        ]
    },
    //TODO: Add conditions `ater
    {
        title: 'iOS 14+ campaign',
        itemType: 'block',
        type: 'multiInput',
        condition: `additionalVars.brick.data.publish.data.publish.data.objective === 'OUTCOME_APP_PROMOTION'`,
        opened: true,
        items: [
            {
                type: 'buttonGroup',
                model: 'is_skadnetwork_attribution',
                identifier: 'meta_campaign-is_skadnetwork_attribution',
                label: 'SKAdNetwork',
                tooltip: 'To create an iOS 14 campaign, enable SKAdNetwork attribution for this campaign.',
                options: {
                    false: 'OFF',
                    true: 'ON'
                }
            },
            {
                type: 'number',
                identifier: 'meta_campaign-application_id',
                tooltip: 'The ID of a Facebook Application. Usually related to mobile or canvas games being promoted on Facebook for installs or engagement',
                model: 'promoted_object.application_id',
                label: 'Application id'
            }
        ]
    }
];

export default settings;
