import Translation from 'components/data/Translation';
import { generateKey } from 'components/template-designer/utils/generateKey';
import { PredefinedRotationAnimation, PredefinedLoopAnimation } from 'components/template-designer/types/animation.type';
import { PredefinedAnimationSetup } from '.';

const defaultValue: { value: PredefinedRotationAnimation['value'] & PredefinedLoopAnimation['value']; easing: PredefinedRotationAnimation['easing'] } = {
    value: {
        rotation: {
            value: 20,
            transformOrigin: {
                xOffset: 50,
                yOffset: 50
            },
            direction: 'right'
        },
        loop: {
            value: 0
        }
    },
    easing: {
        type: 'ease-in-out',
        value: 'ease-in-out'
    }
};

const wiggle: PredefinedAnimationSetup = {
    label: Translation.get('animation.predefinedAnimations.animations.wiggle', 'template-designer'),
    key: 'wiggle',
    duration: 0.75,
    defaultValue: defaultValue,
    reset: { fadeIn: false, fadeOut: false },
    stamps: {
        position: [],
        size: [],
        scale: [],
        rotation: [
            {
                value: {
                    value: 0,
                    transformOrigin: {
                        xOffset: 50,
                        yOffset: 50
                    }
                },
                stamp: 0,
                id: generateKey(),
                easing: {
                    type: 'ease-in-out',
                    value: 'ease-in-out'
                }
            },
            {
                value: {
                    transformOrigin: {
                        xOffset: 50,
                        yOffset: 50
                    },
                    value: 100
                },
                stamp: 0.2,
                id: generateKey(),
                easing: {
                    type: 'ease-in-out',
                    value: 'ease-in-out'
                }
            },
            {
                value: {
                    transformOrigin: {
                        xOffset: 50,
                        yOffset: 50
                    },
                    value: 0
                },
                stamp: 0.4,
                id: generateKey(),
                easing: {
                    type: 'ease-in-out',
                    value: 'ease-in-out'
                }
            },
            {
                value: {
                    transformOrigin: {
                        xOffset: 50,
                        yOffset: 50
                    },
                    value: -100
                },
                stamp: 0.6,
                id: generateKey(),
                easing: {
                    type: 'ease-in-out',
                    value: 'ease-in-out'
                }
            },
            {
                value: {
                    transformOrigin: {
                        xOffset: 50,
                        yOffset: 50
                    },
                    value: 0
                },
                stamp: 0.8,
                id: generateKey(),
                easing: {
                    type: 'ease-in-out',
                    value: 'ease-in-out'
                }
            },
            {
                value: {
                    transformOrigin: {
                        xOffset: 50,
                        yOffset: 50
                    },
                    value: 0
                },
                stamp: 1,
                id: generateKey(),
                easing: {
                    type: 'ease-in-out',
                    value: 'ease-in-out'
                }
            }
        ],
        opacity: [],
        backgroundColor: [],
        color: [],
        shadow: [],
        rotationX: [],
        rotationY: []
    }
};

export default wiggle;
