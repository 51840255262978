import React from 'react';
import Format from 'types/format.type';
import { isCustomUploadCreative, isMediaCreative, isTemplateCreative } from 'components/creatives-v2/guards/creative-type-guards';
import AudioPlayer from 'components/assets/DynamicAsset/components/audio-player';
import Iframe from './components/iframe';
import Image from './components/image';
import Video from './components/video';
import { CreativeV2 } from '../creative-editor/types/creativeV2.type';
import { IframeData, IframeSettings, IframeOverwrites, CreativeV2TemplateType } from '../../creative-types/template-creative.class';
import { AfterEffects } from './components/after-effects';
import { Indesign } from './components/indesign';
import { DynamicVideo } from './components/dynamic-video';

interface Props {
    creative: CreativeV2;
    formatKey?: Format['key'];
    scale?: number;
    classes?: any;
    iframeOverwrites?: IframeOverwrites<IframeData>;
}

const DynamicAssetV2: React.FC<Props> = ({ creative, formatKey, scale, classes, iframeOverwrites }) => {
    let AssetComponent: React.ReactNode = null;

    if (!creative) return null;

    if (isTemplateCreative(creative)) {
        const iframeSettings = new CreativeV2TemplateType(creative).prepareIframeSettings(formatKey, iframeOverwrites);

        if (creative.data?.templateType === 'dynamicAfterEffects') {
            AssetComponent = <AfterEffects creative={creative} format={formatKey as string} data={iframeSettings as IframeSettings<null>} scale={scale} />;
        } else if (creative.data?.templateType === 'dynamicVideo') {
            AssetComponent = <DynamicVideo creative={creative} format={formatKey as string} data={iframeSettings as IframeSettings<null>} scale={scale} />;
        } else if (creative.data?.templateType === 'dynamicInDesign') {
            AssetComponent = <Indesign creative={creative} format={formatKey as string} data={iframeSettings as IframeSettings<null>} scale={scale} />;
        } else {
            AssetComponent = <Iframe iframeSettings={iframeSettings as IframeSettings<IframeData>} classes={classes} scale={scale} />;
        }
    } else if (isMediaCreative(creative)) {
        // media logic here
    } else if (isCustomUploadCreative(creative)) {
        const fileType = creative.data.fileType;

        if (fileType === 'image') {
            AssetComponent = <Image data={creative.data} classes={classes} />;
        } else if (fileType === 'video') {
            AssetComponent = <Video data={creative.data} classes={classes} />;
        } else if (fileType === 'audio') {
            AssetComponent = <AudioPlayer url={creative.data.url} classes={classes} />;
        } else if (fileType === 'zip') {
            AssetComponent = <Iframe iframeSettings={creative.data as any} classes={classes} scale={scale} />;
        }
    }

    return AssetComponent;
};

export default DynamicAssetV2;
