import Translation from 'components/data/Translation';
import Format, { FormatSortOrder } from '../types/format.type';
import Layer from '../types/layer.type';
import { UnitOptions } from '../types/template.type';

export const TEN_SECONDS_IN_MILLISECONDS = 10000;
export const THREE_SECONDS_IN_MILLISECONDS = 3000;
export const PLUS_KEY = 'Equal';
export const MIN_KEY = 'Minus';
export const OPEN_ON_MOUNT_KEY = 'openOnMount-';
export const UNSPLASH_MODULE = 'assetsUnsplash';
export const AI_MODULE = 'AI';
export const DEFAULT_UNIT: UnitOptions = UnitOptions.Pixels;
export const SCALE_LAYER_BY = 1.5;
export const APRIMO_ACCOUNT_IDS = [
    '15' // Philips
];

/**
 * Topbar
 */
export const TOP_BAR_HEIGHT = 48;

/**
 * Sidenav
 */
export const SIDE_NAV_WIDTH = 64;

/**
 * Left sidebar
 */
export const LEFT_SIDEBAR_HEADERS_HEIGHT = 40;
export const LEFT_SIDEBAR_PANEL_KEY = 'panelHeight_percentage_formatManagement';

/**
 * Export
 */
export const DEFAULT_LAYER_HOVER_TRANSITION =
    'opacity 0s ease-in 0s, background-color 0s ease-in 0s, border-color 0s ease-in 0s, color 0s ease-in 0s, transform 0s ease-in 0s, left 0s 0s, top 0s 0s, right 0s 0s, bottom 0s 0s, width 0s 0s, height 0s 0s, opacity 0s 0s';
export const DEFAULT_FORMAT_HOVER_TRANSITION = 'all 0s ease-in 0s, left 0s 0s, top 0s 0s, right 0s 0s, bottom 0s 0s, width 0s 0s, height 0s 0s';

/**
 * History
 */
export const MAX_HISTORY_LENGTH = 20;

/**
 * Timeline
 */
export const MOST_ZOOMED_IN_SECONDS = 2;
export const MOST_ZOOMED_OUT_SECONDS = 10;
export const TIMELINE_MARGIN_LEFT = 22;
export const TIMELINE_MARGIN_RIGHT = 22;
export const TOTAL_TIMELINE_MARGIN = TIMELINE_MARGIN_LEFT + TIMELINE_MARGIN_RIGHT;
export const TIMELINE_BOTTOM_BAR_HEIGHT = 24;
export const MILLISECOND_ZOOM_STEP = 5;
export const MIN_ANIMATION_DURATION = 0.1;
export const SHOW_TIMELINE_HEIGHT = 200;
export const HIDE_TIMELINE_HEIGHT = 40;
export const DURATION_BEFORE_TIMELINE_SHOULD_PLAY = 500;
export const DEFAULT_VISIBILITY = [0, 1];
export const TIMELINE_HEIGHT_VIEWSTATE = 'timelineHeight';
export const SHOW_ONLY_LAYERS_WITH_ANIMATIONS_VIEWSTATE = 'showOnlyLayersWithAnimations';
export const TIMELINE_GROUP_HANDLE_MARGIN = 4;
export const TIMELINE_KEYFRAME_WIDTH = 12;
// Selecto no select class is used to let selecto know that it should not start a drag event when the user starts dragging on this element.
export const SELECTO_NO_SELECT_START_CLASS = 'template-designer__timeline__selecto-no-select-start';
export const MIN_WIDTH_TO_SHOW_ANIMATION_BAR_TEXT = 75;
export const ADOBE_SIDEBAR_LEFT_KEY = 'TD_ADOBE_SIDEBAR_LEFT';
export const TIMELINE_SIDEBAR_LEFT_KEY = 'TD_TIMELINE_SIDEBAR_LEFT';
export const TIMELINE_SIDE_SNAP_THRESHOLD_PX = 7;
export const TRACK_HEIGHT = 34;
export const TIMELINE_TOPBAR_HEIGHT = 40;

/**
 * Settings
 */
export const DEFAULT_GRID_SIZE = 8;
export const DEFAULT_IMAGE_FILE_TYPES = ['jpg', 'jpeg', 'png', 'svg'];
export const DEFAULT_VIDEO_FILE_TYPES = ['mp4', 'webm'];
export const DEFAULT_AUDIO_FILE_TYPES = 'audio';
export const DEFAULT_FONT_FILE_TYPES = ['woff', 'woff2', 'ttf', 'otf'];

/**
 * Canvas
 */
export const DEFAULT_ZOOM_LEVEL = 1;
export const MIN_ZOOM = 0.05;
export const MAX_ZOOM = 10;
export const ZOOM_STEP = 0.1;
export const MAX_WIDTH_CANVAS_DISPLAY = 2000;
export const MAX_WIDTH_CANVAS_IMAGE = 4000;
export const MAX_WIDTH_CANVAS_VIDEO = MAX_WIDTH_CANVAS_IMAGE;
export const MAX_WIDTH_CANVAS_PDF = 12000;
export const DEFAULT_FORMAT_GAP = 64;
export const PDF_FORMAT_GAP = 512;
export const FOOTER_HEIGHT_BIG = 20;
export const FOOTER_HEIGHT_SMALL = 16;
export const MIN_FORMATS_IN_VIEW = 15; // ? Is now the same as MAX_VISIBILE_FORMATS, but we disabled this feature for now because there are some issues with it. And check if there are no big performance issues with it.
export const MAX_VISIBILE_FORMATS = 15;
export const LAYER_COLLAPSED_KEY = 'TD_LAYER_COLLAPSED';
export const CANVAS_SCROLL_POSITION = 'TD_CANVAS_SCROLL_POSITION';
export const CANVAS_ZOOM_LEVEL = 'TD_CANVAS_ZOOM_LEVEL';

/**
 * Formats
 */
export const FORMAT_MANAGEMENT_SEARCH_TERM_KEY = 'templateDesigner_formatManagementSearchTerm';
export const DEFAULT_FORMAT_ORDER = FormatSortOrder.Position;
export const MIN_TITLE_LENGTH = 2;
export const MAX_TITLE_LENGTH = 25;
export const MIN_FORMATS_SEARCH_FIELD = 2;
export const MIN_FORMAT_SIZE = 25;
export const MAX_FORMAT_SIZE = 30000;
export const MAX_LENGTH_FORMAT_SET = 25;
export const SELECTED_LAYER_BOX_SHADOW = 'rgb(41, 113, 235) 0px 0px 0px 1.5px inset';

/**
 * Layers
 */
export const ANIMATION_LAYER_TYPES: Layer['type'][] = ['video', 'audio', 'lottie'];
export const CANT_HAVE_CHILDREN_LAYER_TYPES: Layer['type'][] = ['text', 'image', 'video', 'audio', 'lottie'];

/**
 * Dynamic layers
 */
export const DYNAMIC_INPUTS_COLLAPSED_KEY = 'TD_DYNAMIC_INPUTS_COLLAPSED';
export const MIN_LABEL_LENGTH = 0;
export const MAX_LABEL_LENGTH = 50;
export const MAX_INPUT_DEPTH = 6;
export const DYNAMIC_LAYER_CONDITIONS_ACCOUNT_IDS = [
    '202', // Colab6 acceptance
    '219' // Colab6 production
];
export const DYNAMIC_LAYER_DIALOG_MAX_WIDTH = 365;
export const DYNAMIC_LAYER_SIDEBAR_WIDTH = 320;

/**
 * Miscellaneous
 */
export const WARNING_MEDIA_SIZE_BYTES = 150 * 1024;
export const WARNING_MEDIA_SIZE_KB = 150;
export const TEMPLATE_DESIGNER_TOOLTIP_DELAY = 750;
export const NO_PREVIEW_LAYER = ['audio', 'format'];
export const TREE_INDENT_SIZE = 23;
export const PREVIEW_LAYER_DARK_BACKGROUND_KEY = 'TD_PREVIEW_LAYER_DARK_BACKGROUND';
export const DESIGN_SIDEBAR_LEFT_KEY = 'TD_DESIGN_SIDEBAR_LEFT';
export const ADOBE_SIDEBAR_LEFT_DEFAULT_MIN_WIDTH = 300;
export const ADOBE_SIDEBAR_LEFT_DEFAULT_WIDTH = 420;
export const ADOBE_SIDEBAR_LEFT_DEFAULT_MAX_WIDTH = 550;
export const SIDEBAR_LEFT_DEFAULT_WIDTH = 280;
export const SIDEBAR_LEFT_SNAP_WIDTH = 10;
export const GENERAL_FORMAT: Format = {
    key: 'general',
    title: Translation.get('general.labels.generalSetup', 'template-designer'),
    width: 0,
    height: 0,
    type: 'general',
    sets: [],
    category: 'general'
};
export const CHECKLIST_DONE_KEY = 'TD_CHECKLIST_DONE';

/**
 * Colors used in template
 */
export const COLOR_LAYER_MODELS = [
    'properties.background',
    'properties.border.borderColor',
    'properties.shadow.color',
    'properties.textStyling.normal.color',
    'properties.textStyling.highlighted.color',
    'hover.properties.borderColor',
    'hover.propreties.background'
];
export const COLOR_ANIMATION_MODELS = ['animations.backgroundColor', 'animations.shadow'];
