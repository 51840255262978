import { AssetV2 } from 'types/asset.type';
import ComponentStore from 'components/data/ComponentStore';
import EditorData from 'components/editor-data/EditorData';
import AssetManagementBaseService from './asset-management.base.service';

export default class AssetManagementService extends AssetManagementBaseService {
    // Some asset types store their data outside Asset Management while being edited, If so get that data before sending them to the API
    static getExtendedAsset = (asset: AssetV2<unknown, unknown>): AssetV2<unknown, unknown> => {
        if (asset.type === 'creative') {
            // If a creative is being edited, it stores it's data in the CreativeEditor store, so we need to get it before sending it to the API.
            // In all other cases, we just use the asset as is.
            const creativeEditorData = ComponentStore.get('CreativeEditor');
            if (creativeEditorData) {
                const { dataModel } = ComponentStore.get('CreativeEditor');
                const data = dataModel ? EditorData.getValueFromModel(dataModel) : null;
                if (data) return { ...asset, data };
            }
        }
        return asset;
    };

    // When patching an asset, we want to send the right data with it.
    static async patchAsset(asset: AssetV2<unknown, unknown>, queryParams?: { updateOnlyMetadata?: boolean }): Promise<AssetV2<unknown, unknown>> {
        const extendedAsset = this.getExtendedAsset(asset);
        return await super.patchAsset(extendedAsset, queryParams);
    }
}
