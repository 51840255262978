import React, { useMemo } from 'react';
import { MenuItem } from '@mui/material';
import Translation from 'components/data/Translation';
import { FontType } from 'components/template-designer/types/font.type';
import Select from 'components/ui-components-v2/Select';
import Template, { TemplateData } from 'components/template-designer/types/template.type';

interface Props {
    brandGuide: Template['brandGuide'];
    brands: TemplateData['brands'];
    fontSource: FontType;
    onChange: (fontSource: FontType) => void;
}

const FontPickerFontSource = ({ brandGuide, brands, fontSource, onChange }: Props) => {
    /**
     * Font source options.
     */
    const fontSourceOptions = useMemo(() => {
        const options = [
            {
                key: 'standard',
                value: 'standard',
                label: Translation.get('sidebarRight.inputs.fontSourceOptions.standard', 'template-designer')
            },
            {
                key: 'googleFonts',
                value: 'googleFonts',
                label: Translation.get('sidebarRight.inputs.fontSourceOptions.googleFonts', 'template-designer')
            }
        ];

        // Add brand guide font options when available.
        if (brandGuide && brandGuide.fonts && brandGuide.fonts.length > 0) {
            const generalFonts = brandGuide.fonts.filter((font) => font.brand === 'general');
            const brandFonts = brandGuide.fonts.filter((font) => font.brand !== 'general');

            if (generalFonts.length > 0) {
                options.push({
                    key: 'brandGuide',
                    value: 'brandGuide',
                    label: `${Translation.get('sidebarRight.inputs.fontSourceOptions.brandGuide', 'template-designer')}`
                });
            }

            if (brandFonts.length > 0) {
                options.push({
                    key: `brandGuide_brand`,
                    value: `brandGuide_brand`,
                    label: `${Translation.get('sidebarRight.inputs.fontSourceOptions.brandGuideBrand', 'template-designer')}`
                });
            }
        }

        options.push({
            key: 'templateFonts',
            value: 'templateFonts',
            label: `${Translation.get('sidebarRight.inputs.fontSourceOptions.templateFonts', 'template-designer')}`
        });

        return options;
    }, [brandGuide?.fonts, brands]);

    return (
        <Select
            value={fontSource}
            onChange={(event) => onChange(event.target.value as FontType)}
            fullWidth
            size="small"
            data-cy="templateDesigner-fontSource-select">
            {fontSourceOptions.map((option) => (
                <MenuItem key={option.key} value={option.value} data-cy={`templateDesigner-fontSource-${option.key}-button`}>
                    {option.label}
                </MenuItem>
            ))}
        </Select>
    );
};

export { FontPickerFontSource };
