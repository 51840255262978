import React, { useEffect, useMemo } from 'react';
import { MetadataInputProps } from 'components/input/Metadata/types/metadataInputProps.types';
import { MetadataAutocomplete, MetadataAutocompleteMultiple } from 'components/input/Metadata/shared';
import { PeachConnectGenericMetaData, PeachConnectGenericMetaDataResponse, PeachConnectItem } from '../types';
import { PropertyMapping } from '../helpers/properties-mapping';

interface Props extends MetadataInputProps {
    response?: any;
    config: {
        lookupTypes: string;
        page: number;
        pageSize: number;
    };
    value: string | string[];
    readOnly?: boolean;
    metadataType: string;
}

const PeachConnectGeneric = ({ response, value, config, readOnly, setRequest, onMutation, metadataType, selectType }: Props) => {
    useEffect(() => {
        // set parameters to start a request
        setRequest({
            params: {
                ...config
            }
        });
    }, [JSON.stringify(config)]);

    const getOptions = (response: PeachConnectGenericMetaDataResponse | undefined): PeachConnectItem[] | undefined => {
        if (!response || !response.data || !response.data.length) return;
        const MetadataTypeProps = PropertyMapping[config.lookupTypes];

        return response.data.map((item: PeachConnectGenericMetaData) => {
            if (MetadataTypeProps) {
                const id = String(item[MetadataTypeProps.id]);
                return { id, name: item[MetadataTypeProps.name] };
            }

            return { id: item.name, name: item.name };
        });
    };

    const options = useMemo(() => getOptions(response), [response]);

    if (!options) return;

    if (selectType === 'multiple' && typeof value !== 'string') return <MetadataAutocompleteMultiple options={options} value={value} onMutation={onMutation} />;
    else if ((!selectType || selectType === 'single') && (!value || typeof value === 'string'))
        return <MetadataAutocomplete readOnly={readOnly} options={options} value={value} onMutation={onMutation} />;
};

export default PeachConnectGeneric;
