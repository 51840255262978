import moment from 'moment';
import { TemplateMangementTemplateType } from 'types/templates.type';
import { getTemplatePreviewImage, isTemplateDesignerTemplate, isTemplateDesignerTemplateType } from 'components/template-management/utilities';
import {
    TemplateDataResponseOld,
    TemplateDataVersionResponseOld,
    TemplateHistoryResponse,
    TemplateHistoryResponseOld
} from 'components/template-designer/services/template-designer.responses';
import { CustomTemplateAsset, TDTemplateAsset, TemplateAsset } from 'components/template-management/types/template-management.type';

export function convertAssetV2toTemplate(asset: TemplateAsset): TemplateDataResponseOld {
    const active: '0' | '1' = asset.status === 'available' ? '1' : '0';

    const data: Omit<TemplateDataResponseOld, 'channel' | 'adType' | 'image' | 'publishMapping' | 'previewUrl' | 'templateSetup' | 'templateExport'> = {
        id: asset._id,
        accountId: asset.accountId ? asset.accountId.toString() : '0',
        type: asset.subType,
        brand: asset.labels?.brands?.[0] || '',
        department: asset.labels?.departments?.[0] || null,
        category: asset.labels?.categories?.[0] || null,
        identifier: asset.data.identifier,
        title: asset.title,
        description: asset.data.description || null,
        groupKey: asset.data.groupKey || null,
        createdDate: asset.createdAt,
        createdUserId: asset.createdBy ? asset.createdBy.toString() : '0',
        updatedDate: asset.updatedAt,
        updatedUserId: asset.updatedBy ? asset.updatedBy.toString() : '0',
        active,
        settings: asset.data.settings || null,
        interfaceSetup: asset.data.interfaceSetup || null,
        defaultData: asset.data.defaultData,
        global: null,
        html: null,
        icon: null,
        templateFile: null,
        version: asset.version ? String(asset.version) : null,
        // for the caching of templates we need to store if its full data or not. This happens in data/Templates file
        fullData: asset['fullData']
    };

    if (isTemplateDesignerTemplate(asset)) {
        const tdData = asset.data;
        return {
            ...data,
            version: asset.version ? String(asset.version) : '0',
            channel: null,
            adType: null,
            image: getTemplatePreviewImage(asset) || null,
            publishMapping: null,
            previewUrl: null,
            templateExport: tdData.templateExport || null,
            templateSetup: tdData.templateSetup || null,
            settings: asset.data.settings || null
        };
    }

    const customData = asset.data;

    return {
        ...data,
        channel: customData.channel || null,
        adType: customData.adType || null,
        image: customData.image || null,
        publishMapping: customData.publishMapping,
        previewUrl: customData.previewUrl || null,
        html: customData.html || null,
        templateExport: null,
        templateSetup: null,
        version: null,
        settings: asset.data.settings || null
    };
}

export function batchConvertAssetV2toTemplate(assets: TemplateAsset[]): TemplateDataResponseOld[] {
    const templates: TemplateDataResponseOld[] = [];

    for (const asset of assets) {
        const template = convertAssetV2toTemplate(asset);

        templates.push(template);
    }

    return templates;
}

export function convertTemplateToAssetV2(template: TemplateDataResponseOld): TemplateAsset {
    const commonData: Omit<TemplateAsset, 'data' | 'subType'> = {
        _id: template.id,
        accountId: parseInt(template.accountId),
        createdBy: parseInt(template.createdUserId),
        updatedBy: parseInt(template.updatedUserId),
        public: false,
        type: 'template',
        status: template.active === '1' ? 'available' : 'draft',
        title: template.title,
        createdAt: template.createdDate,
        updatedAt: template.updatedDate,
        labels: {
            brands: template.brand ? [template.brand] : [],
            departments: template.department ? [template.department] : [],
            markets: []
        },
        collections: []
    };
    if (isTemplateDesignerTemplateType(template.type)) {
        const tdTemplate: TDTemplateAsset = {
            ...commonData,
            subType: template.type as TDTemplateAsset['subType'],
            version: template.version ? parseInt(template.version) : 0,
            data: {
                templateExport: template.templateExport || undefined,
                interfaceSetup: template.interfaceSetup || undefined,
                templateSetup: template.templateSetup || undefined,
                description: template.description ?? '',
                identifier: template.identifier,
                brand: template.brand,
                image: template.image ?? '',
                settings: (template.settings as TDTemplateAsset['data']['settings']) || undefined,
                thumbnail: {
                    url: template.image ?? '',
                    width: 0,
                    height: 0
                }
            }
        };
        return tdTemplate;
    }
    const customTemplate: CustomTemplateAsset = {
        ...commonData,
        subType: template.type as CustomTemplateAsset['subType'],
        data: {
            brand: template.brand,
            department: template.department || undefined,
            channel: template.channel || undefined,
            adType: template.adType || undefined,
            groupKey: template.groupKey || undefined,
            identifier: template.identifier,
            category: template.category || undefined,
            description: template.description || undefined,
            icon: template.icon || undefined,
            image: template.image || undefined,
            interfaceSetup: template.interfaceSetup || undefined,
            html: template.html,
            defaultData: template.defaultData,
            publishMapping: template.publishMapping,
            settings: template.settings || undefined,
            previewUrl: template.previewUrl || undefined,
            library: false
        }
    };
    return customTemplate;
}

export function convertTemplateHistoryToAssetV2(template: TemplateDataVersionResponseOld): TemplateAsset {
    const newTemplate: TemplateAsset = {
        _id: template.templateId,
        accountId: parseInt(template.userId),
        data: {
            templateExport: template.templateExport,
            interfaceSetup: template.interfaceSetup,
            templateSetup: {
                designerSettings: template.templateSetup.designerSettings,
                templateSetup: template.templateSetup.templateSetup,
                dataVariables: template.templateSetup.dataVariables,
                brandGuide: template.templateSetup.brandGuide,
                formats: template.templateSetup.formats,
                frameTypes: template.templateSetup.frameTypes,
                layers: template.templateSetup.layers,
                state: template.templateSetup.state,
                layerProperties: template.templateSetup.layerProperties,
                dynamicLayers: template.templateSetup.dynamicLayers,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                interfaceSetup: template.templateSetup.interfaceSetup
            },
            description: template.description ?? '',
            identifier: template.identifier,
            brand: template.brand,
            type: template.type,
            image: '',
            settings: template.settings,
            thumbnail: {
                url: '',
                width: 0,
                height: 0
            }
        },
        createdBy: 0,
        updatedBy: 0,
        public: false,
        type: 'template',
        subType: template.type as TemplateMangementTemplateType,
        status: template.active === '1' ? 'available' : 'draft',
        image: '',
        interfaceSetup: template.interfaceSetup,
        previewUrl: '',
        publishMapping: null,
        settings: template.settings,
        templateExport: template.templateExport,
        templateFile: null,
        templateSetup: template.templateSetup,
        version: parseInt(template.version),
        title: template.title,
        createdAt: moment().toDate(),
        updatedAt: moment().toDate(),
        labels: {
            brands: [],
            departments: [],
            markets: []
        },
        collections: []
    };

    return newTemplate;
}

export function convertTemplateHistoryToAssetV2List(histories: TemplateHistoryResponseOld[]): TemplateHistoryResponse[] {
    const newTemplates: TemplateHistoryResponse[] = [];

    for (const history of histories) {
        newTemplates.push({
            versionNumber: parseInt(history.id),
            createdAt: history.updatedDate,
            createdBy: parseInt(history.updatedUser.id)
        });
    }

    return newTemplates;
}
