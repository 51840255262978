import get from 'lodash/get';
import types from 'reducers/ComponentStore/types';
import store from '../../../../store';
import { ComponentStoreNames } from '../hooks/useComponentStore';

/**
 * Recursively gets all nested keys of an object as a union type of string literals.
 * @template ObjectType The type of the object to get the nested keys from.
 * @param ObjectType The object to get the nested keys from.
 * @returns A union type of string literals representing all the nested keys of the object.
 */
export type NestedKeyOf<ObjectType extends object> = {
    [Key in keyof ObjectType & (string | number)]: ObjectType[Key] extends object ? `${Key}` | `${Key}.${NestedKeyOf<ObjectType[Key]>}` : `${Key}`;
}[keyof ObjectType & (string | number)];

/**
 * A utility type that returns the value type for a given key in a model object.
 * If the key is a nested property (e.g. 'outer.inner'), the type of the nested property is returned.
 * If the key is not found in the model object, `unknown` is returned.
 */
export type ValueForModelKey<M, K extends string> = K extends `${infer Outer}.${infer Inner}`
    ? Outer extends keyof M
        ? Inner extends keyof M[Outer]
            ? M[Outer][Inner]
            : unknown
        : unknown
    : K extends keyof M
      ? M[K]
      : unknown;

/**
 * A utility class that provides helper methods for working with the ComponentData redux store.
 */
export default class Helpers {
    /**
     * Use the ComponentData redux store to pass data to an entire set of components
     * This function overwrites existing data
     * @param {ComponentStoreNames} componentName The name of the component to set data for. e.g. "TemplateDesigner", "Bricks"
     * @param {*} data The data object with all additional data.
     */
    static resetData<D>(componentName: ComponentStoreNames, data: D): void {
        store.dispatch({
            type: types.RESETDATA,
            componentName: componentName,
            data: data
        });
    }

    /**
     * Use the ComponentData redux store to pass data to an entire set of components
     *
     * @template T The type of data to set. e.g. "Template", "BricksComponentStore"
     * @param {ComponentStoreNames} componentName The name of the component to set data for. e.g. "TemplateDesigner", "Bricks"
     * @param {T} data The data to set.
     * @example
     * setData<Test>('TestComponentName', { age: 1, name: "example"});
     */
    static setData<T>(componentName: ComponentStoreNames, data: T): void {
        store.dispatch({
            type: types.SETDATA,
            componentName: componentName,
            data: data
        });
    }

    /**
     * Sets the model data for a given component in the store.
     *
     * @template T - The type of the component data to be updated. e.g. "Template", "BricksComponentStore"
     * @template K - The key type of the model. e.g. "templateData.brands", "templateData.name"
     * @param {ComponentStoreNames} componentName - The name of the component. e.g. "TemplateDesigner", "Bricks"
     * @param model - The key of the component model. e.g. "templateData.brands", "templateData.name"
     * @param value - The value to set for the given model key.
     * @example
     * setModel<Template, 'templateData.brands'>('TemplateDesigner', 'templateData.brands', 'brand1');
     */
    static setModel<T extends object | unknown, K extends T extends object ? NestedKeyOf<T> : unknown>(
        componentName: ComponentStoreNames,
        model: K,
        value: K extends string ? ValueForModelKey<T, K> : unknown
    ): void {
        store.dispatch({
            type: types.SETMODEL,
            componentName: componentName,
            model,
            value
        });
    }

    /**
     * Sets multiple models for a given component in the store.
     *
     * @template T - The type of the component data. e.g. "Template", "BricksComponentStore"
     * @param {ComponentStoreNames} componentName - The name of the component. e.g. "TemplateDesigner", "Bricks"
     * @param {Array<[NestedKeyOf<T>, ValueForModelKey<T, NestedKeyOf<T>>]> | unknown[][]} models - An array of model-value pairs to set. e.g. [["templateData.brand", "brand1"], ["templateData.name", "name1"]]
     * @example
     * setMultiModels<Template>(componentName, [["templateData.brand", "brand1"], ["templateData.name", "name1"]]);
     */
    static setMultiModels<T extends object | unknown>(
        componentName: ComponentStoreNames,
        models: T extends object ? Array<[NestedKeyOf<T>, ValueForModelKey<T, NestedKeyOf<T>>]> : unknown[][]
    ): void {
        store.dispatch({
            type: types.SETMODELS,
            componentName: componentName,
            models
        });
    }

    /**
     * Remove data from the redux component store
     * @param {*} componentName The name of the component. Is a subobject of the store.
     * @param {*} data The data object with all additional data. Will be merged with the current data.
     */
    static remove(componentName: ComponentStoreNames): void {
        store.dispatch({
            type: types.REMOVEDATA,
            componentName: componentName
        });
    }

    /**
     * Get data from component
     * @param {String} componentName The name of the component
     * @return {Object} requested user data
     */
    static get(componentName: ComponentStoreNames) {
        if (componentName) {
            return store.getState().componentStore[componentName];
        }

        return store.getState().componentStore;
    }

    /**
     * Get item from specific component store
     * @param {String} componentName The name of the component
     * @return {Object} requested user data
     */
    static getItem<T>(componentName: ComponentStoreNames, path: string): T | undefined {
        const component = store.getState().componentStore[componentName];

        if (!component) return;

        return get(component, path);
    }

    /**
     * Remove an item from  specific model in the store.
     * @param {string} componentName
     * @param {string} model
     */
    static removeItem<M>(componentName: ComponentStoreNames, model: M): void {
        store.dispatch({
            type: types.REMOVEITEM,
            componentName,
            model
        });
    }

    /**
     * Remove items from specific models in the store.
     * @param {string} componentName
     * @param {string} model
     */
    static removeItems<M>(componentName: ComponentStoreNames, models: M): void {
        store.dispatch({
            type: types.REMOVEITEMS,
            componentName,
            models
        });
    }
}
