import React from 'react';
import { TemplateType } from 'types/templates.type';
import Illustration from 'components/ui-components-cape/Illustration';

type TemplateIconProps = {
    noSquarcle?: boolean;
    templateType: TemplateType;
    size?: 'medium' | 'small';
};

const TemplateIcon = ({ templateType, noSquarcle, size = 'medium' }: TemplateIconProps) => {
    const templateIcon: { [key in TemplateType]: React.ReactNode | undefined } = {
        displayAd: <Illustration size={size} noSquarcle={noSquarcle} illustration="display-ad" />,
        displayAdDesigned: <Illustration size={size} noSquarcle={noSquarcle} illustration="template-designer" />,
        dynamicImage: <Illustration size={size} noSquarcle={noSquarcle} illustration="image" />,
        dynamicImageDesigned: <Illustration size={size} noSquarcle={noSquarcle} illustration="template-designer" />,
        dynamicPDF: <Illustration size={size} noSquarcle={noSquarcle} illustration="pdf" />,
        dynamicPDFDesigned: <Illustration size={size} noSquarcle={noSquarcle} illustration="template-designer" />,
        dynamicVideo: <Illustration size={size} noSquarcle={noSquarcle} illustration="video" />,
        dynamicVideoDesigned: <Illustration size={size} noSquarcle={noSquarcle} illustration="template-designer" />,
        dynamicAfterEffects: <Illustration size={size} noSquarcle={noSquarcle} illustration="after-effects" color="secondary" />,
        dynamicInDesign: <Illustration size={size} noSquarcle={noSquarcle} illustration="inDesign" color="secondary" />,
        socialChannelItem: <Illustration size={size} noSquarcle={noSquarcle} illustration="fallback" />,
        staticAsset: <Illustration size={size} noSquarcle={noSquarcle} illustration="fallback" />,
        landingpageBlock: <Illustration size={size} noSquarcle={noSquarcle} illustration="fallback" />,
        emailBlock: <Illustration size={size} noSquarcle={noSquarcle} illustration="mail" />,
        socialChannelItemOverwrites: <Illustration size={size} noSquarcle={noSquarcle} illustration="fallback" />,
        assetItemOverwrites: <Illustration size={size} noSquarcle={noSquarcle} illustration="fallback" />,
        marketDefault: <Illustration size={size} noSquarcle={noSquarcle} illustration="fallback" />,
        emailBase: <Illustration size={size} noSquarcle={noSquarcle} illustration="fallback" />
    };

    return templateIcon[templateType];
};

export default TemplateIcon;
