import { EventEmitterTypes } from 'types/event-emitter.type';
import { SceneHelpers } from 'helpers/scene.helpers';
import ComponentStore from 'components/data/ComponentStore';
import { EventEmitterHelpers } from 'helpers/event-emitter.helpers';
import { GAP_WIDTH, FRAME_WIDTH_PER_SECOND } from 'components/creatives-v2/constants';
import { TemplateManager } from 'components/creatives-v2/data/template-manager';
import { TemplateDataResponse } from 'components/template-designer/services/template-designer.responses';
import { TDTemplateAsset } from 'components/template-management/types/template-management.type';
import { CreativeV2Template } from '../../creative-editor/types/creativeV2.type';

const DEFAULT_DURATION = 5;

class CreativeOverviewHelpers {
    static seek = (progress: number, duration: number): void => {
        SceneHelpers.pauseScene();

        const isPlaying = ComponentStore.getItem('CreativeOverview', 'playing');
        if (isPlaying) ComponentStore.setModel('CreativeOverview', 'playing', false);

        EventEmitterHelpers.sent(EventEmitterTypes.CEiframe, { action: { type: 'seek', seekTime: progress / 100 } });
        EventEmitterHelpers.sent(EventEmitterTypes.CEcurrentTime, (progress * duration) / 100);
    };

    // Get the duration of the frame type
    // If frameType is not provided, it will return the duration of the first frame type that is not 'base'
    static getFrameTypeDuration = (creative: CreativeV2Template, frameType?: string): number => {
        const frameTypes = (TemplateManager.getTemplateByIdentifier(creative.data.templateIdentifier) as TDTemplateAsset)?.data.templateSetup?.frameTypes;

        const frame = frameTypes?.find((frame) => (frameType ? frame.key === frameType : frame.key !== 'base'));

        return frame?.duration || DEFAULT_DURATION;
    };

    static getProgress = (progress: number, creative: CreativeV2Template, template: TemplateDataResponse, iframeToEditor = true): number => {
        if (!creative || !creative.data) return progress;

        let updatedProgress = 0;
        let totalDuration = 0;
        let cumulativeDuration = 0;
        const dataWithDurations = {};

        const framesFromData = creative.data?.templateInput?.frames || {};

        // TODO If you just open a creative but don't save it, the frames are not saved in the templateInput
        // Should we add a default object?

        Object.keys(framesFromData).forEach((frameKey) => {
            const frame = framesFromData[frameKey];
            // TODO 'main' as default? What if the main frameType is called different? This is mostly important for the framesbar outside of the editor,
            // because the editor will automatically push the frametype because of the select
            const duration = (template as TDTemplateAsset)?.data.templateExport?.frames?.[frame.type || 'main']?.duration || DEFAULT_DURATION;
            cumulativeDuration += duration || 0;
            totalDuration = cumulativeDuration;

            dataWithDurations[frameKey] = {
                ...frame,
                duration,
                cumulativeDuration
            };
        });

        const framesCount = Object.keys(framesFromData).length || 1; // should be dynamic, fixed for testing
        const gaps = framesCount - 1;
        const totalLengthPxWithGaps = totalDuration * FRAME_WIDTH_PER_SECOND + gaps * GAP_WIDTH;
        const totalLengthPxWithoutGaps = totalLengthPxWithGaps - gaps * GAP_WIDTH;
        const gapPerc = GAP_WIDTH / totalLengthPxWithGaps;

        // Determine what the active frame is, based on the percentage from the iframe
        const activeFrame = (() => {
            let activeFrame = 1;

            Object.keys(dataWithDurations).forEach((frameKey, index) => {
                const frame = dataWithDurations[frameKey];
                const progressSeconds = (progress / 100) * totalDuration;

                if (iframeToEditor) {
                    if (progressSeconds <= frame.cumulativeDuration && progressSeconds >= frame.cumulativeDuration - frame.duration) {
                        activeFrame = index + 1;
                    }
                } else {
                    if (
                        progress <= (frame.cumulativeDuration / totalDuration) * 100 + (index + 1) * gapPerc &&
                        progress >= ((frame.cumulativeDuration - frame.duration) / totalDuration) * 100 + (index + 1) * gapPerc
                    ) {
                        activeFrame = index + 1;
                    }
                }
            });

            return activeFrame;
        })();

        const extraGapPerc = (activeFrame - 1) * gapPerc * 100;

        if (iframeToEditor) {
            updatedProgress = CreativeOverviewHelpers.convertPercentage(totalLengthPxWithGaps, totalLengthPxWithoutGaps, progress);
            if (activeFrame > 1) updatedProgress += extraGapPerc;
        } else {
            updatedProgress = CreativeOverviewHelpers.convertPercentage(totalLengthPxWithoutGaps, totalLengthPxWithGaps, progress);
            if (activeFrame > 1) updatedProgress -= extraGapPerc;
        }

        return updatedProgress;
    };

    private static convertPercentage = (progressBar1Width: number, progressBar2Width: number, percentage: number) => {
        const ratio = progressBar2Width / progressBar1Width;
        const convertedPercentage = (percentage / 100) * ratio * 100;
        return convertedPercentage;
    };
}

export default CreativeOverviewHelpers;
