import { BrickSetup } from 'components/bricks/types/brick.type';

const setup: BrickSetup = {
    subType: 'youtube_post',
    title: 'Youtube post',
    channel: 'video',
    platform: 'youtube',
    outputAction: 'publish',
    description: 'Create a post for Youtube',
    addBrickText: 'Add post',
    availableParentBricks: [],
    availableChildBricks: [],
    defaultTab: 'adSetup',
    config: {
        general: {
            creativeSelector: {
                selectors: ['upload', 'template', 'campaignAssets'],
                fileType: ['video'],
                templateTypes: ['dynamicVideo', 'dynamicVideoDesigned', 'dynamicVideo'],
                multiple: false
            },
            publish: {
                previewType: 'social'
            }
        },
        adSetup: {
            hasAdPlacements: true,
            allowsMultiFrame: false
        }
    },
    tabs: [
        {
            key: 'settings',
            title: 'Settings',
            type: 'multiInput'
        },
        {
            key: 'adSetup',
            title: 'Ad setup',
            type: 'component',
            component: 'adSetup'
        },
        {
            key: 'creatives',
            title: 'Creatives',
            type: 'component',
            component: 'creative'
        },
        {
            key: 'briefing',
            title: 'Briefing',
            type: 'component',
            component: 'briefing'
        },
        {
            key: 'publish',
            title: 'Publish',
            type: 'component',
            component: 'output'
        }
    ]
};

export default setup;
