import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/ui-components-v2/Icon';
import DynamicAsset from 'components/assets/DynamicAsset';
import iconMore from '../images/icon_more.png';

class PinterestCarouselAd extends React.Component {
    static propTypes = {
        asset: PropTypes.object,
        title: PropTypes.string,
        description: PropTypes.string
    };

    static defaultProps = {};

    constructor(props) {
        super(props);

        this.state = {
            carouselIndex: 0
        };
        this.pinterestCarouselRef = React.createRef();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.frameNr !== this.props.frameNr) {
            if (this.props.frameNr > 0) {
                this.swipeOnFrameNr(this.props.frameNr);
            }
        }
    }

    componentDidMount() {
        if (this.pinterestCarouselRef.current) {
            this.swipeOnFrameNr(this.props.frameNr);
        }
    }

    swipe = (direction) => {
        const element = this.pinterestCarouselRef.current;
        const distance = 320;
        if (direction === 'right') {
            element.scrollLeft += distance;
        } else {
            if (element.scrollLeft % distance === 0) {
                element.scrollLeft += -distance;
            } else {
                element.scrollLeft += -(element.scrollLeft % distance);
            }
        }

        setTimeout(() => {
            const index = element.scrollLeft / distance;

            this.setState({
                carouselIndex: Math.round(index)
            });
        }, 340);
    };

    swipeOnFrameNr = (frameNr) => {
        const element = this.pinterestCarouselRef.current;
        const distance = 320;
        this.setState({ carouselIndex: frameNr - 1 }, () => (element.scrollLeft = (frameNr - 1) * distance));
    };

    getAsset = (index, asset, image, video) => {
        const { assetComponents } = this.props;

        // Existing prop for assets
        if (assetComponents) {
            return assetComponents[index] && assetComponents[index][0] ? assetComponents[index][0] : assetComponents[index];
        }
        // Create in component
        else {
            return (
                <DynamicAsset
                    {...asset}
                    image={image}
                    video={video}
                    frameWidth={asset && asset.frameWidth ? asset.frameWidth : 1000}
                    frameHeight={asset && asset.frameHeight ? asset.frameHeight : 1500}
                    width={320}
                />
            );
        }
    };

    render() {
        const { frames = [], frameCount = 0, title, description, advertiser, items } = this.props;
        const { carouselIndex } = this.state;
        let framesRestructured = [];

        // Framecount based items
        if (frameCount > 0) {
            for (let i = 1; i <= frameCount; i++) {
                framesRestructured.push(frames['frame' + i] ? frames['frame' + i] : {});
            }
        }
        // Support object based (
        else if (items && items.frame1) {
            framesRestructured = Object.values(items);
        }
        // Support array based (items)
        else if (Array.isArray(items)) {
            framesRestructured = items;
        }
        // Support object based
        else if (frames && frames.frame1) {
            framesRestructured = Object.values(frames);
        }
        // Support array based
        else if (Array.isArray(frames)) {
            framesRestructured = frames;
        }

        return (
            <div className="carousel-ad">
                <div className="carousel-ad__carousel" ref={this.pinterestCarouselRef}>
                    {framesRestructured.map(({ displayUrl, asset, image, video }, index) => (
                        <div key={displayUrl + index} className="carousel-ad__carousel__item">
                            <div className="carousel-ad__carousel__asset-wrapper">{this.getAsset(index, asset, image, video)}</div>
                        </div>
                    ))}
                </div>

                {carouselIndex !== 0 && framesRestructured.length > 1 && (
                    <div className="carousel-ad__arrow-left" onClick={() => this.swipe('left')}>
                        <Icon fontSize="large" className="stories-ad__arrow-icon">
                            chevron_left
                        </Icon>
                    </div>
                )}

                {carouselIndex !== framesRestructured.length - 1 && framesRestructured.length > 1 && (
                    <div className="carousel-ad__arrow-right" onClick={() => this.swipe('right')}>
                        <Icon fontSize="large" className="stories-ad__arrow-icon">
                            chevron_right
                        </Icon>
                    </div>
                )}

                {title && <div className="carousel-ad__title">{title}</div>}
                {!title && <div className="carousel-ad__title">{description}</div>}
                <div className="carousel-ad__advertiser">
                    <div className="carousel-ad__advertiser__logo" style={{ backgroundImage: `url(${advertiser.logo})` }} />
                    <div className="carousel-ad__advertiser__text">
                        <div className="carousel-ad__advertiser__sponsored">Promoted by</div>
                        <div className="carousel-ad__advertiser__name">{advertiser.name}</div>
                    </div>
                    <div className="carousel-ad__advertiser__more">
                        <img src={iconMore} alt="icon-more" />
                    </div>
                </div>
            </div>
        );
    }
}

export default PinterestCarouselAd;
