import { ValidationObject } from 'components/bricks/types/validator.type';

const validators: ValidationObject = {
    validators: [
        {
            condition: '!brick.data?.settings?.advertiser || !brick.data?.settings.advertiser?.length',
            message: 'Advertiser is required',
            inputIdentifiers: ['peachconnect_campaign-advertiser'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition: '!brick.data?.settings?.reference || !brick.data?.settings.reference?.length',
            message: 'Reference is required',
            inputIdentifiers: ['peachconnect_campaign-reference'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition: '!brick.data?.settings?.brand || !brick.data?.settings.brand?.length',
            message: 'Brand is required',
            inputIdentifiers: ['peachconnect_campaign-brand'],
            tabKey: 'settings',
            severity: 'error'
        }
    ]
};

export default validators;
