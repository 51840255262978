import PropTypes from 'prop-types';
import React from 'react';
import sizeMe from 'react-sizeme';
import classNames from 'classnames';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import Icon from 'components/ui-components-v2/Icon';
import Card from 'components/ui-components-v2/Card';
import Tooltip from 'components/ui-components-v2/Tooltip';
import Chip from 'components/ui-components-v2/Chip';
import Button from 'components/ui-components-v2/Button';
import FeedHelpers from 'components/data/FeedManagementHelpers';
import Translation from 'components/data/Translation';
import Placeholder from '../images/placeholder.png';
import ItemPreview from '../../ItemPreview';
import '../styles/main.scss';

/** Shows the details of a data entry in a card view */
class DataCard extends React.Component {
    static propTypes = {
        data: PropTypes.object,
        mapping: PropTypes.object,
        canEdit: PropTypes.bool,
        canDelete: PropTypes.bool,
        canOpenDetailView: PropTypes.bool,
        onEdit: PropTypes.func,
        onDelete: PropTypes.func,
        onOpenDetailView: PropTypes.func,
        onSelect: PropTypes.func
    };

    static defaultProps = {
        data: {},
        mapping: {},
        canEdit: false,
        canDelete: false,
        canOpenDetailView: false,
        selected: false
    };

    constructor(props) {
        super(props);

        const { item, mapping } = props;
        this.state = {
            mappedItem: item.data ? FeedHelpers.mapDataForDisplay(item.data, mapping) : {},
            expanded: false,
            hovered: false
        };
    }

    componentDidUpdate(prevProps) {
        const { item, mapping } = this.props;
        // Remap the item if the item changes.
        if (prevProps.item !== this.props.item && item.data) {
            this.setState({ mappedItem: FeedHelpers.mapDataForDisplay(item.data, mapping) });
        }
    }

    render() {
        const {
            mapping = {},
            size: { width },
            item,
            canEdit,
            canDelete,
            canOpenDetailView,
            displayTypes = [],
            onEdit,
            onDelete,
            onOpenDetailView,
            selected,
            onSelect,
            onClickImage
        } = this.props;
        const { expanded, mappedItem, hovered } = this.state;

        return (
            <div
                className={classNames('data-card', { 'data-card--selected': selected })}
                style={{ width }}
                onMouseEnter={() => this.setState({ hovered: true })}
                onMouseLeave={() => this.setState({ hovered: false })}>
                <Card className="data-card__card">
                    <CardMedia
                        onClick={() => {
                            if (!!onClickImage && typeof onClickImage === 'function') onClickImage();
                        }}
                        className="data-card__media"
                        image={mappedItem.image ? mappedItem.image : Placeholder}>
                        {hovered && (
                            <React.Fragment>
                                <div className="data-card__actions">
                                    {canOpenDetailView && (
                                        <Tooltip title={Translation.get('dataSet.table.viewDetails', 'feed-management')} onClick={() => onOpenDetailView(item)}>
                                            <IconButton size="large">
                                                <Icon fontSize="small">visibility</Icon>
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                    {canEdit && (
                                        <Tooltip title={Translation.get('dataSet.table.editItem', 'feed-management')}>
                                            <IconButton onClick={() => onEdit({ ...item })} size="large">
                                                <Icon fontSize="small">edit</Icon>
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                    {canDelete && (
                                        <Tooltip title={Translation.get('dataSet.table.removeItem', 'feed-management')}>
                                            <IconButton onClick={() => onDelete(item)} size="large">
                                                <Icon fontSize="small">delete</Icon>
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </div>

                                {onSelect && (
                                    <div className="data-card__select">
                                        <Button
                                            onClick={() => onSelect(item)}
                                            size="small"
                                            style={{ border: '1px solid white' }}
                                            variant="contained"
                                            color="primary">
                                            {selected ? Translation.get('actions.unselect', 'common') : Translation.get('actions.select', 'common')}
                                        </Button>
                                    </div>
                                )}
                            </React.Fragment>
                        )}
                    </CardMedia>
                    <div className="data-card__copy">
                        <div className="data-card__copy__title">{mappedItem.title}</div>
                        <div className="data-card__copy__subtitle">{mappedItem.subtitle}</div>
                    </div>
                    <div className={`data-card__footer data-card__footer${expanded ? '--expanded' : ''}`}>
                        {!expanded && (
                            <div className="data-card__footer__unexpanded">
                                {mappedItem.chip && (
                                    <div className="data-card__footer__unexpanded__feed-name">
                                        <Chip label={mappedItem.chip} size="small" className="data-card__chip" />
                                    </div>
                                )}
                                <IconButton size="small" style={{ marginLeft: 'auto' }} onClick={() => this.setState({ expanded: !expanded })}>
                                    <Icon fontSize="small">expand_more</Icon>
                                </IconButton>
                            </div>
                        )}
                        {expanded && (
                            <div className="data-card__footer__expanded">
                                <div className="data-card__footer__expanded__header">
                                    <div className="data-card__footer__expanded__header__title">{mappedItem.title}</div>
                                    <div className="data-card__footer__expanded__header__button">
                                        <IconButton size="small" onClick={() => this.setState({ expanded: !expanded })}>
                                            <Icon fontSize="small">expand_more</Icon>
                                        </IconButton>
                                    </div>
                                </div>
                                <div className="data-card__footer__expanded__content">
                                    {mappedItem.chip && (
                                        <div style={{ width: '100%' }}>
                                            {this.renderSubItem(`${mapping && mapping.chipCopy ? mapping.chipCopy : 'Feed'}`, mappedItem.chip, 'chip')}
                                        </div>
                                    )}
                                    <div style={{ width: '100%', display: 'flex' }}>
                                        {mappedItem.category && (
                                            <div style={{ maxWidth: mappedItem.label ? '50%' : '100%' }}>
                                                {this.renderSubItem(
                                                    `${mapping && mapping.categoryCopy ? mapping.categoryCopy : 'Category'}`,
                                                    mappedItem.category
                                                )}
                                            </div>
                                        )}
                                        {mappedItem.label && (
                                            <div style={{ maxWidth: mappedItem.label ? '50%' : '100%' }}>
                                                {this.renderSubItem(`${mapping && mapping.labelCopy ? mapping.labelCopy : 'Label'}`, mappedItem.label)}
                                            </div>
                                        )}
                                    </div>
                                    {mappedItem.url && (
                                        <div style={{ width: '100%' }}>
                                            {this.renderSubItem(`${mapping && mapping.urlCopy ? mapping.urlCopy : 'Url'}`, mappedItem.url, 'url')}
                                        </div>
                                    )}
                                    <ItemPreview item={item} displayTypes={displayTypes}>
                                        <div className="data-card__footer__expanded__content__view-details">
                                            {Translation.get('dataSet.table.viewDetails', 'feed-management')}
                                        </div>
                                    </ItemPreview>
                                </div>
                            </div>
                        )}
                    </div>
                </Card>
                {selected && (
                    <div className="data-card__selected">
                        <Icon>done</Icon>
                    </div>
                )}
            </div>
        );
    }

    /**
     * Renders a subitem in the DataCard detail view
     * Can return a string, an array of chips or a url
     * @param {*} title
     * @param {*} data
     * @param {*} type Optional type (e.g. 'chip', 'url') These are formatted differently
     */
    renderSubItem = (title, data, type) => {
        let value;

        if (Array.isArray(data) && data.length > 0) {
            value = data.map((item, index) => {
                return <Chip key={item + index} label={item} size="small" className="data-card__chip" />;
            });
        } else if (type === 'chip') {
            value = <Chip label={data} size="small" className="data-card__chip" />;
        } else if (type === 'url') {
            value = (
                <span className="data-card__footer__expanded__content__subitem__value__url" onClick={() => window.open(data, '_blank')}>
                    {data}
                </span>
            );
        } else if (data) {
            value = data;
            // Add a space after a comma if there isn't already one
            value = String(value).replace(/,(?=[^\s])/g, ', ');
        }

        return (
            <div className="data-card__footer__expanded__content__subitem">
                <div className="data-card__footer__expanded__content__subitem__title">{title}</div>
                <div className="data-card__footer__expanded__content__subitem__value">{value}</div>
            </div>
        );
    };
}

export default sizeMe()(DataCard);
