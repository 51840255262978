import React, { useEffect, useMemo, useState } from 'react';
import { Brick } from 'components/bricks/types/brick.type';
import GridWrapper from 'components/creatives-v2/components/grid-wrapper';
import { CreativeV2 } from 'components/creatives-v2/components/creative-editor/types/creativeV2.type';
import { IframeData, IframeOverwrites } from 'components/creatives-v2/creative-types/template-creative.class';
import { getCreativeInstance } from 'components/creatives-v2/helpers/creatives-factory';
import { CreativeV2Helpers } from 'components/creatives-v2/helpers/creatives-v2.helpers';
import CircularProgress from 'components/ui-components-v2/CircularProgress';

interface Props {
    brick: Brick;
    activeFormat?: string;
}

const OutputDialogCreativePreview: React.FC<Props> = ({ brick, activeFormat }) => {
    const [loading, setLoading] = useState(true);

    const getIframeOverwrites = (creatives: CreativeV2[]): IframeOverwrites<IframeData> => {
        const showPlaybar = getCreativeInstance(creatives[0])?.showPlayBar();

        return {
            data: {
                autoplay: showPlaybar,
                showPlaybar
            }
        };
    };

    useEffect(() => {
        const loadTemplates = async () => {
            await CreativeV2Helpers.loadTemplateForCreative(brick.data?.creatives[0]);
            setLoading(false);
        };
        loadTemplates();
    }, [brick, activeFormat]);

    const creatives = brick?.data?.creatives || [];
    const iframeOverwrites = useMemo(() => getIframeOverwrites(creatives), [creatives]);

    if (loading) return <CircularProgress />;

    return (
        <GridWrapper
            creatives={creatives}
            singleViewKey={activeFormat}
            disableZoom
            iframeOverwrites={iframeOverwrites}
            classes={{ creativeItem: 'output-jobs-dialog-preview__content__item' }}
            view={'single'}
        />
    );
};

export default OutputDialogCreativePreview;
