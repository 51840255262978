import User from 'components/data/User';
import Setup from 'components/data/Setup';
import Layer from '../types/layer.type';
import { AllProperties } from '../types/layerProperties.type';
import { DEFAULT_IMAGE_FILE_TYPES, DEFAULT_VIDEO_FILE_TYPES } from '../constants';
import Template, { DesignerSettings, TemplateData } from '../types/template.type';
import { getTemplateData } from './data.helpers';

/**
 * Class for configuration helpers.
 */
class ConfigHelpers {
    /**
     * Check if the template is an Adobe template.
     * @param templateType - The template type to check if it is an Adobe template. If not provided, it will be fetched from the template data.
     * @returns - Returns true if the template is an Adobe template.
     */
    static isAdobeTemplate = (templateType?: TemplateData['type']): boolean => {
        if (templateType === undefined) templateType = getTemplateData<TemplateData['type']>('templateData.type');
        return ['dynamicAfterEffects', 'dynamicInDesign'].includes(templateType);
    };

    /**
     * Check if the shortcuts dialog should be shown.
     * @param templateType - The template type to check if the shortcuts dialog should be shown. If not provided, it will be fetched from the template data.
     * @returns - Returns true if the shortcuts dialog should be shown.
     */
    static hasShortcutsDialog = (templateType?: TemplateData['type']): boolean => {
        if (templateType === undefined) templateType = getTemplateData<TemplateData['type']>('templateData.type');
        return !this.isAdobeTemplate(templateType);
    };

    /**
     * Check if the version management should be shown.
     * @param templateType - The template type to check if version management should be shown. If not provided, it will be fetched from the template data.
     * @returns - Returns true if the version management should be shown.
     */
    static hasVersionManagement = (templateType?: TemplateData['type']): boolean => {
        if (templateType === undefined) templateType = getTemplateData<TemplateData['type']>('templateData.type');
        return !this.isAdobeTemplate(templateType);
    };

    /**
     * Check if Lottie is available.
     * @param templateType - The template type to check if Lottie is available. If not provided, it will be fetched from the template data.
     * @returns - Returns true if Lottie is available.
     */
    static hasLottie = (templateType?: TemplateData['type']): boolean => {
        if (templateType === undefined) templateType = getTemplateData<TemplateData['type']>('templateData.type');
        return ['displayAdDesigned', 'dynamicVideoDesigned'].includes(templateType);
    };

    /**
     * Check if the template has hover.
     * @param templateType - The template type to check if the template has hover. If not provided, it will be fetched from the template data.
     * @returns - Returns true if the template has hover.
     */
    static hasHover = (templateType?: TemplateData['type']): boolean => {
        if (templateType === undefined) templateType = getTemplateData<TemplateData['type']>('templateData.type');
        return ['displayAdDesigned'].includes(templateType);
    };

    /**
     * Check if the template has animations.
     * @param templateType - The template type to check if the template has animations. If not provided, it will be fetched from the template data.
     * @returns - Returns true if the template has animations.
     */
    static hasAnimations = (templateType?: TemplateData['type']): boolean => {
        if (templateType === undefined) templateType = getTemplateData<TemplateData['type']>('templateData.type');
        return ['displayAdDesigned', 'dynamicVideoDesigned'].includes(templateType);
    };

    /**
     * Checks if video layers can be added.
     * @returns If video layers can be added.
     */
    static hasVideoLayers = (): boolean => {
        const templateType = getTemplateData<Template['templateData']['type']>('templateData.type');
        const enableAnimations = getTemplateData<DesignerSettings['enableAnimations']>('designerSettings.enableAnimations');
        return enableAnimations && (templateType === 'dynamicVideoDesigned' || (templateType === 'displayAdDesigned' && User.get('type') === 'superadmin'));
    };

    /**
     * Checks if audio layers can be added.
     * @returns If audio layers can be added.
     */
    static hasAudioLayers = (): boolean => {
        const templateType = getTemplateData<Template['templateData']['type']>('templateData.type');
        const enableAnimations = getTemplateData<DesignerSettings['enableAnimations']>('designerSettings.enableAnimations');
        return enableAnimations && (templateType === 'dynamicVideoDesigned' || (templateType === 'displayAdDesigned' && User.get('type') === 'superadmin'));
    };

    /**
     * Checks if Lottie layers can be added.
     * @returns If Lottie layers can be added.
     */
    static hasLottieLayers = (): boolean => {
        const templateType = getTemplateData<Template['templateData']['type']>('templateData.type');
        if (!ConfigHelpers.hasLottie(templateType)) return false;
        const enableLottie = getTemplateData<DesignerSettings['enableLottie']>('designerSettings.enableLottie');
        return enableLottie;
    };

    /**
     * Check if the text should not shrink.
     * @param width - Width of the layer.
     * @param height - Height of the layer.
     * @returns - Returns true if the text should not shrink.
     */
    static isShrinkTextDisabled = (width: AllProperties['width'], height: AllProperties['height']): boolean => {
        return (
            typeof width.value === 'string' ||
            typeof height.value === 'string' ||
            width.value === null ||
            height.value === null ||
            width.unit === '%' ||
            height.unit === '%' ||
            width.value === 0 ||
            height.value === 0
        );
    };

    /**
     * Get the file types for image files.
     * @param layer - The layer to get the file types for.
     * @param templateType - The template type to check which file types are compatible.
     */
    static getFileTypesForUpload = (layer?: Layer): string | string[] => {
        if (Setup.hasModule('mediaManagement') && User.hasRight(['assetManagerMediaWrite', 'assetManagerMediaRead', 'assetManagerMediaManagement'])) {
            switch (layer?.type) {
                case 'image': {
                    const templateType = getTemplateData<TemplateData['type']>('templateData.type');

                    if (templateType === 'displayAdDesigned') {
                        return [...DEFAULT_IMAGE_FILE_TYPES, 'gif'];
                    }

                    // ? Only support Webp for non display templates as some services gives an error.
                    return [...DEFAULT_IMAGE_FILE_TYPES, 'webp'];
                }
                case 'video':
                    return [...DEFAULT_VIDEO_FILE_TYPES];
                case 'audio':
                    return 'audio';
                case undefined:
                default:
                    return [...DEFAULT_IMAGE_FILE_TYPES];
            }
        }

        if (layer === undefined) return ['image'];

        if (layer.type === 'image') {
            const templateType = getTemplateData<TemplateData['type']>('templateData.type');
            if (['displayAdDesigned'].includes(templateType)) return [layer.type, '.gif'];
            return [layer.type];
        }

        if (layer.type === 'video') return 'video';
        if (layer.type === 'audio') return 'audio';
        return ['image'];
    };
}

export { ConfigHelpers };
