import { StyledEngineProvider } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import getTheme from './styles/globals/theme';
import settings from './config/settings';
import PreviewRoutes from './preview-routes.js';
import './styles/setup/main.scss';
import { SnackbarUtilsConfigurator } from './components/ui-base/SnackbarUtils';

class App extends React.Component {
    componentDidMount() {}

    render() {
        document.title = settings.headerTitle;
        return (
            <div>
                <SnackbarProvider maxSnack={3} classes={{ containerRoot: 'campaigndesigner-snackbar-container' }}>
                    <SnackbarUtilsConfigurator />

                    <StyledEngineProvider injectFirst>
                        <ThemeProvider theme={getTheme()}>
                            <SnackbarProvider maxSnack={3}>
                                <PreviewRoutes />
                            </SnackbarProvider>
                        </ThemeProvider>
                    </StyledEngineProvider>
                </SnackbarProvider>
            </div>
        );
    }
}

export default App;
