import { BrickSetup } from 'components/bricks/types/brick.type';

const setup: BrickSetup = {
    subType: 'group',
    title: 'Group',
    description: 'Create a group',
    addBrickText: 'Add group',
    channel: 'group',
    availableParentBricks: [],
    availableChildBricks: ['all'],
    outputAction: 'download',
    defaultTab: 'settings',
    tabs: [
        {
            key: 'settings',
            title: 'Settings',
            type: 'multiInput'
        },
        {
            key: 'briefing',
            title: 'Briefing',
            type: 'component',
            component: 'briefing'
        },
        {
            key: 'publish',
            title: 'Export',
            type: 'component',
            component: 'output'
        }
    ],
    config: {
        general: {
            publish: {
                previewType: 'settings'
            }
        }
    }
};

export default setup;
