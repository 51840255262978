import axios from 'axios';
import store from '../../../store';

/**
 * Set initial request data
 */
const Request = axios.create({
    baseURL: process.env.APP_API_HOST,
    timeout: process.env.APP_API_TIMOUT,
    withCredentials: true
});

/**
 * Intercept requests
 * 1.   Add auth information as default to request, will be overridden if is set
 *      in the request data.
 */
Request.interceptors.request.use(
    (config) => {
        const data = {};
        try {
            const { previewToken } = store.getState().auth;
            const id = localStorage.getItem('userId') ? localStorage.getItem('userId') : 0;
            const token = localStorage.getItem('userToken') ? localStorage.getItem('userToken') : 0;

            if (id && token) {
                config.headers.Authorization = token ? `Bearer ${id}:${token}` : '';
            }

            // Previewtoken
            if (previewToken && config && config.data && typeof config.data === 'object') {
                config.data = { previewToken: previewToken, ...config.data };
            }

            return config;
        } catch (e) {
            console.error(e);
        }
    },

    (error) => {
        return Promise.reject(error);
    }
);

/**
 * Intercept responses
 * 1.   Catch connection/auth errors
 * 2.   Catch API errors, and tranform to default 'Promise.reject'.
 */
Request.interceptors.response.use(
    // LW custom error middleware, response has code 200 but success=0 in data
    (response) => {
        if (!response.data) {
            return Promise.reject('Error in api callback');
        }
        // We have an error in the response
        if (!response.data || !response.data.success || response.data.error || response.data.errors) {
            // The token expired of user doesn't exist, relogin user after too many times
            if ((response.data && response.data.error === 'userIncorrect') || (response.data && response.data.error === 'tokenExpired')) {
                localStorage.removeItem('userId');
                localStorage.removeItem('userToken');
                window.location.href = '/';
                return;
            }
            return Promise.reject(response.data);
        }
        return response.data ? response.data : response;
    },

    // Default error handler, because response is not 200. Handled when no connection or page not found (for example).
    (error) => {
        return Promise.reject(error);
    }
);

export { Request };
export default Request;
