import TextField from 'components/ui-components-v2/TextField';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Icon from 'components/ui-components-v2/Icon';
import { set } from 'lodash';
import IconButton from '@mui/material/IconButton';
import Select from 'components/ui-components-v2/Select';
import Button from 'components/ui-components-v2/Button';
import Translation from 'components/data/Translation';
import '../styles/visual-editor.scss';

/**
 * JSON class
 * This shows a visual JSON editor that doesn't require code.
 */
export default class VisualEditor extends Component {
    static propTypes = {
        value: PropTypes.string,
        onChange: PropTypes.func
    };

    constructor(props) {
        super(props);

        const newSetup = this.convertValue(JSON.parse(props.value ? props.value : ''));
        this.state = {
            value: newSetup,
            openedItems: []
        };
    }

    /**
     * Convert value
     * @param {*} value
     */
    convertValue = (value) => {
        const newSetup = [];
        for (const i in value) {
            if (typeof value[i] === 'string') {
                newSetup.push({
                    key: i,
                    type: 'string',
                    value: value[i]
                });
            }

            if (typeof value[i] === 'object' && value[i] !== null) {
                newSetup.push({
                    key: i,
                    type: 'object',
                    subitems: this.convertValue(value[i])
                });
            }
        }

        return newSetup;
    };

    // Add item to the element
    addItem = (path) => {
        const value = this.state.value;
        let activeObject = value;
        for (const i in path) {
            activeObject = activeObject[path[i]];
        }

        activeObject.push({
            key: 'newItem',
            type: 'string',
            value: '',
            subitems: []
        });
        this.setState({ value: value });
        this.updateValue(value);
    };

    /**
     * Remove element
     * @param {*} path
     */
    removeItem = (path, index) => {
        const value = this.state.value;
        let newValue = value;
        for (const i in path) {
            newValue = newValue[path[i]];
        }
        newValue.splice(index, 1);
        this.setState({ value: value });
        this.updateValue(newValue);
    };

    /**
     * Change value of field
     * @param {*} path
     * @param {*} fieldValue
     */
    onChangeField = (type, path, fieldValue) => {
        const value = this.state.value;
        if (type == 'key') {
            fieldValue = fieldValue.replace(/[\W_]+/g, '');
        }

        const newValue = set(value, path, fieldValue);
        this.setState({ value: newValue });
        this.updateValue(newValue);
    };

    /**
     * Set new value
     * @param {*} newValue
     */
    updateValue = (newValue) => {
        const outputObject = {};
        this.loopValues(outputObject, newValue);
        this.props.onChange(JSON.stringify(outputObject));
    };

    /**
     * Loop through the list
     * @param {*} outputObject
     * @param {*} value
     * @param {*} type
     */
    loopValues = (outputObject, value, type = 'object') => {
        for (const i in value) {
            const activeItem = value[i];
            let activeKey = activeItem.key;
            if (type == 'array') {
                activeKey = i;
            }

            if (activeItem.type == 'string') {
                outputObject[activeKey] = activeItem.value;
            } else if (activeItem.type == 'object') {
                outputObject[activeKey] = {};
                this.loopValues(outputObject[activeKey], activeItem.subitems);
            } else if (activeItem.type == 'array') {
                outputObject[activeKey] = [];
                this.loopValues(outputObject[activeKey], activeItem.subitems, 'array');
            }
        }
    };

    /**
     * Render an individual item from the list
     * @param {*} path
     * @param {*} data
     * @param {*} type
     */
    renderObject = (path, data = [], type = 'object') => {
        return (
            <div className="form-flow__field__json__visual__block">
                {data.map((item, index) => (
                    <div className="form-flow__field__json__visual__block__item" key={index}>
                        {(item.type == 'object' || item.type == 'array') && (
                            <div>
                                <div className="form-flow__field__json__visual__block__item__object">
                                    {path.length > 0 && <Icon>subdirectory_arrow_right</Icon>}

                                    {type != 'array' && (
                                        <TextField
                                            value={item.key}
                                            variant="outlined"
                                            margin="dense"
                                            style={{ margin: 0 }}
                                            onChange={(e) => this.onChangeField('key', [...path, index, 'key'], e.target.value)}
                                        />
                                    )}

                                    <div className="form-flow__field__json__visual__block__item__select">
                                        <Select
                                            native
                                            value={item.type}
                                            variant="outlined"
                                            margin="dense"
                                            onChange={(e) => this.onChangeField('type', [...path, index, 'type'], e.target.value)}>
                                            <option value="string">{Translation.get('formflow.JSON.value', 'common')}</option>
                                            <option value="array">{Translation.get('formflow.JSON.list', 'common')}</option>
                                            <option value="object">{Translation.get('formflow.JSON.subobject', 'common')}</option>
                                        </Select>
                                    </div>

                                    <div className="form-flow__field__json__visual__block__item__remove">
                                        <IconButton size="small" onClick={() => this.removeItem([...path], index)}>
                                            <Icon>delete</Icon>
                                        </IconButton>
                                    </div>
                                </div>

                                <div className="form-flow__field__json__visual__block__item__sub">
                                    {this.renderObject([...path, index, 'subitems'], item.subitems, item.type)}
                                </div>
                            </div>
                        )}

                        {item.type == 'string' && (
                            <div className="form-flow__field__json__visual__block__item__value">
                                {path.length > 0 && <Icon>subdirectory_arrow_right</Icon>}

                                {type != 'array' && (
                                    <TextField
                                        value={item.key}
                                        variant="outlined"
                                        margin="dense"
                                        style={{ margin: 0 }}
                                        onChange={(e) => this.onChangeField('key', [...path, index, 'key'], e.target.value)}
                                    />
                                )}

                                <div className="form-flow__field__json__visual__block__item__select">
                                    <Select
                                        native
                                        value={item.type}
                                        variant="outlined"
                                        margin="dense"
                                        onChange={(e) => this.onChangeField('type', [...path, index, 'type'], e.target.value)}>
                                        <option value="string">{Translation.get('formflow.JSON.value', 'common')}</option>
                                        <option value="array">{Translation.get('formflow.JSON.list', 'common')}</option>
                                        <option value="object">{Translation.get('formflow.JSON.subobject', 'common')}</option>
                                    </Select>
                                </div>

                                <div className="form-flow__field__json__visual__block__item__dots"> : </div>
                                <TextField
                                    value={item.value}
                                    variant="outlined"
                                    margin="dense"
                                    style={{ margin: 0 }}
                                    onChange={(e) => this.onChangeField('value', [...path, index, 'value'], e.target.value)}
                                />

                                <div className="form-flow__field__json__visual__block__item__remove">
                                    <IconButton size="small" onClick={() => this.removeItem([...path], index)}>
                                        <Icon>delete</Icon>
                                    </IconButton>
                                </div>
                            </div>
                        )}
                    </div>
                ))}

                <div className="form-flow__field__json__visual__block__item_add">
                    <Button variant="contained" onClick={() => this.addItem(path)} startIcon={<Icon>add</Icon>}>
                        {Translation.get('formflow.JSON.addItem', 'common')}
                    </Button>
                </div>
            </div>
        );
    };

    render() {
        const { value = [] } = this.state;
        return <div className="form-flow__field__json__visual">{this.renderObject([], value)}</div>;
    }
}
