import React, { useState, useMemo, useEffect } from 'react';
import { BrickChannel, FileExt, FileType } from 'components/bricks/types/brick.type';
import Dialog, { ContentWithSidebarWrapper } from 'components/ui-components/Dialog';
import GenericFilter from 'components/ui-base/GenericFilter';
import { getFilteredAvailableChannels } from 'components/bricks/helpers/brick-filters.helpers';
import Translation from 'components/data/Translation';
import Resources from 'components/data/Resources/components';
import { SidebarItem } from 'components/ui-components/Dialog/components/sidebar-items';
import ConfirmDialog from 'components/ui-components/ConfirmDialog';
import { useResources } from 'components/data/Resources/hooks/useResources';
import PresetsTable from './presets-table';
import { CustomPresetsData, Preset } from '../../../../../types/preset';
import { PresetFilters } from '../types/PresetInfo.type';
import filterSetupMain from '../constants/filter-setup';
import { filterPresets } from '../utils/presets-filter';
import allPresetChannels from '../constants/channels';
import RestrictionAlertDialog from '../../creative-input/components/alert-dialog';
import PresetHelpers from '../helpers/presets.helper';
import CustomPresetDialog from './custom-presets-dialog';

import '../styles/main.scss';

interface Props {
    value: Preset[];
    multiple: boolean;
    onClose: () => void;
    onSelect: (selectedPresets: Preset[]) => void;
}

/**
 * Dialog to select one or multiple presets that one asset or a list of assets must meet
 */
const PresetsDialog = ({ value = [], multiple = false, onClose, onSelect }: Props) => {
    const [presets, setPresets] = useState<Preset[]>([]);
    const { resources, loading } = useResources<{ bricks_default_presets: Preset[] }>(['bricks_default_presets']);
    const [selectedPresets, setSelectedPresets] = useState<Preset[]>(value);
    const [activeChannel, setActiveChannel] = useState<BrickChannel>();
    const [openInfoDialog, setOpenInfoDialog] = useState<boolean>(false);
    const [openCustomDialog, setOpenCustomDialog] = useState<boolean>(false);
    const [focusedPreset, setFocusedPreset] = useState<Preset>();
    const [filterSetup, setFilterSetup] = useState<any>(filterSetupMain);
    const [filters, setFilters] = useState<any>({});
    const [defaultPresets, setDefaultPresets] = useState<Preset[]>([]);
    const [customPresets, setCustomPresets] = useState<Preset[]>([]);
    const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = useState<boolean>(false);
    const [presetToDelete, setPresetToDelete] = useState<Preset | null>(null);
    const [editPreset, setEditPreset] = useState<Preset | undefined>();

    let defaultAssetType: FileType;
    let defaultFileExtension: FileExt;

    switch (activeChannel) {
        case 'video':
            defaultAssetType = 'video';
            defaultFileExtension = 'mp4';
            break;
        case 'display':
            defaultAssetType = 'zip';
            defaultFileExtension = 'zip';
            break;
        default:
            defaultAssetType = 'image';
            defaultFileExtension = 'jpg';
            break;
    }

    // Load custom presets and base presets using resources
    useEffect(() => {
        if (!loading) {
            setDefaultPresets(resources?.bricks_default_presets || []);
        }
        const customPresetsData = Resources.get('custom_presets') as CustomPresetsData;

        if (customPresetsData?.presets?.add) {
            setCustomPresets(customPresetsData.presets.add);
        }
    }, [resources, loading]);

    const handleDeletePresetClick = (preset: Preset) => {
        setPresetToDelete(preset);
        setShowDeleteConfirmDialog(true);
    };

    const confirmDeletePreset = async () => {
        if (presetToDelete) {
            await deleteCustomPreset(presetToDelete);
        }
        setShowDeleteConfirmDialog(false);
        setPresetToDelete(null);
    };

    const cancelDeletePreset = () => {
        setShowDeleteConfirmDialog(false);
        setPresetToDelete(null);
    };

    // Combine all presets (base + custom) and sort them once
    const combinedPresets = useMemo(() => {
        const uniquePresets = [...defaultPresets, ...customPresets];
        return uniquePresets
            .filter((preset, index, self) => index === self.findIndex((p) => p.identifier === preset.identifier))
            .sort((a, b) => a.title.localeCompare(b.title));
    }, [defaultPresets, customPresets]);

    // Set initial presets list based on the combinedPresets
    useEffect(() => {
        if (activeChannel === 'all') {
            setPresets(combinedPresets);
        }
    }, [combinedPresets, activeChannel]);

    // Save a new custom preset and update the list
    const saveCustomPreset = async (newPreset: Preset) => {
        const updatedCustomPresets = await PresetHelpers.saveCustomPreset(newPreset, customPresets);
        setCustomPresets(updatedCustomPresets);

        // Update the combinedPresets with the new custom preset
        const updatedCombinedPresets = [...combinedPresets, newPreset];

        // Automatically select the new preset if `multiple` is true
        if (multiple) {
            setSelectedPresets((prevSelected) => {
                // Avoid duplication
                if (!prevSelected.find((preset) => preset.identifier === newPreset.identifier)) {
                    return [...prevSelected, newPreset];
                }
                return prevSelected;
            });
        }

        // Apply filtering based on the active channel
        if (activeChannel === ('custom' as BrickChannel)) {
            setPresets(updatedCustomPresets);
        } else if (activeChannel) {
            // Filter out the custom preset for other channels
            const filteredPresets = filterPresets(updatedCombinedPresets, filters, activeChannel);
            setPresets(filteredPresets);
        }
    };

    const deleteCustomPreset = async (presetToDelete: Preset) => {
        const updatedCustomPresets = await PresetHelpers.deleteCustomPreset(presetToDelete, customPresets);
        setCustomPresets(updatedCustomPresets);

        const updatedCombinedPresets = combinedPresets.filter((preset) => preset.identifier !== presetToDelete.identifier);

        if (activeChannel === ('custom' as BrickChannel)) {
            setPresets(updatedCustomPresets);
        } else {
            setPresets(updatedCombinedPresets);
        }
    };

    const filteredAvailableChannels = useMemo(() => {
        const channels = getFilteredAvailableChannels(defaultPresets, ['group'], allPresetChannels);

        const customChannel: SidebarItem = {
            title: 'Custom Presets',
            description: 'Presets created by the user',
            icon: 'tune',
            type: 'custom',
            badgeCount: selectedPresets.filter((preset) => preset.custom).length
        };

        const channelsWithBadgeCount = channels.map((channel) => {
            // If the channel type is "all", use the total selected presets count
            const count = channel.type === 'all' ? selectedPresets.length : selectedPresets.filter((preset) => preset.channel === channel.type).length;

            return {
                ...channel,
                badgeCount: count
            };
        });

        return [...channelsWithBadgeCount, customChannel];
    }, [defaultPresets, selectedPresets, customPresets]);

    /**
     * filter presets based on active sidebar item
     * @param channel
     */
    const filterPresetsBasedOnActiveSidebar = (selectedSideBarItem: string) => {
        setActiveChannel(selectedSideBarItem as BrickChannel);

        // Handle the custom presets filter
        if (selectedSideBarItem === 'custom') {
            setPresets(customPresets);
        } else {
            // Filter presets by the selected channel or criteria
            const filteredPresets = filterPresets(combinedPresets, filters, selectedSideBarItem as BrickChannel);
            setPresets(filteredPresets);

            const filterSetupCopy = PresetHelpers.modifyPlatformFiltersBasedOnChannel(combinedPresets, selectedSideBarItem, filterSetup);

            // Add filters in first render
            if (!activeChannel) {
                const minWidthFilter = PresetHelpers.getFilterForRestriction(combinedPresets, 'minWidth', 'Min width', 'selectMultiple');
                const minHeightFilter = PresetHelpers.getFilterForRestriction(combinedPresets, 'minHeight', 'Min height', 'selectMultiple');
                const minAspectRatioFilter = PresetHelpers.getFilterForRestriction(combinedPresets, 'minAspectRatio', 'Min aspect ratio', 'selectMultiple');
                const maxAspectRatioFilter = PresetHelpers.getFilterForRestriction(combinedPresets, 'maxAspectRatio', 'Max aspect ratio', 'selectMultiple');
                setFilterSetup([...filterSetupCopy, minWidthFilter, minHeightFilter, minAspectRatioFilter, maxAspectRatioFilter]);
            } else {
                setFilterSetup(filterSetupCopy);
            }
        }

        const filtersCopy = { ...filters };
        if (selectedSideBarItem !== 'all' && filtersCopy.platform) {
            // Delete the platform filters if the channel is not 'all'
            if (filtersCopy.platform) {
                delete filtersCopy.platform;
                setFilters(filtersCopy);
            }
        }
    };

    /**
     * Selects preset
     */
    const handleConfirm = () => {
        onSelect(selectedPresets);
    };

    /**
     * Modify quantity of preset
     * @param preset
     * @param quantity
     */
    const modifyQuantityOfPreset = (preset: Preset, quantity: number) => {
        // Sets the preset as focused
        if (focusedPreset?.identifier !== preset.identifier) setFocusedPreset(preset);

        let presetsCopy = [...selectedPresets];
        let newPresets: Preset[] = Array(quantity).fill(preset);
        presetsCopy = presetsCopy.filter((selectedPreset) => selectedPreset.identifier !== preset.identifier);

        newPresets = [...newPresets, ...presetsCopy];
        setSelectedPresets(newPresets);
        if (!multiple) onSelect(newPresets);
    };

    const newPresetsCount = useMemo(() => {
        const countMap = (presets: Preset[]) =>
            presets.reduce((map, preset) => map.set(preset.identifier, (map.get(preset.identifier) || 0) + 1), new Map<string, number>());

        const selectedMap = countMap(selectedPresets);
        const valueMap = countMap(value);

        let count = 0;
        for (const [id, selectedCount] of selectedMap) {
            const initialCount = valueMap.get(id) || 0;
            count += Math.max(selectedCount - initialCount, 0);
        }
        return count;
    }, [selectedPresets, value]);

    const isConfirmDisabled = newPresetsCount === 0;

    /**
     * Gets confirm text
     * @returns Confirm text
     */
    const getConfirmText = () => {
        if (newPresetsCount < 1) return Translation.get('addPreset', 'bricks');
        return `${Translation.get('actions.add', 'common')} ${newPresetsCount} ${Translation.get('presets', 'bricks')}`;
    };

    /**
     * Handles clicking the button for more info for a preset
     * @param event
     * @param preset
     */
    const handleMoreInfoButtonClick = (event, preset: Preset): void => {
        event.stopPropagation();
        setFocusedPreset(preset);
        setOpenInfoDialog(true);
    };

    const handleEditPreset = (event: React.MouseEvent, preset: Preset): void => {
        event.stopPropagation();
        setEditPreset(preset);
        setOpenCustomDialog(true);
    };

    /**
     * Handle change of filters and filters the presets
     * @param newFilters
     */
    const handleChangeFilters = (newFilters: PresetFilters) => {
        const filteredPresets = filterPresets(combinedPresets, newFilters, activeChannel ? activeChannel : 'all');
        setFilters(newFilters);
        setPresets(filteredPresets);
    };

    const toggleDialog = (isOpen: boolean) => {
        if (!isOpen) {
            setEditPreset(undefined);
        }
        setOpenCustomDialog(isOpen);
    };

    return (
        <>
            <Dialog
                open
                title={Translation.get('presetDialog.title', 'bricks')}
                onClose={onClose}
                onConfirm={multiple ? handleConfirm : undefined}
                confirmText={getConfirmText()}
                showCancel
                confirmButtonDisabled={isConfirmDisabled}
                fixedHeightPaperScrollPaper={true}>
                <ContentWithSidebarWrapper
                    classes={{ root: 'presets-dialog', content: 'presets-dialog__content' }}
                    sidebarItems={filteredAvailableChannels}
                    defaultActiveSidebarItem={filteredAvailableChannels[0]?.type}
                    onChangeActiveSidebarItem={filterPresetsBasedOnActiveSidebar}>
                    <div className={'presets-dialog__generic-filter'}>
                        <GenericFilter
                            buttonVariant="text"
                            inDialog
                            ignoreInFiltersCount={['searchTerm']}
                            classes={{
                                bar: 'presets-dialog__generic-filter__bar',
                                chips: 'presets-dialog__generic-filter__chips',
                                search: 'presets-dialog__generic-filter__search'
                            }}
                            searchField={true}
                            filterSetup={filterSetup}
                            filters={filters}
                            onMutation={(newFilters) => handleChangeFilters(newFilters)}
                        />
                    </div>
                    <PresetsTable
                        presets={presets}
                        multiple={multiple}
                        modifyQuantityOfPreset={modifyQuantityOfPreset}
                        selectedPresets={selectedPresets}
                        focusedPreset={focusedPreset}
                        setFocusedPreset={setFocusedPreset}
                        handleMoreInfoButtonClick={handleMoreInfoButtonClick}
                        handleDeletePreset={handleDeletePresetClick}
                        handleEditPreset={handleEditPreset}
                        toggleDialog={toggleDialog}
                    />
                </ContentWithSidebarWrapper>
            </Dialog>
            {showDeleteConfirmDialog && (
                <ConfirmDialog
                    open
                    title={Translation.get('confirmDialog.title', 'common', { item: 'this preset' })}
                    description={Translation.get('confirmDialog.description', 'common')}
                    onClose={cancelDeletePreset}
                    onConfirm={confirmDeletePreset}
                    confirmText={Translation.get('actions.remove', 'common')}
                    confirmColor="error"
                />
            )}
            {openCustomDialog && (
                <CustomPresetDialog
                    open
                    onClose={() => toggleDialog(false)}
                    onSave={saveCustomPreset}
                    preset={editPreset}
                    defaultAssetType={defaultAssetType}
                    defaultFileExtension={defaultFileExtension}
                />
            )}
            {focusedPreset && openInfoDialog && <RestrictionAlertDialog severity="info" onClose={() => setOpenInfoDialog(false)} preset={focusedPreset} />}
        </>
    );
};

export default PresetsDialog;
