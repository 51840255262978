import { v4 as uuidv4 } from 'uuid';
import { CreativeV2, CreativeV2CustomUpload, CreativeV2Template } from 'components/creatives-v2/components/creative-editor/types/creativeV2.type';
import { TemplateAsset } from 'components/template-management/types/template-management.type';
import cloneDeep from 'helpers/cloneDeep';
import { isTemplateDesignerTemplate } from 'components/template-management/utilities';

const createCustomUploadCreative = (customUploadData: any): CreativeV2CustomUpload => {
    const creative: CreativeV2CustomUpload = {
        id: uuidv4(),
        title: customUploadData.title,
        type: 'customUpload',
        data: {
            ...customUploadData,
            width: customUploadData.width || customUploadData.assetData?.width,
            height: customUploadData.height || customUploadData.assetData?.height
        }
    };

    return creative;
};

const createTemplateCreative = (selectedTemplate: TemplateAsset): CreativeV2Template => {
    const thumbnail = (() => {
        if (selectedTemplate.data.image && selectedTemplate.data.image.length > 0) {
            return selectedTemplate.data.image;
        }

        if (selectedTemplate.data.thumbnail && selectedTemplate.data.thumbnail[0]) {
            return selectedTemplate.data.thumbnail[0]?.url;
        }

        return undefined;
    })();

    const templateInput = (() => {
        if (selectedTemplate.data.defaultData) {
            return cloneDeep(selectedTemplate.data.defaultData);
        }

        return {};
    })();

    const creative: CreativeV2Template = {
        id: uuidv4(),
        title: selectedTemplate.title,
        type: 'template',
        thumbnail,
        data: {
            templateType: selectedTemplate.subType,
            templateIdentifier: selectedTemplate.data.identifier,
            settings: {
                frames: 1
            },
            templateInput
        }
    };

    // set the active formats to the creative
    if (isTemplateDesignerTemplate(selectedTemplate)) {
        if (selectedTemplate.data.templateSetup && selectedTemplate.data.templateSetup.formats) {
            creative.data.settings.activeFormats = cloneDeep(selectedTemplate.data.templateSetup.formats.map((format) => format.key));
        }
    } else {
        if (selectedTemplate.data.settings && selectedTemplate.data.settings.formats) {
            creative.data.settings.activeFormats = cloneDeep(selectedTemplate.data.settings.formats);
        }
    }

    return creative;
};

const createCreativeBasedOnCampaignAsset = (customUploadData: any): CreativeV2 => {
    switch (customUploadData.type) {
        case 'template': {
            //TODO: creatives v2
            return {
                id: uuidv4(),
                title: customUploadData.title,
                type: 'template',
                data: {
                    templateType: customUploadData.subType,
                    templateIdentifier: customUploadData.data.identifier,
                    settings: {
                        frames: 1
                    }
                }
            };
        }
        default:
            return createCustomUploadCreative(customUploadData);
    }
};

export { createCustomUploadCreative, createTemplateCreative, createCreativeBasedOnCampaignAsset };
