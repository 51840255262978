import React, { useContext } from 'react';
import classNames from 'classnames';
import { DarkContext } from 'components/ui-components/Dark';
import '../styles/working-area-container.scss';

interface Props {
    noMargin?: boolean;
    noScroll?: boolean;
    children: React.ReactNode | React.ReactNode[];
}

const FullScreenOverlayWorkingAreaContainer = ({ noMargin, noScroll, children }: Props) => {
    const dark = useContext(DarkContext);
    return (
        <div
            className={classNames('full-screen-overlay-working-area-container', {
                'full-screen-overlay-working-area-container--dark': dark,
                'full-screen-overlay-working-area-container--no-margin': noMargin,
                'full-screen-overlay-working-area-container--no-scroll': noScroll
            })}>
            {children}
        </div>
    );
};

export default FullScreenOverlayWorkingAreaContainer;
