import LayerProperties from '../types/layerProperties.type';
import Template from '../types/template.type';
import { MigrateFunction } from './types/migrateFunction.type';

/**
 * 28-05-2024 - 11:00
 * Convert brand guide font family source to brand guide font key.
 * @param template - The whole template.
 * @returns New template
 */
const convertBrandGuideFonts: MigrateFunction = (template: Template): Template => {
    Object.keys(template.layerProperties).forEach((formatKey) => {
        Object.keys(template.layerProperties[formatKey]).forEach((frameTypeKey) => {
            Object.keys(template.layerProperties[formatKey][frameTypeKey]).forEach((layerKey) => {
                if (layerKey === 'properties') return;
                const layerProperties = template.layerProperties[formatKey][frameTypeKey][layerKey] as LayerProperties;

                // TODO: DELETE AFTER WE CREATED A WRAPPER
                if (Array.isArray(layerProperties) || Array.isArray(layerProperties.properties) || !layerProperties || !layerProperties.properties) return;
                if (!('textStyling' in layerProperties.properties)) return;

                const normalFontSource = layerProperties.properties.textStyling?.normal?.fontSource;
                if (normalFontSource && normalFontSource.includes('brandGuide')) {
                    const normalFontFamily = layerProperties.properties.textStyling?.normal?.fontFamily;
                    const brandFont = template.brandGuide?.fonts.find((font) => font.key === normalFontFamily || font.value === normalFontFamily);

                    if (brandFont) {
                        layerProperties.properties.textStyling.normal.fontFamily = brandFont.key;
                    }
                }

                const highlightFontSource = layerProperties.properties.textStyling?.highlighted?.fontSource;
                if (highlightFontSource && highlightFontSource.includes('brandGuide')) {
                    const highlightFontFamily = layerProperties.properties.textStyling?.highlighted?.fontFamily;
                    const brandFont = template.brandGuide?.fonts.find((font) => font.key === highlightFontFamily || font.value === highlightFontFamily);

                    if (brandFont) {
                        layerProperties.properties.textStyling.highlighted.fontFamily = brandFont.key;
                    }
                }
            });
        });
    });

    return template;
};

export default convertBrandGuideFonts;
