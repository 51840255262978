import get from 'lodash/get';
import merge from 'lodash/merge';
import mergeWith from 'lodash/mergeWith';
import ViewState from 'components/data/ViewState';
import { TDTemplateAsset } from 'components/template-management/types/template-management.type';
import { isAMV2Enabled } from 'components/template-management/utilities/isAmV2Enabled';
import cssStringToObject from '../components/canvas/utils/cssStringToObject';
import emptyTemplate from '../config/empty-template';
import { DEFAULT_FORMAT_HOVER_TRANSITION, DEFAULT_FORMAT_ORDER, DEFAULT_LAYER_HOVER_TRANSITION, OPEN_ON_MOUNT_KEY } from '../constants';
import Layer from '../types/layer.type';
import Template, { BannerLoopOptions, CurrentView, ExportForPlatform, RightSidebarTab } from '../types/template.type';
import { SHOW_LAYOUT_GRID, SHOW_RULERS, SHOW_TIMELINE } from '../config/defaultValues';
import cloneDeep from '../utils/cloneDeep';
import { StylingHelpers } from './styling.helpers';
import { FontHelpers } from './font.helpers';
import TemplateExport from '../types/templateExport.type';
import { TemplateVersionHelpers } from './template-version.helpers';
import { CanvasHelpers } from './canvas.helpers';
import FrameTypeHelpers from './frame-type.helpers';
import LayerProperties from '../types/layerProperties.type';
import { LayerHover } from '../types/hover.type';
import { getTemplateData } from './data.helpers';
import { TimelineHelpers } from './timeline.helpers';
import FormatHelpers from './format.helpers';

class TemplateDesignerHelpers {
    /**
     * Parses data coming from the api, to data Template Designer can read.
     * @param data - Data from the api.
     * @returns Template Designer data.
     */
    static parseTemplateDesignerData = (data: TDTemplateAsset): Template => {
        const showRulers = (() => {
            const value = ViewState.get('templateDesigner', 'showRulers');
            if (value === undefined) return SHOW_RULERS;
            return value;
        })();
        const showLayoutGrid = (() => {
            const value = ViewState.get('templateDesigner', 'showLayoutGrid');
            if (value === undefined) return SHOW_LAYOUT_GRID;
            return value;
        })();
        const showTimeline = (() => {
            const value = ViewState.get('templateDesigner', 'timelineHeight');
            if (value === undefined) return SHOW_TIMELINE;
            return value > 60;
        })();

        const brands = (() => {
            if (isAMV2Enabled()) {
                return data.labels?.brands ?? [];
            }
            return data.data?.brand ? [data.data?.brand ?? ''] : [];
        })();

        if (!data.data.templateSetup || Object.keys(data.data.templateSetup).length === 0) {
            return {
                ...emptyTemplate,
                id: data._id,
                templateData: {
                    ...emptyTemplate.templateData,
                    title: data.title ?? emptyTemplate.templateData.title,
                    type: data.subType,
                    identifier: data.data.identifier,
                    settings: {
                        ...(data.data.settings ? data.data.settings : emptyTemplate.templateData.settings),
                        dynamicFrames: data.subType === 'displayAdDesigned'
                    },
                    ...(isAMV2Enabled()
                        ? {
                              version: data.version || 0,
                              releasedBy: data.releasedBy,
                              releasedAt: data.releasedAt,
                              releasedVersion: data.releasedVersion,
                              createdDate: data.createdAt,
                              updatedDate: data.updatedAt,
                              status: data.status === 'available' ? 'available' : 'draft',
                              brands
                          }
                        : {})
                },
                view: {
                    ...emptyTemplate.view,
                    showRulers,
                    showLayoutGrid,
                    showTimeline
                }
            };
        }

        const template: Template = {
            ...data.data.templateSetup,
            id: data._id,
            url: emptyTemplate.url,
            dynamicLayers: data.data.templateSetup.dynamicLayers ?? emptyTemplate.dynamicLayers,
            templateData: {
                title: data.title,
                identifier: data.data.identifier,
                description: data.data.description || null,
                brands: brands,
                type: data.subType,
                status: data.status === 'available' ? 'available' : 'draft',
                settings: data.data.settings ?? emptyTemplate.templateData.settings,
                image: data.data.image || '',
                version: data.version || 0,
                releasedBy: data.releasedBy,
                releasedAt: data.releasedAt,
                releasedVersion: data.releasedVersion,
                createdDate: data.createdAt,
                updatedDate: data.updatedAt,
                customData: data.customData,
                public: data.public,
                thumbnail: data.data.thumbnail
            },
            designerSettings: {
                ...data.data.templateSetup.designerSettings,
                disableBase: data.data.templateSetup.designerSettings.disableBase ?? false,
                customFormats: data.data.templateSetup.designerSettings.customFormats || [],
                autoSave: data.status === 'available' ? false : data.data.templateSetup.designerSettings.autoSave ?? false,
                enableAnimations:
                    data.data.templateSetup.designerSettings.enableAnimations === undefined ? true : data.data.templateSetup.designerSettings.enableAnimations,
                enableLottie: data.data.templateSetup.designerSettings.enableLottie || false,
                exportForPlatform: data.data.templateSetup.designerSettings.exportForPlatform || ExportForPlatform.General,
                positionByLanguage: data.data.templateSetup.designerSettings.positionByLanguage || false,
                customCSS: data.data.templateSetup.designerSettings.customCSS || false,
                customJS: data.data.templateSetup.designerSettings.customJS || false,
                gridSize: data.data.templateSetup.designerSettings.gridSize === undefined ? 8 : data.data.templateSetup.designerSettings.gridSize,
                bannerLoop: data.data.templateSetup.designerSettings.bannerLoop || BannerLoopOptions.NoLoop,
                highlightedCharacter: data.data.templateSetup.designerSettings.highlightedCharacter || '*',
                backgroundbackupImageWaitTime: data.data.templateSetup.designerSettings.backgroundbackupImageWaitTime
            },
            view: {
                frameType: data.data.templateSetup.frameTypes[data.data.templateSetup.frameTypes.length - 1].key,
                currentView: CurrentView.Design,
                showRulers,
                showLayoutGrid,
                showTimeline,
                hideLeftSidebar: false,
                showLayerPanel: false,
                showFormatManagementDialog: false,
                testEnvironment: {
                    open: false,
                    uuid: '',
                    dataModel: ''
                },
                showVersionHistory: false,
                showComments: false,
                showTab: RightSidebarTab.LayerEdit,
                showHelpPanel: false
            },
            state: {
                isPlaying: false,
                openItemTree: false,
                isUploading: false,
                selectedLayers: [],
                activeAnimations: [],
                displayFormats: (() => {
                    const maxVisibibleFormats = FormatHelpers.getMaxVisibleFormats();
                    const displayFormats =
                        data.data.templateSetup.state?.displayFormats ?? data.data?.templateSetup?.formats?.map((format) => format.key) ?? [];

                    return displayFormats.slice(0, maxVisibibleFormats);
                })(),
                conflictingAnimations: data.data.templateSetup.state?.conflictingAnimations || null,
                largeMedia: data.data.templateSetup.state?.largeMedia,
                selectedFormats: ['general'],
                selectedFormatSets: data.data.templateSetup.state?.selectedFormatSets ?? [],
                formatOrder: data.data.templateSetup.state?.formatOrder ?? DEFAULT_FORMAT_ORDER,
                restoreVersion: null,
                timelineHeight: 0,
                leftSidebarWidth: 0,
                formatsWithAudio: data.data.templateSetup.formats.length ? [data.data.templateSetup.formats[0].key] : [], // Only play the audio of first format (if available). Otherwise multiple formats will be playing audio.
                changedAfterSave: false,
                lastUsedFont: data.data.templateSetup.state?.lastUsedFont
            },
            templateSetup: {
                ...(data.data.templateSetup.templateSetup ?? {}),
                templateVersion:
                    (data.data.templateSetup && data.data.templateSetup.templateSetup && data.data.templateSetup.templateSetup.templateVersion) || '1'
            }
        };

        // /**
        //  * We need to add the default values to the template if they are not present.
        //  * MongoDB removes the empty objects from the data.
        //  */
        // if (template.dataVariables === undefined) {
        //     template.dataVariables = emptyTemplate.dataVariables;
        // }

        // if (template.layers === undefined) {
        //     template.layers = emptyTemplate.layers;
        // }

        // if (template.formats === undefined) {
        //     template.formats = emptyTemplate.formats;
        // }

        // if (template.formats === undefined) {
        //     template.formats = emptyTemplate.formats;
        // }

        // template.frameTypes.forEach((frameType) => {
        //     if (template.interfaceSetup[frameType.key] === undefined) {
        //         template.interfaceSetup[frameType.key] = [];
        //     }

        //     if (template.dataVariables[frameType.key] === undefined || Array.isArray(template.dataVariables[frameType.key])) {
        //         template.dataVariables[frameType.key] = {};
        //     }

        //     if (template.layers[frameType.key] === undefined || Array.isArray(template.layers[frameType.key])) {
        //         template.layers[frameType.key] = [];
        //     }
        // });

        return template;
    };

    /**
     * Get the brand guide and store the colors and fonts in the store.
     * Load in the fonts that are used.
     * @param template - The whole template.
     * @returns An export of the template.
     */
    static getTemplateExport = (template: Template): TemplateExport => {
        const getLayerStyles = (layers, frameType) => {
            return layers.reduce((acc, layer) => {
                const {
                    properties: generalProps,
                    visibility = TimelineHelpers.getDefaultVisibility(),
                    animations: generalAnimations
                } = template.layerProperties.general[frameType][layer.key] as LayerProperties;
                const generalProperties = cloneDeep(generalProps);

                acc[layer.key] = {
                    formats: template.formats.reduce((a, format) => {
                        const formatProps = cloneDeep(get(template.layerProperties[format.key][frameType], `${layer.key}`, {}));
                        const formatProperties = cloneDeep(formatProps.properties);

                        if (formatProperties && formatProperties.textStyling) {
                            if (formatProperties.textStyling.normal && Array.isArray(formatProperties.textStyling.normal)) {
                                formatProperties.textStyling.normal = {};
                            }
                            if (formatProperties.textStyling.highlighted && Array.isArray(formatProperties.textStyling.highlighted)) {
                                formatProperties.textStyling.highlighted = {};
                            }
                        }

                        const formatStyle = {
                            ...StylingHelpers.getLayerStyle(
                                // TODO: replace mergeWith with merge once we have a script to remove empty arrays.
                                mergeWith(
                                    cloneDeep(generalProperties),
                                    merge({ measurePoint: generalProperties.measurePoint }, cloneDeep(formatProperties)),
                                    (general, format) => {
                                        if (Array.isArray(format) && format.length === 0) {
                                            return general;
                                        }
                                    }
                                ),
                                merge(cloneDeep(generalAnimations), cloneDeep(formatProps.animations)),
                                layer.type,
                                format
                            ),
                            textStyling: {
                                // Don't include normal because that is inside the root.
                                highlighted: StylingHelpers.getTextStyle(
                                    merge(cloneDeep(generalProperties), cloneDeep(formatProperties)),
                                    {},
                                    'highlighted',
                                    format
                                )
                            }
                        };

                        // Remove empty textStyling.
                        if (
                            formatStyle.textStyling.normal &&
                            Object.keys(formatStyle.textStyling.normal).length === 0 &&
                            Object.keys(formatStyle.textStyling.highlighted).length === 0
                        ) {
                            delete formatStyle.textStyling;
                        }

                        if (formatStyle.textStyling) {
                            delete formatStyle.textStyling.highlighted.display;
                            delete formatStyle.textStyling.highlighted.alignItems;
                        }

                        const customCSS = template.designerSettings.customCSS && get(formatProps, 'properties.customCSS', '');
                        if (customCSS) {
                            formatStyle.customCSS = customCSS.replaceAll(';', ' !important;');
                        }

                        a[format.key] = formatStyle;
                        return a;
                    }, {}),
                    variables: template.dataVariables[frameType]?.[layer.key] ?? {}
                };

                if (Object.keys(acc[layer.key].variables).length === 0) {
                    delete acc[layer.key].variables;
                }

                if (visibility?.[0] !== 0 || visibility?.[1] !== 1) {
                    acc[layer.key].visibility = visibility;
                }

                if (template.designerSettings.customCSS && generalProps.customCSS) {
                    acc[layer.key].customCSS = generalProps.customCSS.replaceAll(';', ' !important;');
                }

                if (template.templateData.type === 'displayAdDesigned') {
                    const generalHoverData = template.layerProperties.general[frameType][layer.key].hover as LayerHover;

                    template.formats.forEach((format) => {
                        const formatHoverData = get(template.layerProperties, `${format.key}.${frameType}.${layer.key}.hover`, {});
                        const hoverData = merge(cloneDeep(generalHoverData), cloneDeep(formatHoverData));

                        const duration = (hoverData?.transition.duration ?? 0) / 1000;
                        const timingFunction = hoverData?.transition.timingFunction;
                        const delay = (hoverData?.transition.delay ?? 0) / 1000;
                        const transitionProps = `${duration}s ${timingFunction} ${delay}s`;

                        let transition = `opacity ${transitionProps}, background-color ${transitionProps}, border-color ${transitionProps}, color ${transitionProps}, transform ${transitionProps}, left 0s 0s, top 0s 0s, right 0s 0s, bottom 0s 0s, width 0s 0s, height 0s 0s`;
                        // Set opacity hover duration to 0s
                        if (!hoverData?.properties?.enableProperties?.opacity) {
                            transition += ', opacity 0s 0s';
                        }
                        // Set transform hover duration to 0s
                        if (hoverData) {
                            const { scale, rotation, translateX, translateY } = hoverData.properties;
                            if (scale === 1 && !rotation && !translateX && !translateY) {
                                transition += ', transform 0s 0s';
                            }
                        }

                        if (transition !== DEFAULT_LAYER_HOVER_TRANSITION) {
                            if (acc[layer.key].style === undefined) {
                                acc[layer.key].style = {};
                            }
                        }

                        const generalProperties = template.layerProperties.general[frameType][layer.key];
                        const formatProperties = get(template.layerProperties, `${format.key}.${frameType}.${layer.key}`, {});
                        const properties = merge(cloneDeep(generalProperties), cloneDeep(formatProperties));
                        const style = StylingHelpers.getLayerStyle(properties, layer.type);
                        const hoverStyle = StylingHelpers.getHoverStyle(style.transform, properties.hover.properties);

                        acc[layer.key].formats[format.key] = {
                            ...acc[layer.key].formats[format.key],
                            hover: {
                                ...StylingHelpers.getHoverStyle(hoverStyle.transform, formatProperties.hover, hoverStyle),
                                transition
                            }
                        };

                        if (Object.keys(acc[layer.key].formats[format.key].hover).length === 0) {
                            delete acc[layer.key].formats[format.key].hover;
                        }
                    });
                }

                if (layer.children.length > 0) {
                    acc = {
                        ...acc,
                        ...getLayerStyles(layer.children, frameType)
                    };
                }

                return acc;
            }, {});
        };

        // create simplified layerProperties object
        const frames = Object.keys(template.layerProperties.general).reduce((acc, cur) => {
            if (template.designerSettings.disableBase && cur === 'base') {
                return acc;
            }

            acc[cur] = template.formats.reduce(
                (a, c) => {
                    const generalStyle = cloneDeep(template.layerProperties.general[cur].properties);
                    const formatStyle = cloneDeep(get(template, `layerProperties.${c.key}.${cur}.properties`, {}));
                    const generalHover = template.layerProperties.general?.[cur]?.properties?.hover;
                    if (!generalHover) return a;

                    const formatHover = get(template.layerProperties, `${c.key}.${cur}.properties.hover`, {});
                    const hover = merge(cloneDeep(generalHover), cloneDeep(formatHover));

                    const enableProps = hover.properties.enableProperties;
                    const { duration, timingFunction, delay } = hover.transition;

                    const mergedStyle = merge(generalStyle, formatStyle);
                    const style = StylingHelpers.getLayerStyle(mergedStyle, 'format');

                    const transition = `all ${duration / 1000}s ${timingFunction} ${
                        delay / 1000
                    }s, left 0s 0s, top 0s 0s, right 0s 0s, bottom 0s 0s, width 0s 0s, height 0s 0s`;

                    a.style[c.key] = {
                        ...style
                    };

                    if (transition !== DEFAULT_FORMAT_HOVER_TRANSITION) {
                        a.style[c.key].transition = transition;
                    }

                    if (!!enableProps?.background || !!enableProps?.opacity || !!enableProps?.borderColor) {
                        a.hover[c.key] = {
                            background:
                                !!enableProps.background &&
                                StylingHelpers.convertColor(get(hover, 'properties.background') || generalHover.properties.background),
                            opacity: !!enableProps.opacity && get(hover, 'properties.opacity', generalHover.properties.opacity),
                            ['border-color']:
                                !!enableProps.borderColor &&
                                StylingHelpers.convertColor(get(hover, 'properties.borderColor') || generalHover.properties.borderColor.hex)
                        };
                    }

                    if (template.designerSettings.customCSS && mergedStyle.customCSS) {
                        const customCSS = mergedStyle.customCSS.replaceAll(';', ' !important;');
                        a.customCSS[c.key] = customCSS;
                    }

                    return a;
                },
                { style: {}, hover: {}, customCSS: {} }
            );

            if (Object.keys(acc[cur].style).length === 0) {
                delete acc[cur].style;
            }

            if (Object.keys(acc[cur].hover).length === 0) {
                delete acc[cur].hover;
            }

            if (Object.keys(acc[cur].customCSS).length === 0) {
                delete acc[cur].customCSS;
            }

            // Current frame
            const frame = template.frameTypes.filter((ft) => ft.key === cur)[0];

            // Add customJS to the frameTypes
            if (template.designerSettings.customJS && frame.customJS) acc[cur].customJS = frame.customJS;

            if (cur !== 'base') {
                acc[cur].duration = frame.duration;
                acc[cur].name = frame.title;

                const type = template.templateData.type;
                if (type === 'displayAdDesigned' || type === 'dynamicVideoDesigned') {
                    const formats = template.formats.map((format) => format.key);
                    acc[cur].animations = CanvasHelpers.generateSceneKeyframes(
                        cloneDeep(template.layerProperties),
                        cloneDeep(template.layers[cur]),
                        cur,
                        formats,
                        frame.duration ?? FrameTypeHelpers.DEFAULT_DURATION,
                        false,
                        template.designerSettings.enableAnimations
                    );

                    if (Object.keys(acc[cur].animations).length === 0) {
                        delete acc[cur].animations;
                    }
                }
            }

            acc[cur].layers = getLayerStyles(template.layers[cur], cur);

            return acc;
        }, {});

        // create simplified layers array
        const mapLayers = ({ key, title, type, ...rest }) => {
            return { key, title, type, children: rest.children ? rest.children.map(mapLayers) : [] };
        };

        const layers = Object.entries(template.layers).reduce((acc, [key, array]) => {
            if (template.designerSettings.disableBase && key === 'base') {
                return acc;
            }

            acc[key] = array.map(mapLayers);
            return acc;
        }, {});

        const allFonts = FontHelpers.getAllFonts(template);

        const clonedSettings = cloneDeep(template.designerSettings);

        // delete unnecessary settings props for publish export
        delete clonedSettings.gridSize;
        delete clonedSettings.autoSave;
        delete clonedSettings.customFormats;
        delete clonedSettings.fonts;
        delete clonedSettings.disableBase;
        delete clonedSettings.highlightedCharacter;
        delete clonedSettings.defaultUnit;

        const templateExport = {
            type: template.templateData.type,
            createdDate: template.templateData.createdDate,
            templateVersion: template.templateSetup.templateVersion,
            frames,
            formats: template.formats,
            layers,
            options: {
                layersShouldReverse: !TemplateVersionHelpers.layersShouldNotReverse(),
                highlightedCharacter: template.designerSettings.highlightedCharacter,
                noLanguageFallback: template.templateSetup.finishedMigrations?.includes('convertInterfaceSetupToDynamicLayers'),
                shouldShowBaseFrameAbove: TemplateVersionHelpers.shouldShowBaseFrameAbove()
            },
            ...clonedSettings,
            dynamicFrames: template.templateData.type === 'displayAdDesigned' ? template.templateData.settings.dynamicFrames : false
        };

        if (allFonts.length > 0) {
            templateExport.fonts = allFonts;
        }

        return templateExport;
    };

    /**
     * Check if this is valid CSS.
     * Checking by total lines in string and total lines from the object.
     * If they do not match, then it is not valid CSS.
     * Because if if a CSS in the stirng is not valid, then it will not be in the object.
     * @param css - CSS string.
     * @returns {boolean} - Returns true if the CSS string is valid.
     */
    static isValidCSS = (css: string): boolean => {
        css = css.replace(/\n/g, '');
        if (css === '') return true;
        const totalLines = css.split(';').filter((row) => row !== '').length;
        const customCssLines = Object.keys(cssStringToObject(css).attributes).length;
        return totalLines === customCssLines;
    };

    /**
     * Checks session storage if the specific layer already has shown a upload dialog. If not, return true.
     * @param layerKey - Layer key of the current layer.
     * @returns If there is a value it returns false.
     */
    static checkAssetGalleryInputOpenedBefore = (layerKey: Layer['key']): boolean => {
        const openOnMount = sessionStorage.getItem(OPEN_ON_MOUNT_KEY + layerKey);
        return !openOnMount;
    };

    /**
     * Saves layer key in session storage so it won't open next time.
     * @para layerKey - Layer key of the current layer.
     */
    static setAssetGalleryInputOpenedBefore = (layerKey: Layer['key']): void => {
        sessionStorage.setItem(OPEN_ON_MOUNT_KEY + layerKey, 'false');
    };

    /**
     * Get the current view of TD from the component store.
     * @returns The current view.
     */
    static getCurrentView = (): CurrentView => {
        return getTemplateData<CurrentView>('view.currentView');
    };
}

export { TemplateDesignerHelpers };
