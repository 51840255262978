import React, { useEffect, useMemo, useState } from 'react';
import CampaignAssetsService from 'services/campaign-assets/campaign-assets.service';
import { CreativeV2 } from 'components/creatives-v2/components/creative-editor/types/creativeV2.type';
import { AssetMetadata } from 'components/bricks/helpers/asset.helpers';
import EditorData from 'components/editor-data/EditorData';
import SelectorView from '../view';

export interface SelectorCampaignAssetsProps {
    campaignId?: string;
    assetType?: string;
}

interface Props extends SelectorCampaignAssetsProps {
    onSelect?: (sourceData: AssetMetadata, dataType?: string) => void;
}

/**
 * The component is responsible for rendering the campaign assets selector.
 */
const SelectorCampaignAssets: React.FC<Props> = ({ campaignId, assetType, onSelect }) => {
    const [assets, setAssets] = useState<CreativeV2[]>([]);

    const selectorViewItems = useMemo((): CreativeV2[] => {
        return assets.map((asset) => {
            return {
                ...asset,
                thumbnail: asset.data?.thumbnail,
                templateType: asset.data?.templateType,
                description: asset.data?.description
            };
        });
    }, [assets]);

    const handleCampaignAssets = async (campaignId?: string, searchQuery?: string, assetType?: string) => {
        const id = campaignId || EditorData.getId();
        const campaignAssets = await CampaignAssetsService.getCampaignAssets(id, searchQuery, assetType);
        setAssets(campaignAssets);
    };

    useEffect(() => {
        handleCampaignAssets(campaignId, undefined, assetType);
    }, []);

    return (
        <SelectorView
            items={selectorViewItems}
            onSelect={(sourceData) => onSelect && onSelect(sourceData.data || sourceData)}
            onSearch={(searchQuery) => handleCampaignAssets(campaignId, searchQuery, assetType)}
        />
    );
};

export default SelectorCampaignAssets;
