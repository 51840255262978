import '../styles/main.scss';
import React, { Component } from 'react';
import Dialog from 'components/ui-components-v2/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Icon from 'components/ui-components-v2/Icon';
import CircularProgress from 'components/ui-components-v2/CircularProgress';
import Button from 'components/ui-components-v2/Button';
import EditorData from 'components/editor-data/EditorData';
import SnackbarUtils from 'components/ui-base/SnackbarUtils';
import { EditorLocalScope } from 'components/editor-data/EditorLocalScope';
import Translation from 'components/data/Translation';
import MultiInput from '../../MultiInput';
import { FeedRequest } from '../.././../data/FeedManagementHelpers';

/**
 * Dialog to add a record to a manual data set.
 */
export default class DialogManualRecord extends Component {
    constructor(props) {
        super(props);

        const { initialData = {} } = props;
        let copy;
        if (Object.keys(initialData).length === 0) {
            // We're adding a new record.
            copy = Translation.get('dataSet.manualRecordDialog.addRecord', 'feed-management');
        } else if (initialData._id) {
            // We're editing an existing record.
            copy = Translation.get('dataSet.manualRecordDialog.editRecord', 'feed-management');
        } else {
            // We're duplicating an existing record.
            copy = Translation.get('dataSet.manualRecordDialog.copyRecord', 'feed-management');
        }

        this.state = {
            campaignId: EditorData.get('id'),
            loading: false,
            copy
        };

        this.multiInputData = {};
    }

    /**
     * Add a new manual record
     * @param {object} formData The data of the form
     */
    addManualItem = async (formData) => {
        const { dataSet = {}, onAddManualRecord, onClose } = this.props;

        const datasetPostData = { ...formData };

        // Set the dataSet to manual, if that's not the case yet
        if (!(dataSet.customdata && dataSet.customData.isManual)) {
            await FeedRequest.patch(`dataset/${dataSet._id}`, {
                ...dataSet,
                customData: {
                    ...dataSet.customData,
                    isManual: true
                }
            });
        }

        FeedRequest.post(`dataset/${dataSet._id}/item`, datasetPostData)
            .then((data) => {
                onAddManualRecord(data.data[0]);
                onClose();
            })
            .catch((error) => {
                SnackbarUtils.error(Translation.get('dataSet.manualRecordDialog.failedToAddManual', 'feed-management'));
                console.log('Failed to add manual record', error);
                this.setState({ loading: false });
            });
    };

    /**
     * Edit an existing manual item
     * @param {object} formData The data of the form
     */
    editManualItem = (formData) => {
        const { dataSet, onClose, initialData, onEditManualItem } = this.props;
        // const itemId = initialData._id;

        const updatedItem = {
            // ...initialData,
            ...formData
        };

        FeedRequest.patch(`dataset/${dataSet._id}/item/${initialData._id}`, updatedItem)
            .then(() => {
                onEditManualItem({
                    _id: initialData._id,
                    ...updatedItem
                });
                onClose();
            })
            .catch((error) => {
                SnackbarUtils.error(Translation.get('dataSet.manualRecordDialog.failedToEditManual', 'feed-management'));
                console.log('Failed to edit manual record', error);
                this.setState({ loading: false });
            });
    };

    /**
     * Determine whether to edit or add a manual record
     */
    onSave = () => {
        const { initialData = {} } = this.props;

        this.setState({ loading: true }, () => {
            if (initialData && initialData._id) {
                this.editManualItem(this.multiInputData);
            } else {
                this.addManualItem(this.multiInputData);
            }
        });
    };

    /**
     * Store
     * @param {object} multiInputData
     */
    onChangeInput = (multiInputData) => {
        this.multiInputData = multiInputData;
    };

    render() {
        const { open, onClose, initialData = {}, manualRecordInputFields = { items: [] } } = this.props;
        const { loading, copy } = this.state;

        return (
            <React.Fragment>
                <Dialog title={Translation.get('dataSet.manualRecordDialog.createFeedSpreadsheet', 'feed-management')} onClose={onClose} open={open}>
                    <DialogTitle onClose={onClose} style={{ display: 'flex', borderBottom: '1px solid lightgrey' }}>
                        <div style={{ flexGrow: 1, display: 'flex' }}>{copy}</div>
                        <IconButton onClick={onClose} size="small">
                            <Icon>close</Icon>
                        </IconButton>
                    </DialogTitle>
                    <div className="data-set-manager__manual-form">
                        {manualRecordInputFields.items && manualRecordInputFields.items.length > 0 && (
                            <EditorLocalScope onChange={this.onChangeInput} initialData={initialData.data}>
                                <MultiInput data={manualRecordInputFields} />
                            </EditorLocalScope>
                        )}

                        {manualRecordInputFields.items && manualRecordInputFields.items.length === 0 && (
                            <span className="data-set-manager__manual-form__no-structure">
                                {Translation.get('dataSet.manualRecordDialog.addStructure', 'feed-management')}
                            </span>
                        )}

                        {!loading && manualRecordInputFields.items && manualRecordInputFields.items.length > 0 && (
                            <Button variant="contained" color="primary" onClick={this.onSave} style={{ marginLeft: 'auto', marginTop: 16 }}>
                                {copy}
                            </Button>
                        )}

                        {loading && (
                            <div style={{ marginLeft: 'auto', marginTop: 16 }}>
                                <CircularProgress color="primary"></CircularProgress>
                            </div>
                        )}
                    </div>
                </Dialog>
            </React.Fragment>
        );
    }
}
