import React from 'react';
import { CreativeV2 } from 'components/creatives-v2/components/creative-editor/types/creativeV2.type';
import SnackbarUtils from 'components/ui-base/SnackbarUtils';
import CreativeSelectorItem from './item';
import { CreativeSelectorAddItem } from './add-item';

interface Props {
    creatives: CreativeV2[];
    openAssetGalleryDialog: () => void;
    onChange: (creatives: CreativeV2[]) => void;
    editCreative: (creative: CreativeV2) => void;
}

const MultiCreativeSelector = ({ creatives, openAssetGalleryDialog, onChange, editCreative }: Props) => {
    const removeCreative = (creative: CreativeV2) => {
        const newCreatives = creatives.filter((item) => item.id !== creative.id);

        onChange(newCreatives);
        SnackbarUtils.success('Creative removed');
    };

    return (
        <>
            {creatives.map((creative, index) => {
                return <CreativeSelectorItem key={index} creative={creative} editCreative={editCreative} removeCreative={removeCreative} />;
            })}
            <CreativeSelectorAddItem
                openDialog={() => openAssetGalleryDialog()}
                classes={{ root: creatives.length ? 'creative-selector-add-item--space' : undefined }}
            />
        </>
    );
};

export { MultiCreativeSelector };
