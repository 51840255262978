import React, { useEffect, useState } from 'react';
import { useMemo } from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import IconButton from '@mui/material/IconButton/IconButton';
import Icon from 'components/ui-components-v2/Icon';
import { MetadataInputProps } from 'components/input/Metadata/types/metadataInputProps.types';
import TextField from 'components/ui-components/TextField';
import Translation from 'components/data/Translation';
import { SnapchatGeoLocationMetaData, SnapchatGeoLocationMetaDataResponse, SnapchatGeoLocationItem } from '../types/types';
import { SnapchatItem } from '../../SnapchatGeneric/types/types';
import '../styles/main.scss';

interface Props extends MetadataInputProps {
    response?: SnapchatGeoLocationMetaDataResponse;
    config: {
        countryCode: string;
        limit?: number;
        pageToken?: string;
        type: string;
    };
    value: SnapchatItem[];
}

/**
 * Snapchat metadata input component
 *
 */
export const SnapchatGeoLocations = ({ response, value, config, setRequest, onMutation }: Props) => {
    const [q, setQ] = useState<string | undefined>('');

    useEffect(() => {
        // set parameters to start a request
        setRequest({
            params: { ...config }
        });
    }, []);

    // Formatting the options based on the resposne
    const getOptions = (response: SnapchatGeoLocationMetaDataResponse | undefined) => {
        if (!response || !response.data || !response.data.length) return [];

        return response.data.reduce((acc: SnapchatGeoLocationItem[], item: SnapchatGeoLocationMetaData) => {
            const type = config.type;
            const geoLocation = item[type][type];
            // Check if the item is not selected already
            const isItemSelected = value ? value.some((selectedItem: SnapchatItem) => selectedItem.id === geoLocation.id) : false;

            // Retrieve the options which include q
            if (!isItemSelected && q && geoLocation.name.toLowerCase().includes(q.toLowerCase())) acc.push(geoLocation);
            return acc;
        }, []);
    };

    const options: SnapchatGeoLocationItem[] = useMemo(() => getOptions(response), [response, q]);

    const capitalizeFirstCharacter = (str: string): string => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    // Highlights the text
    const getHighlightedText = (title, highlight = '') => {
        title = capitalizeFirstCharacter(title);

        // Split on highlight term and include term into parts, ignore case
        const parts = title.split(new RegExp(`(${highlight})`, 'gi'));
        return (
            <span>
                {' '}
                {parts.map((part, i) => (
                    <span key={i} style={part.toLowerCase() === highlight.toLowerCase() ? { fontWeight: 'bold' } : {}}>
                        {part}
                    </span>
                ))}{' '}
            </span>
        );
    };

    const handleSetQ = (event) => {
        setQ(event.target.value);
    };

    /**
     * Add an item
     * @param {*} item
     */
    const handleItemClick = (item) => {
        let newValue: Array<object> = [];
        if (value) newValue = value;

        newValue.push(item);
        onMutation(newValue);
        setQ('');
    };

    /**
     * Handle remove item
     * @param {*} index
     */
    const handleRemove = (index) => {
        const newValue = [...value];
        newValue.splice(index, 1);
        onMutation(newValue);
    };

    // Close the available items window
    const handleClickAway = () => {
        if (q && q.length) setQ('');
    };

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div className="input__snapchat-geo-location">
                <div className="input__snapchat-geo-location__input">
                    <TextField fullWidth onChange={handleSetQ} value={q} margin="none" variant="outlined" />
                </div>
                {options && options.length > 0 && q && q !== '' && (
                    <div className="input__snapchat-geo-location__results">
                        {options.map((item, i) => (
                            <div className="input__snapchat-geo-location__results__item" onClick={() => handleItemClick(item)} key={`result-${i}`}>
                                <div className="input__snapchat-geo-location__results__item__name">{getHighlightedText(item.name, q)}</div>
                            </div>
                        ))}
                    </div>
                )}
                {value && value.length > 0 && (
                    <div className="input__snapchat-geo-location__list">
                        <div className="input__snapchat-geo-location__list__title">
                            {Translation.get('input.metadata.snapchat.geoLocation.selectedItems', 'common')}
                        </div>

                        {value.map((item, i) => (
                            <div className="input__snapchat-geo-location__list__item" key={'list' + i}>
                                <div className="input__snapchat-geo-location__list__item__name">{capitalizeFirstCharacter(item.name)}</div>{' '}
                                <div className="input__snapchat-geo-location__list__item__actions">
                                    <div className="input__snapchat-geo-location__list__item__actions__remove" onClick={() => handleRemove(i)}>
                                        <IconButton size="small">
                                            <Icon fontSize="small">delete</Icon>
                                        </IconButton>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </ClickAwayListener>
    );
};
