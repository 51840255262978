/**
 * Calculates the font size based on the size of the container.
 * @param {DOM element} container - Text container.
 * @param {number} maxFontSize - The maximum font size the text should be.
 */
const shrinkText = (container: HTMLDivElement, maxFontSizeStyle: number): void => {
    const maxFontSize: number = maxFontSizeStyle;

    /**
     * Find all highlighted text in the text container.
     * If there is highlighted text. Change the em and rem to pixels for the normal text.
     * And change the highlighted text to em, so if the normal text changes in font size,
     * the highlighted text will also change in proportion.
     */
    const highlightedText = [...container.querySelectorAll('span')].filter((item) => {
        return [...item.classList].some((c) => ['highlighted-text__inner', 'highlighted-text__outer'].includes(c));
    });
    const highlightedTextInner = [...container.querySelectorAll('span')].filter((item) => {
        return [...item.classList].some((c) => 'highlighted-text__inner' === c);
    });
    highlightedTextInner.forEach((item) => {
        item.style.fontSize = '';
    });

    if (highlightedText && highlightedText.length) {
        // If the highlighted text is in pixels, convert it to em so it will scale with the scaled text.
        highlightedText.forEach((item) => {
            if (item.style.fontSize.includes('px')) {
                const normalTextFontSize = maxFontSize;
                const highlightedTextFontSize = Number(item.style.fontSize.replace(/[^0-9.]+/g, ''));
                const emFontSize = highlightedTextFontSize / normalTextFontSize;
                item.style.fontSize = emFontSize + 'em';
            }
        });
    }

    // Get the container dimensions and font size.
    const containerWidth = container.offsetWidth;
    const containerHeight = container.offsetHeight;
    const containerFontSize = parseFloat(window.getComputedStyle(container).getPropertyValue('font-size'));

    // Get the innerHTML from the element.
    const textElement = container.querySelector('span');
    if (!textElement) return;

    const text = textElement.innerHTML;

    // Create a test element with the same font and styling as the container.
    const testElement = document.createElement('div');
    testElement.style.font = window.getComputedStyle(container).getPropertyValue('font');
    testElement.style.lineHeight = container.style.lineHeight;
    testElement.style.textAlign = container.style.textAlign;
    testElement.style.textTransform = container.style.textTransform;
    testElement.style.letterSpacing = container.style.letterSpacing;
    testElement.style.position = 'absolute';
    testElement.style.top = '-99999px';
    testElement.style.left = '-99999px';
    testElement.style.width = containerWidth + 'px';
    testElement.style.height = containerHeight + 'px';
    testElement.style.padding = container.style.padding;
    testElement.style.margin = container.style.margin;
    testElement.style.wordBreak = container.style.wordBreak;
    testElement.style.transform = container.style.transform;
    testElement.style.whiteSpace = textElement.style.whiteSpace;

    // Set the innerHTML and calculate the font size that fits the text inside the container
    testElement.innerHTML = text;
    document.body.appendChild(testElement);

    /**
     * Calculate the font size based on the width and height of the testEelement.
     * If it is to large, decrease the font size.
     * If it fits, then the loop stops.
     */
    let lowerBound = 0;
    let upperBound = maxFontSize;
    let fontSize = containerFontSize;
    while (lowerBound <= upperBound) {
        const midPoint = Math.floor((lowerBound + upperBound) / 2);
        testElement.style.fontSize = midPoint + 'px';
        if (testElement.scrollWidth <= containerWidth && testElement.scrollHeight <= containerHeight && midPoint <= maxFontSize) {
            fontSize = midPoint;
            lowerBound = midPoint + 1;
        } else {
            upperBound = midPoint - 1;
        }
    }

    // Clean up and set the font size.
    document.body.removeChild(testElement);
    container.style.fontSize = fontSize + 'px';
};

export default shrinkText;
