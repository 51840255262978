import { AssetManagementConfig } from 'components/asset-management/types/asset-management-config.type';
import { CREATIVE_MANAGEMENT_DEFAULT_SETUP } from './default-setup';
import {
    CodedCreativeSubType,
    CustomCreativeSubType,
    CustomTemplateCreativeSubType,
    StaticCreativeSubType,
    TDCreativeSubType,
    TemplateBasedCreativeSubType
} from '../types/creative-sub-type.type';

const CREATIVE_MANAGEMENT_CONFIG: AssetManagementConfig = {
    storeName: 'CreativeManagementV2',
    type: 'creative',
    gridType: 'grid',
    dropZone: false,
    versioning: true,
    fullDataInList: true,
    setupResource: 'setup_creativeManagement_v2',
    assetsPlaceholder: 'media',
    assetsListViewHeaderKeys: ['empty', 'title', 'type', 'content', 'lastModified', 'empty'],
    subtypeIcons: {
        displayAdDesigned: 'dashboard',
        dynamicVideoDesigned: 'movie',
        dynamicImageDesigned: 'image',
        dynamicPDFDesigned: 'picture_as_pdf',
        dynamicAfterEffects: 'movie',
        dynamicInDesign: 'description_outlined',
        displayAd: 'dashboard',
        dynamicImage: 'image',
        dynamicVideo: 'movie',
        dynamicPDF: 'picture_as_pdf',
        socialChannelItem: 'groups',
        staticAssetDisplay: 'dashboard',
        staticAssetImage: 'image',
        staticAssetVideo: 'movie',
        staticAssetAudio: 'volume_up',
        staticAssetPDF: 'picture_as_pdf'
    }
};

const TD_CREATIVE_TYPES: TDCreativeSubType[] = [
    'dynamicImageDesigned',
    'dynamicVideoDesigned',
    'displayAdDesigned',
    'dynamicPDFDesigned',
    'dynamicAfterEffects',
    'dynamicInDesign'
];

const CUSTOM_TEMPLATE_CREATIVE_TYPES: CustomTemplateCreativeSubType[] = ['dynamicVideo', 'dynamicImage', 'displayAd', 'dynamicPDF'];

const STATIC_CREATIVE_TYPES: StaticCreativeSubType[] = ['staticAssetDisplay', 'staticAssetImage', 'staticAssetVideo', 'staticAssetAudio', 'staticAssetPDF'];

const CODED_CREATIVE_TYPES: CodedCreativeSubType[] = ['socialChannelItem', ...STATIC_CREATIVE_TYPES];

const CUSTOM_CREATIVE_TYPES: CustomCreativeSubType[] = [...CUSTOM_TEMPLATE_CREATIVE_TYPES, ...CODED_CREATIVE_TYPES];

const TEMPLATE_BASED_CREATIVE_TYPES: TemplateBasedCreativeSubType[] = [...TD_CREATIVE_TYPES, ...CUSTOM_TEMPLATE_CREATIVE_TYPES];

export {
    CREATIVE_MANAGEMENT_CONFIG,
    CREATIVE_MANAGEMENT_DEFAULT_SETUP,
    TD_CREATIVE_TYPES,
    CUSTOM_TEMPLATE_CREATIVE_TYPES,
    CUSTOM_CREATIVE_TYPES,
    CODED_CREATIVE_TYPES,
    TEMPLATE_BASED_CREATIVE_TYPES,
    STATIC_CREATIVE_TYPES
};
