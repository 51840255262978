import React from 'react';
import { Row, TableOptions, useReactTable } from '@tanstack/react-table';
import { BrickTree } from 'components/bricks/types/brick.type';

import './styles/main.scss';

interface Props {
    className?: string;
    options: TableOptions<BrickTree>;
    renderItem: (row: Row<BrickTree>) => React.ReactNode;
}

/**
 * This component is responsible for rendering the tree view list.
 */
const TreeViewList = React.forwardRef<HTMLUListElement, Props>(({ className, options, renderItem }: Props, ref) => {
    const table = useReactTable(options);
    const rows = table.getRowModel().rows;

    return (
        <ul ref={ref} className={`tree-view-list ${className}`}>
            {rows.map((row) => (
                <li key={row.id}>{renderItem(row)}</li>
            ))}
        </ul>
    );
});

TreeViewList.displayName = 'TreeViewList';

export default TreeViewList;
