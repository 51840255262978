export const PropertyMapping = {
    adtypes: {
        name: 'name',
        id: 'name'
    },
    regions: {
        name: 'name',
        id: 'regionCode'
    },
    referencetypes: {
        name: 'type',
        id: 'type'
    },
    destinations: {
        name: 'destinationType',
        id: 'destination'
    }
};
