import React, { useCallback, useEffect, useRef, useState } from 'react';
import debounce from 'lodash/debounce';
import { TextFieldProps } from '@mui/material/TextField';
import classNames from 'classnames';
import { InputAdornment } from '@mui/material';
import Icon from 'components/ui-components-v2/Icon';
import Tooltip from 'components/ui-components-v2/Tooltip';
import TextField from 'components/ui-components-v2/TextField';
import { RegexHelpers } from 'helpers/regex.helpers';
import { calculateInputValue } from './helpers/calculateInputValue';
import './styles/main.scss';

interface Props extends Omit<TextFieldProps, 'onChange' | 'onBlur' | 'defaultValue'> {
    className?: string;
    value?: number;
    availableUnits?: string[];
    label?: string;
    placeholder?: string;
    onChange?: (value: number, unit?: string | null) => void;
    onChangeIncrement?: (value: number, unit?: string | null) => void;
    onBlur?: (value: number) => void;
    disabled?: boolean;
    min?: number;
    max?: number;
    step?: number;
    decimalValue?: number | undefined;
    selectOnFocus?: boolean;
    useCalculator?: boolean;
    allowEmpty?: boolean;
    adornment?: string;
    alwaysTwoDecimals?: boolean;
    showNumericInputs?: boolean;
    tooltip?: string;
    onChangeOnBlur?: boolean;
    dataCyPrefix?: string;
}

const NumberInputField = ({
    className,
    value,
    availableUnits,
    label,
    placeholder = '-',
    onChange,
    onChangeIncrement,
    onBlur,
    onMouseDown,
    onFocus,
    disabled,
    min,
    max,
    step = 1,
    decimalValue,
    selectOnFocus = true,
    useCalculator = true,
    allowEmpty = false,
    adornment,
    showNumericInputs = true,
    alwaysTwoDecimals,
    tooltip,
    onChangeOnBlur = true,
    dataCyPrefix,
    ...props
}: Props) => {
    /**
     * Convert number to string for display.
     * @param number - Number to convert.
     * @returns String representation of the number.
     */
    const parseValue = (number: number): string => {
        const newValue = alwaysTwoDecimals ? number.toFixed(2) : number.toString();
        return newValue.replace(',', '.');
    };

    const [inputValue, setInputValue] = useState<string>(parseValue(value ?? 0));
    const originalValue = useRef<string>(parseValue(value ?? 0));
    const inputRef = useRef<HTMLInputElement>(null);
    const arrowButtonInterval = React.useRef<NodeJS.Timeout | null>();
    const ARROW_BUTTON_INTERVAL_TIME = 150;

    /**
     * Handle focus event.
     * @param event - Focus event from input element.
     */
    const handleFocus = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>): void => {
        onFocus?.(event);
    };

    /**
     * Handle click event.
     * Select the entire text if no text is selected, otherwise partially select the text from the current cursor position.
     */
    const handleClick = (): void => {
        if (!selectOnFocus) return;

        const inputElement = inputRef.current;
        if (inputElement) {
            // Select the entire text if no text is selected.
            if (inputElement.selectionStart === inputElement.selectionEnd) {
                inputElement.select();
            } else {
                // Partially select the text from the current cursor position.
                const selectionStart = inputElement.selectionStart;
                const selectionEnd = inputElement.selectionEnd;
                inputElement.setSelectionRange(selectionStart, selectionEnd);
            }
        }
    };

    /**
     * Set internal state.
     * @param event - Event from event listener.
     */
    const handleMouseDown = (event: React.MouseEvent<HTMLInputElement, MouseEvent>): void => {
        onMouseDown?.(event);
    };

    /**
     * Checks if new value is between min or max.
     * @param newValue - New value from input or calculation.
     * @returns - New value.
     */
    const isValidValue = (newValue: number): boolean => {
        if (min !== undefined && newValue < min) {
            return false;
        }

        if (max !== undefined && newValue > max) {
            return false;
        }

        return true;
    };

    /**
     * Checks if new value is between min or max.
     * @param newValue - New value from input or calculation.
     * @returns - New value.
     */
    const checkNewValue = (newValue: number): number => {
        if (min !== undefined && newValue < min) return min;
        if (max !== undefined && newValue > max) return max;
        const effectiveDecimalValue = decimalValue !== undefined ? decimalValue : step;

        // Don't allow -0.
        if (Object.is(newValue, -0)) return 0;

        // Round to step
        newValue = Math.round(newValue / effectiveDecimalValue) * effectiveDecimalValue;

        // Round to 2 decimals.
        return Math.round(newValue * 100) / 100;
    };

    /**
     * Handle increment event.
     */
    const handleIncrement = () => {
        inputRef?.current?.focus();

        setInputValue((oldInputValue) => {
            let parsedValue = parseFloat(oldInputValue);
            if (isNaN(parsedValue)) parsedValue = 0;
            let newValue = parsedValue + step;
            newValue = checkNewValue(newValue);
            onChange?.(newValue);
            onChangeIncrement?.(newValue);
            return parseValue(newValue);
        });
    };

    /**
     * Handle decrement event.
     */
    const handleDecrement = () => {
        inputRef?.current?.focus();

        setInputValue((oldInputValue) => {
            let parsedValue = parseFloat(oldInputValue);
            if (isNaN(parsedValue)) parsedValue = 0;
            let newValue = parsedValue - step;
            newValue = checkNewValue(newValue);
            onChange?.(newValue);
            onChangeIncrement?.(newValue);
            return parseValue(newValue);
        });
    };

    /**
     * Start the increment the input from the arrow button.
     */
    const startIncrement = () => {
        handleIncrement();
        arrowButtonInterval.current = setInterval(handleIncrement, ARROW_BUTTON_INTERVAL_TIME);
    };

    /**
     * Stop the increment the input from the arrow button.
     */
    const stopIncrement = () => {
        if (arrowButtonInterval.current === null) return;
        clearInterval(arrowButtonInterval.current);
        arrowButtonInterval.current = null;
    };

    /**
     * Start decrementing of the input.
     */
    const startDecremnt = () => {
        handleDecrement();
        arrowButtonInterval.current = setInterval(handleDecrement, ARROW_BUTTON_INTERVAL_TIME);
    };

    /**
     * Stop decrementing of the input.
     */
    const stopDecrement = () => {
        if (arrowButtonInterval.current !== null) {
            clearInterval(arrowButtonInterval.current);
            arrowButtonInterval.current = null;
        }
    };

    /**
     * Handle arrow key event.
     * @param event - Keyboard event from input element.
     * @param inputValue - Current input value.
     * @param shiftKey - Shift key pressed.
     * @param direction - Direction of arrow key.
     */
    const handleArrowKey = (event: React.KeyboardEvent<HTMLInputElement>, inputValue: string, shiftKey: boolean, direction: 'up' | 'down') => {
        event.preventDefault();
        const newStep = shiftKey ? step * 10 : step;
        let newValue = parseFloat(inputValue) + (direction === 'up' ? newStep : -newStep);
        if (isNaN(newValue)) newValue = 0;
        newValue = checkNewValue(newValue);
        onBlur?.(newValue);
        handleArrowKeyChange(newValue);
        setInputValue(parseValue(newValue));
    };

    /**
     * Handle arrow key change event with debounce.
     */
    const handleArrowKeyChange = useCallback(
        debounce((newValue: number) => {
            onChange?.(newValue);
        }, 50),
        [onChange]
    );

    /**
     * Handle change event.
     * @param event - Change event from input element.
     */
    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        const value = event.target.value.replace(',', '.');

        setInputValue(value);
        const newValue = parseFloat(value);
        const validValue = isValidValue(newValue);

        if (!validValue || isNaN(newValue)) return;
        if (value === '') return;

        if (!onChangeOnBlur) {
            onChange?.(newValue);
        }
    };

    /**
     * Handle blur event.
     * @param event - Blur event from input element.
     */
    const handleBlur = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        const value = event.target.value;
        handleValue(value);
    };

    /**
     * Handle key down event.
     * @param event - Key down event from input element.
     */
    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>): void => {
        const shiftKey = event.shiftKey;

        switch (event.key) {
            case 'ArrowUp':
                return handleArrowKey(event, inputValue, shiftKey, 'up');
            case 'ArrowDown':
                return handleArrowKey(event, inputValue, shiftKey, 'down');
            case 'Escape':
                inputRef.current?.blur();
                return setInputValue(originalValue.current ?? '');
            case 'Enter': {
                event.preventDefault();
                event.stopPropagation();

                if (inputRef?.current) {
                    inputRef.current.blur(); // This will trigger the onBlur event.
                } else {
                    handleValue(inputValue);
                }

                break;
            }
        }
    };

    /**
     * Handle calculation of the value.
     * @param event - Blur event from input element.
     */
    const handleValue = (value: string): void => {
        if (!allowEmpty && value === '') {
            value = '0';
        }

        // Check if value on contains text.
        const onlyText = RegexHelpers.validate('onlyLetters', value);

        if (onlyText) {
            return setInputValue(originalValue.current);
        }

        let newValue: number;

        // Check if the unit is valid.
        let newUnit: string | null = RegexHelpers.extractMatches('valueAndUnit', value)[1];
        if (!newUnit || !availableUnits || !availableUnits.includes(newUnit)) {
            newUnit = null;
        }

        if (useCalculator) {
            try {
                newValue = calculateInputValue(value);
            } catch {
                newValue = parseFloat(value);
            }
        } else {
            newValue = parseFloat(value);
        }

        newValue = checkNewValue(newValue);
        const validValue = isValidValue(newValue);
        if (!validValue) return;
        onChange?.(newValue, newUnit);
        onBlur?.(newValue);

        if (isNaN(newValue)) {
            originalValue.current = '';
            setInputValue('');
            return;
        }

        setInputValue(newValue.toString());
        originalValue.current = newValue.toString();
    };

    /**
     * Update the input value when the value prop changes.
     */
    useEffect(() => {
        if (value === undefined || value === null || isNaN(value)) return;
        const newValue = parseValue(value);
        if (newValue === inputValue) return;
        setInputValue(newValue);
        originalValue.current = newValue;
    }, [value]);

    return (
        <Tooltip title={tooltip ?? ''}>
            <TextField
                className={classNames('template-designer__number-input-field', className)}
                size="small"
                inputRef={inputRef}
                disabled={disabled}
                label={label}
                placeholder={placeholder}
                value={inputValue}
                slotProps={{
                    input: {
                        endAdornment: (() => {
                            if (!adornment && !showNumericInputs) return null;

                            return (
                                <>
                                    {adornment && (
                                        <InputAdornment position="end" disableTypography>
                                            {adornment}
                                        </InputAdornment>
                                    )}

                                    {showNumericInputs && (
                                        <InputAdornment position="end">
                                            <div
                                                className={classNames('template-designer__number-input-field__adornment__numeric', {
                                                    ['template-designer__number-input-field__adornment__numeric--adornment']: adornment
                                                })}>
                                                <button onMouseDown={startIncrement} onMouseUp={stopIncrement} onMouseLeave={stopIncrement} tabIndex={-1}>
                                                    <Icon>arrow_drop_up</Icon>
                                                </button>
                                                <button onMouseDown={startDecremnt} onMouseUp={stopDecrement} onMouseLeave={stopDecrement} tabIndex={-1}>
                                                    <Icon>arrow_drop_down</Icon>
                                                </button>
                                            </div>
                                        </InputAdornment>
                                    )}
                                </>
                            );
                        })()
                    }
                }}
                onMouseDown={handleMouseDown}
                onClick={handleClick}
                onKeyDown={handleKeyDown}
                onFocus={handleFocus}
                onChange={handleChange}
                onBlur={handleBlur}
                data-cy={`${dataCyPrefix}-number-input-field`}
                {...props}
            />
        </Tooltip>
    );
};

export { NumberInputField };
