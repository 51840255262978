import { Workflow } from '../workflow.type';

const fallbackWorkflow: Workflow = {
    id: 'id_fallbackWorkflow',
    name: 'Fallback Workflow',
    subTypes: ['all'],
    statuses: [
        {
            id: 'todo',
            status: 'To Do',
            message: 'Starting point for tasks',
            color: 'default'
        },
        {
            id: 'draft',
            status: 'Draft',
            message: 'Work is in draft stage',
            color: 'warning'
        },
        {
            id: 'progress',
            status: 'Progress',
            message: 'Work is in progress',
            color: 'info'
        },
        {
            id: 'review',
            status: 'Review',
            message: 'Work is under review',
            color: 'primary'
        },
        {
            id: 'declined',
            status: 'Declined',
            message: 'Work has been declined',
            color: 'error'
        },
        {
            id: 'completed',
            status: 'Completed',
            message: 'Work has been completed successfully',
            color: 'success'
        }
    ],
    transitions: []
};

export const fallbackWorkflowData = {
    workflows: {
        fallbackWorkflow: fallbackWorkflow
    }
};
