import { useHistory, useLocation, useParams } from 'react-router-dom';
import { AssetStatus } from 'types/asset.type';
import { Filters } from 'types/filter.type';
import { useMemo } from 'react';
import { OverlayView } from '../types/asset-management-overlay-view.type';
import { AssetManagementView } from '../types/asset-management-view.type';

// Since asset management can be used in different routes, we need to know the root path.
const useAMNavigation = (): {
    assetId: string | undefined;
    collectionId: string | undefined;
    pathname: string;
    rootPath: string;
    basePath: string;
    state: any;
    assetManagementView: AssetManagementView | undefined;
    statusInView: AssetStatus;
    navigate: (path: string, state?: unknown) => void;
    navigateFromRoot: (path: string, state?: unknown) => void;
    removeParamsFromPath: (paramsToRemove: string[]) => string;
    matchPathToStatus: (status?: AssetStatus, view?: OverlayView) => void;
    openAssetDetailOverlay: (assetId: string, view?: OverlayView, action?: string) => void;
    openCollection: (collectionId: string) => void;
    setFiltersInQueryParams: (filters: Filters) => void;
    getFiltersFromQueryParams: () => Filters | undefined;
} => {
    const history = useHistory();
    const { pathname, state } = useLocation();

    const params = useParams<Record<string, string>>();

    const { assetId, collectionId } = params;

    // Calculate the root path of this asset management instance.
    const rootPath = pathname.split('/')[1];

    // Calculate the path with specified params removed.
    const removeParamsFromPath = (paramsToRemove: string[]) => {
        return Object.values(paramsToRemove).reduce((pathname: string, param) => pathname.replace('/' + param, ''), pathname);
    };

    // Calculate the path without it's params.
    const basePath = removeParamsFromPath(Object.values(params));

    // Navigate from the root of this asset management
    const navigateFromRoot = (path: string, state?: unknown) => {
        history.push(`/${rootPath}${path}`, state);
    };

    // Determine the current view of the asset management.
    const assetManagementView: AssetManagementView | undefined = useMemo(() => {
        const segments = basePath.split(`/${rootPath}`);
        const lastSegment = segments.pop() || '';
        switch (lastSegment) {
            case '':
                return 'assets';
            case '/collection':
                return 'collection';
            case '/drafts':
                return 'draft';
            case '/bin':
                return 'bin';
            case '/collections':
                return 'collections';
            default:
                return undefined;
        }
    }, [basePath, rootPath]);

    const statusInView: AssetStatus = useMemo(() => {
        switch (assetManagementView) {
            case 'draft':
                return 'draft';
            case 'bin':
                return 'binned';
            default:
                return 'available';
        }
    }, [assetManagementView]);

    // Navigate to a specific path
    const navigate = (path: string, state?: unknown) => {
        history.push(path, state);
    };

    // Check if the asset status corresponds with the view it is loaded in. If not, redirect.
    const matchPathToStatus = (status?: AssetStatus, view?: OverlayView) => {
        // Check if the asset status corresponds with the view it is loaded in. If not, redirect.

        if (!assetId) return;
        const paramsToRemove = [assetId];
        if (collectionId) paramsToRemove.push(collectionId);
        const cleanPath = removeParamsFromPath(paramsToRemove);

        if (status === 'available' && ![`/${rootPath}`, `/${rootPath}/collection`].includes(cleanPath)) {
            navigateFromRoot(`/${assetId}`, { view });
        } else if (status === 'draft' && cleanPath !== `/${rootPath}/drafts`) {
            navigateFromRoot(`/drafts/${assetId}`, { view });
        } else if (status === 'binned' && cleanPath !== `/${rootPath}/bin`) {
            navigateFromRoot(`/bin/${assetId}`, { view });
        }
    };

    // Open the detail asset overlay
    const openAssetDetailOverlay = (assetId: string, view?: OverlayView, action?: string): void => {
        const { assetId: currentAssetId } = params;

        const gotoPath = (() => {
            if (currentAssetId) {
                return removeParamsFromPath([currentAssetId]);
            }
            return pathname;
        })();

        if (view) {
            history.push(`${gotoPath}/${assetId}`, { view, action });
        } else {
            history.push(`${gotoPath}/${assetId}`);
        }
    };

    // Open a collection
    const openCollection = (collectionId: string) => {
        navigateFromRoot(`/collection/${collectionId}`);
    };

    // Function to set query parameters without triggering a re-render
    const setFiltersInQueryParams = (filters: Filters) => {
        const searchParams = new URLSearchParams();
        Object.entries(filters).forEach(([key, value]) => {
            if (Array.isArray(value)) {
                value.forEach((v) => searchParams.append(key, v));
            } else {
                searchParams.set(key, value);
            }
        });
        const newUrl = searchParams.toString() ? `${window.location.pathname}?${searchParams.toString()}` : window.location.pathname;
        window.history.replaceState({}, '', newUrl);
    };

    const getFiltersFromQueryParams = (): Filters | undefined => {
        const searchParams = new URLSearchParams(window.location.search);
        if (!searchParams.toString()) {
            return undefined;
        }
        const filters: Filters = {};
        searchParams.forEach((value, key) => {
            if (filters[key]) {
                if (Array.isArray(filters[key])) {
                    (filters[key] as string[]).push(value);
                } else {
                    filters[key] = [filters[key] as string, value];
                }
            } else if (key === 'query') {
                filters[key] = value;
            } else {
                filters[key] = [value];
            }
        });
        return filters;
    };

    return {
        assetId,
        collectionId,
        pathname,
        rootPath,
        basePath,
        state,
        assetManagementView,
        statusInView,
        navigate,
        navigateFromRoot,
        removeParamsFromPath,
        matchPathToStatus,
        openAssetDetailOverlay,
        openCollection,
        setFiltersInQueryParams,
        getFiltersFromQueryParams
    };
};

export default useAMNavigation;
