import React, { useMemo } from 'react';
import { FilterOptionsState } from '@mui/base';
import Checkbox from 'components/ui-components-v2/Checkbox';
import Autocomplete from 'components/ui-components-v2/Autocomplete';
import TextField from 'components/ui-components-v2/TextField';
import Chip from 'components/ui-components-v2/Chip';
import { AutocompleteMetadataOption } from '../../Autocomplete/types/types';

interface Props {
    options: AutocompleteMetadataOption[];
    value?: string[];
    onMutation: (value: unknown, type?: string) => void;
}

const MetadataAutocompleteMultiple: React.FC<Props> = ({ options, value, onMutation }: Props) => {
    /**
     * Creates a value as object so it can be passed as value to the Autocomplete
     */
    const valueAsObjects: AutocompleteMetadataOption[] = useMemo(() => {
        const result: AutocompleteMetadataOption[] = [];

        if (!options || !options.length || !value || !value.length) return [];

        options.forEach((option) => {
            if (value.includes(option.id)) result.push(option);
        });

        return result;
    }, [value, options]);

    /**
     * Changes the internal state and updates the model in redux
     *
     * @params event Select Change event from mui/material
     */
    const handleChange = (value: AutocompleteMetadataOption[] | null) => {
        if (!value || value === null) return onMutation(undefined);
        const result = value.map((item) => item.id);
        onMutation(result);
    };

    return (
        <Autocomplete
            options={options}
            onChange={(event, newValue) => {
                handleChange(newValue);
            }}
            fullWidth
            multiple
            openOnFocus
            disableClearable
            disableCloseOnSelect
            disablePortal
            value={valueAsObjects}
            getOptionLabel={(option) => option.value}
            renderOption={(props, option, { selected }) => {
                return (
                    <li {...props}>
                        <span>
                            <Checkbox color="default" checked={selected} />
                            <span>{`${option.name}`}</span>
                        </span>
                    </li>
                );
            }}
            renderTags={(values, getTagProps) => {
                return values.map((option, index) => {
                    const { key, ...tagProps } = getTagProps({ index });

                    return <Chip size="small" label={option.name} key={key} {...tagProps} />;
                });
            }}
            filterOptions={(options: AutocompleteMetadataOption[], state: FilterOptionsState<AutocompleteMetadataOption>) => {
                return options.filter((option: AutocompleteMetadataOption) => option?.name.includes(state.inputValue) || option.id.includes(state.inputValue));
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="outlined"
                    slotProps={{
                        htmlInput: { ...params.inputProps }
                    }}
                />
            )}
        />
    );
};

export default MetadataAutocompleteMultiple;
