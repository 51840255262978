import React from 'react';
import classNames from 'classnames';
import IconButton from 'components/ui-components-v2/IconButton';
import Icon from 'components/ui-components-v2/Icon';
import Tooltip from 'components/ui-components-v2/Tooltip';
import Translation from 'components/data/Translation';
import Src from 'components/template-designer/types/src.type';
import { Media } from 'components/template-designer/types/layerProperties.type';
import './styles/main.scss';

interface Props {
    className?: string;
    label?: string;
    fileType: 'image' | 'background' | 'video' | 'audio' | 'lottie';
    onClickOpen: () => void;
    onClickRemove?: () => void;
    value: Media['src'] | { extension: Src['extension']; url: Src['url']; title: Src['title'] } | null;
    dataCyPrefix?: string;
}

const MediaInput = ({ className, label, fileType, onClickOpen, onClickRemove, value, dataCyPrefix }: Props) => {
    const hasValue = value && typeof value !== 'boolean';

    const icon = (() => {
        if (hasValue && value && (fileType === 'image' || fileType === 'background')) {
            return <img src={value.url} />;
        }

        if (fileType === 'image') {
            return <Icon className="template-designer__media-input__input-icon">image</Icon>;
        }

        if (fileType === 'video') {
            return <Icon className="template-designer__media-input__input-icon">movie</Icon>;
        }

        if (fileType === 'audio') {
            return <Icon className="template-designer__media-input__input-icon">music_note</Icon>;
        }

        if (fileType === 'lottie') {
            return <Icon className="template-designer__media-input__input-icon">code</Icon>;
        }

        if (fileType === 'background') {
            return (
                <div className="template-designer__media-input__input-icon template-designer__media-input__input-icon--grid">
                    <div className="template-designer__media-input__input-icon__grid">
                        <div className="template-designer__media-input__input-icon__background" />
                        <div className="template-designer__media-input__input-icon__background template-designer__media-input__input-icon__background--grey" />
                        <div className="template-designer__media-input__input-icon__background template-designer__media-input__input-icon__background--grey" />
                        <div className="template-designer__media-input__input-icon__background" />
                    </div>
                </div>
            );
        }
    })();

    const fileTypeText = (() => {
        if (hasValue && value.title) {
            return value.title + '.' + value.extension;
        }

        if (label) {
            return label;
        }

        if (Array.isArray(fileType)) {
            return `${Translation.get('actions.add', 'common')} ` + fileType.map((type) => type).join(', ');
        }

        return `${Translation.get('actions.add', 'common')} ` + fileType;
    })();

    return (
        <div className={classNames('template-designer__media-input', className)}>
            <div className="template-designer__media-input__input" onClick={onClickOpen} data-cy={`${dataCyPrefix}-media-input-div`}>
                <div className="template-designer__media-input__input-file-container">{icon}</div>
                <Tooltip title={fileTypeText}>
                    <div
                        className={classNames('template-designer__media-input__text', {
                            'template-designer__media-input__text--has-value': hasValue
                        })}>
                        {fileTypeText}
                    </div>
                </Tooltip>
                {hasValue && (
                    <IconButton
                        className="template-designer__media-input__input-delete-button"
                        size="small"
                        onClick={(event) => {
                            event.stopPropagation();
                            onClickRemove?.();
                        }}>
                        <Icon className="template-designer__media-input__input-delete">close</Icon>
                    </IconButton>
                )}
            </div>
        </div>
    );
};

export { MediaInput };
