import React from 'react';
import PropTypes from 'prop-types';
import { withResources } from 'components/data/Resources';
import LinkedInSingle from './linkedin-single';
import LinkedInCarouselAd from './linkedin-carousel-ad';
import LinkedInTextAd from './linkedin-text-ad';
import iconArrowDown from '../images/icon_arrow_down.png';
import icon_8_likes from '../images/icon_8_likes.png';
import iconComment from '../images/icon_comment.svg';
import iconReact from '../images/icon_react.svg';
import iconShare from '../images/icon_share.svg';
import iconMore from '../images/icon_more.svg';
import './../styles/main.scss';

const reactStringReplace = require('react-string-replace');

class PreviewLinkedIn extends React.Component {
    static propTypes = {
        adType: PropTypes.string,
        advertiser: PropTypes.shape({
            name: PropTypes.string,
            logo: PropTypes.string
        }),
        text: PropTypes.string,
        headline: PropTypes.string,
        linkDescription: PropTypes.string,
        displayUrl: PropTypes.string,
        cta: PropTypes.string,
        items: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.arrayOf(
                PropTypes.shape({
                    text: PropTypes.string,
                    headline: PropTypes.string,
                    linkDescription: PropTypes.string,
                    displayUrl: PropTypes.string,
                    image: PropTypes.string,
                    cta: PropTypes.string
                })
            )
        ])
    };

    static defaultProps = {
        adType: 'imageAd',
        cta: 'OPEN_LINK',
        advertiser: {
            name: 'Cape',
            logo: ''
        }
    };

    constructor(props) {
        super(props);

        this.state = {
            showFullText: false
        };
    }

    showFullText = () => {
        this.setState({ showFullText: true });
    };

    static getChild(adType, props) {
        let component = null;

        switch (adType) {
            case 'imageAd':
                component = <LinkedInSingle {...props} />;
                break;

            case 'linkAd':
                component = <LinkedInSingle {...props} />;
                break;

            case 'post':
                component = <LinkedInSingle {...props} />;
                break;

            case 'textAd':
                component = <LinkedInTextAd {...props} />;
                break;

            case 'videoAd':
                component = <LinkedInSingle {...props} />;
                break;

            case 'carouselAd':
                component = <LinkedInCarouselAd {...props} />;
                break;
            default:
                component = null;
        }

        return component;
    }

    formatText = (text) => {
        return reactStringReplace(text, /(\n|#\w+)/g, (match, i) => {
            if (match === '\n') {
                return <br key={i} />;
            }
            return (
                <span key={i} className="hashtag">
                    {match}
                </span>
            );
        });
    };

    render() {
        const { adType, advertiser, text } = this.props;
        const { showFullText } = this.state;
        const maxWords = 26;
        let shortText, shortTextArray;

        if (text) {
            shortTextArray = text.split(' ');
            shortText = text.split(' ', maxWords).join(' ');
        }

        return (
            <div className="social-preview__linkedin">
                {adType !== 'textAd' && (
                    <div className="social-preview__linkedin__wrapper">
                        <div className="social-preview__linkedin__wrapper__activity">
                            {adType !== 'post' && (
                                <div className="social-preview__linkedin__wrapper__activity__text">
                                    <b>John Doe, Jane Doe</b> <span style={{ color: '#90949c' }}>and 68 others follow</span> <b>NewCo Group</b>
                                </div>
                            )}
                            {adType === 'post' && (
                                <div className="social-preview__linkedin__wrapper__activity__text">
                                    <b>John Doe</b> likes this
                                </div>
                            )}
                            <div className="social-preview__linkedin__wrapper__activity__more">
                                {adType !== 'post' && (
                                    <img className="social-preview__linkedin__wrapper__activity__more-icon" src={iconMore} alt={'icon-more'} />
                                )}
                                {adType === 'post' && (
                                    <img className="social-preview__linkedin__wrapper__activity__icon_arrow_down" src={iconArrowDown} alt={'icon-arrow-down'} />
                                )}
                            </div>
                        </div>
                        <div className="social-preview__linkedin__wrapper__advertiser">
                            <div className="social-preview__linkedin__wrapper__advertiser__logo" style={{ backgroundImage: `url(${advertiser.logo})` }} />

                            <div className="social-preview__linkedin__wrapper__advertiser__text">
                                <div className="social-preview__linkedin__wrapper__advertiser__name">{advertiser.name}</div>
                                <div className="social-preview__linkedin__wrapper__advertiser__followers">1.203 followers</div>
                                {adType !== 'post' && <div className="social-preview__linkedin__wrapper__advertiser__promoted">Promoted</div>}
                                {adType === 'post' && <div className="social-preview__linkedin__wrapper__advertiser__promoted">3d</div>}
                            </div>
                        </div>

                        <div className="social-preview__linkedin__wrapper__text" id="text">
                            {showFullText ? (
                                <div>{this.formatText(text)}</div>
                            ) : (
                                <div>
                                    {this.formatText(shortText)}
                                    {shortText && shortTextArray.length > maxWords && (
                                        <span>
                                            <span> ... </span>
                                            <span
                                                className="textMore"
                                                onClick={() => {
                                                    this.showFullText();
                                                }}>
                                                see more
                                            </span>
                                        </span>
                                    )}
                                </div>
                            )}
                        </div>

                        {PreviewLinkedIn.getChild(adType, this.props)}

                        {adType === 'post' && (
                            <div className="social-preview__linkedin__wrapper__likes-wrapper">
                                <img src={icon_8_likes} alt="icon-8-likes" />
                            </div>
                        )}

                        <div className="social-preview__linkedin__wrapper__actions">
                            <div className="social-preview__linkedin__wrapper__actions__action">
                                <img className="social-preview__linkedin__wrapper__actions__action-img" src={iconReact} />
                                <div className="social-preview__linkedin__wrapper__actions__action-text">Like</div>
                            </div>
                            <div className="social-preview__linkedin__wrapper__actions__action">
                                <img className="social-preview__linkedin__wrapper__actions__action-img" style={{ marginTop: 3 }} src={iconComment} />
                                <div className="social-preview__linkedin__wrapper__actions__action-text">Comment</div>
                            </div>
                            <div className="social-preview__linkedin__wrapper__actions__action">
                                <img className="social-preview__linkedin__wrapper__actions__action-img" src={iconShare} />
                                <div className="social-preview__linkedin__wrapper__actions__action-text">Share</div>
                            </div>
                        </div>
                    </div>
                )}

                {adType === 'textAd' && <div>{PreviewLinkedIn.getChild(adType, this.props)}</div>}
            </div>
        );
    }
}

export default withResources(PreviewLinkedIn, ['linkedin_cta']);
