import React, { useMemo, useState } from 'react';
import { BricksComponentStore } from 'components/bricks/types/bricksComponentStore.type';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import BrickHelpers from 'components/bricks/helpers/brick.helpers';
import CreativeOverview from 'components/creatives-v2/components/creative-overview';
import { MODEL_CREATIVES } from 'components/bricks/constants';
import CreativeSelector from 'components/input/CreativeSelector';
import Illustration from 'components/ui-components-cape/Illustration';
import EmptyState from 'components/ui-components-cape/EmptyState';
import BricksComponentStoreHelper from 'components/bricks/helpers/bricks-component-store.helper';
import { CreativeViewType } from 'components/creatives-v2/components/creative-overview/types/creative-overview.type';
import { CreativeV2, CreativeV2Template } from 'components/creatives-v2/components/creative-editor/types/creativeV2.type';
import MultiInputWrapperHelpers from 'components/bricks/components/shared/components/bricks-multi-input-block-wrapper/helpers';
import brickSetup from './setup';

import './styles/creatives.scss';

interface ComponentStoreProps {
    activeItemId: BricksComponentStore['activeItemId'];
    view?: CreativeViewType;
    singleViewKey?: string;
    hideSingleViewSideBar?: boolean;
}

const Creatives = () => {
    const [creative, setCreative] = useState<CreativeV2[]>([]);
    const { activeItemId, view, singleViewKey, hideSingleViewSideBar } = useComponentStore<ComponentStoreProps>('Bricks', {
        fields: {
            activeItemId: 'activeItemId',
            view: 'creative.view',
            singleViewKey: 'creative.singleViewKey',
            hideSingleViewSideBar: 'creative.hideSingleViewSideBar'
        }
    });
    const brick = useMemo(() => BrickHelpers.getBrickById(activeItemId), [activeItemId, creative]);

    const handleSetCreatives = (newCreatives: CreativeV2[]) => {
        if (!activeItemId) return;

        BricksComponentStoreHelper.setBrickModel(activeItemId, MODEL_CREATIVES, newCreatives);

        setCreative(newCreatives);
    };

    if (!brick) return null;

    const handleOnSaveCreative = (creative: CreativeV2Template) => {
        handleSetCreatives([creative]);
        MultiInputWrapperHelpers.handleOnActiveFormatChange(brick, creative.data.settings.activeFormats ?? []);
    };

    const { multiple, canEditCreative, selectors, templateTypes = [], isButton } = brickSetup?.config?.general?.creativeSelector || {};

    return (
        <div className="bricks-template-creatives">
            {(!brick.data?.creatives || !brick.data.creatives.length) && (
                <div className="bricks-template-creatives__placeholder">
                    <EmptyState
                        illustration={<Illustration illustration="template-designer" />}
                        primaryText="Ready to build?"
                        secondaryText="You have not created a template yet!"
                    />
                    <CreativeSelector
                        onMutation={(creatives) => handleSetCreatives(creatives)}
                        multiple={multiple}
                        canEditCreative={canEditCreative}
                        selectors={selectors}
                        templateTypes={templateTypes}
                        isButton={isButton}
                    />
                </div>
            )}

            {brick.data?.creatives && brick.data.creatives.length && (
                <CreativeOverview
                    creatives={brick.data?.creatives}
                    availableViews={['canvasFixed', 'canvasFree', 'single']}
                    showEditCreativeButton
                    defaultView={view}
                    autoplayOnFirstOpen
                    defaultSingleViewKey={singleViewKey}
                    hideSingleViewSideBar={hideSingleViewSideBar}
                    onSaveCreative={handleOnSaveCreative}
                />
            )}
        </div>
    );
};

export default Creatives;
