import React, { ReactNode, Children, useRef } from 'react';
import { useComponentSize } from 'hooks/useComponentSize';
import classNames from 'classnames';
import ColumnHelpers from 'components/data/ColumnHelpers';

import './styles/main.scss';

interface Props {
    children: ReactNode | ReactNode[];
    className?: string;
    targetSize?: number;
}

const ColumnWrapper = ({ children, targetSize, className }: Props) => {
    const componentRef = useRef<HTMLDivElement>(null);
    const { width: componentWidth } = useComponentSize(componentRef);

    const columnCount = ColumnHelpers.getColumnCount(componentWidth, targetSize);
    const columns = new Array(columnCount).fill([]).map(() => [] as ReactNode[]);
    Children.forEach(children, (child: ReactNode, index) => {
        const columnIndex = index % columnCount;
        columns[columnIndex].push(child);
    });

    return (
        <div className={classNames('column-wrapper', className)} ref={componentRef}>
            {componentWidth > 0 && (
                <div className={classNames('column-wrapper__columns')}>
                    {columns.map((column, i) => (
                        <div className={classNames('column-wrapper__column', `column-wrapper__column--${columnCount}`)} key={`column_${i}`}>
                            {column.map((child) => child)}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default ColumnWrapper;
