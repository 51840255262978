import {
    CreativeBuilderCustomTemplateType,
    CreativeBuilderItemType,
    CreativeBuilderTemplateType,
    CustomTemplateType,
    EmailTemplateType,
    TDTemplateType,
    TemplateMangementTemplateType,
    TemplateType
} from 'types/templates.type';
import {
    CREATIVE_TEMPLATE_TYPES,
    CUSTOM_TEMPLATE_TYPES,
    EMAIL_TEMPLATE_TYPES,
    TD_TEMPLATE_TYPES,
    CUSTOM_CREATIVE_BUILDER_TEMPLATES_TYPES
} from 'components/template-management/constants';
import { CreativeBuilderTemplateAsset, CustomTemplateAsset, StaticAssetType, TDTemplateAsset, TemplateAsset } from '../types/template-management.type';
import { CREATIVE_BUILDER_ITEM_TYPES, TEMPLATE_MANAGEMENT_TEMPLATE_TYPES } from '../constants/template-types';

/**
 * Checked if the Template type is a Template Designer type.
 * @param {string} asset - asset to check on either custom or td.
 * @returns {boolean} - If it is an Template Designer type.
 */
export const isTemplateDesignerTemplate = (asset: TemplateAsset): asset is TDTemplateAsset => {
    return asset !== null && typeof asset === 'object' && typeof asset.subType === 'string' && isTemplateDesignerTemplateType(asset.subType);
};

/**
 * Checked if the Template type is a custom type.
 * @param {string} asset - asset to check on either custom or td.
 * @returns {boolean} - If it is an Template Designer type.
 */
export const isCustomTemplate = (asset: TemplateAsset): asset is CustomTemplateAsset => {
    return asset !== null && typeof asset === 'object' && typeof asset.subType === 'string' && isCustomTemplateType(asset.subType);
};

/**
 * Checked if the Template type is a type that can be opened in Creative Builder V1.
 * @param {string} asset - asset to check on either custom or td.
 * @returns {boolean} - If it is an Template Designer type.
 */
export const isCreativeBuilderTemplate = (asset: TemplateAsset): asset is CreativeBuilderTemplateAsset => {
    return asset !== null && typeof asset === 'object' && typeof asset.subType === 'string' && isCreativeBuilderTemplateType(asset.subType);
};

/**
 * Checked if the Template type is a custom type that can be opened in Creative Builder V1.
 * @param {string} asset - asset to check on either custom or td.
 * @returns {boolean} - If it is an Template Designer type.
 */
export const isTemplateManagementTemplate = (asset: TemplateAsset): asset is TDTemplateAsset | CustomTemplateAsset => {
    return asset !== null && typeof asset === 'object' && typeof asset.subType === 'string' && isTemplateManagementTemplateType(asset.subType);
};

/**
 * Checked if the Template type is a custom type that can be opened in Creative Builder V1.
 * @param {string} asset - asset to check on either custom or td.
 * @returns {boolean} - If it is an Template Designer type.
 */
export const isCustomCreativeBuilderTemplate = (asset: TemplateAsset): asset is CustomTemplateAsset => {
    return asset !== null && typeof asset === 'object' && typeof asset.subType === 'string' && isCustomCreativeBuilderTemplateType(asset.subType);
};

/**
 * @param type Type of template
 * @returns boolean if the template is a email template
 */
export const isEmailTemplateType = (type: TemplateType): type is EmailTemplateType => {
    return EMAIL_TEMPLATE_TYPES.includes(type as EmailTemplateType);
};

/**
 * @param type Type of template
 * @returns boolean if the template is a template designer template
 */
export const isTemplateDesignerTemplateType = (type: TemplateType): type is TDTemplateType => {
    return TD_TEMPLATE_TYPES.includes(type as TDTemplateType);
};

/**
 * @param type
 * @returns boolean if the template is a custom template
 */
export const isCustomTemplateType = (type: TemplateType): type is CustomTemplateType => {
    return CUSTOM_TEMPLATE_TYPES.includes(type as CustomTemplateType);
};

/**
 * @param type
 * @returns boolean if the template is a template that is comptible with the creative builder V1.
 */
export const isCreativeBuilderTemplateType = (type: TemplateType): type is CreativeBuilderTemplateType => {
    return CREATIVE_TEMPLATE_TYPES.includes(type as CreativeBuilderTemplateType);
};

/**
 * @param type
 * @returns boolean if the template is a template that is comptible with the creative builder V1.
 */
export const isCreativeBuilderItemType = (type: TemplateType | StaticAssetType): type is CreativeBuilderItemType => {
    return CREATIVE_BUILDER_ITEM_TYPES.includes(type as CreativeBuilderItemType);
};

/**
 * @param type
 * @returns boolean if the template is a custom template that is comptible with the creative builder V1.
 */
export const isCustomCreativeBuilderTemplateType = (type: TemplateType): type is CreativeBuilderCustomTemplateType => {
    return CUSTOM_CREATIVE_BUILDER_TEMPLATES_TYPES.includes(type as CreativeBuilderCustomTemplateType);
};

/**
 * @param type
 * @returns boolean if the template is a template that is stored in AMV2.
 */
export const isTemplateManagementTemplateType = (type: TemplateType): type is TemplateMangementTemplateType => {
    return TEMPLATE_MANAGEMENT_TEMPLATE_TYPES.includes(type as TemplateMangementTemplateType);
};
