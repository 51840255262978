import '../styles/main.scss';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { isEqual } from 'lodash';
import FeedHelpers, { FeedRequest } from 'components/data/FeedManagementHelpers';
import Button from 'components/ui-components-v2/Button';
import ConfirmDialog from 'components/ui-components/ConfirmDialog';
import SnackbarUtils from 'components/ui-base/SnackbarUtils';
import FeedForm from 'components/feed-management/FeedForm';
import FeedAlerts from 'components/feed-management/FeedAlerts';
import { withComponentStore } from 'components/data/ComponentStore';
import Loader from 'components/ui-components/Loader';
import Translation from 'components/data/Translation';
import DialogManualRecord from './dialog-manual-record';
import DialogManualDataset from './dialog-manual-dataset';
import Table from './table';
import Grid from './grid';
import DataSetManagerNoItems from './no-items';
import FeedWrapper from './feed-wrapper';
import Empty from './empty';
import Placeholder from '../images/placeholder_default.svg';

/**
 * DataSetManager
 * Manage a data set in the Feed Manager or, when used with the 'in campaign' wrapper, in the campaign editor.
 */
class DataSetManager extends Component {
    _isMounted = false;

    static defaultProps = {
        structure: [],
        datasetId: null, // If you manually give a datasetId, it will use this id instead of the id that is returned from the api,
        hideGridView: false,
        inCampaign: true,
        showFeedItem: true
    };

    constructor(props) {
        super(props);
        const displayStructure = FeedHelpers.getStructure(props.dataSet);
        const displayTypes = FeedHelpers.getDisplayTypes(displayStructure);

        this.state = {
            displayStructure,
            displayTypes,
            list: [],
            nextPageToken: false,
            recordsPerPage: 25,
            page: 0,
            totalItems: 0,
            manualRecordDialogOpen: false,
            manualRecordInputFields: {},
            manualDatasetDialogOpen: false,
            warning: null,
            confirmDialogOpen: false,
            itemsLoading: false
        };
    }

    componentDidMount = () => {
        // Get the data set items on mount.
        const { dataSet } = this.props;
        if (dataSet && dataSet._id) {
            this._isMounted = dataSet._id;
            if (dataSet && dataSet.metadata && dataSet.metadata.itemCount > 0) {
                this.setState({ itemsLoading: true }, () => this.fetchDataSetItems());
            }
        } else {
            this._isMounted = 'xxx';
        }
    };

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidUpdate = (prevProps) => {
        const { dataSet = {}, latestUpdatePatch, displayType, searchTerm, kickManualRecordDialog } = this.props;

        // If a (new) data set is loaded, define it's structure and get it's items.
        if (dataSet) {
            if (!prevProps.dataSet || prevProps.dataSet._id !== dataSet._id || !isEqual(prevProps.dataSet.structure, dataSet.structure)) {
                this._isMounted = dataSet._id;
                const displayStructure = FeedHelpers.getStructure(dataSet);
                const displayTypes = FeedHelpers.getDisplayTypes(displayStructure);
                const hasItems = dataSet.metadata && dataSet.metadata.itemCount > 0;
                this.setState(
                    {
                        displayStructure,
                        displayTypes,
                        itemsLoading: hasItems
                    },
                    () => {
                        if (hasItems) this.fetchDataSetItems();
                    }
                );
            }
        }

        // See if there was a Redux path caused by a websocket message
        if (!isEqual(prevProps.latestUpdatePatch, latestUpdatePatch)) {
            // Only do something if the patch concerns de loaded dataSet
            if (dataSet && latestUpdatePatch && latestUpdatePatch.dataSetId === dataSet._id && latestUpdatePatch.operationId) {
                this._isMounted = dataSet._id;
                if (dataSet.structure && latestUpdatePatch.type === 'internal/feed-manager/feed-update/finished') {
                    this.setState(
                        {
                            page: 0,
                            nextPageToken: false,
                            itemsLoading: true
                        },
                        () => this.fetchDataSetItems()
                    );
                } else if (latestUpdatePatch.type !== 'internal/feed-manager/feed-update/failed') {
                    // If a websocket message was received for a dataset with no known structure and it was not a failed update, reload the data set.
                    FeedHelpers.reloadDataSet(dataSet._id);
                }
            }
        }

        // Handel a display type switch.
        if (prevProps.displayType !== displayType) {
            this.setDisplayType();
        }

        if (prevProps.searchTerm !== searchTerm) {
            this.onSearch();
        }

        if (prevProps.kickManualRecordDialog !== kickManualRecordDialog) {
            if (kickManualRecordDialog) this.handleOpenManualRecordDialog();
        }
    };

    /**
     * Get data set items from the API.
     * @param {array} list existing list of items to append to.
     * @param {boolean|number} count number of aditional items to fetch.
     */
    fetchDataSetItems = (list = [], count = false) => {
        const { dataSet, searchTerm, onSetHasContent } = this.props;
        const { nextPageToken, recordsPerPage } = this.state;
        const numberToFetch = count ? count : recordsPerPage;

        // check if the route location object has an itemId in the state object
        // if it is set we need to filter the feed item results with this id
        const filteredItemIds = (() => {
            if (!this.props.location) return undefined;
            if (!this.props.location.state) return undefined;

            return this.props.location.state.itemIds;
        })();

        let apiUrl = `dataset/${dataSet._id}/item/search?limit=${numberToFetch}`;
        if (searchTerm && searchTerm.length > 0) {
            apiUrl = `${apiUrl}&searchTerm=${searchTerm}`;
        }
        if (nextPageToken) {
            apiUrl = `${apiUrl}&pageToken=${nextPageToken}`;
        }

        const requestBody = filteredItemIds ? { itemIds: filteredItemIds } : null;

        FeedRequest.post(apiUrl, requestBody)
            .then((result) => {
                const { data, nextPageToken, totalItems } = result.data;
                const newList = [...list, ...data];
                this.setState(
                    {
                        list: newList,
                        nextPageToken,
                        totalItems,
                        itemsLoading: false
                    },
                    () => onSetHasContent(Boolean(newList.length))
                );
            })
            .catch((error) => {
                SnackbarUtils.error(Translation.get('feeds.failedToLoad', 'feed-management'));
                console.log('Failed to load data set items', error);
                this.setState({
                    itemsLoading: false
                });
            });
    };

    /**
     * Handle the callback of DataSet / Feed creation.
     * @param {object} dataSet
     */
    handleInCampaignFeedCreation = (dataSet) => {
        const { inCampaign, structure } = this.props;

        if (inCampaign && dataSet && dataSet._id && structure && !(dataSet.customData && dataSet.customData.dataSetStructure)) {
            // Sets the datastructure in the database with the structure given in the campaignFormat json (only if there is not dataSetStructure already)
            const newDataSet = {
                ...dataSet,
                customData: {
                    ...dataSet.customData,
                    dataSetStructure: structure
                }
            };

            FeedRequest.patch(`dataset/${dataSet._id}`, newDataSet)
                .then(() => {
                    FeedHelpers.setActiveDataSet(dataSet);
                    FeedHelpers.reloadDataSet(dataSet._id);
                })
                .catch((error) => {
                    console.log('Error when updating dataset', error);
                });
        } else {
            FeedHelpers.setActiveDataSet(dataSet);
        }
    };

    /**
     * Controls the navigation. If necessary, load more records. Otherwise just navigate to the specific page in the table
     */
    handleChangePage = (e, newPage) => {
        const { page, list, recordsPerPage } = this.state;
        const neededRecords = (page + 2) * recordsPerPage;
        if (newPage > page && neededRecords > list.length) {
            this.setState(
                {
                    page: newPage,
                    itemsLoading: true
                },
                () => {
                    this.fetchDataSetItems(list);
                }
            );
        } else {
            this.setState({
                page: newPage
            });
        }
    };

    /**
     * Change the amount of rows that are shown in one page
     */
    handleChangeRowsPerPage = (event) => {
        const { list } = this.state;
        const recordsPerPage = event.target.value;
        this.setState(
            {
                recordsPerPage,
                page: 0,
                itemsLoading: list.length < recordsPerPage
            },
            () => {
                if (list.length < recordsPerPage) {
                    this.fetchDataSetItems(list, recordsPerPage - list.length);
                }
            }
        );
    };

    /**
     * Set the searchTerm in the state and get data set items.
     * @param {string} term
     */
    onSearch = () => {
        this.setState(
            {
                page: 0,
                itemsLoading: true,
                nextPageToken: false
            },
            () => this.fetchDataSetItems()
        );
    };

    /**
     * Open the confirmation dialog for removing an item.
     * @param {*} item
     */
    openConfirmDialog = (item) => {
        this.setState({ confirmDialogOpen: true, itemData: item });
    };

    /**
     * If a manual record is edited patch the record in the state
     * @param {*} formData
     */
    onEditManualItem = (data) => {
        const { list } = this.state;
        const { onSetHasContent } = this.props;
        let newList = [...list];
        newList = newList.map((item) => {
            if (item._id === data._id) {
                return {
                    ...item,
                    data: {
                        ...item.data,
                        ...data
                    }
                };
            }

            return item;
        });
        this.setState({ list: newList }, () => onSetHasContent(Boolean(newList.length)));
    };

    /**
     * Handle updating the data set after a manual record was added.
     * @param {object} data the added record
     */
    onAddManualRecord = (data) => {
        const { dataSet = {}, onSetHasContent, onKickManualRecordDialog } = this.props;
        const { list } = this.state;
        const newList = [...list, data];

        this.setState({ list: newList }, () => {
            FeedHelpers.reloadDataSet(dataSet._id);
            onSetHasContent(Boolean(newList.length));
            onKickManualRecordDialog(false);
        });
    };

    /**
     * Sets the display type
     * @param {string} type 'grid' or 'list'
     */
    setDisplayType = () => {
        const { list } = this.state;
        const recordsPerPage = 25;
        this.setState(
            {
                page: 0,
                recordsPerPage,
                itemsLoading: list.length < recordsPerPage
            },
            () => {
                if (list.length < recordsPerPage) {
                    this.fetchDataSetItems(list, recordsPerPage - list.length);
                }
            }
        );
    };

    /**
     * Open the dialog to edit a record
     * This is only possible for manual datasets
     * @param {*} item
     */
    handleEditItem = (item) => {
        this.handleOpenManualRecordDialog();
        this.setState({ manualRecordDialogOpen: true, itemData: item });
    };

    /**
     * Open the dialog to duplicate / copy a record
     * This is only possible for manual datasets
     * @param {object} item
     */
    handleCopyItem = (item) => {
        delete item._id;
        this.handleOpenManualRecordDialog();
        this.setState({ manualRecordDialogOpen: true, itemData: item });
    };

    /**
     * Manually remove a record from the dataset
     * This is only possible for manual datasets
     * @param {*} item
     */
    handleRemoveItem = (data) => {
        const { list, page, onSetHasContent } = this.state;
        const { dataSet = {} } = this.props;

        FeedRequest.delete(`dataset/${dataSet._id}/item/${data._id}`)
            .then(() => {
                this.setState(
                    {
                        confirmDialogOpen: false,
                        itemData: {}
                    },
                    () => {
                        if (page === 0) {
                            FeedHelpers.reloadDataSet(dataSet._id);
                            this.setState({ itemsLoading: true, nextPageToken: false }, () => {
                                this.fetchDataSetItems();
                            });
                        } else {
                            let newList = [...list];

                            newList = newList.filter((item) => item._id !== data._id);

                            this.setState({ list: newList }, () => onSetHasContent(Boolean(newList.length)));
                        }
                    }
                );
            })
            .catch((error) => {
                SnackbarUtils.error(Translation.get('feeds.failedToRemove', 'feed-management'));
                console.log('Failed to remove item', error);
            });
    };

    /**
     * When trying to open the dialog for adding a manual record. First check if a valid dataSet structure is provided.
     */
    handleOpenManualRecordDialog = () => {
        const { dataSet } = this.props;
        const dataSetStructure = dataSet && dataSet.customData && dataSet.customData.dataSetStructure;
        const manualRecordInputFields = { items: [] };

        if (dataSetStructure && Array.isArray(dataSetStructure)) {
            dataSetStructure.forEach((item) => {
                if (item.interfaceSetup) {
                    manualRecordInputFields.items.push(item.interfaceSetup);
                }
            });
        }

        if (manualRecordInputFields.items.length > 0) {
            this.setState({
                manualRecordDialogOpen: true,
                manualRecordInputFields
            });
        } else
            this.setState({
                warning: Translation.get('feeds.warningDataStructure', 'feed-management'),
                manualRecordInputFields
            });
    };

    render() {
        const { inCampaign, dataSet, dataSetLoadCompleted, structure, displayType, searchTerm, onKickManualRecordDialog, showFeedItem } = this.props;
        const {
            displayStructure,
            displayTypes,
            list,
            page,
            recordsPerPage,
            totalItems,
            manualRecordDialogOpen,
            manualDatasetDialogOpen,
            confirmDialogOpen,
            warning,
            itemData,
            itemsLoading,
            manualRecordInputFields
        } = this.state;

        return (
            <Loader isLoading={!dataSetLoadCompleted}>
                <div className="data-set-manager">
                    {dataSet === null && (
                        <Empty
                            placeholder={Placeholder}
                            title={Translation.get('feeds.noDataset', 'feed-management')}
                            subtitle={Translation.get('feeds.addDescription', 'feed-management')}>
                            <FeedForm inCampaign={inCampaign} callback={this.handleInCampaignFeedCreation}>
                                <Button variant="contained" color="primary">
                                    {Translation.get('feeds.addFeed', 'feed-management')}
                                </Button>
                            </FeedForm>
                            <Button
                                variant="outlined"
                                style={{ marginLeft: 16 }}
                                color="primary"
                                onClick={() => this.setState({ manualDatasetDialogOpen: true })}>
                                {Translation.get('feeds.createManualDataSet', 'feed-management')}
                            </Button>
                        </Empty>
                    )}

                    {dataSet && (
                        <React.Fragment>
                            <FeedAlerts dataSet={dataSet} warning={warning} onClearWarning={() => this.setState({ warning: null })} />

                            {showFeedItem &&
                                dataSet.feeds &&
                                dataSet.feeds.length > 0 &&
                                dataSet.feeds.map((feed) => (
                                    // Show imported feed.
                                    <div className="data-set-manager__feed-item" key={feed._id}>
                                        <FeedWrapper feed={feed} dataSet={dataSet} inCampaign={inCampaign} onGetDatasetItems={this.fetchDataSetItems} />
                                    </div>
                                ))}
                            {showFeedItem && dataSet.feeds && dataSet.feeds.length === 0 && dataSet.customData && dataSet.customData.isManual && (
                                <div className="data-set-manager__feed-item">
                                    <FeedWrapper dataSet={dataSet} inCampaign={inCampaign} onGetDatasetItems={this.fetchDataSetItems} />
                                </div>
                            )}

                            {/* Retrieving data after adding a feed (this can take a while for large spreadsheets) */}
                            <Loader isLoading={itemsLoading}>
                                {list && !list.length && (
                                    <DataSetManagerNoItems dataSet={dataSet} list={list} onHandleOpenManualRecordDialog={this.handleOpenManualRecordDialog} />
                                )}
                                {list && list.length && displayType === 'list' ? (
                                    <Table
                                        list={list}
                                        structure={displayStructure}
                                        tableLoading={itemsLoading}
                                        page={page}
                                        searchTerm={searchTerm}
                                        dataSet={dataSet}
                                        rowsPerPage={recordsPerPage}
                                        totalItems={totalItems}
                                        displayTypes={displayTypes}
                                        inCampaign={inCampaign}
                                        onHandleChangePage={this.handleChangePage}
                                        onHandleEditItem={this.handleEditItem}
                                        onHandleCopyItem={this.handleCopyItem}
                                        onOpenConfirmDialog={this.openConfirmDialog}
                                        onHandleChangeRowsPerPage={this.handleChangeRowsPerPage}
                                        datatCyPrefix="feedManager"
                                    />
                                ) : (
                                    <Grid
                                        list={list}
                                        structure={displayStructure}
                                        displayTypes={displayTypes}
                                        tableLoading={itemsLoading}
                                        dataSet={dataSet}
                                        hasMore={list.length < totalItems}
                                        fetchDataSetItems={this.fetchDataSetItems}
                                        onHandleEditItem={this.handleEditItem}
                                        onOpenConfirmDialog={this.openConfirmDialog}
                                    />
                                )}
                            </Loader>
                        </React.Fragment>
                    )}
                    {manualRecordDialogOpen && (
                        <DialogManualRecord
                            dataSet={dataSet}
                            initialData={itemData}
                            manualRecordInputFields={manualRecordInputFields}
                            open={manualRecordDialogOpen}
                            onClose={() => this.setState({ manualRecordDialogOpen: false, itemData: {} }, () => onKickManualRecordDialog(false))}
                            onEditManualItem={this.onEditManualItem}
                            onAddManualRecord={this.onAddManualRecord}
                        />
                    )}

                    {manualDatasetDialogOpen && (
                        <DialogManualDataset
                            open={manualDatasetDialogOpen}
                            dataSetStructure={structure}
                            onClose={() => this.setState({ manualDatasetDialogOpen: false })}
                        />
                    )}

                    {confirmDialogOpen && (
                        <ConfirmDialog
                            open={confirmDialogOpen}
                            title={Translation.get('feeds.confirmDialogRemove', 'feed-management')}
                            description={Translation.get('feeds.confirmDialogDescription', 'feed-management')}
                            onConfirm={() => this.handleRemoveItem(itemData)}
                            onClose={() => this.setState({ confirmDialogOpen: false, itemData: {} })}
                        />
                    )}
                </div>
            </Loader>
        );
    }
}

export default withRouter(withComponentStore(DataSetManager, 'FeedManager', []));
