import React from 'react';
import Icon from 'components/ui-components-v2/Icon';
import classNames from 'classnames';
import AccordionSummary from 'components/ui-components-v2/AccordionSummary';
import AccordionDetails from 'components/ui-components-v2/AccordionDetails';
import Accordion from 'components/ui-components-v2/Accordion';
import './../styles/publish-subsection.scss';

interface Props {
    title: string;
    children: React.ReactNode;
    severity?: 'success' | 'warning' | 'error';
}

const PublishSubSection = ({ title, children, severity }: Props) => {
    return (
        <Accordion defaultExpanded={true} disableGutters>
            <AccordionSummary expandIcon={<Icon>expand_more</Icon>}>
                <div className="publish-subsection__title__content">
                    <span
                        className={classNames('publish-subsection__title__content__severity-indicator', {
                            'publish-subsection__title__content__severity-indicator--error': severity === 'error',
                            'publish-subsection__title__content__severity-indicator--warning': severity === 'warning',
                            'publish-subsection__title__content__severity-indicator--success': severity === 'success'
                        })}
                    />
                    <span className="publish-subsection__title__content__text">{title}</span>
                </div>
            </AccordionSummary>
            <AccordionDetails>{children}</AccordionDetails>
        </Accordion>
    );
};

export { PublishSubSection };
