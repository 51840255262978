import { TD_CREATIVE_TYPES, TEMPLATE_BASED_CREATIVE_TYPES } from '../constants';
import { CreativeAsset, TDCreativeAsset, TemplateBasedCreativeAsset } from '../types/creative-management.type';
import { CreativeSubType, TDCreativeSubType, TemplateBasedCreativeSubType } from '../types/creative-sub-type.type';

/**
 * Checked if the Creative type is a Template Designer type.
 * @param {string} asset - asset to check on either custom or td.
 * @returns {boolean} - If it is an Template Designer type.
 */
export const isTemplateDesignerCreative = (asset: CreativeAsset): asset is TDCreativeAsset => {
    return asset !== null && typeof asset === 'object' && typeof asset.subType === 'string' && isTemplateDesignerCreativeType(asset.subType);
};
/**
 * Checked if the Creative is based on a template from Template Management.
 */
export const isTemplateBasedCreative = (asset: CreativeAsset): asset is TemplateBasedCreativeAsset => {
    return asset !== null && typeof asset === 'object' && typeof asset.subType === 'string' && isTemplateBasedCreativeType(asset.subType);
};

/**
 * @param type Type of template
 * @returns boolean if the template is a template designer template
 */
export const isTemplateDesignerCreativeType = (type: CreativeSubType): type is TDCreativeSubType => {
    return TD_CREATIVE_TYPES.includes(type as TDCreativeSubType);
};

/**
 *
 * @param type Type of template
 * @returns boolean if the creative is based on a template that ca be found in Template Management
 */
export const isTemplateBasedCreativeType = (type: CreativeSubType): type is TemplateBasedCreativeSubType => {
    return TEMPLATE_BASED_CREATIVE_TYPES.includes(type as TemplateBasedCreativeSubType);
};
