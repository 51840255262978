import '../styles/main.scss';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import React from 'react';
import Icon from 'components/ui-components-v2/Icon';
import classNames from 'classnames';
import FileUpload from 'components/data/FileUpload';
import InlineAlert from 'components/ui-components/InlineAlert';
import cloneDeep from 'helpers/cloneDeep';

/**
 * Asset selector
 * Allows you to select from several assets.
 * You can also upload new items.
 * This is a simple inline visualisation of a low number of images.
 */
class AssetSelector extends React.Component {
    static propTypes = {
        /** Images that already have been uploaded */
        sourceData: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.shape({ title: PropTypes.string, url: PropTypes.string })), PropTypes.any]),
        /** Can be either 'single' | 'multiple' */
        selectType: PropTypes.string,
        /** Can be either true or false, depending on whether you have the rights to upload files */
        canUpload: PropTypes.bool,
        /** Can be either true or false, depending on whether you have the rights to upload files */
        dark: PropTypes.bool,
        /** Function to call when the value has changed */
        onMutation: PropTypes.func,
        /** Function to call when the value has changed with full object */
        onSelect: PropTypes.func,
        /** Filetype of the item */
        fileType: PropTypes.oneOfType([PropTypes.any, PropTypes.oneOf(['image', 'video', 'audio', 'pdf', 'png', 'jpg']), PropTypes.arrayOf(PropTypes.string)])
    };

    static defaultProps = {
        sourceData: [],
        selectType: 'single',
        canUpload: true,
        dark: false,
        fileType: 'image',
        onMutation: (data) => {},
        onSelect: (data) => {}
    };

    constructor(props) {
        super(props);

        this.state = {
            sourceData: props.sourceData && Array.isArray(props.sourceData) ? props.sourceData : [],
            selectType: props.selectType,
            canUpload: props.canUpload,
            selected: [],
            value: props.value ? props.value : ''
        };

        // We have removed the asset from the source data, but it's still set. Add it to be able to remove.
        if (props.value && !this.findinSourceData(this.state.sourceData, props.value)) {
            this.state.sourceData.push({
                url: props.value,
                title: props.sourceData.title
            });
        }
    }

    /**
     * Find the image in the source data
     */
    findinSourceData(sourceData, value) {
        for (const i in sourceData) {
            if (sourceData[i] && sourceData[i].url == value) {
                return true;
            }
        }
        return false;
    }

    /**
     * Value changed externally, so check whether we need to update
     */
    componentDidUpdate = (prevProps) => {
        let data = {};

        data = {};

        // Value changed
        if (prevProps.value !== this.props.value && this.props.value !== this.state.value) {
            data.value = this.props.value;

            // Check if value is not in source data, add it
            if (data.value && !this.findinSourceData(this.state.sourceData, data.value)) {
                data.sourceData = this.state.sourceData;
                data.sourceData.push({
                    url: data.value,
                    title: 'Asset'
                });
            }
        }

        // Source data changed
        if (this.props.sourceData.length > 0 && !isEqual(this.props.sourceData, this.state.sourceData)) {
            data.sourceData = this.props.sourceData;
        }

        if (Object.keys(data).length > 0) {
            this.setState(data);
        }
    };

    /**
     * Select an individual file
     * Sets the internal state and output value
     */
    selectItem = (file) => {
        const url = file.url;
        const { selectType } = this.state;
        const { onMutation, onSelect, readOnly } = this.props;

        // Read only, open file
        if (readOnly) {
            window.open(url);
            return;
        }

        // Otherwise, select file
        let value = '';

        if (selectType === 'multiple') {
            value = this.state.value.slice();
            const index = value.indexOf(url);

            if (index === -1) {
                value.push(url);
            } else {
                value.splice(index, 1);
            }
        } else if (selectType === 'single') {
            value = url;
        }

        this.setState({ value: value });

        onSelect(file);
        onMutation(value);
    };

    /**
     * Handle file upload
     * Called when a file upload was completed.
     */
    handleFileUpload = (files) => {
        const { sourceData } = this.state;
        const { onMutation } = this.props;

        const sourceDataClone = cloneDeep(sourceData);

        files.forEach((file) => {
            const url = file.url;
            const title = file.title;

            sourceDataClone.push({
                url,
                title
            });

            this.selectItem(file);
        });

        this.setState({ sourceData: sourceDataClone });
        onMutation(sourceDataClone, 'sourceData');
    };

    /**
     * Remove item
     * Called when clicking the remove item
     */
    removeItem = (index) => {
        const { sourceData } = this.state;
        const { onMutation } = this.props;

        const itemToRemove = sourceData[index];

        // Remove and save
        sourceData.splice(index, 1);

        // Removed item is current active one
        if (itemToRemove.url == this.state.value) {
            let alternative;
            if (sourceData[0]) {
                alternative = sourceData[0].url;
            }

            this.setState({
                value: alternative,
                sourceData: sourceData
            });

            onMutation(sourceData, 'sourceData');
            onMutation(alternative);
        }
        // Remove other one
        else {
            this.setState({
                sourceData
            });

            onMutation(sourceData, 'sourceData');
        }
    };

    /**
     * View an item
     * Open the item in an external window
     */
    viewItem = (index) => {
        const { sourceData } = this.state;

        const itemToView = sourceData[index];
        window.open(itemToView.url);
    };

    /**
     * Check whether an item is active at the moment and result the classname
     */
    itemIsActive = (item) => {
        const { selectType, value } = this.state;

        if (!item) {
            return '';
        }

        if (selectType === 'multiple') {
            return value.indexOf(item.url) !== -1 ? 'asset-selector__list__item--selected' : '';
        } else if (selectType === 'single') {
            return value == item.url ? 'asset-selector__list__item--selected' : '';
        }
    };

    /**
     * Render a file asset.
     */
    renderAsset = (file) => {
        if (!file || !file.url || typeof file.url !== 'string') {
            return <React.Fragment />;
        }

        if (file.url && (file.url.endsWith('.mp4') || file.url.endsWith('.mov'))) {
            return (
                <div
                    className="asset-selector__list__item__video"
                    onClick={() => {
                        this.selectItem(file);
                    }}>
                    <video src={file.url} controls />
                </div>
            );
        } else if (file.url && (file.url.endsWith('.mp3') || file.url.endsWith('.wav'))) {
            return (
                <div
                    className="asset-selector__list__item__audio"
                    onClick={() => {
                        this.selectItem(file);
                    }}>
                    <audio src={file.url} controls />
                </div>
            );
        } else if (file.url && file.url.endsWith('.pdf')) {
            return (
                <div
                    className="asset-selector__list__item__file"
                    onClick={() => {
                        this.selectItem(file);
                    }}>
                    <Icon>picture_as_pdf</Icon>
                </div>
            );
        } else {
            return (
                <img
                    className={classNames('asset-selector__list__item__image', {
                        'asset-selector__list__item__image--large': !this.props.showTitles
                    })}
                    src={file.url}
                    onClick={() => {
                        this.selectItem(file);
                    }}
                />
            );
        }
    };

    render() {
        const { sourceData, canUpload } = this.state;
        const { narrow = false, fileType, maxFileSize, readOnly, dark, selectType, showTitles = true } = this.props;

        return (
            <div className="asset-selector">
                <div className="asset-selector-content">
                    {maxFileSize && <InlineAlert type="info">The maximum file size is {maxFileSize}kb</InlineAlert>}
                    <div className="asset-selector__list">
                        {sourceData.map &&
                            sourceData.map((file, i) => {
                                if (file && (this.itemIsActive(file) || !readOnly)) {
                                    return (
                                        <div
                                            className={
                                                'asset-selector__list__item ' +
                                                this.itemIsActive(file) +
                                                ' ' +
                                                (narrow ? 'asset-selector__list__item--narrow' : 'asset-selector__list__item--' + fileType)
                                            }
                                            key={i}
                                            onClick={() => {
                                                this.selectItem(file);
                                            }}>
                                            {canUpload && !readOnly && (
                                                <div className="asset-selector__list__item__actions">
                                                    <div
                                                        className="asset-selector__list__item__actions__remove"
                                                        onClick={(e) => {
                                                            this.removeItem(i);
                                                            e.stopPropagation();
                                                        }}>
                                                        <Icon fontSize="small">delete</Icon>
                                                    </div>
                                                    {fileType === 'pdf' && (
                                                        <div
                                                            className="asset-selector__list__item__actions__view"
                                                            onClick={(e) => {
                                                                this.viewItem(i);
                                                                e.stopPropagation();
                                                            }}>
                                                            <Icon fontSize="small">download</Icon>
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                            {this.renderAsset(file)}
                                            {showTitles && <div className="asset-selector__list__item__title">{file.title}</div>}
                                        </div>
                                    );
                                }
                                return false;
                            })}
                    </div>

                    {canUpload && !readOnly && (
                        <FileUpload
                            fileType={fileType}
                            multiple={selectType !== 'single'}
                            dark={dark}
                            onUploadComplete={this.handleFileUpload}
                            size="small"
                            maxFileSize={maxFileSize}
                        />
                    )}
                </div>
            </div>
        );
    }
}

export default AssetSelector;
