import React, { useCallback } from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import debounce from 'lodash/debounce';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import Tooltip from 'components/ui-components-v2/Tooltip';
import TextField from 'components/ui-components-v2/TextField';
import { MetadataSearchOption } from '../types/types';
import ValueBlock from './value-block';
import '../styles/main.scss';

interface Props {
    options?: MetadataSearchOption[];
    value?: MetadataSearchOption[] | string[];
    onMutation: (value: unknown, type?: string) => void;
    showName?: (item: MetadataSearchOption) => ReactJSXElement;
    setCustomConfig: React.Dispatch<React.SetStateAction<CustomConfig>>;
    customConfig: CustomConfig;
    q?: string;
    setQ: React.Dispatch<React.SetStateAction<string | undefined>>;
    isQueryParamRequired: boolean;
    tooltipPopper?: (item: MetadataSearchOption) => ReactJSXElement;
}

interface CustomConfig {
    q?: string;
    [key: string]: any;
}

const MetadataSearch = ({ options, value, onMutation, setCustomConfig, customConfig, q, setQ, showName, tooltipPopper }: Props) => {
    /**
     * Add a interest/behavior
     * @param {*} item
     */
    const handleItemClick = (item) => {
        let newValue: Array<MetadataSearchOption | string> = [];
        if (value) newValue = [...value];

        newValue.push(item);
        onMutation(newValue);
        setQ('');
    };

    // Close the available items window
    const handleClickAway = () => {
        if (q && q.length) setQ('');
    };

    /**
     * Changes the state of the custom config
     * @param newQ
     */
    const handleChangeCustomConfig = (newQ: string) => {
        setCustomConfig({ ...customConfig, q: newQ });
    };

    // Changes the config of the request
    const handleChangeConfig = useCallback(debounce(handleChangeCustomConfig, 500), []);

    const handleSetQ = (event) => {
        setQ(event.target.value);
        handleChangeConfig(event.target.value);
    };

    // Highlights the text
    const getHighlightedText = (title, highlight = '') => {
        // Split on highlight term and include term into parts, ignore case
        const parts = title.split(new RegExp(`(${highlight})`, 'gi'));
        return (
            <span>
                {' '}
                {parts.map((part, i) => (
                    <span key={i} style={part.toLowerCase() === highlight.toLowerCase() ? { fontWeight: 'bold' } : {}}>
                        {part}
                    </span>
                ))}{' '}
            </span>
        );
    };

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div className="input__metadata-search">
                <div className="input_metadata-search__input">
                    <TextField fullWidth onChange={handleSetQ} value={q} margin="none" variant="outlined" />
                </div>
                {options && !!options.length && q && q !== '' && (
                    <div className="input__metadata-search__results">
                        {options.map((item: MetadataSearchOption, i) => (
                            <Tooltip
                                placement="right"
                                key={i}
                                disableHoverListener={!tooltipPopper}
                                arrow
                                className="input__metadata-search__input-tooltip"
                                title={tooltipPopper ? tooltipPopper(item) : ''}>
                                <div className="input__metadata-search__results__item" onClick={() => handleItemClick(item)} key={`result-${i}`}>
                                    <div className="input__metadata-search__results__item__name">{getHighlightedText(item.name, q)}</div>
                                    <div className="input__metadata-search__results__item__type">{item.type}</div>
                                </div>
                            </Tooltip>
                        ))}
                    </div>
                )}
                {value && value.length > 0 && <ValueBlock showName={showName} value={value} onMutation={onMutation} tooltipPopper={tooltipPopper} />}
            </div>
        </ClickAwayListener>
    );
};

export default MetadataSearch;
