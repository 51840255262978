import PropTypes from 'prop-types';
import React, { Component } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Icon from 'components/ui-components-v2/Icon';
import TextField from 'components/ui-components-v2/TextField';
import Validator from 'components/data/Validator';

/**
 * Link field
 * Allows you to enter a link and open it
 */
export default class Link extends Component {
    static propTypes = {
        /** Equal to the output of the field, requiresTranslation and maxLength should be copied from this input to the output.*/
        value: PropTypes.any,
        /** Function to call when the value has changed */
        onMutation: PropTypes.func
    };

    static defaultProps = {
        onMutation: () => {}
    };

    constructor(props) {
        super(props);
        this.state = {
            value: props.value
        };
    }

    /**
     * Change the text
     * @param {*} event
     */
    onTextChange = (event) => {
        this.setState({ value: event.target.value });
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
        this.timeout = setTimeout(this.updateModel, 300);
    };

    /**
     * Update the model
     */
    updateModel = () => {
        const { onMutation } = this.props;
        const { value } = this.state;
        onMutation(value);
    };

    /**
     * Handle link preview click
     */
    handleClick = () => {
        if (Validator.validateValue(this.state.value, { type: 'url' })) {
            window.open(this.state.value);
        }
    };

    render() {
        const { value = 'https://' } = this.state;

        return (
            <TextField
                value={value}
                onChange={this.onTextChange}
                margin="dense"
                variant="outlined"
                fullWidth
                style={{ marginTop: 0 }}
                slotProps={{
                    input: {
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={this.handleClick} edge="end" size="large">
                                    <Icon>visibility</Icon>
                                </IconButton>
                            </InputAdornment>
                        )
                    }
                }}
            />
        );
    }
}
