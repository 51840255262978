import Translation from 'components/data/Translation';
import { generateKey } from 'components/template-designer/utils/generateKey';
import { PredefinedAnimation } from 'components/template-designer/types/animation.type';
import { PredefinedAnimationSetup } from '.';

const defaultValue: { easing: PredefinedAnimation['easing'] } = {
    easing: {
        type: 'ease-out',
        value: 'ease-out'
    }
};

const fadeIn: PredefinedAnimationSetup = {
    label: Translation.get('animation.predefinedAnimations.animations.fadeIn', 'template-designer'),
    key: 'fadeIn',
    duration: 0.75,
    defaultValue: defaultValue,
    reset: { fadeIn: false, fadeOut: true },
    stamps: {
        position: [],
        size: [],
        scale: [],
        rotation: [],
        opacity: [
            {
                value: 0,
                stamp: 0,
                id: generateKey(),
                easing: {
                    type: 'ease-out',
                    value: 'ease-out'
                }
            },
            {
                value: 1,
                stamp: 1,
                id: generateKey(),
                easing: {
                    type: 'ease-out',
                    value: 'ease-out'
                }
            }
        ],
        backgroundColor: [],
        color: [],
        shadow: [],
        rotationX: [],
        rotationY: []
    }
};

export default fadeIn;
