import React from 'react';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import AssetGalleryDialogSelectorType from 'components/assets/AssetGalleryDialog/interfaces/AssetGalleryDialogSelectorType';
import AssetGalleryDialogState, {
    AssetGalleryDialogContentViewType,
    SourceData
} from 'components/assets/AssetGalleryDialog/interfaces/AssetGalleryDialogState';
import AssetGalleryStore from 'components/assets/AssetGalleryDialog/helpers/asset-gallery-store';
import File from 'components/data/Files';
import AssetGalleryDialogProps from 'components/assets/AssetGalleryDialog/interfaces/AssetGalleryDialogProps';
import TemplateSelector, { TemplateDialogTemplatesProps } from 'components/ui-base/TemplateDialog/components/template-dialog-templates';
import CreativeSelector from 'components/ui-base/TemplateDialog/components/template-dialog-creatives';
import ComponentStore from 'components/data/ComponentStore';
import MediaManagementSelector from 'components/media-management/components/selector';
import TemplateManagementSelector from 'components/template-management/components/selector';
import CreativeManagementSelector from 'components/creative-management-v2/components/selector';
import { isAMV2Enabled } from 'components/template-management/utilities';
import User from 'components/data/User';
import { CreativeSelectorProps } from 'components/creative-management-v2/types/creative-selector-props.type';
import TemplateManagementSelectorStaticAssets from 'components/template-management/components/selector/components/static-assets';
import TemplateManagementSelectorSocial from 'components/template-management/components/selector/components/social';
import AssetHelper from 'helpers/asset.helper';
import SelectorUploadWrapper from '../selectors/upload';
import SelectorLibraryWrapper from '../selectors/asset-library';
import SelectorAiContentWrapper from '../selectors/ai-content';
import SelectorUnsplashWrapper from '../selectors/unsplash';
import SelectorUrlWrapper from '../selectors/url';
import SelectorReuseImageWrapper from '../selectors/reuse-image';
import SelectorAprimoWrapper from '../selectors/aprimo';
import SelectorVoiceOverWrapper from '../selectors/voice-over';
import SelectorImageToVideo from '../selectors/image-to-video';
import SelectorFrontify from '../selectors/frontify';
import SelectorCampaignAssets from '../selectors/campaign-assets';

const SUPPORTED_FILE_TYPES = ['image', 'audio', 'video'];
interface SidebarContentState {
    sourceData: AssetGalleryDialogState['data']['sourceData'];
    selectedSelector: AssetGalleryDialogSelectorType;
}

interface Props {
    selectorsProps?: AssetGalleryDialogProps['selectorsProps'];
    onChangeAcceptedList?: (acceptedList: File[]) => void;
    onMutation: (data: unknown, type?: string) => void;
    onClose: () => void; // Closes the dialog.
}

/**
 * Renders the content of the sidebar in the asset gallery dialog.
 * Switches between different selectors based on the selectedSelector prop.
 */
const AssetGalleryDialogSidebarContent: React.FC<Props> = ({ onChangeAcceptedList, onMutation, onClose, selectorsProps }) => {
    const { selectedSelector } = useComponentStore<SidebarContentState>('AssetGalleryDialog', {
        fields: { selectedSelector: 'components.sidebar.selectedSelector' }
    });

    /**
     *   Set the newly selected asset and open cropper or save and close dialog
     */
    const handleSelectAsset = (item: SourceData | any, skipCropper = false, isMultipleUpload = false) => {
        const { conditionProps, data, config }: AssetGalleryDialogState = ComponentStore.get('AssetGalleryDialog');
        const { userCanTrim, useTrimmer, canReuseImage, userCanCrop } = conditionProps;
        const { outputHeight, outputWidth } = data.assetData;
        const cropMode = config.cropper?.cropMode;
        const fileType = AssetHelper.getFileType(item.extension);

        // Immediately return the item (with the width and height)
        if (selectedSelector === 'campaignAssets') {
            onMutation(item, selectedSelector);
            return;
        }

        if (skipCropper) {
            return onMutation(item, selectedSelector);
        }

        // Update the value model with the new item value.
        ComponentStore.setModel('AssetGalleryDialog', 'value', item);

        // Open cropper
        if (
            fileType &&
            SUPPORTED_FILE_TYPES.includes(fileType) &&
            item.extension !== 'gif' &&
            item.extension !== 'svg' &&
            !item.sourceWasUrl &&
            (userCanCrop ||
                (userCanTrim && useTrimmer) ||
                (outputWidth && outputWidth > 0) ||
                (outputHeight && outputHeight > 0) ||
                (cropMode && cropMode === 'free'))
        ) {
            // Do not open asset editor when multiple upload prop is true.
            if (!isMultipleUpload) {
                // By default it is expected to open the asset-editor.
                let contentView: AssetGalleryDialogContentViewType = 'asset-editor';

                if (fileType === 'audio') {
                    // Set content view to audio trimmer.
                    contentView = 'audio-trimmer';
                }

                // Update asset dialog content view.
                ComponentStore.setModel<AssetGalleryDialogState, 'selectedContentView'>('AssetGalleryDialog', 'selectedContentView', contentView);
            }
        } else {
            // Save selected asset and close dialog
            if (canReuseImage) {
                AssetGalleryStore.storeAsset(item);
            }
            onMutation(item, selectedSelector);
            onClose();
        }
    };

    /**
     * While both supporting the old TemplateManagement through ContentSpace and the new TmplateManegement through the AMV2,
     * we decide which template selector to show based on the user's rights. If the new rights for AMV2 are present,
     * we show the new template selector.
     */
    const getTemplateSelector = () => {
        if (isAMV2Enabled()) {
            if (!selectorsProps?.templateProps) return null;
            return <TemplateManagementSelector {...selectorsProps.templateProps} />;
        }
        if (selectorsProps?.templateProps) return <TemplateSelector {...(selectorsProps.templateProps as TemplateDialogTemplatesProps)} />;
    };

    const getCreativeSelector = () => {
        if (isAMV2Enabled() && User.hasRight(['assetManagerCreativeManagement', 'assetManagerCreativeWrite', 'assetManagerCreativeRead'])) {
            if (!selectorsProps?.creativeProps) return null;
            return <CreativeManagementSelector {...(selectorsProps.creativeProps as CreativeSelectorProps)} />;
        }
        if (selectorsProps?.creativeProps) return <CreativeSelector {...selectorsProps.creativeProps} />;
    };

    return (
        <>
            {selectedSelector === 'upload' && (
                <SelectorUploadWrapper onSelect={handleSelectAsset} onChangeAcceptedList={onChangeAcceptedList} onMutation={onMutation} />
            )}
            {selectedSelector === 'unsplash' && <SelectorUnsplashWrapper onSelect={handleSelectAsset} />}
            {selectedSelector === 'reuseImage' && <SelectorReuseImageWrapper onSelect={handleSelectAsset} />}
            {selectedSelector === 'media' && selectorsProps?.mediaProps && (
                <MediaManagementSelector {...selectorsProps.mediaProps} onSelect={handleSelectAsset} />
            )}
            {selectedSelector === 'contentSpace' && <SelectorLibraryWrapper onSelect={handleSelectAsset} />}
            {selectedSelector === 'url' && <SelectorUrlWrapper onSelect={handleSelectAsset} />}
            {selectedSelector === 'aprimo' && <SelectorAprimoWrapper onSelect={handleSelectAsset} onMutation={onMutation} />}
            {selectedSelector === 'aiContent' && <SelectorAiContentWrapper onSelect={handleSelectAsset} />}
            {selectedSelector === 'voiceOverGenerator' && <SelectorVoiceOverWrapper onSelect={handleSelectAsset} />}
            {selectedSelector === 'template' && getTemplateSelector()}
            {selectedSelector === 'templateLibrary' && selectorsProps?.templateLibraryProps && (
                <TemplateManagementSelector {...selectorsProps.templateLibraryProps} />
            )}
            {selectedSelector === 'creative' && getCreativeSelector()}
            {selectedSelector === 'staticAsset' && selectorsProps?.staticAssetProps && (
                <TemplateManagementSelectorStaticAssets {...selectorsProps?.staticAssetProps} />
            )}
            {selectedSelector === 'socialChannelItem' && selectorsProps?.socialChannelItemProps && (
                <TemplateManagementSelectorSocial {...selectorsProps?.socialChannelItemProps} />
            )}
            {selectedSelector === 'imageToVideo' && <SelectorImageToVideo onSelect={handleSelectAsset} />}
            {selectedSelector === 'frontify' && <SelectorFrontify onSelect={handleSelectAsset} />}
            {selectedSelector === 'campaignAssets' && (
                <SelectorCampaignAssets onSelect={(sourceData) => handleSelectAsset(sourceData, true, false)} {...selectorsProps?.campaignAssetsProps} />
            )}
        </>
    );
};

export default AssetGalleryDialogSidebarContent;
