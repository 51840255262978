import React, { useEffect, useState } from 'react';
import DataSetType from 'types/dataset.type';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import CircularProgress from 'components/ui-components-v2/CircularProgress';
import FeedHelpers from 'components/data/FeedManagementHelpers';
import Translation from 'components/data/Translation';
import { FeedTableItem } from '../types/types';
import FeedTableRow from './feed-table-row';

import '../styles/main.scss';

interface Props {
    onSelectFeed: (datasetId: string, feedId: string) => void;
}

const FeedTable = ({ onSelectFeed }: Props) => {
    const [tableData, setTableData] = useState<FeedTableItem[] | undefined>(undefined);

    useEffect(() => {
        FeedHelpers.getAllFeeds().then((response: DataSetType[]) => {
            const datasetsWithFeeds = response.filter((dataset) => dataset.feeds[0]);
            if (!datasetsWithFeeds.length) return [];

            const newTableData = datasetsWithFeeds.map((dataset) => {
                return {
                    datasetId: dataset._id,
                    feedId: dataset.feeds[0].feedId,
                    feedName: dataset.feeds[0].name,
                    feedUrl: dataset.feeds[0].url,
                    feedType: dataset.feeds[0].type,
                    feedItemsCount: dataset.metadata?.itemCount || 0
                };
            });

            setTableData(newTableData);
        });
    }, []);

    return (
        <div className="feed-table">
            {!tableData && <CircularProgress size={64} />}

            {tableData && tableData.length && (
                <Table size="small" className="feed-table__table">
                    <TableHead className="feed-table__table__head">
                        <TableCell className="feed-table__table__head__cell"></TableCell>
                        <TableCell className="feed-table__table__head__cell">{Translation.get('feedTable.feed', 'feed-management')}</TableCell>
                        <TableCell align="center" className="feed-table__table__head__cell">
                            {Translation.get('feedTable.feedItems', 'feed-management')}
                        </TableCell>
                        <TableCell align="center" className="feed-table__table__head__cell">
                            {Translation.get('feedTable.preview', 'feed-management')}
                        </TableCell>
                    </TableHead>
                    <TableBody>
                        {tableData.map((item, index) => {
                            return <FeedTableRow onSelectFeed={onSelectFeed} key={index} item={item}></FeedTableRow>;
                        })}
                    </TableBody>
                </Table>
            )}
        </div>
    );
};

export default FeedTable;
