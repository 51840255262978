import { Message } from 'services/messaging-client/Message';
import { MessageClient } from 'services/messaging-client/MessageClient.service';

import ComponentStore from 'components/data/ComponentStore';
import { FeedProcessDoneMessage, FeedProcessProgressMessage, FeedProcessStartedMessage } from '../types/feed.type';
import BrickHelpers from '../helpers/brick.helpers';
import BricksComponentStoreHelper from '../helpers/bricks-component-store.helper';
import { BrickFeedProcess } from '../types/bricksComponentStore.type';
import { BrickType } from '../types/brick.type';

const PATH_FEED_PROCESS_START = 'internal/bricks/feedProcess/started';
const PATH_FEED_PROCESS_PROGRESS = 'internal/bricks/feedProcess/progress';
const PATH_FEED_PROCESS_DONE = 'internal/bricks/feedProcess/done';

// Handles messages
export default class FeedMessagesService {
    /**
     * Subscribes to feed topic
     * @param onMessage function to handle getting the messages
     * @return subscription id
     */
    static subscribe = (feedProcessId: string): string => {
        const messageClient = MessageClient.getInstance();
        return messageClient.subscribeToTopic(`internal/bricks/feedProcess/${feedProcessId}`, (message) => {
            this.onMessage(message);
        });
    };

    /**
     * Unsubscibe from a topic
     * @param subscription id
     */
    static unsubscribe = (feedProcessId: string, subscription: string): void => {
        const messageClient = MessageClient.getInstance();
        messageClient.unsubscribeFromTopic(`internal/bricks/feedProcess/${feedProcessId}`, subscription);
    };

    /**
     * Handles message
     * @param message
     */
    static onMessage = (message: Message): void => {
        switch (message.path) {
            case PATH_FEED_PROCESS_START:
                this.handleStartGenerateOutputMessage(message as FeedProcessStartedMessage);
                break;
            case PATH_FEED_PROCESS_PROGRESS:
                this.handleProgressGenerateOutputMessage(message as FeedProcessProgressMessage);
                break;
            case PATH_FEED_PROCESS_DONE:
                this.handleDoneGenerateOutputMessage(message as FeedProcessDoneMessage);
                break;
        }
    };

    /**
     * Handles start message of feed processing
     * @param message
     */
    static handleStartGenerateOutputMessage = (message: FeedProcessStartedMessage): void => {
        const feedBrickId = message.content.feedBrickId;

        const feedBrick = BrickHelpers.getBrickById(feedBrickId);
        if (!feedBrick) return;

        const feedBrickChildIds = BrickHelpers.getChildrenOfBrick(feedBrick)
            .filter((child) => child.type !== BrickType.MASTER && child.type !== BrickType.MASTER_CHILD)
            .map((child) => child.id);
        BricksComponentStoreHelper.deleteMultipleBricks(feedBrickChildIds);

        const { totalRows } = message.content;
        const brickFeedProcess: BrickFeedProcess = {
            totalRows,
            processedRows: 0
        };

        ComponentStore.setModel('Bricks', `feedState.${feedBrickId}`, brickFeedProcess);
    };

    /**
     * Handles progress message of feed processing
     * @param message
     */
    static handleProgressGenerateOutputMessage = (message: FeedProcessProgressMessage): void => {
        const feedBrickId = message.content.feedBrickId;

        const feedBrick = BrickHelpers.getBrickById(feedBrickId);

        if (!feedBrick) return;

        const { processedRows, totalRows } = message.content;

        const brickFeedProcess: BrickFeedProcess = {
            totalRows,
            processedRows
        };

        ComponentStore.setModel('Bricks', `feedState.${feedBrickId}`, brickFeedProcess);

        const brickChiltrenCount = BrickHelpers.getChildrenOfBrick(feedBrick).map((child) => child.id).length;

        if (!brickChiltrenCount) BrickHelpers.loadMoreBricks(feedBrickId);
    };

    /**
     * Handles end message of feed processing
     * @param message
     */
    static handleDoneGenerateOutputMessage = (message: FeedProcessDoneMessage): void => {
        const feedBrickId = message.content.feedBrickId;

        const feedBrick = BrickHelpers.getBrickById(feedBrickId);
        if (!feedBrick) return;

        ComponentStore.setModel('Bricks', `feedState.${feedBrickId}`, undefined);
    };
}
