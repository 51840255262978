import React from 'react';
import Icon from 'components/ui-components-v2/Icon';
import Setup from 'components/data/Setup';
import GridHeaderDatesBottom from './grid-header-dates-bottom';
import GridHeaderDatesMiddle from './grid-header-dates-middle';
import getCellPercentages from '../helpers/getCellPercentages';
import '../styles/grid.scss';

/**
 * Class CalendarGridHeaderDates
 * The top three rows of the dates in the calendar header
 */
class CalendarGridHeaderDates extends React.Component {
    constructor(props) {
        super(props);

        this.setup = Setup.get();
    }

    /**
     * Calculates the height of the overlay
     */
    getOverlayHeight = (rowsCount) => {
        const { compactView } = this.props;

        let overlayHeight;

        if (compactView) {
            overlayHeight = 56 * rowsCount;
        } else {
            overlayHeight = 120 + 56 * rowsCount;
        }

        return overlayHeight;
    };

    /**
     * Calculates the width of the overlay
     */
    getOverlayWidth = (cellWidthPerc) => {
        const { timeframe, componentWidth } = this.props;

        let cellsBefore;
        if (timeframe === 'week' || timeframe === 'quarterly') {
            cellsBefore = 1;
        } else if (timeframe === 'month') {
            cellsBefore = 3;
        } else if (timeframe === 'year') {
            cellsBefore = 2;
        }

        return `${((componentWidth - 256) / componentWidth) * 100 * ((cellsBefore * cellWidthPerc) / 100)}%`;
    };

    /**
     * Calculates the amount of rows of the current view. Also takes into account to fill the calender up with some empty rows, if there are not enough campaigns to show
     */
    getRowsCount = () => {
        const { filteredCampaigns, showChannels, compactView } = this.props;
        let rowsCount = 0;

        filteredCampaigns.forEach((campaignType) => {
            if (!campaignType.campaigns) return;
            rowsCount += campaignType.campaigns.length;
        });
        rowsCount += filteredCampaigns.length;

        showChannels.forEach((campaignId) => {
            filteredCampaigns.forEach((campaignType) => {
                if (!campaignType.campaigns) return;
                const target = campaignType.campaigns.find((c) => c.id == campaignId);
                if (target && target.channelDates) rowsCount += Object.keys(target.channelDates).length;
            });
        });

        if (rowsCount < 4) rowsCount = 4;

        const minimumRows = compactView ? 4 : 15;
        if (rowsCount < minimumRows) rowsCount += minimumRows - rowsCount;

        return rowsCount;
    };

    render() {
        const { startDate, endDate, cellCount, componentWidth, compactView, prevTimeRange, nextTimeRange, currentDate, timeframe } = this.props;

        // Calculates the width for each cell (this depends on the total width of the component)
        const cellWidthPerc = (componentWidth / cellCount / componentWidth) * 100;

        const { middleRowCellWidths, bottomRowCellWidths } = getCellPercentages(startDate, endDate, cellWidthPerc, currentDate, timeframe);
        const rowsCount = this.getRowsCount();
        const hideOverlayArrows = this.setup?.campaigns?.calendar?.hideOverlayArrows;

        return (
            <div className="calendar-grid-header__dates">
                {!hideOverlayArrows && (
                    <div
                        onClick={() => prevTimeRange()}
                        className={`calendar-grid-header__dates__overlay__start hover ${compactView ? 'overlay-compact' : ''}`}
                        style={{
                            width: this.getOverlayWidth(cellWidthPerc),
                            height: this.getOverlayHeight(rowsCount)
                        }}>
                        <Icon className="calendar-grid-header__dates__overlay__start__icon">arrow_back</Icon>
                    </div>
                )}

                {!compactView && (
                    <div>
                        <div className="calendar-grid-header__dates__middle">
                            <GridHeaderDatesMiddle cells={middleRowCellWidths} timeframe={timeframe} startDate={startDate} />
                        </div>
                    </div>
                )}

                <div className={`calendar-grid-header__dates__bottom calendar-grid-header__dates__bottom${compactView ? '--compact' : ''}`}>
                    <GridHeaderDatesBottom
                        cells={bottomRowCellWidths}
                        timeframe={timeframe}
                        startDate={startDate}
                        cellWidthPerc={cellWidthPerc}
                        cellCount={cellCount}
                    />
                </div>

                {!hideOverlayArrows && (
                    <div
                        onClick={() => nextTimeRange()}
                        className={`calendar-grid-header__dates__overlay__end hover ${compactView ? 'overlay-compact' : ''}`}
                        style={{
                            width: this.getOverlayWidth(cellWidthPerc),
                            height: this.getOverlayHeight(rowsCount)
                        }}>
                        <Icon className="calendar-grid-header__dates__overlay__end__icon">arrow_forward</Icon>
                    </div>
                )}
            </div>
        );
    }
}

export default CalendarGridHeaderDates;
