import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';
import classNames from 'classnames';
import Tooltip from 'components/ui-components-v2/Tooltip';
import Icon from 'components/ui-components-v2/Icon';
import Slider from 'components/ui-components-v2/Slider';
import SnackbarUtils from 'components/ui-base/SnackbarUtils';
import Translation from 'components/data/Translation';
import ComponentStoreHelpers from 'components/data/ComponentStore';
import AssetEditorCropperHelper from 'components/assets/AssetGalleryCropper/helpers/asset-editor-cropper-helper';
import AssetVideoCropperOverlay from './asset-video-cropper-overlay';
import VideoProgressBar from './video-cropper-progress-bar';
import AssetVideoTime from './asset-video-time';

import '../styles/asset-video-cropper.scss';

/**
 * VideoCropper
 * Is used to display a video and select a part of and/or crop the video to be rendered into a new mp4
 */
export default class AssetVideoCropper extends React.Component {
    static propTypes = {
        video: PropTypes.object,
        minimumDuration: PropTypes.number,
        maximumDuration: PropTypes.number,
        duration: PropTypes.number,
        ratio: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        canCrop: PropTypes.bool,
        outputWidth: PropTypes.number,
        outputHeight: PropTypes.number,
        isProcessing: PropTypes.bool,
        changeCropData: PropTypes.func,
        cropData: PropTypes.object,
        changeTrimData: PropTypes.func,
        trimData: PropTypes.object,
        time2sec: PropTypes.func,
        cropMode: PropTypes.string,
        showEditControls: PropTypes.bool,
        onVideoLoad: PropTypes.func
    };

    static defaultProps = {
        video: {},
        minimumDuration: null,
        maximumDuration: null,
        duration: null,
        ratio: '',
        canCrop: false,
        outputWidth: 0,
        outputHeight: 0,
        isProcessing: false,
        changeCropData: () => {},
        cropData: { w: 0, h: 0, x: 0, y: 0 },
        changeTrimData: () => {},
        trimData: { start: 0, end: 0 },
        time2sec: () => {},
        cropMode: '',
        dragging: false,
        showEditControls: true
    };

    constructor(props) {
        super(props);
        this.videoRef = React.createRef();
        this.sliderHandleRef = React.createRef();
        this.trackRef = React.createRef();
        this.videoCropperRef = React.createRef();
        this.playButtonRef = React.createRef();
        this.resetCountRef = React.createRef();

        this.state = {
            currentTime: props.video.inputStartTime || 0,
            isVideoSliderDragging: false,
            maxLength: 100,
            isLoaded: false,
            isPlaying: false,
            isMuted: false,
            isFullscreen: false,
            startTime: '00:00.000',
            endTime: '00:00.000',
            durationSlider: [props.trimData.start, props.trimData.end],
            previousDragXPercentage: 0
        };

        this.shiftX = 0;
    }

    componentDidMount() {
        const { trimData } = this.props;

        this.setState((prevState) => {
            const newState = {
                ...prevState,
                currentTime: trimData.start || 0
            };
            if (trimData.end) {
                newState.endTime = AssetEditorCropperHelper.sec2Time(trimData.end, true);
            }
            if (trimData.start) {
                this.videoRef.current.currentTime = trimData.start;
                newState.startTime = AssetEditorCropperHelper.sec2Time(trimData.start, true);
            }
            return newState;
        });

        document.addEventListener('fullscreenchange', this.toggleFullscreen);
        // Add event listeners for keyboard controls
        document.addEventListener('keydown', this.handleKeyDown);

        this.sliderHandleRef.current?.addEventListener('mousedown', this.handleSliderDragStart);
        this.videoCropperRef.current?.addEventListener('mousemove', this.handleSliderDrag);
        document.addEventListener('mouseup', this.handleSliderDragEnd);
        if (this.videoRef.current) {
            this.videoRef.current.addEventListener('canplay', this.videoLoaded);
            this.videoRef.current.addEventListener('error', this.videoError);
            this.videoRef.current.addEventListener('loadeddata', (event) => this.onVideoLoaded(event));
        }

        this.playButtonRef.current.focus({ preventScroll: true }); // Set focus on the play button
        this.playButtonRef.current.blur(); // Remove hover effect from the play button when the play button is focused.
    }

    componentDidUpdate(_, prevState) {
        // Check if the relevant props or state have changed
        if (
            prevState.durationSlider[0] !== this.state.durationSlider[0] ||
            prevState.durationSlider[1] !== this.state.durationSlider[1] ||
            prevState.maxLength !== this.state.maxLength
        ) {
            // Recalculate the overlay styles
            const overlayStyles = this.calculateOverlayStyles();
            this.setState({ overlayStyles }); // You can store it in state or a class property if needed
        }
    }

    componentWillUnmount() {
        this.sliderHandleRef.current?.removeEventListener('mousedown', this.handleSliderDragStart);
        this.videoCropperRef.current?.removeEventListener('mousemove', this.handleSliderDrag);
        document.removeEventListener('mouseup', this.handleSliderDragEnd);
        document.removeEventListener('fullscreenchange', this.toggleFullscreen);
        this.videoRef.current.removeEventListener('canplay', this.videoLoaded);
        this.videoRef.current.removeEventListener('error', this.videoError);
        this.videoRef.current.removeEventListener('loadeddata', (event) => this.onVideoLoaded(event));

        document.removeEventListener('keydown', this.handleKeyDown); // Remove event listeners when the component unmounts
    }

    /**
     * Get the latest video duration when the video is loaded.
     * Trigger the onVideoLoad callback.
     */
    onVideoLoaded = (event) => {
        this.getVideoDuration();
        this.props.onVideoLoad(event, this.videoRef);
    };

    /**
     * Set isLoaded state to true.
     */
    videoLoaded = () => {
        this.setState({ isLoaded: true });
    };

    /** Reset the video when an error occurs.*/
    resetVideo = () => {
        const video = this.videoRef.current;
        video.src = '';
        video.src = this.getVideoUrl();
        video.load();

        this.resetCountRef.current = 1; // Set the reset count to 1
    };

    /** On video error reset video else show an error message when the video fails to load */
    videoError = () => {
        // If the reset count is not set yet, reset the video else show an error message.
        if (!this.resetCountRef.current) {
            this.resetVideo();
            return;
        }

        SnackbarUtils.error(Translation.get('assetGalleryCropper.videoError', 'content-space'));
    };

    /**
     * getVideoDuration
     * Gets the video duration of the current loaded video
     */
    getVideoDuration = () => {
        if (this.videoRef.current && this.videoRef.current.duration) {
            this.setState(
                (prevState) => ({
                    maxLength: this.videoRef.current.duration,
                    endTime: this.props.trimData.end ? prevState.endTime : AssetEditorCropperHelper.sec2Time(this.videoRef.current.duration, true),
                    durationSlider: this.props.trimData.end ? prevState.durationSlider : [prevState.durationSlider[0], this.videoRef.current.duration]
                }),
                () => {
                    !this.props.trimData.end && this.props.changeTrimData({ ...this.props.trimData, end: this.videoRef.current.duration });
                }
            );
        }
    };

    /**
     * Callback function that sets the state of `isPlaying` to true when the video starts playing.
     */
    onVideoPlay = () => {
        this.setState({ isPlaying: true });
        this.onVideoTimeUpdate();
    };

    /**
     * Callback function that is called when the video ends.
     * Sets the current time of the video to the start time of the trim data and stops the video playback.
     */
    onVideoEnd = () => {
        this.setState({ isPlaying: false });
    };

    /**
     * On every animation frame, update the progress bar.
     */
    onVideoTimeUpdate = () => {
        const { trimData } = this.props;

        // Request the next animation frame.
        this.animationId = requestAnimationFrame(this.onVideoTimeUpdate);

        // Update the progress bar.
        if (this.videoRef.current?.currentTime) {
            ComponentStoreHelpers.setModel('AssetEditor', 'videoCropperState.progressBar', this.videoRef.current?.currentTime);
        }

        // Cancel the animation frame if the video is paused
        if (this.videoRef.current?.paused) {
            cancelAnimationFrame(this.animationId);
        }

        // Check if the video needs to be paused.
        if (!this.videoRef.current?.paused && this.videoRef.current?.currentTime >= trimData.end) {
            this.videoRef.current.currentTime = trimData.start;
            this.videoRef.current.pause();

            this.setState({ isPlaying: false });
        }
    };

    /**
     * handlePlayDuration
     * Updates the slider values when user drags the slider
     */
    handlePlayDuration = (_event, newValue) => {
        const { minimumDuration, maximumDuration, duration } = this.props;
        const { durationSlider, maxLength } = this.state;

        this.setSliderHandleWidth();

        // Check if the video slider should be moved to the left or right.
        if (durationSlider[0] !== newValue[0]) {
            this.onChangeProgress(_event, newValue[0]);
        } else if (durationSlider[1] !== newValue[1]) {
            this.onChangeProgress(_event, newValue[1]);
        }

        if (newValue[0] === durationSlider[0] && newValue[1] === durationSlider[1]) return;

        let start = newValue[0],
            end = newValue[1];

        if (newValue[1] === durationSlider[1]) {
            if ((duration || minimumDuration) && newValue[0] + (duration || minimumDuration) > maxLength) {
                start = maxLength - (duration || minimumDuration);
                end = maxLength;
            } else if (duration) {
                end = newValue[0] + duration;
            } else if (maximumDuration && end - start > maximumDuration) {
                end = start + maximumDuration;
            } else if (minimumDuration && end - start < minimumDuration) {
                end = start + minimumDuration;
            }
        } else if (newValue[0] === durationSlider[0]) {
            if ((duration || minimumDuration) && newValue[1] < (duration || minimumDuration)) {
                start = 0;
                end = duration || minimumDuration;
            } else if (duration) {
                start = end - duration;
            } else if (maximumDuration && end - start > maximumDuration) {
                start = end - maximumDuration;
            } else if (minimumDuration && end - start < minimumDuration) {
                start = end - minimumDuration;
            }
        }

        this.props.changeTrimData({ start, end });
        this.setState({ durationSlider: [start, end] });
    };

    // Updates the state after user releases the slider.
    setDuration = (type, val) => {
        const { trimData, minimumDuration, maximumDuration, duration, changeTrimData } = this.props;
        const { durationSlider, maxLength } = this.state;

        if (durationSlider[0] === trimData.start && durationSlider[1] === trimData.end && typeof type !== 'string') return;

        let start = durationSlider[0],
            end = durationSlider[1];

        if (type === 'start') {
            start = val;
            if (duration) {
                if (maxLength - start < duration) {
                    start = maxLength - duration;
                    end = maxLength;
                } else {
                    end = start + duration;
                }
            } else if (maximumDuration && end - start > maximumDuration) {
                end = val + maximumDuration;
            } else if (minimumDuration) {
                if (maxLength - start < minimumDuration) {
                    start = maxLength - minimumDuration;
                    end = maxLength;
                } else if (end - start < minimumDuration) {
                    end = val + minimumDuration;
                }
            } else if (start > end) {
                const cur_duration = durationSlider[1] - durationSlider[0];
                end = start + cur_duration < maxLength ? start + cur_duration : maxLength;
            }
        } else if (type === 'end') {
            end = val;
            if (duration) {
                if (end < duration) {
                    start = 0;
                    end = duration;
                } else {
                    start = val - duration;
                }
            } else if (maximumDuration && end - start > maximumDuration) {
                start = val - maximumDuration;
            } else if (minimumDuration) {
                if (end < minimumDuration) {
                    start = 0;
                    end = minimumDuration;
                } else if (end - start < minimumDuration) {
                    start = val - minimumDuration;
                }
            } else if (end < start) {
                const cur_duration = durationSlider[1] - durationSlider[0];
                start = end - cur_duration > 0 ? end - cur_duration : 0;
            }
        }
        changeTrimData({ start, end });
        this.setState({
            isPlaying: false,
            durationSlider: [start, end],
            // currentTime: start,
            startTime: AssetEditorCropperHelper.sec2Time(start, true),
            endTime: AssetEditorCropperHelper.sec2Time(end, true)
        });
        // this.videoRef.current.currentTime = start;
    };

    /**
     * Custom label render function
     */
    ValueLabelComponent(props) {
        const { children, open, value } = props;

        return (
            <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
                <>{children}</>
            </Tooltip>
        );
    }

    /**
     * togglePlay
     * Toggles the video between play and pause
     */
    togglePlay = () => {
        const { isPlaying } = this.state;
        isPlaying ? this.videoRef.current.pause() : this.videoRef.current.play();
        this.setState({ isPlaying: !isPlaying });
    };

    /**
     * toggleMute
     * Toggles the audio of the video element
     */
    toggleMute = () => {
        const { isMuted } = this.state;
        this.setState({ isMuted: !isMuted });
    };

    /**
     * Opens the asset in full screen
     */
    openFullscreen = () => {
        const elem = this.videoRef.current;

        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem.mozRequestFullScreen) {
            /* Firefox */
            elem.mozRequestFullScreen();
        } else if (elem.webkitRequestFullscreen) {
            /* Chrome, Safari & Opera */
            elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) {
            /* IE/Edge */
            elem.msRequestFullscreen();
        }
    };

    // toggle fullscreen state
    toggleFullscreen = () => {
        const { isFullscreen } = this.state;
        this.setState({ isFullscreen: !isFullscreen });
    };

    // change the currentTime of video when user slides the video progress bar
    onChangeProgress = (e, newValue) => {
        this.setIsVideoSliderDragging(true);
        this.videoRef.current.currentTime = newValue;

        ComponentStoreHelpers.setModel('AssetEditor', 'videoCropperState.progressBar', newValue);
    };

    // converts the user input (number) in to a string in format: MM:SS:MMM
    handleTimeInput = (e, target) => {
        let value = e.target.value;

        // do nothing when more than 4 numbers are filled in
        if (value.length > 9) return;

        // check if input is number
        if (isNaN(value.replace(':', ''))) return;

        // place ':' when third number is filled in
        if (value.length === 3) {
            value = value.slice(0, 2) + (value[2] !== ':' ? ':' + value.slice(2) : '');
        }
        // place '.' when sixth number is filled in
        if (value.length === 6) {
            value = value.slice(0, 5) + (value[5] !== '.' ? '.' + value.slice(5) : '');
        }

        this.setState({ [target]: value });
    };

    /**
     *
     * @param {Event} e
     * Calculate position of mouse compared to left of the drag handle
     */
    handleSliderDragStart = (e) => {
        this.shiftX = e.clientX - this.trackRef.current.getBoundingClientRect().left;

        this.setState({ dragging: true });
    };

    /**
     *
     * @param {Event} e
     * @returns null
     * Calculates drag coordinates in percentages then
     * multiplies total time value of slider to get new timing values
     */
    handleSliderDrag = (e) => {
        if (!this.state.dragging) return;

        const { maxLength } = this.state;
        const parentLeft = this.trackRef.current.parentNode.getBoundingClientRect().left;
        const parentWidth = this.trackRef.current.parentNode.offsetWidth;
        const currentWidth = this.trackRef.current.offsetWidth;

        const dragXPercentage = (e.clientX - parentLeft - this.shiftX) / parentWidth;

        const handleWidthPercentage = currentWidth / parentWidth;

        const dragXTimingLeft = maxLength * (dragXPercentage + handleWidthPercentage);
        const dragXTimingRight = maxLength * dragXPercentage;

        const newValue = [dragXTimingRight, dragXTimingLeft];

        //Check if slider handle is not dragged out of slider
        if (dragXTimingLeft < maxLength && dragXTimingRight > 0) {
            this.handlePlayDuration(e, newValue);
        }

        e.stopPropagation();
    };

    /**
     * Calculates the width of the slider handle one more time after a small delay and sets dragging false
     */
    handleSliderDragEnd = () => {
        this.setState({ dragging: false });
        setTimeout(this.setSliderHandleWidth, 100);
    };

    /**
     * calculates the left and right of the slider handle based on the position of the track
     */
    setSliderHandleWidth = () => {
        if (this.sliderHandleRef.current) {
            this.sliderHandleRef.current.style.left = this.trackRef.current.offsetLeft + 'px';
            this.sliderHandleRef.current.style.right =
                this.trackRef.current.parentNode.offsetWidth - (this.trackRef.current.offsetLeft + this.trackRef.current.offsetWidth) + 'px';
        }
    };

    /**
     * Returns the URL of the video to be displayed in the video cropper.
     * If `showEditControls` is true and the video has an originalVideo property, returns the originalVideo URL.
     * Otherwise, returns the regular video URL.
     * @returns {string} The URL of the video to be displayed in the video cropper.
     */
    getVideoUrl = () => {
        const { video } = this.props;

        if (video.originalVideo && this.props.showEditControls) {
            return video.originalVideo; // Return original video.
        }
        return video.url; // Return modified video.
    };

    // Calculate the left position and width for the overlay slider
    calculateOverlayStyles() {
        const min = this.state.durationSlider[0];
        const max = this.state.durationSlider[1];

        const overlayWidth = ((max - min) / this.state.maxLength) * 100; // Calculate the width as a percentage
        const overlayLeft = (min / this.state.maxLength) * 100; // Calculate the left position as a percentage
        return { width: `${overlayWidth}%`, left: `${overlayLeft}%` };
    }

    handleKeyDown = (e) => {
        if (e.key === ' ') {
            // Toggle play/pause when the Space bar is pressed
            this.togglePlay();
            // Unfocus the play button, to prevent double triggering of the play/pause function.
            this.playButtonRef.current.blur();
        }
    };

    /**
     * Sets the state of `isVideoSliderDragging` to the given value.
     * @param {boolean} isDragging - The new value of `isVideoSliderDragging`.
     */
    setIsVideoSliderDragging = (isDragging) => {
        this.setState({ isVideoSliderDragging: isDragging });
    };

    /**
     * Returns the CSS class for the video slider track based on whether the video slider is being dragged or not.
     * If dragging is active, the the transition wil be set to 0s else transition will be set to 0.3s
     */
    getVideoSliderTrackClass = () => {
        return this.state?.isVideoSliderDragging ? 'video-cropper__video-wrapper__progress__track-active' : 'video-cropper__video-wrapper__progress__track';
    };

    render() {
        const { maxLength, isLoaded, isPlaying, isMuted, isFullscreen, durationSlider, dragging, overlayStyles } = this.state;
        const { ratio, canCrop, outputWidth, outputHeight, isProcessing, changeCropData, cropData } = this.props;

        return (
            <div className="video-cropper" ref={this.videoCropperRef}>
                <div
                    className={classNames('video-cropper__video-wrapper', {
                        ['video-cropper__video-wrapper--fullscreen']: isFullscreen,
                        ['video-cropper__video-wrapper--video-loaded']: isLoaded
                    })}>
                    <AssetVideoCropperOverlay
                        canCrop={canCrop && this.props.showEditControls}
                        ratio={ratio}
                        outputWidth={outputWidth}
                        outputHeight={outputHeight}
                        cropData={cropData}
                        setData={changeCropData}
                        manualInput={this.props.manualInput}
                        cropMode={this.props.cropMode}>
                        <video
                            muted={isMuted || isProcessing}
                            ref={this.videoRef}
                            onEnded={this.onVideoEnd}
                            onPlay={this.onVideoPlay}
                            src={this.getVideoUrl()}
                        />
                    </AssetVideoCropperOverlay>
                    {this.props.showEditControls ? (
                        <div className="video-cropper__controls">
                            <div className="video-cropper__controls__slider">
                                <Slider
                                    classes={{ rail: 'video-cropper__controls__slider__rail', track: 'video-cropper__controls__slider__track' }}
                                    value={durationSlider}
                                    onChangeCommitted={this.setDuration}
                                    onChange={this.handlePlayDuration}
                                    slots={{ valueLabel: this.ValueLabelComponent }}
                                    slotProps={{
                                        track: { ref: this.trackRef, sx: { transition: 'none' } },
                                        thumb: { sx: { pointerEvents: 'all' } },
                                        root: { sx: { pointerEvents: 'none' } }
                                    }}
                                    valueLabelDisplay="auto"
                                    valueLabelFormat={(e) => AssetEditorCropperHelper.sec2Time(e, true)}
                                    max={maxLength}
                                    step={0.1}
                                />
                                <div className="video-cropper__controls__slider__overlay" style={overlayStyles}>
                                    <VideoProgressBar
                                        classes={{
                                            rail: 'video-cropper__controls__slider__rail-transparent',
                                            track: 'video-cropper__controls__slider__track-transparent',
                                            thumb: 'video-cropper__controls__slider__thumb-line',
                                            thumbColorPrimary: 'video-cropper__controls__slider__thumb-line',
                                            valueLabel: 'video-cropper__controls__slider__thumb-label'
                                        }}
                                        onChange={this.onChangeProgress}
                                        valueLabelDisplay="on"
                                        valueLabelFormat={(e) => AssetEditorCropperHelper.sec2Time(e, true)}
                                        min={durationSlider[0]}
                                        max={durationSlider[1]}
                                        step={0.1}
                                    />
                                </div>
                                <div
                                    className={classNames('video-cropper__controls__slider__handle', {
                                        'video-cropper__controls__slider__handle--dragging': dragging
                                    })}
                                    draggable="false"
                                    ref={this.sliderHandleRef}
                                />
                            </div>
                        </div>
                    ) : (
                        <div className="video-cropper__video-wrapper__progress">
                            <VideoProgressBar
                                color="primary"
                                classes={{ thumb: 'video-cropper__video-wrapper__progress__thumb', track: this.getVideoSliderTrackClass() }}
                                min={0}
                                max={maxLength}
                                step={0.1}
                                onChange={this.onChangeProgress}
                                onChangeCommitted={() => this.setIsVideoSliderDragging(false)}
                            />
                        </div>
                    )}

                    <div className="video-cropper__video-wrapper__video-controls">
                        <div className="volume">
                            <IconButton onClick={this.toggleMute} aria-label="volume" size="small">
                                {isMuted ? <Icon>volume_off</Icon> : <Icon>volume_up</Icon>}
                            </IconButton>
                        </div>

                        <IconButton onClick={this.togglePlay} aria-label="play" size="large" className="play" ref={this.playButtonRef}>
                            {isPlaying ? <Icon fontSize="large">pause_circle</Icon> : <Icon fontSize="large">play_circle</Icon>}
                        </IconButton>

                        <AssetVideoTime className="time" maxTime={maxLength} />
                    </div>
                </div>
            </div>
        );
    }
}
