import React, { useState } from 'react';
import { TemplateType } from 'types/templates.type';
import { FileType } from 'components/bricks/types/brick.type';
import CreativeBuilderTemplateDialog from 'components/creative-builder/CreativeBuilderTemplateDialog';
import Setup from 'components/data/Setup';
import { CreativeV2, CreativeV2Template } from 'components/creatives-v2/components/creative-editor/types/creativeV2.type';
import { CreativeEditorV2 } from 'components/creatives-v2/components/creative-editor';
import { isTemplateCreative } from 'components/creatives-v2/guards/creative-type-guards';
import AssetGalleryDialogProps from 'components/assets/AssetGalleryDialog/interfaces/AssetGalleryDialogProps';
import AssetGalleryDialogSelectorType from 'components/assets/AssetGalleryDialog/interfaces/AssetGalleryDialogSelectorType';
import AssetGalleryDialog from 'components/assets/AssetGalleryDialog';
import { isAMV2Enabled } from 'components/template-management/utilities';
import cloneDeep from 'helpers/cloneDeep';
import Translation from 'components/data/Translation';
import { SingleCreativeSelector } from './single-selector';
import { MultiCreativeSelector } from './multi-selector';
import { createCreativeBasedOnCampaignAsset, createCustomUploadCreative, createTemplateCreative } from '../utils/create-creative';

import '../styles/main.scss';
import { TemplateManager } from 'components/creatives-v2/data/template-manager';

interface AdditionalVars {
    onActiveFormatChange?: (activeFormats: string[]) => void;
}
interface Props {
    value?: CreativeV2[];
    templateTypes: TemplateType[];
    multiple?: boolean;
    onMutation: (creatives: CreativeV2[]) => void;
    isButton?: boolean;
    customButton?: React.ReactNode;
    selectors?: AssetGalleryDialogSelectorType[];
    fileType?: FileType | FileType[];
    canEditCreative?: boolean;
    additionalVars?: AdditionalVars;
}

const CreativeSelector = ({
    value = [],
    templateTypes = ['displayAdDesigned'],
    selectors = ['template'],
    fileType = ['image', 'video'],
    multiple = false,
    isButton = false,
    customButton,
    canEditCreative = true,
    onMutation,
    additionalVars
}: Props) => {
    const [creativeEditorOpen, setCreativeEditorOpen] = useState<boolean>(false);
    const [activeCreative, setActiveCreative] = useState<CreativeV2Template | undefined>();
    const [assetGalleryDialogOpen, setAssetGalleryDialogOpen] = useState<boolean>(false);
    // TODO: When template management is done we can remove this
    const assetGalleryDialogProps: AssetGalleryDialogProps = {
        selectors, // Enable selectors for the AssetGalleryDialog. //  add 'upload', 'contentSpace'
        open: true, // This is being overwritten by TemplateDialog.
        fileType, // Filetype is set to handle images and videos.
        canUseContentSpace: true, // Enables the content space selector.
        onMutation: (data: any, dataType?: undefined | 'sourceData') => handleOnMutationAssetGallery(data, dataType), // Handle Onutation for the AssetGalleryDialog.
        onClose: () => null // This is being overwritten by TemplateDialog.
    };

    /**
     * Update the creatives array based on changes from the single- and multiselector (e.g. after a user has updated or removed a creative
     * @param updatedCreatives The updated creatives array
     */
    const updateCreatives = (updatedCreatives: CreativeV2[]) => {
        if (updatedCreatives.length > 0 && updatedCreatives[0].data) {
            const activeFormats = updatedCreatives[0].data?.settings?.activeFormats ?? [];

            // This is needed in order to update the active formats outside the multiple input component.
            if (additionalVars && additionalVars.onActiveFormatChange) {
                additionalVars.onActiveFormatChange(activeFormats);
            }
        }

        if (updatedCreatives.length === 0) {
            onMutation([]);
            return;
        }

        // Go over the new creatives array and push the unenriched creatives to redux
        const cleanedCreatives: CreativeV2[] = updatedCreatives.filter((creative): creative is CreativeV2 => !!creative);

        onMutation(cleanedCreatives);
    };

    const editCreative = (creative: CreativeV2) => {
        if (isTemplateCreative(creative)) {
            setActiveCreative(cloneDeep(creative));
            setCreativeEditorOpen(true);
        }
    };

    const addCreative = (creative: CreativeV2, preventEdit = false) => {
        if (!value) value = [];

        if (multiple) {
            onMutation([...value, creative]);
        } else {
            onMutation([creative]);
        }

        setAssetGalleryDialogOpen(false);

        if (canEditCreative && isTemplateCreative(creative) && !preventEdit) editCreative(creative);
    };

    // We can handle both custom uploads and campaign assets here. If we choose a creative in the asset gallery via the campaign assets,
    // we will reuse this creative and assetManagerId
    const handleOnMutationAssetGallery = (customUploadData: any, dataType?: undefined | string) => {
        if (dataType === 'sourceData') return;
        if (!customUploadData) return;

        let creative: CreativeV2 | undefined;

        switch (dataType) {
            case 'campaignAssets':
                creative = createCreativeBasedOnCampaignAsset(customUploadData);
                break;
            default:
                creative = createCustomUploadCreative(customUploadData);
                break;
        }

        addCreative(creative, true);
    };

    /**
     * When a user selects an item from the template dialog, we will create a new creative based on the selected template
     * TODO: CREATIVES-v2: Only supports templates for now. We should add support for creatives and media assets as well
     * @param selectedTemplate
     */
    const onSelectTemplate = (selectedTemplate: any) => {
        if (!selectedTemplate?.data?.identifier) return;

        TemplateManager.addTemplate(selectedTemplate);

        const creative = createTemplateCreative(selectedTemplate);

        addCreative(creative);
    };

    const handleOnCloseCE = () => {
        setCreativeEditorOpen(false);
    };

    // When the data of the creative changes, we should also update that data in the creative in the creatives array
    // Otherwise we have to enrich it again via a call to the backend
    const handleSaveTemplateCreative = (updatedCreative: CreativeV2Template) => {
        const newCreatives = cloneDeep(value).map((creative: CreativeV2) => {
            if (isTemplateCreative(creative) && creative.id === updatedCreative.id) {
                return updatedCreative;
            }
            return creative;
        });

        onMutation(newCreatives);
    };

    const getSingleCreativeValue = () => {
        if (Array.isArray(value)) {
            return value[0];
        }

        return value;
    };

    const openAssetGalleryDialog = () => {
        setAssetGalleryDialogOpen(true);
    };

    return (
        <div className="creative-selector">
            {!multiple && (
                <SingleCreativeSelector
                    creative={getSingleCreativeValue()}
                    openAssetGalleryDialog={openAssetGalleryDialog}
                    editCreative={editCreative}
                    onChange={updateCreatives}
                    isButton={isButton}
                    customButton={customButton}
                />
            )}
            {multiple && (
                <MultiCreativeSelector
                    creatives={value}
                    openAssetGalleryDialog={openAssetGalleryDialog}
                    editCreative={editCreative}
                    onChange={updateCreatives}
                />
            )}

            {assetGalleryDialogOpen && isAMV2Enabled() && (
                <AssetGalleryDialog
                    {...assetGalleryDialogProps}
                    title={Translation.get('formflow.JSON.addItem', 'common')}
                    onMutation={assetGalleryDialogProps?.onMutation ?? (() => null)}
                    open
                    fullWidth
                    fixedHeightPaperScrollPaper
                    onClose={() => setAssetGalleryDialogOpen(false)}
                    selectors={selectors}
                    selectorsProps={{
                        templateProps: {
                            subType: templateTypes,
                            onSelectItem: onSelectTemplate
                        }
                    }}
                />
            )}

            {assetGalleryDialogOpen && !isAMV2Enabled() && (
                <CreativeBuilderTemplateDialog
                    open
                    templateTypes={templateTypes}
                    showCreatives={Setup.hasModule('creativeManagement')}
                    onClose={() => setAssetGalleryDialogOpen(false)}
                    onSelectItem={onSelectTemplate}
                    assetGalleryDialogProps={assetGalleryDialogProps}
                />
            )}

            {creativeEditorOpen && activeCreative && (
                <CreativeEditorV2 creativeV2Template={activeCreative} onClose={handleOnCloseCE} onSaveCreative={handleSaveTemplateCreative} />
            )}
        </div>
    );
};

export default CreativeSelector;
