import React from 'react';
import PropTypes from 'prop-types';
import GoogleAdsDemandGen from './google-ads-demand-gen';
import './../styles/main.scss';

class PreviewGoogleAds extends React.Component {
    static propTypes = {
        adType: PropTypes.string,
        advertiser: PropTypes.shape({
            name: PropTypes.string,
            logo: PropTypes.string
        }),
        headline: PropTypes.string,
        displayUrl: PropTypes.string
    };

    static defaultProps = {
        adType: 'demandGen',
        advertiser: {
            name: 'Cape',
            logo: ''
        }
    };

    static getChild(adType, props) {
        let component = null;

        switch (adType) {
            case 'demandGen':
                component = <GoogleAdsDemandGen {...props} />;
                break;

            default:
                component = null;
        }

        return component;
    }

    render() {
        const { adType } = this.props;
        return <div className="social-preview__google-ads">{PreviewGoogleAds.getChild(adType, this.props)}</div>;
    }
}

export default PreviewGoogleAds;
