import PropTypes from 'prop-types';
import React, { Component } from 'react';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import ListItemText from '@mui/material/ListItemText';
import Dialog from 'components/ui-components-v2/Dialog';
import Icon from 'components/ui-components-v2/Icon';
import IconButton from '@mui/material/IconButton';
import ListItem from 'components/ui-components-v2/ListItem';
import List from 'components/ui-components-v2/List';
import Divider from 'components/ui-components-v2/Divider';
import TextField from 'components/ui-components-v2/TextField';
import User from 'components/data/User';

const Transition = React.forwardRef((props, ref) => {
    return <Slide direction="up" ref={ref} {...props} />;
});

Transition.displayName = 'Transition';

/**
 * SelectDialog
 * The dialog for selecting from a list of text options.
 * The options can be managed if you have the rights to do so.
 */
export class SelectDialog extends Component {
    static propTypes = {
        onChange: PropTypes.func,
        data: PropTypes.object,
        onClose: PropTypes.func
    };

    constructor(props) {
        super(props);

        this.state = {
            data: props.data ? props.data : {},
            selectDialogOpen: false,
            canEdit: User.hasLevel(props.selectDialogEditUserLevel),
            editorMode: false
        };
        if (!this.state.data.options) {
            this.state.data.options = [];
        }
    }

    /**
     * Selecting an individual item
     */
    handleSelect = (item) => {
        const data = { ...this.state.data };
        data.value = item;
        this.props.onChange(data, true);
    };

    /**
     * Removing an item from the list
     */
    handleRemoveItem = (index) => {
        const data = { ...this.state.data };
        data.options.splice(index, 1);
        this.setState({ data: data });
        this.props.onChange(data, false);
    };

    /**
     * Toggle edit mode
     * This changes the usr
     */
    toggleEditMode = () => {
        const newState = this.state.editMode ? false : true;
        const data = { ...this.state.data };
        data.options = data.options.filter((option) => {
            return option !== '';
        });
        if (newState) {
            data.options.push('');
        }
        this.setState({ editMode: newState, data: data });
    };

    /**
     * Change
     */
    handleChangeItem = (event, index) => {
        const data = { ...this.state.data };
        if (data.options[index] === '') {
            data.options.push('');
        }
        data.options[index] = event.target.value;
        this.setState({ data: data });
    };

    /**
     * On close the view
     */
    onClose = () => {
        const data = { ...this.state.data };
        data.options = data.options.filter((option) => {
            return option !== '';
        });
        this.props.onChange(data, true);
    };

    render() {
        const { data, canEdit, editMode } = this.state;

        return (
            <Dialog open={true} TransitionComponent={Transition} onClose={this.onClose} fullWidth={true} maxWidth="sm">
                <DialogTitle>
                    <div style={{ display: 'flex' }}>
                        <div style={{ flexGrow: 1 }}>{'Select preset copy'}</div>
                        {canEdit && (
                            <div style={{ alignContent: 'flex-end' }}>
                                <IconButton size="small" edge="end" aria-label="delete" onClick={() => this.toggleEditMode()}>
                                    {editMode && <Icon>view_list</Icon>}
                                    {!editMode && <Icon>edit</Icon>}
                                </IconButton>
                            </div>
                        )}
                    </div>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {canEdit && editMode && (
                            <React.Fragment>
                                <div>You can edit the items below.</div>
                                <List>
                                    {data.options.map((item, index) => (
                                        <React.Fragment key={index}>
                                            <ListItem
                                                secondaryAction={
                                                    <IconButton size="small" edge="end" aria-label="delete" onClick={() => this.handleRemoveItem(index)}>
                                                        <Icon>delete</Icon>
                                                    </IconButton>
                                                }
                                                button
                                                key={'item_' + index}>
                                                <ListItemText primary={<TextField onChange={(e) => this.handleChangeItem(e, index)} value={item} />} />
                                            </ListItem>
                                            <Divider />
                                        </React.Fragment>
                                    ))}
                                </List>
                            </React.Fragment>
                        )}

                        {!editMode && (
                            <React.Fragment>
                                <div>Please select the preferred copy below.</div>
                                <List>
                                    {data.options.map((item, index) => (
                                        <React.Fragment key={index}>
                                            <ListItem button onClick={() => this.handleSelect(item)} key={item}>
                                                <ListItemText primary={item} />
                                            </ListItem>
                                            <Divider />
                                        </React.Fragment>
                                    ))}
                                </List>
                            </React.Fragment>
                        )}
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        );
    }
}

export default SelectDialog;
