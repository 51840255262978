import React from 'react';
import camelCase from 'lodash/camelCase';
import AssetGalleryInput from 'components/input/AssetGalleryInput';
import { FontType } from 'components/template-designer/types/font.type';
import { getTemplateData } from 'components/template-designer/helpers/data.helpers';
import { DesignerSettings } from 'components/template-designer/types/template.type';
import TemplateDesignerStore from 'components/template-designer/data/template-designer-store';
import { FontHelpers } from 'components/template-designer/helpers/font.helpers';
import SnackbarUtils from 'components/ui-base/SnackbarUtils';
import Translation from 'components/data/Translation';
import { DEFAULT_FONT_FILE_TYPES } from 'components/template-designer/constants';
import '../styles/font-picker-add-font-dialog.scss';

interface Props {
    trigger: React.ReactElement;
    setCurrentFontSource: (fontSource: FontType) => void;
    onAdd: (fontSource: FontType, fontFamily: string, fontLabel: string) => void;
}

const FontPickerAddFontDialog = ({ trigger, setCurrentFontSource, onAdd }: Props) => {
    /**
     * Add font to the template fonts.
     * @param file - The font file.
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleAddFont = (data: any) => {
        const currentTemplateFonts = getTemplateData<DesignerSettings['templateFonts']>('designerSettings.templateFonts') ?? [];

        const newTemplateFont = {
            extension: data.extension,
            humanSize: data.humanSize,
            key: camelCase(data.title),
            size: data.size,
            value: camelCase(data.title),
            title: data.title,
            url: data.url
        };

        if (currentTemplateFonts.find((font) => font.key === newTemplateFont.key)) {
            return SnackbarUtils.error(
                Translation.get('sidebarRight.inputs.fontPicker.alreadyAdded', 'template-designer', {
                    font: newTemplateFont.title
                })
            );
        }

        onAdd('templateFonts', newTemplateFont.value, newTemplateFont.value);
        currentTemplateFonts.push(newTemplateFont);
        FontHelpers.loadTemplateFonts(currentTemplateFonts);
        TemplateDesignerStore.save(['designerSettings.templateFonts', currentTemplateFonts], { saveToHistory: false });
        setCurrentFontSource('templateFonts');
    };

    return (
        <AssetGalleryInput
            className="template-designer__font-picker-add-font-dialog"
            multiple={false}
            value={{}}
            fileType={DEFAULT_FONT_FILE_TYPES}
            onMutation={(data, type) => {
                if (type === 'sourceData') return;
                handleAddFont(data);
            }}>
            {({ onClickOpen }) => React.cloneElement(trigger, { onClick: () => onClickOpen(true) })}
        </AssetGalleryInput>
    );
};

export { FontPickerAddFontDialog };
