/* ---------- CREATIVE DATA CONSTANTS ---------- */

// We use these constants to prevent mistakes in setting the models in the creative.
// These are also used in the creative-data.helpers.ts (getDataFromCreative()) to get data from the creative.
// It prevents a lot of code like: frameTypes = creative?.data?.template?.templateSetup?.frameTypes

// Template identifier
export const TEMPLATE_IDENTIFIER = 'data.templateIdentifierentifier';

// Template input
export const TEMPLATE_INPUT = 'data.templateInput';
export const FRAMES = 'data.templateInput.frames';

// Frame previews
export const FRAME_PREVIEWS = 'data.framePreviews';

// Settings
export const ACTIVE_FORMATS = 'data.settings.activeFormats';
export const FRAMESCOUNT = 'data.settings.frames';

/* ---------- UI CONSTANTS ---------- */

// Width per frame in pixels
export const FRAME_WIDTH_PER_SECOND = 40;

// Width per gap in pixels between frames. If you change this, also check frames-bar.scss
export const GAP_WIDTH = 12;
