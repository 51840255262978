import { StaticAsset } from '../types/template-management.type';

const convertStaticV2ToStaticV1 = (item: StaticAsset): any => {
    const { _id, title, data } = item;
    const { description, interfaceSetup } = data;
    return {
        id: _id,
        title,
        description,
        type: 'staticAsset',
        identifier: data.identifier,
        interfaceSetup
    };
};

export { convertStaticV2ToStaticV1 };
