import * as Sentry from '@sentry/react';
import axios from 'axios';
import EditorData from 'components/editor-data/EditorData';
import { Brick, BrickToCreate } from 'components/bricks/types/brick.type';
import SnackbarUtils from 'components/ui-base/SnackbarUtils';
import User from 'components/data/User';
import PublishHelpers from '../helpers/publish.helpers';
import { BricksObject, Filters } from '../types/bricksComponentStore.type';
import { BrickFilterRoles } from '../types/filter.type';
import { PAGE_LIMIT } from '../constants';

export default class BrickDataService {
    static updateBricks = async (data: Brick[]): Promise<Brick[] | undefined> => {
        const campaignId = EditorData.getId();

        try {
            const response = await axios.patch(process.env.APP_V2_API_HOST + `bricks`, data, {
                params: { campaignId },
                headers: { Authorization: `Bearer ${User.get('apiToken')}` }
            });
            return response.data;
        } catch (error: unknown) {
            Sentry.captureException(error);
            SnackbarUtils.error('Failed to save brick');

            if (error instanceof Error) {
                throw new Error(error?.message);
            }
        }
    };

    static saveBricks = async (data: BrickToCreate[]): Promise<Brick[] | undefined> => {
        const campaignId = EditorData.getId();

        try {
            const response = await axios.post(process.env.APP_V2_API_HOST + 'bricks', data, {
                params: { campaignId },
                headers: { Authorization: `Bearer ${User.get('apiToken')}` }
            });
            return response.data;
        } catch (error: unknown) {
            Sentry.captureException(error);
            SnackbarUtils.error('Failed to save brick');

            if (error instanceof Error) {
                throw new Error(error?.message);
            }
        }
    };

    static getBricksOverview = async (): Promise<Brick[] | undefined> => {
        const campaignId = EditorData.getId();

        try {
            const response = await axios.get(process.env.APP_V2_API_HOST + 'bricks', {
                params: { campaignId },
                headers: { Authorization: `Bearer ${User.get('apiToken')}` }
            });
            return response.data;
        } catch (error: unknown) {
            Sentry.captureException(error);
            SnackbarUtils.error('Failed to get the data');

            if (error instanceof Error) {
                throw new Error(error?.message);
            }
        }
    };

    static loadMoreChildrenOfBrick = async (brickId: string, offset: number): Promise<BricksObject | undefined> => {
        const campaignId = EditorData.getId();

        try {
            const response = await axios.post(
                process.env.APP_V2_API_HOST + `bricks/${brickId}/children`,
                {},
                {
                    params: { campaignId, offset: offset, pageLimit: PAGE_LIMIT },
                    headers: { Authorization: `Bearer ${User.get('apiToken')}` }
                }
            );
            return response.data;
        } catch (error: unknown) {
            Sentry.captureException(error);
            SnackbarUtils.error('Failed to get the data');

            if (error instanceof Error) {
                throw new Error(error?.message);
            }
        }
    };

    static getBricksOverviewAsObject = async (filters?: Filters, offset = 0): Promise<BricksObject | undefined> => {
        const campaignId = EditorData.getId();

        try {
            const response = await axios.post(
                process.env.APP_V2_API_HOST + 'bricks/filters',
                { filters },
                {
                    params: { campaignId, asObject: true, pageLimit: PAGE_LIMIT, offset },
                    headers: { Authorization: `Bearer ${User.get('apiToken')}` }
                }
            );
            return response.data;
        } catch (error: unknown) {
            Sentry.captureException(error);
            SnackbarUtils.error('Failed to get the data');

            if (error instanceof Error) {
                throw new Error(error?.message);
            }
        }
    };

    static getBrick = async (id: string): Promise<Brick[] | undefined> => {
        const campaignId = EditorData.getId();

        try {
            const response = await axios.get(process.env.APP_V2_API_HOST + `bricks/${id}`, {
                params: {
                    campaignId
                },
                headers: { Authorization: `Bearer ${User.get('apiToken')}` }
            });
            return response.data;
        } catch (error: unknown) {
            Sentry.captureException(error);
            SnackbarUtils.error('Failed to get the data');

            if (error instanceof Error) {
                throw new Error(error?.message);
            }
        }
    };

    static removeBrick = async (brickId: string | number): Promise<Brick | undefined> => {
        const campaignId = EditorData.getId();

        try {
            const response = await axios.delete(process.env.APP_V2_API_HOST + 'bricks/' + brickId, {
                params: {
                    campaignId
                },
                headers: { Authorization: `Bearer ${User.get('apiToken')}` }
            });

            const removedBrick: Brick = response?.data;

            // Brick doens't exist
            if (!removedBrick || !removedBrick.id) return;

            // Remove the brick from the publish execution panel
            PublishHelpers.updateExecutionPanelBricks([removedBrick.id], 'remove');

            // Return the brick that is removed
            return removedBrick;
        } catch (error: unknown) {
            Sentry.captureException(error);
            SnackbarUtils.error('Failed to remove brick');

            if (error instanceof Error) {
                throw new Error(error?.message);
            }
        }
    };

    /**
     * Remove multiple bricks from the campaign based on the provided brick ids.
     */
    static removeMultipleBricks = async (brickIds: string[]): Promise<Brick[] | undefined> => {
        const campaignId = EditorData.getId();

        try {
            const response = await axios.delete(process.env.APP_V2_API_HOST + 'bricks', {
                data: { brickIds },
                params: { campaignId },
                headers: { Authorization: `Bearer ${User.get('apiToken')}` }
            });

            return response?.data;
        } catch (error: unknown) {
            Sentry.captureException(error);

            if (error instanceof Error) SnackbarUtils.error('Failed to delete bricks.');
        }
    };

    /**
     * Get publishing bricks
     * @returns bricks in the campaign which are curently in publish
     */
    static getPublishingBricks = async (): Promise<Brick[] | undefined> => {
        const campaignId = EditorData.getId();
        try {
            const response = await axios.get(process.env.APP_V2_API_HOST + 'bricks/publishing', {
                params: { campaignId },
                headers: { Authorization: `Bearer ${User.get('apiToken')}` }
            });

            if (!response) return;

            return response.data;
        } catch (error: unknown) {
            if (error instanceof Error) {
                SnackbarUtils.error('Failed getting publishing bricks');
            }
        }
    };

    /**
     * Get all of the roles that has been assigned to a brick either as a reviewer or assignee.
     * This is needed in order to filter the bricks based on the roles.
     */
    static getBricksFilterRoles = async (): Promise<BrickFilterRoles | undefined> => {
        const campaignId = EditorData.getId();

        try {
            const response = await axios.get(process.env.APP_V2_API_HOST + 'bricks/roles', {
                params: { campaignId },
                headers: { Authorization: `Bearer ${User.get('apiToken')}` }
            });

            return response?.data;
        } catch (error: unknown) {
            Sentry.captureException(error);

            if (error instanceof Error) SnackbarUtils.error('Failed to fetch brick roles.');
        }
    };

    /**
     * Generate feed output
     */
    static generateFeedOutput = async (brickId: string, datasetId: string): Promise<any> => {
        const campaignId = EditorData.getId();

        try {
            const response = await axios.post(process.env.APP_V2_API_HOST + 'bricks/feed/output', undefined, {
                params: { campaignId, feedBrickId: brickId, datasetId },

                headers: { Authorization: `Bearer ${User.get('apiToken')}` }
            });
            return response?.data;
        } catch (error: unknown) {
            Sentry.captureException(error);

            if (error instanceof Error) SnackbarUtils.error('Failed generate output for feed brick.');
        }
    };

    /**
     * Cancel feed outpu
     */
    static cancelFeedOutput = async (feedBrickId: string, datasetId: string): Promise<any> => {
        const campaignId = EditorData.getId();

        try {
            const response = await axios.patch(process.env.APP_V2_API_HOST + 'bricks/feed/output', undefined, {
                params: { campaignId, feedBrickId, datasetId },

                headers: { Authorization: `Bearer ${User.get('apiToken')}` }
            });
            return response?.data;
        } catch (error: unknown) {
            Sentry.captureException(error);

            if (error instanceof Error) SnackbarUtils.error('Failed generate output for feed brick.');
        }
    };
}
