import React, { useState } from 'react';
import TextField from 'components/ui-components-v2/TextField';
import Slider from 'components/ui-components-v2/Slider';
import Button from 'components/ui-components-v2/Button';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import Translation from 'components/data/Translation';
import ComponentStoreHelpers from 'components/data/ComponentStore';
import { ImageCropperState, ImageCropData } from 'components/assets/AssetGalleryCropper/interfaces/asset-cropper-state';
import AssetEditorActionRow from 'components/assets/AssetEditor/components/AssetEditorActionsRow/asset-editor-actions';
import assetFlipperControllerItems from '../data/asset-flipper-controller-items';
import AssetFlipperControllerItem from './asset-flipper-controller-item';
import { AssetFlipperAction } from '../interfaces/asset-flipper-controller-item';
import AssetFlipperState from '../interfaces/AssetFlipperState';

import '../styles/asset-flipper-controller.scss';

/** This value is used to rotate image by 90 degrees. */
const NINETY_DEGREES_ANGLE = 90;
/** This value is used to rotate image by 180 degrees. */
const HUNDRED_EIGHTY_DEGREES_ANGLE = 180;
/**
 * The AssetFlipperController component controls rotation of the  AssetFlipper component.
 */
const AssetFlipperController: React.FC = () => {
    const { cropData } = useComponentStore<ImageCropperState>('ImageCropper', {
        fields: { cropData: 'cropData', imageData: 'imageData' }
    });

    const {
        rotationAngle = 0,
        scaleX,
        scaleY
    } = useComponentStore<AssetFlipperState>('AssetFlipper', {
        fields: { scaleX: 'scaleX', scaleY: 'scaleY', rotationAngle: 'rotationAngle' }
    });

    const [horizontalFlips, setHorizontalFlips] = useState(1);
    const [verticalFlips, setVerticalFlips] = useState(1);

    /**
     * Handles the change event of the rotation slider.
     * @param event - The change event object.
     */
    const handleSliderRotationChange = (event) => {
        const angle = parseInt(event.target.value);
        rotate(angle);
    };

    /**
     * Rotates the AssetFlipper component by the specified angle.
     * @param angle The angle to rotate the AssetFlipper component.
     */
    const rotate = (angle: number) => {
        ComponentStoreHelpers.setModel('AssetFlipper', 'rotationAngle', angle);
    };

    /**
     * Handles the rotation of the asset in the AssetFlipper component.
     * @param action - The action to perform on the asset rotation. Can be 'rotateLeft' or 'rotateRight'.
     */
    const handleRotate = (action: AssetFlipperAction) => {
        const angle = rotationAngle !== undefined ? rotationAngle : 0;
        const newRotationAngle = action === 'rotateLeft' ? angle - NINETY_DEGREES_ANGLE : angle + NINETY_DEGREES_ANGLE;

        if (newRotationAngle < -HUNDRED_EIGHTY_DEGREES_ANGLE) {
            const angleToRotate = -rotationAngle - NINETY_DEGREES_ANGLE; // Because -180 is the maximum angle, calculate how much degrees it needs to rotate from 180 to 0;
            rotate(angleToRotate);
        } else if (newRotationAngle > HUNDRED_EIGHTY_DEGREES_ANGLE) {
            const angleToRotate = NINETY_DEGREES_ANGLE - rotationAngle; // Because 180 is the maximum angle, calculate how much degrees it needs to rotate from -180 to 0;
            rotate(angleToRotate);
        } else {
            rotate(newRotationAngle); // Rotate 90 degrees to the left or right
        }
    };

    /**
     * Handles flipping the asset horizontally or vertically.
     * @param action - The action to perform, either 'flipHorizontal' or 'flipVertical'.
     */
    const handleFlip = (action: 'flipHorizontal' | 'flipVertical') => {
        if (action === 'flipHorizontal') {
            const scaleX = horizontalFlips % 2 === 0 ? 1 : -1;
            setHorizontalFlips((prevFlips) => prevFlips + 1);
            ComponentStoreHelpers.setModel('AssetFlipper', 'scaleX', scaleX);
        } else {
            const scaleY = verticalFlips % 2 === 0 ? 1 : -1;
            setVerticalFlips((prevFlips) => prevFlips + 1);
            ComponentStoreHelpers.setModel('AssetFlipper', 'scaleY', scaleY);
        }
    };

    /**
     * Handles the click event for an AssetFlipperAction button.
     * @param {AssetFlipperAction} action - The action to perform.
     */
    const onActionClick = (action: AssetFlipperAction) => {
        if (action === 'rotateLeft' || action === 'rotateRight') {
            handleRotate(action);
        } else if (action === 'flipHorizontal' || action === 'flipVertical') {
            handleFlip(action);
        }
    };

    /** Applies rotation and flip transformations to image and updates the canvas size to match the modified image's dimensions. */
    const applyModificationToAssetSrc = () => {
        ComponentStoreHelpers.setMultiModels('AssetEditor', [
            ['assetFlipperState', { scaleX, scaleY, rotationAngle }],
            ['componentKey', 'previewAsset']
        ]);
    };

    const applyCropChanges = () => {
        if (!cropData) {
            return;
        }

        const crop: ImageCropData = { ...cropData };

        // Go back to preview content and set current crop.
        ComponentStoreHelpers.setModel('AssetEditor', 'imageCropperState.cropData', crop);
    };

    /** Applies the modification to the asset source and goes back to preview content. */
    const onModificationApplyClick = () => {
        applyCropChanges();
        applyModificationToAssetSrc();
    };

    /**
     * Resets the AssetFlipper component's scale and rotation to their default values.
     * Calls the `rotate` function with an angle of 0.
     */
    const onDefaultClick = () => {
        ComponentStoreHelpers.setData('AssetFlipper', {
            scaleX: 1,
            scaleY: 1,
            rotationAngle: 0
        });
        rotate(0);
    };

    /**
     * Handles the change event of the text input and rotates the asset if the angle is within the valid range. The valid range is [-180, 180].
     * @param event - The change event object.
     */
    const onTextChange = (event) => {
        const angle = parseInt(event.target.value);

        if (angle >= -HUNDRED_EIGHTY_DEGREES_ANGLE && angle <= HUNDRED_EIGHTY_DEGREES_ANGLE) {
            rotate(angle);
        }
    };

    return (
        <div className="asset-flipper-controller">
            <div className="asset-flipper-controller__list">
                {assetFlipperControllerItems.map((item, index) => {
                    return <AssetFlipperControllerItem key={index} onClick={onActionClick} icon={item.icon} action={item.action} tooltip={item.tooltip} />;
                })}
            </div>
            <div className="asset-flipper-controller__rotation">
                <div className="asset-flipper-controller__rotation__summary">
                    <div className="asset-flipper-controller__rotation__summary__text">Rotation</div>
                    <div>
                        <TextField
                            className="asset-flipper-controller__rotation__summary__text"
                            type="number"
                            value={rotationAngle}
                            onChange={onTextChange}
                            slotProps={{
                                input: {
                                    inputProps: {
                                        min: -HUNDRED_EIGHTY_DEGREES_ANGLE,
                                        max: HUNDRED_EIGHTY_DEGREES_ANGLE
                                    }
                                }
                            }}
                        />
                    </div>
                </div>

                <Slider
                    className="asset-flipper-controller__rotation__slider"
                    min={-HUNDRED_EIGHTY_DEGREES_ANGLE}
                    max={HUNDRED_EIGHTY_DEGREES_ANGLE}
                    step={1}
                    value={rotationAngle}
                    onChange={handleSliderRotationChange}
                />
            </div>
            <AssetEditorActionRow>
                <Button
                    onClick={() => {
                        onDefaultClick();
                    }}
                    variant="text"
                    color="secondary">
                    {Translation.get('actions.default', 'common')}
                </Button>
                <Button
                    onClick={() => {
                        onModificationApplyClick();
                    }}
                    variant="contained"
                    color="primary">
                    {Translation.get('actions.apply', 'common')}
                </Button>
            </AssetEditorActionRow>
        </div>
    );
};

export default AssetFlipperController;
