import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import LayerHelpers from 'components/template-designer/helpers/layer.helpers';
import { PreviewIcon } from 'components/ui-components/PreviewIcon';
import GenericIcon from 'components/ui-components/GenericIcon';
import LayerProperties, { ContainerProperties } from 'components/template-designer/types/layerProperties.type';
import Layer from 'components/template-designer/types/layer.type';
import { useLayerEdit } from '../../layer-edit/hooks/useLayerEdit';
import './styles/main.scss';

interface Props {
    className?: string;
    layer: Layer;
    isActive?: boolean;
    parentRotation?: number;
}

const LayerIcon = ({ className, layer, isActive, parentRotation = 0 }: Props) => {
    const propertyKeys: string[] = [];

    if (layer.type === 'container') {
        propertyKeys.push('smartLayout');
        propertyKeys.push('direction');
    }

    if (layer.type === 'shape') {
        propertyKeys.push('width');
        propertyKeys.push('height');
        propertyKeys.push('borderRadius');
        propertyKeys.push('rotation');
    }

    const { value } = useLayerEdit<{
        width?: LayerProperties['properties']['width'];
        height?: LayerProperties['properties']['height'];
        borderRadius?: LayerProperties['properties']['borderRadius'];
        rotation?: LayerProperties['properties']['rotation'];
        smartLayout?: ContainerProperties['smartLayout'];
        direction?: ContainerProperties['direction'];
    }>(propertyKeys, 'properties', layer.key);

    const totalRotation = (value?.rotation ?? 0) + parentRotation;

    const icon = (() => {
        const iconClassName = classNames('template-designer__layer-icon__icon', { 'template-designer__layer-icon__icon--active': isActive }, className);

        if (layer.type === 'shape') {
            return (
                <PreviewIcon
                    className={iconClassName}
                    width={(() => {
                        if (typeof value?.width?.value === 'number') {
                            return value.width.value;
                        }

                        return 1;
                    })()}
                    height={(() => {
                        if (typeof value?.height?.value === 'number') {
                            return value.height.value;
                        }

                        return 1;
                    })()}
                    rotation={totalRotation}
                    maxSize={14}
                    borderRadius={{
                        topLeft: (value?.borderRadius?.topLeft.value ?? 0) / 4, // To accommodate the scaling
                        topRight: (value?.borderRadius?.topRight.value ?? 0) / 4, // To accommodate the scaling
                        bottomRight: (value?.borderRadius?.bottomRight.value ?? 0) / 4, // To accommodate the scaling
                        bottomLeft: (value?.borderRadius?.bottomLeft.value ?? 0) / 4 // To accommodate the scaling
                    }}
                    onlyBorder
                />
            );
        }

        if (layer.type === 'container') {
            if (!value.smartLayout) {
                return <GenericIcon className={iconClassName} icon="container" />;
            }

            if (value.direction === 'row') {
                return <GenericIcon className={iconClassName} icon="row-direction" />;
            }

            return <GenericIcon className={iconClassName} icon="column-direction" />;
        }

        return <GenericIcon className={iconClassName} icon={LayerHelpers.getLayerIcon(layer.type)} />;
    })();

    return <div className={classNames('template-designer__layer-icon', className)}>{icon}</div>;
};

export default LayerIcon;
