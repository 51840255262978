import React from 'react';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Icon from '@mui/material/Icon';
import { AssetToCreate } from 'types/asset.type';
import { TemplateType } from 'types/templates.type';
import MenuItem from 'components/ui-components-v2/MenuItem';
import Translation from 'components/data/Translation';
import { canOrganise } from 'components/asset-management/utilities';
import { useAMFetch } from 'components/asset-management/hooks';
import AssetManagementService from 'components/asset-management/services/asset-management.service';
import { useAssetManagementConfigContext } from 'components/asset-management/context/asset-management-config.context';
import { TemplateAsset, TemplateCommonData } from 'components/template-management/types/template-management.type';
import { CreativeAsset, CustomCreativeData, TDCreativeData } from 'components/creative-management-v2/types/creative-management.type';
import { CreativeSubType } from 'components/creative-management-v2/types/creative-sub-type.type';
import { getTemplateIdentifier } from 'components/template-management/utilities';
import SnackbarUtils from 'components/ui-base/SnackbarUtils';

interface Props {
    asset: CreativeAsset | TemplateAsset;
    onClose: () => void;
    onSetWaiting: (waiting: boolean) => void;
}

const AssetManagementAssetMenuItemsCopy = ({ asset, onClose, onSetWaiting }: Props) => {
    const { fetchAsset, refreshLists } = useAMFetch();
    const { languageNameSpace, userHasRight, fullDataInList } = useAssetManagementConfigContext();
    const canOrg = canOrganise(userHasRight, asset.status);

    const copyAsset = async () => {
        onSetWaiting(true);
        // We want to get the full asset to copy, if it is not already in the list.
        const assetToCopy = await (async () => {
            if (!fullDataInList) {
                const fullDataAsset = await fetchAsset(asset._id, true);
                return fullDataAsset as CreativeAsset | TemplateAsset;
            }
            return asset;
        })();

        if (!assetToCopy) return;

        const title = `${assetToCopy.title} - copy`;
        const data: object = { ...(assetToCopy.data as object), title };
        if (assetToCopy.type === 'template') {
            (data as TemplateCommonData).identifier = getTemplateIdentifier();
        } else if (assetToCopy.type === 'creative') {
            (data as TDCreativeData | CustomCreativeData).title = title;
        }
        const duplicateAsset: AssetToCreate<unknown, unknown> & { subType: TemplateType | CreativeSubType } = {
            title,
            data,
            type: assetToCopy.type,
            subType: assetToCopy.subType,
            public: assetToCopy.public,
            labels: assetToCopy.labels,
            collections: assetToCopy.collections,
            status: 'draft'
        };
        const response = await AssetManagementService.createAsset(duplicateAsset);
        if (response) {
            if (assetToCopy.status && ['available', 'binned'].includes(assetToCopy.status)) refreshLists(assetToCopy.status);
            refreshLists('draft');
            onSetWaiting(false);
            SnackbarUtils.success(
                assetToCopy.status !== 'draft'
                    ? Translation.get('snackbar.copiedToDraft', languageNameSpace)
                    : Translation.get('snackbar.copied', languageNameSpace)
            );
        }
    };

    if (!canOrg) return null;

    return (
        <MenuItem
            onClick={(e) => {
                e.stopPropagation();
                copyAsset();
                onClose();
            }}>
            <ListItemIcon>
                <Icon>content_copy</Icon>
            </ListItemIcon>
            <ListItemText>{Translation.get('actions.duplicate', languageNameSpace)}</ListItemText>
        </MenuItem>
    );
};

export default AssetManagementAssetMenuItemsCopy;
