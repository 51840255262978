import { IEditorBlock } from 'components/bricks/types/editorBlock.type';

const settings: IEditorBlock[] = [
    {
        title: 'General',
        itemType: 'block',
        type: 'multiInput',
        opened: true,
        items: [
            {
                type: 'text',
                title: 'Feed name',
                identifier: 'feed_custom-title',
                model: 'title',
                itemType: 'input',
                label: 'Title',
                maxLength: 150,
                fixedModel: true,
                useValueObject: false
            },
            {
                type: 'select',
                model: 'type',
                identifier: 'feed_custom-type',
                label: 'Type',
                options: {
                    spreadsheet: 'G Docs'
                }
            },
            {
                type: 'text',
                title: 'URL',
                identifier: 'feed_custom-url',
                model: 'url',
                itemType: 'input',
                label: 'url',
                maxLength: 150,
                useValueObject: false
            }
        ]
    },
    {
        title: 'Feed update',
        itemType: 'block',
        type: 'multiInput',
        opened: true,
        items: [
            {
                type: 'select',
                model: 'update',
                identifier: 'feed_custom-update',
                label: 'Feed update',
                options: {
                    HOURLY: 'Every hour',
                    DAILY: 'Every day',
                    WEEKLY: 'Every week'
                }
            },
            {
                type: 'select',
                model: 'updateAction',
                identifier: 'feed_custom-update_action',
                label: 'Feed behavior',
                options: {
                    append: 'Add as new rows'
                }
            },
            {
                type: 'radioList',
                model: 'scheduleUpdate',
                label: 'Schedule update',
                identifier: 'feed_custom-schedule_update',
                options: [
                    {
                        key: 'REMOVE',
                        value: 'Remove non existing feed items'
                    },
                    {
                        key: 'Keep',
                        value: 'Keep non existing feed items'
                    }
                ]
            }
        ]
    }
];

export default settings;
