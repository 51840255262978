import PropTypes from 'prop-types';
import React, { Component } from 'react';
import IconButton from '@mui/material/IconButton';
import Icon from 'components/ui-components-v2/Icon';
import Dropzone from 'react-dropzone';
import { defaultStyles, FileIcon } from 'react-file-icon';
import LinearProgress from 'components/ui-components-v2/LinearProgress';
import UploadCloud from 'components/data/UploadCloud';
import '../styles/main.scss';
import Translation from 'components/data/Translation';

/**
 * Copy class
 * Displays a textinput
 */
export default class File extends Component {
    static propTypes = {
        value: PropTypes.string
    };

    constructor(props) {
        super(props);

        this.state = {
            value: props.value
        };
    }

    onImageDrop = (files) => {
        this.setState({
            uploadedFile: files[0]
        });

        this.handleImageUpload(files[0]);
    };

    /**
     * Handle image upload
     * This is called to upload an image to firebase.
     * @param {*} file
     */
    handleImageUpload(file) {
        // File is set
        if (file) {
            this.setState({ fileUploading: true, progress: 0 });

            // Select the correct storage
            const transfer = new UploadCloud({ file });

            // Set up events
            transfer.on('error', (data) => this.uploadState('error', data));
            transfer.on('start', (data) => this.uploadState('start', data));
            transfer.on('uploadProgress', (data) => this.uploadState('uploadProgress', data));
            transfer.on('processEnd', (data) => this.uploadState('processEnd', data));
            transfer.start();
        }
    }

    uploadState = (type, data) => {
        if (type == 'uploadProgress') {
            this.setState({ progress: data.progress });
        } else if (type == 'processEnd') {
            this.setState({ fileUploading: false });
            this.onChange(data.url);
        }
    };

    /**
     * Change input
     * Renders the local state and sends the new value up
     */
    onChange = (value) => {
        const { onChange, model } = this.props;
        this.setState({ value: value });
        onChange(model, value);
    };

    /**
     * Remove the current image
     */
    handleRemove = () => {
        const { onChange, model } = this.props;
        this.setState({ value: undefined });
        onChange(model, '');
    };

    render() {
        const { value = '', accept = 'image/*', fileUploading, progress } = this.state;

        let fileType = 'file';
        let fileTitle = '';
        let extension = '';

        // Filename convert
        if (value) {
            try {
                extension = value.split('.').pop().split('?')[0].toLowerCase();
                fileTitle = value.split('/').pop();
                fileTitle = fileTitle.split('.').shift();
                fileTitle = fileTitle.split('___').pop();
                fileTitle = fileTitle.replace(/_/g, ' ');

                if (extension == 'jpg' || extension == 'jpeg' || extension == 'png' || extension == 'gif') {
                    fileType = 'image';
                }
            } catch (e) {}
        }

        return (
            <div className="form-flow__field__file">
                <div className="form-flow__field__file__dropzone">
                    <Dropzone onDrop={this.onImageDrop} multiple={false} accept={accept}>
                        {({ getRootProps, getInputProps }) => (
                            <section>
                                <div className="form-flow__field__file__dropzone__container" {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <p>{Translation.get('dropZone.dropFiles', 'ui-base')}</p>
                                </div>
                            </section>
                        )}
                    </Dropzone>
                </div>

                {fileUploading && (
                    <div className="form-flow__field__file__progress">
                        <div className="form-flow__field__file__progress__label">{Translation.get('dropZone.uploading', 'ui-base')}</div>
                        <div className="form-flow__field__file__progress__bar">
                            <LinearProgress value={progress} variant="determinate" />
                        </div>
                    </div>
                )}

                {value && fileType == 'image' && (
                    <div className="form-flow__field__file__image">
                        <div className="form-flow__field__file__image__remove">
                            <IconButton aria-label="delete" size="small" onClick={this.handleRemove}>
                                <Icon fontSize="small">delete</Icon>
                            </IconButton>
                        </div>
                        <img src={value} />
                    </div>
                )}

                {value && fileType === 'file' && (
                    <div className="form-flow__field__file__preview">
                        <div className="form-flow__field__file__preview__remove">
                            <IconButton aria-label="delete" size="small" onClick={this.handleRemove}>
                                <Icon fontSize="small">delete</Icon>
                            </IconButton>
                        </div>
                        <div className="form-flow__field__file__preview__extension">
                            <FileIcon extension={extension} {...defaultStyles[extension]} />
                        </div>
                        <div className="form-flow__field__file__preview__label">{fileTitle}</div>
                    </div>
                )}
            </div>
        );
    }
}
