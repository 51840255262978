import React, { useEffect } from 'react';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import SidebarList from './list';
import '../styles/main.scss';

interface SidebarItem {
    id: string;
    status?: string;
    title: string;
    subTitle?: string | React.ReactNode;
    typeIcon?: React.ReactNode;
    items?: SidebarItem[];
}

interface SidebarProps {
    list?: SidebarItem[];
    listTitle?: string;
    activeItem?: string;
    freshStart?: boolean;
    onSelect?: (id: string) => void;
    showSingle?: boolean;
    onClickMainButton?: () => void;
    mainButtonCopy?: string;
    mainButtonIcon?: React.ReactNode;
    onClickSubButton?: () => void;
    subButtonCopy?: string;
    subButtonIcon?: React.ReactNode;
    className?: string;
    classes?: {
        root?: string;
        list?: string;
        search?: string;
        detail?: string;
    };
    docked?: boolean;
    children?: React.ReactNode;
}

const Sidebar: React.FC<SidebarProps> = ({
    list = [],
    listTitle,
    activeItem,
    showSingle = false,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSelect = () => {},
    children,
    freshStart,
    onClickMainButton,
    mainButtonCopy,
    mainButtonIcon,
    onClickSubButton,
    subButtonCopy,
    subButtonIcon,
    className,
    classes,
    docked = false
}) => {
    const location = useLocation();

    useEffect(() => {
        const sidebarOpen = sessionStorage.getItem('sidebarOpen');
        let found = false;

        if (sidebarOpen && !freshStart) {
            const checkSidebarOpen = JSON.parse(sidebarOpen);
            if (checkSidebarOpen[location.pathname]) {
                const openId = checkSidebarOpen[location.pathname];
                list.forEach((x) => {
                    if (x.id === openId) {
                        onSelect(x.id);
                        found = true;
                    } else if (x.items) {
                        x.items.forEach((y) => {
                            if (y.id === openId) {
                                onSelect(y.id);
                                found = true;
                            }
                        });
                    }
                });
            }
        }

        if (!found && list && list.length) {
            onSelect(list[0].id);
        }
    }, []);

    const minimumLength = showSingle ? 0 : 1;

    return (
        <div
            className={classNames('sidebar', className, classes?.root, {
                'sidebar--docked': docked
            })}>
            {list && list.length > minimumLength && (
                <SidebarList
                    list={list}
                    listTitle={listTitle}
                    activeItem={activeItem}
                    onSelect={onSelect}
                    onClickMainButton={onClickMainButton}
                    mainButtonCopy={mainButtonCopy}
                    mainButtonIcon={mainButtonIcon}
                    onClickSubButton={onClickSubButton}
                    subButtonCopy={subButtonCopy}
                    subButtonIcon={subButtonIcon}
                    classes={{ root: classes?.list, search: classes?.search }}
                    docked={docked}
                />
            )}
            <div
                className={classNames('sidebar__detail', classes?.detail, {
                    'sidebar__detail--docked': docked
                })}>
                {children}
            </div>
        </div>
    );
};

export default Sidebar;
