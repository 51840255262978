import { SocialChannelItem } from '../types/template-management.type';

/**
 * SocialChannelItems are defined in the code in the format that wa used in the V1 version of Template Management and creative builder.
 * This function converts the V1 format to to something that resembles a AMV2 asset.
 */
const convertSocialV1ToSocialV2 = (item: any): SocialChannelItem => {
    const { id, title, ...other } = item;

    const labels: SocialChannelItem['labels'] = {};
    if (item.category) {
        labels.categories = [item.category];
    }

    return {
        _id: id,
        title: title,
        subType: 'socialChannelItem',
        type: 'template',
        data: {
            ...other
        },
        labels
    };
};

export { convertSocialV1ToSocialV2 };
