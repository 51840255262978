import React from 'react';
import classNames from 'classnames';
import Sidebar, { SidebarItem } from 'components/ui-components/Dialog/components/sidebar-items';
import Search from 'components/ui-components/Dialog/components/search';
import '../styles/content-with-sidebar-wrapper.scss';

/** The sidebar should have at least 2 items in order to be displayed. */
const MINIMUM_SIDEBAR_ITEMS = 2;

interface Props {
    children: React.ReactNode;
    sidebarItems: SidebarItem[];
    defaultActiveSidebarItem?: string;
    searchDebounce?: number;
    onChangeSearchTerm?: (searchTerm: string) => void;
    onChangeActiveSidebarItem: (type: string) => void;
    classes?: {
        root?: string;
        content?: string;
        sidebar?: string;
        search?: string;
    };
}

/**
 * This component is used to display a sidebar and content next to each other, with an optional searchbar.
 * It can be used as the 'children' prop for the Dialog component (where it functions a DialogContent).
 */
const ContentWithSidebarWrapper = ({
    children,
    sidebarItems,
    defaultActiveSidebarItem,
    searchDebounce,
    onChangeSearchTerm,
    onChangeActiveSidebarItem,
    classes
}: Props) => {
    return (
        <div
            className={classNames('dialog-content-with-sidebar-wrapper', {
                [classes?.root || '']: classes?.root
            })}>
            <div
                className={classNames(
                    'dialog-content-with-sidebar-wrapper__sidebar',
                    {
                        [classes?.sidebar || '']: classes?.sidebar
                    },
                    sidebarItems.length < MINIMUM_SIDEBAR_ITEMS ? 'dialog-content-with-sidebar-wrapper__sidebar-hidden' : ''
                )}>
                <Sidebar
                    items={sidebarItems}
                    onChangeActiveSidebarItem={onChangeActiveSidebarItem}
                    defaultActiveSidebarItem={defaultActiveSidebarItem}
                    dataCyPrefix="genericDialog-displaySidebarItems"
                />
            </div>
            <div
                className={classNames('dialog-content-with-sidebar-wrapper__content', {
                    [classes?.content || '']: classes?.content
                })}>
                {onChangeSearchTerm && (
                    <div
                        className={classNames('dialog-content-with-sidebar-wrapper__content__search', {
                            [classes?.search || '']: classes?.search
                        })}>
                        <Search onChangeSearchTerm={onChangeSearchTerm} searchDebounce={searchDebounce} />
                    </div>
                )}
                <div
                    className={classNames('dialog-content-with-sidebar-wrapper__content__children', {
                        'dialog-content-with-sidebar-wrapper__content__children--search-bar': onChangeSearchTerm,
                        [classes?.content || '']: classes?.content
                    })}>
                    {children}
                </div>
            </div>
        </div>
    );
};

export default ContentWithSidebarWrapper;
