import React, { useEffect, useState } from 'react';
import { DataSetStructure } from 'types/dataset.type';
import useBrick from 'components/bricks/hooks/useBrick';
import CircularProgress from 'components/ui-components-v2/CircularProgress';
import { FeedRequest } from 'components/data/FeedManagementHelpers';
import DataSetManagerInBrick from 'components/editor-blocks/DataSetManager/components/in-brick';
import EmptyState from 'components/ui-components-cape/EmptyState';
import Illustration from 'components/ui-components-cape/Illustration';
import BrickFeedDialog from '../../feed-dialog';
import './../styles/main.scss';

const FeedTab = () => {
    const { brick } = useBrick();

    const [dataSet, setDataSet] = useState<DataSetStructure>();
    const [loading, setLoading] = useState<boolean>(true);

    const [addFeedDialogOpen, setAddFeedDialogOpen] = useState<boolean>(false);

    useEffect(() => {
        loadDataset();
    }, [brick?.data?.dataset?.datasetId]);

    async function loadDataset() {
        const datasetId = brick?.data?.dataset?.datasetId;
        const response = await FeedRequest.get(`dataset/${datasetId}`);
        const dataSet: DataSetStructure = response.data;
        setDataSet(dataSet);
        setLoading(false);
    }

    if (!brick) return;

    return (
        <>
            <div className="bricks-feed-tab">
                {loading && <CircularProgress />}
                {!loading && dataSet && <DataSetManagerInBrick displayType="list" dataSet={dataSet} />}
                {!loading && !dataSet && (
                    <div className="bricks-feed-tab__empty">
                        <EmptyState
                            primaryText={'No feed items yet'}
                            illustration={<Illustration size="small" color="disabled" illustration="fallback" />}
                            primaryButton={{
                                label: 'Add feed',
                                className: 'bricks-feed-tab__empty__button',
                                onClick: () => setAddFeedDialogOpen(true)
                            }}
                        />
                    </div>
                )}
            </div>
            {addFeedDialogOpen && <BrickFeedDialog onClose={() => setAddFeedDialogOpen(false)} brickId={brick.id} />}
        </>
    );
};

export default FeedTab;
