import { isCustomUploadCreative, isMediaCreative, isTemplateCreative } from 'components/creatives-v2/guards/creative-type-guards';
import { CreativeV2Base } from '../creative-types/base-creative.class';
import { CreativeV2CustomUploadType } from '../creative-types/custom-upload-creative.class';
import { CreativeV2 } from '../components/creative-editor/types/creativeV2.type';
import { CreativeV2TemplateType } from '../creative-types/template-creative.class';
import { CreativeV2MediaType } from '../creative-types/media-creative.class';

function getCreativeInstance(creative: CreativeV2): CreativeV2Base<CreativeV2> {
    if (isCustomUploadCreative(creative)) {
        return new CreativeV2CustomUploadType(creative);
    } else if (isMediaCreative(creative)) {
        return new CreativeV2MediaType(creative);
    } else if (isTemplateCreative(creative)) {
        return new CreativeV2TemplateType(creative);
    }

    throw new Error(`Creative type ${creative['type']} is not supported`);
}

export { getCreativeInstance };
