import PropTypes from 'prop-types';
import React from 'react';
import Axios from 'axios';
import Icon from 'components/ui-components-v2/Icon';
import CircularProgress from 'components/ui-components-v2/CircularProgress';
import LinearProgress from 'components/ui-components-v2/LinearProgress';
import ButtonGroup from 'components/ui-components-v2/ButtonGroup';
import Button from 'components/ui-components-v2/Button';
import Tooltip from 'components/ui-components-v2/Tooltip';
import SnackbarUtils from 'components/ui-base/SnackbarUtils';
import EditorData from 'components/editor-data/EditorData';
import Request from 'components/data/Request';
import DataHelpers from 'components/data/DataHelpers';
import TemplateHelpers from 'components/data/TemplateHelpers';
import TemplateDesignerService from 'components/template-designer/services/template-designer.service';
import store from '../../../../../store';
import '../styles/main.scss';

const RENDER_STATUS_COMPLETE = 2;
const RENDER_STATUS_RENDERING = 1;
const RENDER_STATUS_QUEUE = 0;
const RENDER_STATUS_ERROR = -1;

/**
 * DynamicVideo
 * This renders a dynamic video
 */
class DynamicVideo extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            previewData: {},
            showOptions: false,
            lowQuality: false,
            renderFromCurrentSession: false,
            renderStatus: 0 // 0: unknown, 1: rendering, 2: rendering ready, -1: error
        };

        // Get the previewdata
        if (props.previewData) {
            this.state.previewData = props.previewData;
        } else if (props.previewDataModel) {
            this.state.previewData = EditorData.getValueFromModel(props.previewDataModel);
        } else if (props.value) {
            this.state.previewData = props.value;
        }

        if (!this.state.previewData) {
            this.state.previewData = {};
        } else {
            if (this.state.previewData && this.state.previewData.lowQuality) {
                this.state.lowQuality = this.state.previewData.lowQuality;
            }
        }

        // Set interval to check whether the video is rendered
        if (this.state.previewData && this.state.previewData.loading) {
            this.interval = setInterval(this.checkIfCompleted, 1500);
        }

        // Set interval to check whether the video is rendered
        if (this.state.previewData && !this.state.previewData.loading) {
            this.state.renderStatus = this.state.previewData.status;

            setTimeout(() => {
                // Start playing
                if (this.refs.vidRef) {
                    this.refs.vidRef.play();
                }
            }, 100);
        }
    }

    static defaultProps = {
        width: '100%',
        height: 'auto',
        style: {},
        format: 'horizontal',
        onMutation: () => {}
    };

    static propTypes = {
        /** Set visual width */
        width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        /** Set visual height */
        height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        /* Format to be displayed. */
        format: PropTypes.string,
        comp: PropTypes.string,
        /* Input data */
        data: PropTypes.object,
        dataModel: PropTypes.string,
        /* The raw mapping of the output data */
        mapping: PropTypes.object,
        /* The preview data (in case it's passed as a prop) */
        previewData: PropTypes.object,
        previewDataModel: PropTypes.string,
        /* On mutation (in case we use DynamicDataWrapper for the preview data) */
        onMutation: PropTypes.func,
        style: PropTypes.object,
        className: PropTypes.string,
        /* Show the controls in a video */
        controls: PropTypes.bool,
        /* Autoplay or not */
        autoplay: PropTypes.bool
    };

    /**
     * Sets the previewdata returned from the server.
     */
    setPreviewData = (previewData) => {
        const { previewDataModel, onMutation } = this.props;
        const { lowQuality } = this.state;

        const data = { ...previewData, lowQuality: lowQuality };

        // In case we write to a model
        if (previewDataModel) {
            EditorData.setModel(previewDataModel, data);
        }
        // In case we use onmutation
        else if (onMutation) {
            onMutation(data);
        }
    };

    cancelRender = async () => {
        const { template } = this.props;

        if (template.type === 'dynamicAfterEffects') {
            const token = await TemplateDesignerService.getAfterEffectsToken();
            const cancelled = await TemplateDesignerService.cancelVideoRender(token, this.state.previewData.uuid);

            if (cancelled) {
                clearInterval(this.interval);
                this.setState({ previewData: { loading: false }, renderStatus: RENDER_STATUS_ERROR });
                SnackbarUtils.info('Rendering was cancelled.');
            }
        }
    };

    /**
     * Rerender the video
     */
    onRerender = async () => {
        const { dataModel, data, mapping, comp, template } = this.props;
        const { lowQuality } = this.state;

        // We got mapping and the datamodel
        let affectivityNew;

        if (template.type === 'dynamicAfterEffects') {
            try {
                affectivityNew = {};
                affectivityNew = TemplateHelpers.parseTdAfterEffects(
                    affectivityNew,
                    {
                        blockData: data,
                        format: comp,
                        template: {
                            type: template.type,
                            identifier: template.templateSetup.templateSetup.adobe.identifier || template.identifier,
                            downloadUrl: template.templateSetup.templateSetup
                                ? template.templateSetup.templateSetup.adobe.downloadUrl
                                : template.templateSetup.designerSettings.downloadUrl,
                            layers: template.templateSetup.layers,
                            interfaceSetup: template.interfaceSetup,
                            compositions: template.templateSetup.compositions,
                            formats: template.templateSetup.formats
                        }
                    },
                    store.getState().editor.language,
                    true
                );
            } catch (error) {
                return SnackbarUtils.error(error.message);
            }
        }
        // We received the data, no model
        else if (mapping && data && comp) {
            affectivityNew = DataHelpers.clone(mapping, false);
            EditorData.parseDataDeep(affectivityNew, {
                blockModel: dataModel,
                blockData: data,
                comp: comp
            });
        } else if (mapping && dataModel) {
            affectivityNew = DataHelpers.clone(mapping, false);
            EditorData.parseDataDeep(affectivityNew, {
                blockModel: dataModel,
                blockData: EditorData.getValueFromModel(dataModel),
                comp: comp
            });
        } else {
            affectivityNew = DataHelpers.clone(data, false);
        }

        // Update date
        affectivityNew.priority = 3;

        if (template.type === 'dynamicAfterEffects') {
            try {
                const token = await TemplateDesignerService.getAfterEffectsToken();
                const response = await TemplateDesignerService.startVideoRender(token, affectivityNew);

                if (response.status === RENDER_STATUS_COMPLETE) {
                    this.setPreviewData({ ...response, loading: false, outputUrlVideo: response.videoUrl });
                    this.setState({ renderStatus: RENDER_STATUS_COMPLETE, previewData: { ...response, loading: false, outputUrlVideo: response.videoUrl } });
                    return;
                }

                response.loading = true;
                this.setPreviewData({ ...response, loading: true });
                this.setState({ renderStatus: RENDER_STATUS_QUEUE, previewData: { ...response, loading: true } });

                clearInterval(this.interval);
                this.interval = setInterval(() => this.checkIfAfterEffectsCompleted(response.uuid, token), 2000);
            } catch (error) {
                sessionStorage.removeItem(TemplateDesignerService.AFTER_EFFECTS_TOKEN);
                clearInterval(this.interval);
                SnackbarUtils.error('Something went wrong. Try again!');
            }
        } else {
            if (comp) {
                affectivityNew.comp = comp;
            }

            if (lowQuality) {
                affectivityNew.comp = affectivityNew.comp + '_lq';
            }

            // if version is set to 2, use the new render service
            if (template.settings.version === 2) {
                // add project url to affectivityNew object
                affectivityNew.projectUrl = template.settings.projectSourceUrl;
                delete affectivityNew.token;

                try {
                    const token = await TemplateDesignerService.getAfterEffectsToken();
                    const response = await TemplateDesignerService.startLegacyVideoRender(token, affectivityNew);

                    if (response.status === RENDER_STATUS_COMPLETE) {
                        this.setPreviewData({ ...response, loading: false, outputUrlVideo: response.videoUrl });
                        this.setState({
                            renderStatus: RENDER_STATUS_COMPLETE,
                            previewData: { ...response, loading: false, outputUrlVideo: response.videoUrl }
                        });
                        return;
                    }

                    response.loading = true;
                    this.setPreviewData({ ...response, loading: true });
                    this.setState({ renderStatus: RENDER_STATUS_QUEUE, previewData: { ...response, loading: true } });

                    clearInterval(this.interval);
                    this.interval = setInterval(() => this.checkIfAfterEffectsCompleted(response.uuid, token), 2000);
                } catch (error) {
                    sessionStorage.removeItem(TemplateDesignerService.AFTER_EFFECTS_TOKEN);
                    clearInterval(this.interval);
                    SnackbarUtils.error('Something went wrong. Try again!');
                }
            } else {
                // Render video
                Request.post(process.env.AFFECTIVITY + 'render', affectivityNew).then((data) => {
                    // Try the action, because of unmounts
                    try {
                        // Set result data
                        data.data.loading = true;
                        this.setPreviewData(data.data);
                        this.setState({ previewData: data.data, renderFromCurrentSession: true, renderStatus: RENDER_STATUS_RENDERING });

                        if (this.interval) {
                            clearInterval(this.interval);
                        }
                        this.interval = setInterval(this.checkIfCompleted, 500);
                    } catch (e) {
                        console.log('Cannot show video result');
                    }
                });
            }
        }
    };

    componentDidUpdate(prevProps) {
        if (prevProps.comp && this.props.comp != prevProps.comp) {
            this.setState({ previewData: {} });
        }
    }

    /**
     * Download the lastest prerender
     */
    onDownload = () => {
        const { previewData } = this.state;
        window.open(previewData.outputUrlVideo);
    };

    checkIfAfterEffectsCompleted = async (uuid, token) => {
        try {
            const response = await TemplateDesignerService.checkVideoRender(uuid, token);
            this.setState({
                renderProgress: response.renderProgress,
                renderStatus: response.status,
                queue: response.queue,
                queuePosition: response.queuePosition
            });

            if (response.status === RENDER_STATUS_COMPLETE) {
                if (this.interval) clearInterval(this.interval);
                this.setPreviewData({ ...response, loading: false, outputUrlVideo: response.videoUrl });
                this.setState({ previewData: { ...response, loading: false, outputUrlVideo: response.videoUrl }, renderStatus: response.status });

                setTimeout(() => {
                    // Start playing
                    if (this.refs.vidRef) {
                        this.refs.vidRef.play();
                    }
                }, 100);
            }

            if (response.status === RENDER_STATUS_ERROR) {
                throw response;
            }
        } catch (error) {
            sessionStorage.removeItem(TemplateDesignerService.AFTER_EFFECTS_TOKEN);
            clearInterval(this.interval);
            this.setState({ previewData: { loading: false }, renderStatus: error?.data?.status });

            // In case of non 200, do not show
            if (error?.data?.statusMessage) {
                SnackbarUtils.error(error?.data?.statusMessage);
            }
        }
    };

    /**
     * Check whether the video was completed
     */
    checkIfCompleted = async () => {
        const { template } = this.props;
        const { previewData } = this.state;

        if (template.type === 'dynamicAfterEffects') {
            // We cleared the preview component
            if (!previewData.uuid) {
                return;
            }

            const token = await TemplateDesignerService.getAfterEffectsToken();

            this.checkIfAfterEffectsCompleted(previewData.uuid, token);
            return;
        }

        // We cleared the preview component
        if (!previewData.id) {
            return;
        }

        // Try the action, because of unmounts
        try {
            // Get the data from affectivity
            Axios.post(process.env.AFFECTIVITY + 'getVideoStatus', { id: previewData.id }).then(
                (res) => {
                    // Render completed
                    if (res.data && res.data.data && res.data.data.status && res.data.data.status == '2') {
                        clearInterval(this.interval);
                        this.setState({ previewData: { ...previewData, loading: false }, renderStatus: RENDER_STATUS_COMPLETE });
                        setTimeout(() => {
                            // Start playing
                            if (this.refs.vidRef) {
                                this.refs.vidRef.play();
                            }
                        }, 100);
                    }
                    // Rendering
                    else if (res.data && res.data.data && res.data.data.status && res.data.data.status == '1') {
                        this.setState({ renderStatus: RENDER_STATUS_RENDERING });
                    }
                    // There was an error
                    else if ((res.data && res.data.success === 0) || (res.data && res.data.data && res.data.data.status && res.data.data.status == '-1')) {
                        clearInterval(this.interval);
                        this.setState({ previewData: { loading: false }, renderStatus: RENDER_STATUS_ERROR });

                        if (this.state.renderFromCurrentSession) {
                            SnackbarUtils.error('There was an error rendering the video. Please check all the required fields. ');
                        }

                        if (res.data.message) {
                            console.error('Error rendering video', res.data.message);
                        }
                    } else {
                        this.setState({ renderStatus: RENDER_STATUS_QUEUE });
                    }
                },
                (data) => {
                    clearInterval(this.interval);
                    this.setState({ previewData: { loading: false } });
                    console.log('Error', data);
                }
            );
        } catch (e) {
            console.log('Cannot show video result');
        }
    };

    componentWillUnmount = () => {
        // Clear interval for refreshing if available
        if (this.interval) {
            clearInterval(this.interval);
        }
    };

    /**
     * On hover show the overlay over the video
     * @param {*} move
     */
    handleHover = (move) => {
        if (move === 'enter') {
            this.setState({ showOptions: true });
        } else {
            this.setState({ showOptions: false });
        }
    };

    /**
     * On change quality
     */
    onChangeQuality = (lowQuality) => {
        this.setState({ lowQuality: lowQuality }, this.onRerender);
    };

    /**
     * Render the component
     */
    render() {
        const { width, style, className, height, autoplay, controls, format, lowQualityAvailable, placeholderImage, template } = this.props;
        const { previewData, showOptions, lowQuality, renderStatus, renderProgress, queue, queuePosition } = this.state;

        return (
            <div className="dynamic-video" onMouseEnter={() => this.handleHover('enter')} onMouseLeave={() => this.handleHover('leave')}>
                <div className={'dynamic-video__video-container dynamic-video__video-container--' + format} style={{ width: width, height: height }}>
                    {!previewData.loading && previewData.outputUrlVideo && (
                        <video
                            height={height}
                            width={width}
                            style={style}
                            className={className}
                            autoPlay={autoplay}
                            controls={controls}
                            ref="vidRef"
                            muted
                            src={previewData.outputUrlVideo}
                        />
                    )}

                    {!previewData.outputUrlVideo && !previewData.loading && (
                        <div className="dynamic-video__empty-video">
                            <div className="dynamic-video__empty-video__button">
                                <Button variant="contained" color="primary" onClick={this.onRerender}>
                                    Render video preview
                                </Button>
                            </div>
                        </div>
                    )}
                </div>

                {previewData.outputUrlVideo && showOptions && (previewData.status === RENDER_STATUS_COMPLETE || renderStatus === RENDER_STATUS_COMPLETE) && (
                    <div>
                        <div className="dynamic-video__rerender-container">
                            <Tooltip title="Render new video">
                                <div className="dynamic-video__rerender-container__rerender-button" onClick={this.onRerender}>
                                    <Icon>update</Icon>
                                </div>
                            </Tooltip>
                            <Tooltip title="Download video">
                                <div className="dynamic-video__rerender-container__download-button" onClick={this.onDownload}>
                                    <Icon>download</Icon>
                                </div>
                            </Tooltip>
                        </div>
                    </div>
                )}

                {lowQualityAvailable && (showOptions || !previewData.outputUrlVideo) && (
                    <div className="dynamic-video__quality__container">
                        <ButtonGroup size="small" style={{ backgroundColor: '#FFFFFF' }}>
                            <Button
                                title="Render in low quality in the preview"
                                size="small"
                                variant={lowQuality ? 'contained' : false}
                                onClick={() => this.onChangeQuality(true)}>
                                LQ
                            </Button>
                            <Button
                                title="Render in high quality in the preview"
                                size="small"
                                variant={!lowQuality ? 'contained' : false}
                                onClick={() => this.onChangeQuality(false)}>
                                HQ
                            </Button>
                        </ButtonGroup>
                    </div>
                )}

                {previewData && previewData.loading && (
                    <div className="dynamic-video__loading-container">
                        <div className="dynamic-video__loading-container__text">
                            {(() => {
                                if (renderStatus === 0) {
                                    if (queuePosition === undefined || queue === undefined) return 'Waiting in queue...';
                                    return `Waiting in queue (${queuePosition}/${queue})`;
                                }

                                if (renderStatus === 1) {
                                    return 'Rendering new video.\nThis may take a few minutes.';
                                }

                                return 'loading...';
                            })()}
                        </div>
                        {renderStatus >= 0 && (
                            <div className="dynamic-video__loading-container__spinner">
                                {renderStatus === 1 && renderProgress !== undefined ? (
                                    <div className="dynamic-video__loading-container__progress">
                                        <LinearProgress
                                            className="dynamic-video__loading-container__progress-bar"
                                            variant="determinate"
                                            value={renderProgress}
                                        />
                                        {renderProgress}%
                                    </div>
                                ) : (
                                    <CircularProgress />
                                )}
                            </div>
                        )}
                        <div className="dynamic-video__loading-container__buttons">
                            <Tooltip title="Render new video">
                                <div className="dynamic-video__loading-container__buttons__rerender-button" onClick={this.onRerender}>
                                    <Icon>Update</Icon>
                                </div>
                            </Tooltip>
                            {renderStatus === 0 && template.type === 'dynamicAfterEffects' && (
                                <Tooltip title="Cancel render">
                                    <div className="dynamic-video__loading-container__buttons__rerender-button" onClick={this.cancelRender}>
                                        <Icon>cancel</Icon>
                                    </div>
                                </Tooltip>
                            )}
                        </div>
                    </div>
                )}

                {(!previewData.outputUrlVideo || previewData.loading) && placeholderImage && (
                    <div className="dynamic-video__placeholder">
                        <div className="dynamic-video__placeholder__image" style={{ backgroundImage: 'url(' + placeholderImage + ')' }}></div>
                    </div>
                )}
            </div>
        );
    }
}

export default DynamicVideo;
