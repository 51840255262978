import React, { useEffect, useMemo } from 'react';
import { useDynamicLayers } from 'components/template-designer/components/dynamic-layers/contexts/dynamic-layers.context';
import { DynamicLayerInput, RadioListSettings, SelectSettings } from 'components/template-designer/types/dynamicLayer.type';
import Translation from 'components/data/Translation';
import ToggleButton from 'components/ui-components-v2/ToggleButton';
import ToggleButtonGroup from 'components/ui-components-v2/ToggleGroup';
import { DynamicLayerHelpers } from 'components/template-designer/helpers/dynamic-layer.helpers';
import { InputAttribute } from 'components/template-designer/components/dynamic-layers/types/attribute.type';
import InputWrapper from 'components/ui-components-cape/InputWrapper';
import Button from 'components/ui-components-v2/Button';
import { FontPicker } from 'components/template-designer/components/ui-base/font-picker';
import cloneDeep from 'helpers/cloneDeep';
import Typography from 'components/ui-components-v2/Typography';
import IconButton from 'components/ui-components-v2/IconButton';
import Icon from 'components/ui-components-v2/Icon';
import { attributeInputs } from '../../../config/attribute-inputs';
import '../styles/main.scss';
import '../styles/font-family-form.scss';

interface Props {
    attribute: DynamicLayerInput['attribute'];
    updateSettings?: InputAttribute<SelectSettings>['updateSettings'];
}

const FontFamilyForm = ({ attribute, updateSettings }: Props): JSX.Element | null => {
    const { settings, activeInput, activeInputLayer, updateInputSettings, updateActiveInput } = useDynamicLayers<SelectSettings | RadioListSettings>();

    const templateValue = useMemo(
        () => activeInputLayer && DynamicLayerHelpers.getGeneralTemplateValue(attribute, settings, activeInputLayer),
        [activeInputLayer]
    );

    // Update the first font if the template font value changes.
    useEffect(() => {
        if (!activeInput || !activeInputLayer || (settings.type !== 'select' && settings.type !== 'radioList')) return;
        if (updateSettings && settings.options && attribute) {
            const newSettings = updateSettings(activeInputLayer, attribute, settings);
            updateActiveInput('settings', newSettings);
        }
    }, [templateValue]);

    /**
     * Update the available font array.
     * @param fontFamily - The font family.
     * @param fontName - The font name.
     */
    const handleChangeFont = (fontFamily: string, fontName: string) => {
        const newOptions: { key: string; value: string }[] = cloneDeep(settings.options);
        if (newOptions.find((font) => font.key === fontFamily)) return;
        newOptions.push({ key: fontFamily, value: fontName });
        updateInputSettings('options', newOptions);
    };

    /**
     * Delete a font from the available font array.
     * @param key - The key of the font to delete.
     */
    const handleDeleteFont = (key: string) => {
        const newOptions = settings.options.filter((font) => font.key !== key);
        updateInputSettings('options', newOptions);
    };

    if (!activeInput || !activeInputLayer) return null;

    const selectAttribute = attributeInputs[activeInputLayer.type].all[activeInput.attribute].settings;

    return (
        <div className="template-designer__dynamic-layer-edit__settings-form">
            {selectAttribute && 'radioList' in selectAttribute && 'select' in selectAttribute && (
                <InputWrapper gutterBottom>
                    <ToggleButtonGroup
                        value={settings.type}
                        size="large"
                        fullWidth
                        color="primary"
                        onChange={(_, type) => {
                            if (!type || type === settings.type) return;

                            const newSettings = attributeInputs[activeInputLayer.type]?.all[activeInput.attribute]?.settings?.[type];
                            if (newSettings) {
                                updateActiveInput('settings', { ...newSettings, options: settings.options, label: activeInput.label });
                            }
                        }}
                        exclusive>
                        <ToggleButton value="radioList">
                            {Translation.get('dynamicLayers.layerEdit.editForms.inputTypes.radioList', 'template-designer')}
                        </ToggleButton>
                        <ToggleButton value="select">
                            {Translation.get('dynamicLayers.layerEdit.editForms.inputTypes.select', 'template-designer')}
                        </ToggleButton>
                    </ToggleButtonGroup>
                </InputWrapper>
            )}

            <div className="template-designer__dynamic-layer-edit__settings-form__fonts">
                {settings.options.map((font, index) => (
                    <div key={font.key} className="template-designer__dynamic-layer-edit__settings-form__font">
                        <Typography variant="body1">{font.value}</Typography>
                        {index !== 0 && (
                            <IconButton
                                size="small"
                                onClick={() => handleDeleteFont(font.key)}
                                className="template-designer__dynamic-layer-edit__settings-form__font__delete">
                                <Icon fontSize="small">delete</Icon>
                            </IconButton>
                        )}
                    </div>
                ))}
            </div>

            <FontPicker
                onChange={(_, fontFamily, fontName) => handleChangeFont(fontFamily, fontName)}
                placement="bottom"
                fontFamily={settings.options.map((font) => font.key)}
                trigger={
                    <Button variant="contained" fullWidth size="medium">
                        {Translation.get('sidebarRight.inputs.fontPicker.addFont', 'template-designer')}
                    </Button>
                }
            />
        </div>
    );
};

export default FontFamilyForm;
