import Icon from 'components/ui-components-v2/Icon';
import React from 'react';
import Translation from 'components/data/Translation';
import {
    AnimationOptions,
    PredefinedAnimationOptions,
    PredefinedAnimationSetupValue,
    PredefinedAnimationValue
} from 'components/template-designer/types/animation.type';
import { TemplateVersionHelpers } from 'components/template-designer/helpers/template-version.helpers';
import fadeIn from './fadeIn';
import fadeOut from './fadeOut';
import scaleOut from './scaleOut';
import scaleIn from './scaleIn';
import bungee from './bungee';
import heartbeat from './heartbeat';
import pulse from './pulse';
import wiggle from './wiggle';
import slideIn from './slideIn';
import slideOut from './slideOut';
import shake from './shake';
import backInOld from './backInOld';
import backIn from './backIn';
import popUpOld from './popUpOld';
import popUp from './popUp';
import rotateIn from './rotateIn';
import growOut from './growOut';
import rotateOut from './rotateOut';

export enum PredefinedAnimationCategories {
    TransitionIn = 'transitionIn',
    TransitionOut = 'transitionOut',
    Effects = 'effects'
}

export type PredefinedAnimationsSetups = {
    [category in PredefinedAnimationCategories]: {
        label: string;
        icon: JSX.Element;
        animations: {
            [animationKey: string]: PredefinedAnimationSetup;
        };
    };
};

export interface PredefinedAnimationSetup {
    label: string;
    key: PredefinedAnimationOptions;
    duration: number;
    reset: { fadeIn: boolean; fadeOut: boolean };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    defaultValue: { value?: any; easing: PredefinedAnimationValue['easing'] };
    stamps: {
        [key in AnimationOptions]: PredefinedAnimationSetupValue[];
    };
}

/**
 * Predefined animations by category
 */
const getPredefinedAnimationsSetup = (): PredefinedAnimationsSetups => {
    const showNewPredefinedAnimations = TemplateVersionHelpers.shouldShowNewPredefinedAnimations();

    return {
        transitionIn: {
            label: Translation.get('animation.predefinedAnimations.categories.transitionIn', 'template-designer'),
            icon: (
                <Icon fontSize="inherit" className="template-designer__add-animation-dialog__left__category-icon">
                    video_library
                </Icon>
            ),
            animations: {
                fadeIn,
                slideIn,
                scaleIn,
                backIn: showNewPredefinedAnimations ? backIn : backInOld,
                popUp: showNewPredefinedAnimations ? popUp : popUpOld,
                rotateIn,
                bungee
            }
        },
        transitionOut: {
            label: Translation.get('animation.predefinedAnimations.categories.transitionOut', 'template-designer'),
            icon: (
                <Icon fontSize="inherit" className="template-designer__add-animation-dialog__left__category-icon">
                    video_library
                </Icon>
            ),
            animations: {
                fadeOut,
                slideOut,
                scaleOut,
                growOut,
                rotateOut
            }
        },
        effects: {
            label: Translation.get('animation.predefinedAnimations.categories.effects', 'template-designer'),
            icon: (
                <Icon fontSize="inherit" className="template-designer__add-animation-dialog__left__category-icon">
                    loop
                </Icon>
            ),
            animations: {
                wiggle,
                heartbeat,
                pulse,
                shake
            }
        }
    };
};

/**
 *
 * Returns an array of predefined animation options for rendering list of options
 */
export const getPredefinedAnimationOptions = (): { key: string; label: string }[] => {
    const currentPredefinedAnimationSetup = getPredefinedAnimationsSetup();
    const predefinedAnimationOptions: { key: string; label: string }[] = [];
    Object.keys(currentPredefinedAnimationSetup).forEach((category) => {
        Object.keys(currentPredefinedAnimationSetup[category].animations).forEach((animation) => {
            predefinedAnimationOptions.push({
                key: currentPredefinedAnimationSetup[category].animations[animation].key,
                label: currentPredefinedAnimationSetup[category].animations[animation].label
            });
        });
    });

    return predefinedAnimationOptions;
};

export default getPredefinedAnimationsSetup;
