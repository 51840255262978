import React, { Dispatch, SetStateAction, useMemo, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { TableSortLabel } from '@mui/material';
import Button from 'components/ui-components-v2/Button';
import Typography from 'components/ui-components-v2/Typography';
import Icon from 'components/ui-components-v2/Icon';
import Translation from 'components/data/Translation';
import EmptyState from 'components/ui-components-cape/EmptyState';
import Illustration from 'components/ui-components-cape/Illustration';
import { PresetsTableRow } from './presets-table-row';
import { Preset } from '../../../../../types/preset';
import PresetHelpers from '../helpers/presets.helper';

import '../styles/presets-dialog.scss';

interface Props {
    presets?: Preset[];
    selectedPresets: Preset[];
    modifyQuantityOfPreset: (preset: Preset, quantity: number) => void;
    multiple: boolean;
    focusedPreset: Preset | undefined;
    setFocusedPreset: Dispatch<SetStateAction<Preset | undefined>>;
    handleMoreInfoButtonClick: (event: React.MouseEvent, preset: Preset) => void;
    handleDeletePreset: (newPreset: Preset) => void;
    handleEditPreset: (event: React.MouseEvent, preset: Preset) => void;
    toggleDialog: (isOpen: boolean) => void;
}

/**
 * Table to view a list of presets a user can select out of
 */
const PresetsTable = ({
    presets = [],
    selectedPresets,
    modifyQuantityOfPreset,
    multiple,
    focusedPreset,
    setFocusedPreset,
    handleMoreInfoButtonClick,
    handleDeletePreset,
    handleEditPreset,
    toggleDialog
}: Props) => {
    const [sort, setSort] = useState<{ orderBy: string; order: 'asc' | 'desc' }>({ orderBy: 'formats', order: 'asc' });

    /**
     * Handle the change of the radio button or checkbox
     * @param checked Whether the radio button or checkbox is checked or not
     * @param preset The preset that is being selected
     */
    const onChange = (preset: Preset) => {
        if (multiple && (!focusedPreset || focusedPreset.identifier !== preset.identifier)) {
            setFocusedPreset(preset);
        } else if (!multiple) modifyQuantityOfPreset(preset, 1);
    };

    // Combine standard and custom presets and sort them based on the selected column (formats, assetType, or fileExtension)
    const combinedPresets = useMemo(() => {
        if (!presets) return [];
        return PresetHelpers.combineCustomAndPredefinedPresets(presets, sort);
    }, [sort, presets]);

    // Helper function to render the empty state
    const renderEmptyState = (toggleDialog: (isOpen: boolean) => void) => (
        <EmptyState
            className="dialog-presets-table__empty"
            primaryText={Translation.get('customPresetDialog.emptyPrimaryText', 'bricks')}
            secondaryText={Translation.get('customPresetDialog.emptySecondaryText', 'bricks')}
            primaryButton={{ label: Translation.get('customPresetDialog.addCustomPreset', 'bricks'), onClick: () => toggleDialog(true) }}
            illustration={<Illustration illustration="images" />}
        />
    );

    return (
        <div className="dialog-presets-table">
            {!combinedPresets || combinedPresets.length === 0 ? (
                renderEmptyState(toggleDialog)
            ) : (
                <Table size="small" className="dialog-presets-table__table">
                    <TableHead>
                        <TableRow>
                            {multiple && <TableCell className="dialog-presets-table__table__head-cell" align="left"></TableCell>}
                            <TableCell className="dialog-presets-table__table__head-cell" align="left"></TableCell>
                            <TableCell align="left">
                                <TableSortLabel
                                    active={sort.orderBy === 'formats'}
                                    direction={sort.orderBy === 'formats' ? sort.order : 'asc'}
                                    onClick={() => setSort({ orderBy: 'formats', order: sort.order === 'asc' ? 'desc' : 'asc' })}>
                                    Formats
                                </TableSortLabel>
                            </TableCell>
                            <TableCell className="dialog-presets-table__table__head-cell">
                                <TableSortLabel
                                    active={sort.orderBy === 'assetType'}
                                    direction={sort.orderBy === 'assetType' ? sort.order : 'asc'}
                                    onClick={() => setSort({ orderBy: 'assetType', order: sort.order === 'asc' ? 'desc' : 'asc' })}>
                                    Asset
                                </TableSortLabel>
                            </TableCell>
                            <TableCell className="dialog-presets-table__table__head-cell">
                                <TableSortLabel
                                    active={sort.orderBy === 'fileExtension'}
                                    direction={sort.orderBy === 'fileExtension' ? sort.order : 'asc'}
                                    onClick={() => setSort({ orderBy: 'fileExtension', order: sort.order === 'asc' ? 'desc' : 'asc' })}>
                                    File type
                                </TableSortLabel>
                            </TableCell>
                            <TableCell className="dialog-presets-table__table__head-cell">Size</TableCell>
                            <TableCell className="dialog-presets-table__table__head-cell"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow className="dialog-presets-table__table__custom-preset-row">
                            <TableCell colSpan={7} className="dialog-presets-table__table__custom-preset-row__cell">
                                <Button
                                    className="dialog-presets-table__table__custom-preset-row__button"
                                    size="medium"
                                    fullWidth
                                    onClick={() => toggleDialog(true)}>
                                    <Typography className="dialog-presets-table__table__custom-preset-row__content">
                                        {Translation.get('customPresetDialog.addCustomPreset', 'bricks')}
                                        <Icon className="dialog-presets-table__table__custom-preset-row__icon">add_circle_outline</Icon>
                                    </Typography>
                                </Button>
                            </TableCell>
                        </TableRow>
                        {combinedPresets.map((preset: Preset) => (
                            <PresetsTableRow
                                key={preset.identifier}
                                preset={preset}
                                onChange={onChange}
                                multiple={multiple}
                                focusedPreset={focusedPreset}
                                modifyQuantityOfPreset={modifyQuantityOfPreset}
                                selectedPresets={selectedPresets}
                                selected={selectedPresets.map((selectedPreset) => selectedPreset.identifier).includes(preset.identifier)}
                                handleMoreInfoButtonClick={handleMoreInfoButtonClick}
                                handleDeletePreset={handleDeletePreset}
                                handleEditPreset={handleEditPreset}
                            />
                        ))}
                    </TableBody>
                </Table>
            )}
        </div>
    );
};

export default PresetsTable;
