import React from 'react';
import classNames from 'classnames';
import MuiTextField from '@mui/material/TextField';
import './styles/main.scss';

class TextField extends React.Component {
    render() {
        const { className, ...other } = this.props;

        return (
            <MuiTextField
                className={classNames('ui-components-textfield', className)}
                variant="outlined"
                margin="dense"
                slotProps={{
                    input: {
                        classes: {
                            root: 'ui-components-textfield__root',
                            input: 'ui-components-textfield__input'
                        }
                    }
                }}
                {...other}
            />
        );
    }
}

export default TextField;
