import Templates from 'components/data/Templates';
import { convertTemplateToAssetV2 } from 'components/data/Templates/helpers/template-converter';
import { CreativeV2 } from '../components/creative-editor/types/creativeV2.type';
import { TemplateManager } from '../data/template-manager';

class CreativeV2Helpers {
    /**
     * Loads in the necesary data to use the creative
     *
     * Template creative: Load in the template data inside TemplateManagers
     *
     * @param {CreativeV2} creative - The creative to load
     * @returns {Promise<CreativeV2>} - The loaded creative
     */
    static loadTemplateForCreative = async (creative: CreativeV2): Promise<CreativeV2> => {
        switch (creative.type) {
            case 'template': {
                if (!TemplateManager.templateExists(creative.data?.templateIdentifier)) {
                    const template = await Templates.asyncGetTemplatesItem(creative.data?.templateType, creative.data?.templateIdentifier);
                    if (template) {
                        TemplateManager.addTemplate(convertTemplateToAssetV2(template));
                    }
                }
                return creative;
            }
            default:
                return creative;
        }
    };
}

export { CreativeV2Helpers };
