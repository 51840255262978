import React from 'react';
import { isAMV2Enabled } from 'components/template-management/utilities';
import CreativeBuilderTemplateDialogV1 from './template-dialog-v1';
import CreativeBuilderTemplateDialogV2 from './template-dialog-v2';

import '../styles/main.scss';

/**
 * CreativeBuilderTemplateDialog
 * Shows the template dialog.
 * This is just a wrapper around the generic template dialog.
 */
class CreativeBuilderTemplateDialog extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { open, onClose, orderedTemplates, onSelectItem, showCreatives, templateTypes, itemIncludes, templateFilter, groupKey } = this.props;

        return (
            <div className="creative-builder-template-dialog">
                {!isAMV2Enabled() && (
                    <CreativeBuilderTemplateDialogV1
                        open={open}
                        templateTypes={templateTypes}
                        orderedTemplates={orderedTemplates}
                        itemIncludes={itemIncludes}
                        groupKey={groupKey}
                        templateFilter={templateFilter}
                        showCreatives={showCreatives}
                        onClose={onClose}
                        onSelectItem={onSelectItem}
                    />
                )}

                {isAMV2Enabled() && (
                    <CreativeBuilderTemplateDialogV2
                        open={open}
                        templateTypes={templateTypes}
                        itemIncludes={itemIncludes}
                        groupKey={groupKey}
                        showCreatives={showCreatives}
                        onClose={onClose}
                        onSelectItem={onSelectItem}
                    />
                )}
            </div>
        );
    }
}

export default CreativeBuilderTemplateDialog;
