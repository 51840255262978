import { GridItem } from 'helpers/arrangeItemsInGrid';
import { CreativeV2 } from '../components/creative-editor/types/creativeV2.type';

export abstract class CreativeV2Base<T extends CreativeV2> {
    protected creative: T;

    constructor(creative: T) {
        this.creative = creative;
    }

    // Prepare the creative to be arranged in a grid. This means that for every creative we should get the width and height.
    // For templates this also means that we should get a copy of the creative for each format.
    abstract prepareForArrangement(): GridItem<T>[];

    // Get the templateInput format overwrites
    abstract getFormatOverwrites(formatKey: string, creativeModel: string): undefined | { [key: string]: any };

    // The model after the templateInput that prefixes the user input (e.g. 'frames.frame'). If the creative doens't accept multiple frames, this will most likely be an empty string
    abstract getCreativeModel(activeFrame?: string): string;

    // If true, there will be a playbar in the creative overview that shows a play/pause button and a potential frames bar
    abstract showPlayBar(): boolean;

    // Whether or not the creative can have multiple frames
    abstract isMultiFrame(): boolean;

    // The overlay is there for iframes, to make sure that in the infinite viewer your mouse is not over the iframe itself (zooming in/out doesn't work over an iframe)
    abstract hasOverlay(): boolean;
}
