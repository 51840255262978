import React, { useMemo } from 'react';
import EditorData from 'components/editor-data/EditorData';
import TemplateDialog from 'components/ui-base/TemplateDialog';
import { CREATIVE_TEMPLATE_TYPES } from 'components/template-management/constants';
import Templates, { withCreativeTemplates } from 'components/data/Templates';
import Translation from 'components/data/Translation';
import User from 'components/data/User';

/**
 * Get the total list of available templates in the selection
 * @returns
 */
const setupTemplateItems = (templateTypes, itemIncludes, templateFilter, groupKey) => {
    let result = [];

    (templateTypes || CREATIVE_TEMPLATE_TYPES).forEach((item) => {
        let set = [];

        if (item.startsWith('staticAsset')) {
            const assetType = item.replace('staticAsset', '').toLowerCase();
            set = { [item]: Templates.get('staticAsset', assetType) };
        } else {
            set = Templates.get(item) || [];
        }

        Object.keys(set).forEach((key) => {
            result.push(set[key]);
        });
    });

    // Filter items if groupKey is set and has value.
    if (groupKey) {
        result = result.filter((item) => {
            return item.groupKey === this.props.groupKey;
        });
    }

    // Filter items if itemIncludes is set and has value.
    if (Array.isArray(itemIncludes) && itemIncludes.length > 0) {
        const itemIncludesLowerCase = itemIncludes.map((i) => i.toLowerCase());
        result = result.filter((item) => {
            return itemIncludesLowerCase.includes(item.identifier.toLowerCase());
        });
    }

    // Filter items if itemIncludes is set and has value.
    if (Array.isArray(templateFilter) && templateFilter.length > 0) {
        const templateFilterLowerCase = templateFilter.map((i) => i.toLowerCase());
        result = result.filter((item) => {
            return templateFilterLowerCase.includes(item.identifier.toLowerCase()) || templateFilterLowerCase.includes(item.type.toLowerCase());
        });
    }

    result = result.filter((item) => {
        // This campaign is for another brand. Check whether the templates are linked to a brand. If so, check whether to display them

        if (
            item.brand &&
            EditorData.getValueFromModel('settings.brand') &&
            EditorData.getValueFromModel('settings.brand') !== '' &&
            item.brand !== EditorData.getValueFromModel('settings.brand')
        ) {
            return false;
        }

        // I don't have access to this brand, remove template
        if (item.brand && Array.isArray(User.get('brands')) && !User.get('brands').includes(item.brand) && !User.hasRight('multiBrand')) {
            return false;
        }

        return true;
    });

    result.sort((a, b) => (a.category > b.category ? 1 : -1));
    return result;
};

/**
 * Set up the different ad types for the categories
 */
const setupCategories = (templateItems) => {
    const categories = [];
    // Loop through all items loaded from the resourc
    templateItems.forEach((item) => {
        if (item.category && !categories.includes(item.category.toLowerCase())) {
            categories.push(item.category.toLowerCase());
        }
    });

    return categories;
};

const CreativeBuilderTemplateDialogV1 = ({
    open,
    templateTypes,
    orderedTemplates,
    itemIncludes,
    groupKey,
    templateFilter,
    showCreatives,
    onClose,
    onSelectItem
}) => {
    const items = useMemo(
        () => setupTemplateItems(templateTypes, itemIncludes, templateFilter, groupKey),
        [templateTypes, itemIncludes, templateFilter, groupKey]
    );

    const categories = useMemo(() => setupCategories(items), [items]);

    return (
        <TemplateDialog
            showCreatives={showCreatives}
            templateTypes={templateTypes || CREATIVE_TEMPLATE_TYPES}
            itemIncludes={itemIncludes}
            isOpen={open}
            options={items}
            categories={categories}
            onClose={onClose}
            onSelectItem={onSelectItem}
            title={Translation.get('titles.selectOption', 'common')}
            key="template-dialog"
            orderedTemplates={orderedTemplates}
        />
    );
};

export default withCreativeTemplates(CreativeBuilderTemplateDialogV1);
