import briefing from 'components/bricks/components/shared/components/briefing-content/components';
import feed from 'components/bricks/components/shared/components/feed-tab';
import output from 'components/bricks/components/shared/components/output-tab';
import settings from './settings';
import setup from './setup';
import icon from './icon';
import metadata from './metadata';
import preview from './preview';
import defaultData from './default-data';

export default { settings, setup, icon, metadata, preview, output, defaultData, briefing, feed };
