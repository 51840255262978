import { TemplateType } from 'types/templates.type';
import { CreativeSubType } from '../types/creative-sub-type.type';

type TemplateIdentifier = 'static_asset_display' | 'static_asset_video' | 'static_asset_image' | 'static_asset_pdf' | 'static_asset_audio' | string;

const convertTemplateSubTypeToCreativeSubType = (templateSubType: TemplateType, templateIdentifier: TemplateIdentifier): CreativeSubType => {
    if (templateSubType === 'staticAsset') {
        switch (templateIdentifier) {
            case 'display':
                return 'staticAssetDisplay';
            case 'video':
                return 'staticAssetVideo';
            case 'image':
                return 'staticAssetImage';
            case 'pdf':
                return 'staticAssetPDF';
            case 'audio':
                return 'staticAssetAudio';
        }
    }
    return templateSubType as CreativeSubType;
};

export { convertTemplateSubTypeToCreativeSubType };
