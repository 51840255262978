import React from 'react';
import fields from './fields';
import Context from './context';
import Button from 'components/ui-components-v2/Button';
import IconButton from '@mui/material/IconButton';
import Icon from 'components/ui-components-v2/Icon';
import '../styles/section.scss';
import cN from 'classnames';
import Empty from './empty';
import SubSection from './sub-section';

/**
 * Section
 * These are the sections in the form
 */
export default class Section extends React.Component {
    static contextType = Context;

    constructor(props) {
        super(props);

        this.state = {
            open: true
        };
    }

    /**
     * Render a list of items
     */
    renderItems = (items = [], fieldModelIndex = -1) => {
        return items.map((item, i) => this.renderItem(item, i, fieldModelIndex));
    };

    /**
     * Render an individual field
     */
    renderItem = (field, i, fieldModelIndex) => {
        const { onChange, model, variant, removeValueOnHide } = this.props;

        // Render model
        let modelFull = field.model;
        if (model && fieldModelIndex >= 0) {
            modelFull = model + '[' + fieldModelIndex + '].' + field.model;
        } else if (model) {
            modelFull = model + '.' + field.model;
        }

        const fieldData = {
            ...field,
            model: modelFull
        };

        if (field.condition && !this.validateCondition(field)) {
            return removeValueOnHide ? (
                <React.Fragment key={'field' + i}>
                    <Empty {...fieldData} variant={variant} onChange={onChange} formFlow />
                </React.Fragment>
            ) : (
                <React.Fragment key={'field' + i} />
            );
        }

        if (fields[field.type]) {
            const TagName = fields[field.type];
            return <TagName key={'field' + i} {...fieldData} variant={variant} onChange={onChange} formFlow />;
        }
        if (field.type === 'subSection') {
            return <SubSection key={'subSection' + i} item={field} renderItems={this.renderItems}></SubSection>;
        } else {
            console.log(`FormFlow: Field type not found [${field.type}]`);
            return <React.Fragment key={'field' + i} />;
        }
    };

    /**
     * Render dynamic fields
     * Render a dynamic block
     */
    renderDynamicFields = () => {
        const { model, items } = this.props;
        let { data } = this.context; // eslint-disable-line

        let sourceData;
        try {
            sourceData = eval('data.' + model);
        } catch (e) {} // eslint-disable-line

        return (
            <div className="form-flow__section__dynamic">
                <div className="form-flow__section__dynamic__add">
                    <Button variant="outlined" color="primary" startIcon={<Icon>add</Icon>} onClick={this.handleAddDynamicFieldSection}>
                        Add
                    </Button>
                    {sourceData &&
                        sourceData.map((item, i) => (
                            <div className="form-flow__section__dynamic__subsection" key={'subsection' + sourceData.length + '-' + i}>
                                <div className="form-flow__section__dynamic__subsection__remove" onClick={() => this.handleRemoveDynamicFieldSection(i)}>
                                    <IconButton aria-label="delete" size="small" onClick={this.handleRemove}>
                                        <Icon fontSize="small">delete</Icon>
                                    </IconButton>
                                </div>

                                {this.renderItems(items, i)}
                            </div>
                        ))}

                    {sourceData && sourceData.length > 1 && (
                        <Button variant="outlined" color="primary" startIcon={<Icon>add</Icon>} onClick={this.handleAddDynamicFieldSection}>
                            Add
                        </Button>
                    )}
                </div>
            </div>
        );
    };

    /**
     * handleAddDynamicFieldSection
     * Adds a new block in the dynamic field section
     */
    handleAddDynamicFieldSection = () => {
        const { onChange, model } = this.props;

        let sourceData;
        try {
            sourceData = eval('data.' + model);
        } catch (e) {} // eslint-disable-line
        if (!sourceData) {
            sourceData = [{}];
        } else {
            sourceData.push({});
        }

        onChange(model, sourceData);
    };

    /**
     * handleRemoveDynamicFieldSection
     * Removes a dynamic field section from the list
     */
    handleRemoveDynamicFieldSection = (index) => {
        const { onChange, model } = this.props;
        let { data } = this.context; // eslint-disable-line

        let sourceData;
        try {
            sourceData = eval('data.' + model);
        } catch (e) {} // eslint-disable-line
        sourceData.splice(index, 1);

        onChange(model, sourceData);
    };

    /**
     * Validate condition
     * Checks the field conditions and returns true or false to see whether we can render the field
     * @param {object} field the field object
     */
    validateCondition = (field) => {
        let { data } = this.context; // eslint-disable-line
        let result = false;

        try {
            result = eval(field.condition); // eslint-disable-line
        } catch (e) {
            return false;
        }

        if (result) {
            return true;
        } else {
            return false;
        }
    };

    /**
     * Handle the toggle open and close of the section
     */
    handleSectionToggle = () => {
        this.setState({ open: !this.state.open });
    };

    // Render
    render() {
        const { title, type, hideBorder, variant, items } = this.props;
        const { open } = this.state;

        // Hide the border, so don't show the section.
        if (hideBorder) {
            return <div className="form-flow__hidden-border">{type == 'section' && this.renderItems(items)}</div>;
        }
        // Show section
        else {
            return (
                <div className={cN('form-flow__section', variant === 'compact' && 'form-flow__section--compact')}>
                    {title && title.length > 0 && (
                        <div className="form-flow__section__title" onClick={this.handleSectionToggle}>
                            {title}
                            {variant === 'compact' && <Icon style={{ transform: open ? 'rotate(180deg)' : 'rotate(0deg)' }}>expand</Icon>}
                        </div>
                    )}
                    {open && (
                        <div className="form-flow__section__content">
                            {type != 'repeatableSection' && this.renderItems(items)}
                            {type == 'repeatableSection' && this.renderDynamicFields()}
                        </div>
                    )}
                </div>
            );
        }
    }
}
