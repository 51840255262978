import React from 'react';
import IconButton from '@mui/material/IconButton';
import Icon from 'components/ui-components-v2/Icon';
import Translation from 'components/data/Translation';
import { LinkedInMetaData } from '../types/types';

interface Props {
    value: LinkedInMetaData[];
    onMutation: (value: unknown, type?: string) => void;
}

const ValueBlock = ({ value, onMutation }: Props) => {
    /**
     * Handle remove selected item
     * @param {*} index
     */
    const handleRemove = (index: number) => {
        const newValue = [...value];
        newValue.splice(index, 1);
        onMutation(newValue);
    };

    return (
        <div className="input__linkedin-select-multiple__list">
            <div className="input__linkedin-select-multiple__list__title">
                {' '}
                {Translation.get('input.metadata.linkedin.selectMultiple.selectedItems', 'common')}
            </div>

            {value.map((item, i) => (
                <div className="input__linkedin-select-multiple__list__item" key={'list' + i}>
                    <div className="input__linkedin-select-multiple__list__item__name">{item.name + ` (${item.urn})`}</div>{' '}
                    <div className="input__linkedin-select-multiple__list__item__actions">
                        <div className="input__linkedin-select-multiple__list__item__actions__remove" onClick={() => handleRemove(i)}>
                            <IconButton size="small">
                                <Icon fontSize="small">delete</Icon>
                            </IconButton>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default ValueBlock;
