import React from 'react';
import Translation from 'components/data/Translation';
import { StaticAsset } from 'components/template-management/types/template-management.type';
import Button from 'components/ui-components-v2/Button';
import { SelectorStaticAsset } from 'components/asset-management/components/selector/types/selector-asset.type';
import GenericIcon from 'components/ui-components/GenericIcon';
import TemplateManagementAssetTileInfo from '../../asset-tile/components/info';

import '../styles/static-asset-tile.scss';

interface Props {
    item?: SelectorStaticAsset;
    onSelect?: (item: StaticAsset) => void;
}

enum PlaceHolderIcon {
    image = 'static-asset-image',
    video = 'static-asset-video',
    audio = 'static-asset-audio',
    display = 'static-asset-display',
    pdf = 'static-asset-pdf'
}

const TemplateManagementSelectorStaticAssetTile = ({ item, onSelect }: Props) => {
    if (!item?.originalRecord) return null;
    if (!onSelect) return null;

    // Select the asset and make sure we notify the usage endpoint of this selection.
    const handleSelect = (item: SelectorStaticAsset) => {
        //TODO: Add usage logic here.

        onSelect(item.originalRecord as StaticAsset);
    };

    return (
        <div onClick={() => handleSelect(item)} className="template-management-selector-static-asset-tile ">
            <div className="template-management-selector-static-asset-tile__preview">
                <GenericIcon icon={PlaceHolderIcon[item.originalRecord.data.identifier]} />
                <div className="template-management-selector-static-asset-tile__overlay">
                    <Button variant="contained" color="primary" className="template-management-selector-static-asset-tile__button">
                        {Translation.get('actions.select', 'common')}
                    </Button>
                </div>
            </div>
            <TemplateManagementAssetTileInfo title={item.title || ''} subtitle={item.subtitle || ''} icon="upload" small />
        </div>
    );
};

export default TemplateManagementSelectorStaticAssetTile;
