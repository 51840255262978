import {
    CreativeBuilderCustomTemplateType,
    CreativeBuilderItemType,
    CreativeBuilderTemplateType,
    CustomTemplateType,
    EmailTemplateType,
    TDTemplateType
} from 'types/templates.type';
import { STATIC_ASSET_TYPES } from './static-assets';

const TD_TEMPLATE_TYPES: TDTemplateType[] = [
    'dynamicImageDesigned',
    'dynamicVideoDesigned',
    'displayAdDesigned',
    'dynamicPDFDesigned',
    'dynamicAfterEffects',
    'dynamicInDesign'
];

const EMAIL_TEMPLATE_TYPES: EmailTemplateType[] = ['emailBase', 'emailBlock'];

const CUSTOM_CREATIVE_BUILDER_TEMPLATES_TYPES: CreativeBuilderCustomTemplateType[] = ['dynamicVideo', 'dynamicImage', 'displayAd', 'dynamicPDF'];

const CUSTOM_TEMPLATE_TYPES: CustomTemplateType[] = [...CUSTOM_CREATIVE_BUILDER_TEMPLATES_TYPES, ...EMAIL_TEMPLATE_TYPES, 'landingpageBlock'];

const CREATIVE_TEMPLATE_TYPES: CreativeBuilderTemplateType[] = [...CUSTOM_CREATIVE_BUILDER_TEMPLATES_TYPES, ...TD_TEMPLATE_TYPES];

const CREATIVE_BUILDER_ITEM_TYPES: CreativeBuilderItemType[] = [...CREATIVE_TEMPLATE_TYPES, 'socialChannelItem', ...STATIC_ASSET_TYPES];

const TEMPLATE_MANAGEMENT_TEMPLATE_TYPES = [...TD_TEMPLATE_TYPES, ...CUSTOM_TEMPLATE_TYPES];

export {
    CREATIVE_TEMPLATE_TYPES,
    CUSTOM_TEMPLATE_TYPES,
    TD_TEMPLATE_TYPES,
    CUSTOM_CREATIVE_BUILDER_TEMPLATES_TYPES,
    EMAIL_TEMPLATE_TYPES,
    CREATIVE_BUILDER_ITEM_TYPES,
    TEMPLATE_MANAGEMENT_TEMPLATE_TYPES
};
