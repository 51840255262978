import BrickHelpers from 'components/bricks/helpers/brick.helpers';
import { Brick, BrickType } from 'components/bricks/types/brick.type';

/**
 * Utility functions for basic brick validation checks.
 */
class ValidatorUtils {
    /**
     * Checks if the brick has an assignee.
     * @param brick - The brick to validate.
     * @returns `true` if validation fails, otherwise `false`.
     */
    static hasAssignee(brick: Brick): boolean {
        return !brick.data?.briefing?.roles?.assignees?.length;
    }

    /**
     * Checks if the brick has a reviewer.
     * @param brick - The brick to validate.
     * @returns `true` if validation fails, otherwise `false`.
     */
    static hasReviewer(brick: Brick): boolean {
        return !brick.data?.briefing?.roles?.reviewers?.length;
    }

    /**
     * Checks if the brick has a deadline.
     * @param brick - The brick to validate.
     * @returns `true` if validation fails, otherwise `false`.
     */
    static hasDeadline(brick: Brick): boolean {
        return !brick.metadata?.dateDeadline;
    }

    /**
     * Checks if the current date is after the brick's deadline.
     * @param brick - The brick to validate.
     * @returns `true` if validation fails, otherwise `false`.
     */
    static isAfterDeadline(brick: Brick): boolean {
        const dateDeadline = brick.metadata?.dateDeadline ? new Date(brick.metadata.dateDeadline) : null;
        if (!dateDeadline) return false; // Skip check if no deadline exists
        return new Date() > dateDeadline;
    }

    /**
     * Checks if the brick has a briefing description.
     * @param brick - The brick to validate.
     * @returns `true` if validation fails, otherwise `false`.
     */
    static hasBriefingDescription(brick: Brick): boolean {
        return !brick.data?.briefing?.description;
    }

    /**
     * Checks if the brick contains an array of attachments in briefing.
     * @param brick - The brick to validate.
     * @returns `true` if attachments array exists and is not empty, otherwise `false`.
     */
    static hasBriefingAttachments(brick: Brick): boolean {
        return !(brick.data?.briefing?.attachments && brick.data.briefing.attachments.length > 0);
    }

    /**
     * Checks if the brick has a valid date range.
     *
     * @param brick - The brick to validate.
     * @returns `true` if the brick has a valid date range, otherwise `false`.
     */
    static hasDateRange(brick: Brick): boolean {
        return !brick.metadata?.dateStart || !brick.metadata?.dateEnd;
    }

    /**
     * Checks if the current date is outside the brick's defined date range.
     *
     * @param brick - The brick to validate.
     * @returns `true` if the current date is outside the date range or if no range exists, otherwise `false`.
     */
    static isInDateRange(brick: Brick): boolean {
        if (brick.metadata?.dateStart && brick.metadata?.dateEnd) {
            const dateStart = new Date(brick.metadata?.dateStart as string);
            const dateEnd = new Date(brick.metadata?.dateEnd as string);
            const now = new Date();
            return now < dateStart || now > dateEnd;
        }
        return false;
    }

    /**
     * Checks if the brick contains an array of creatives.
     * @param brick - The brick to validate.
     * @returns `true` if creatives array exists and is not empty, otherwise `false`.
     */
    static hasCreatives(brick: Brick): boolean {
        return !(brick.data?.creatives && brick.data.creatives.length > 0);
    }

    /**
     * Checks if a brick of type "single_asset" contains media errors.
     * A brick is considered invalid if it has asset errors in the `results.asset.errors` field.
     * @param brick - The brick to validate.
     * @returns `true` if the brick contains media errors, otherwise `false`.
     */
    static hasCorrectMedia(brick: Brick): boolean {
        return brick.subType === 'single_asset' && Boolean(brick.results?.asset?.errors?.length);
    }

    /**
     * Checks if all descendants of a parent brick are already in the specified target status.
     * @param brick - The parent brick to validate.
     * @param targetStatus - The target status to check against.
     * @returns `true` if all descendants are in the target status, otherwise `false`.
     */
    static areAllChildrenInTargetStatus(brick: Brick, targetStatus: string): boolean {
        // Only proceed if the brick is a parent
        if (brick.type !== BrickType.MAIN) {
            return true;
        }

        // Get all descendants of the parent brick
        const descendants = this.getAllDescendants(brick);

        // Check if all descendants are already in the target status
        return descendants.every((descendant) => descendant.metadata?.status === targetStatus);
    }

    /**
     * Recursively retrieves all descendants of a brick.
     * @param brick - The parent brick.
     * @returns Array of all descendant bricks.
     */
    static getAllDescendants(brick: Brick): Brick[] {
        const childBricks = BrickHelpers.getChildrenOfBrick(brick);
        const descendants: Brick[] = [...childBricks];

        for (const childBrick of childBricks) {
            descendants.push(...this.getAllDescendants(childBrick));
        }

        return descendants;
    }
}

export default ValidatorUtils;
