import Translation from 'components/data/Translation';
import { PredefinedAnimation } from 'components/template-designer/types/animation.type';
import { generateKey } from 'components/template-designer/utils/generateKey';
import { PredefinedAnimationSetup } from '.';

const defaultValue: { easing: PredefinedAnimation['easing'] } = {
    easing: {
        type: 'ease-in',
        value: 'ease-in'
    }
};

const fadeOut: PredefinedAnimationSetup = {
    label: Translation.get('animation.predefinedAnimations.animations.fadeOut', 'template-designer'),
    key: 'fadeOut',
    duration: 0.75,
    defaultValue: defaultValue,
    reset: { fadeIn: true, fadeOut: false },
    stamps: {
        position: [],
        size: [],
        scale: [],
        rotation: [],
        opacity: [
            {
                value: 1,
                stamp: 0,
                id: generateKey(),
                easing: {
                    type: 'ease-in',
                    value: 'ease-in'
                }
            },
            {
                value: 0,
                stamp: 1,
                id: generateKey(),
                easing: {
                    type: 'ease-in',
                    value: 'ease-in'
                }
            }
        ],
        backgroundColor: [],
        color: [],
        shadow: [],
        rotationX: [],
        rotationY: []
    }
};

export default fadeOut;
