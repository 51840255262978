import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';
import Icon from 'components/ui-components-v2/Icon';

// Make sure the datepicker starts the week on a Monday instead of a Sunday
moment.updateLocale('en', {
    week: {
        dow: 1
    }
});

/**
 * DatePicker
 * This component displays the datepicker
 */
export class DatePicker extends Component {
    static propTypes = {
        onMutation: PropTypes.func,
        value: PropTypes.any,
        openTo: PropTypes.string,
        daysInFuture: PropTypes.any,
        onClose: PropTypes.func,
        open: PropTypes.bool,
        minDate: PropTypes.string,
        maxDate: PropTypes.string,
        showTimeZone: PropTypes.bool,
        classes: PropTypes.object,
        textFieldInputProps: PropTypes.any
    };

    static defaultProps = {
        onMutation: () => {},
        value: undefined,
        daysInFuture: 0,
        openTo: 'day',
        onClose: () => {},
        open: null,
        minDate: '1900-01-01',
        maxDate: '2100-01-01',
        showTimeZone: false
    };

    constructor(props) {
        super(props);
        const { value } = props;

        let selectedDate = null;
        if (value) {
            selectedDate = moment(value, 'YYYY-MM-DD HH:mm:SS');
        } else if (!value && !props.canBeEmpty) {
            if (props.daysInFuture) {
                selectedDate = moment().add(props.daysInFuture, 'd');
            } else {
                selectedDate = moment();
            }
        }

        this.state = {
            selectedDate: selectedDate
        };
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.value !== this.props.value) {
            this.setState({ selectedDate: moment(this.props.value) });
        }
    };

    handleDateChange = (date) => {
        this.setState({ selectedDate: date }, this.onDataChanged);
    };

    onDataChanged = () => {
        const { onMutation } = this.props;
        const { selectedDate } = this.state;

        if (selectedDate) onMutation(selectedDate.format('YYYY-MM-DD HH:mm:SS'));
    };

    handleOnClose = () => {
        const { onClose } = this.props;

        if (!onClose) return;

        onClose();
    };

    getUtcOffset = () => {
        const offSetInMinutes = moment().utcOffset();
        const offSetInHours = Math.round(offSetInMinutes / 60);

        return `UTC${offSetInHours >= 0 ? '+' : '-'}${offSetInHours}`;
    };

    render() {
        const { openTo, open, readOnly, showTimeZone, minDate, maxDate, classes, textFieldInputProps } = this.props;
        const { selectedDate } = this.state;

        if (readOnly) {
            return <div>{selectedDate.format('DD/MM/YYYY')}</div>;
        } else {
            return (
                <div style={{ alignItems: 'center', display: 'flex' }}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <MuiDatePicker
                            className={classes?.muiDatePicker}
                            slots={{
                                openPickerIcon: () => <Icon>today</Icon>
                            }}
                            slotProps={{ textFieldInputProps: textFieldInputProps }}
                            format="DD/MM/YYYY"
                            value={selectedDate}
                            onChange={this.handleDateChange}
                            open={open}
                            openTo={openTo}
                            onClose={this.handleOnClose}
                            views={['year', 'month', 'day']}
                            minDate={moment(minDate)}
                            maxDate={moment(maxDate)}
                        />
                    </LocalizationProvider>
                    {showTimeZone && <div style={{ marginLeft: 8 }}>{this.getUtcOffset()}</div>}
                </div>
            );
        }
    }
}

export default DatePicker;
