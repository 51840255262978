import { AssetManagementConfig } from 'components/asset-management/types/asset-management-config.type';
import { MEDIA_MANAGEMENT_DEFAULT_SETUP } from './default-setup';

const MEDIA_MANAGEMENT_CONFIG: AssetManagementConfig = {
    storeName: 'MediaManagement',
    type: 'media',
    gridType: 'columns',
    dropZone: true,
    versioning: false,
    fullDataInList: true,
    setupResource: 'setup_mediaManagement',
    assetsPlaceholder: 'media',
    subtypeIcons: {
        image: 'image',
        video: 'slideshow',
        audio: 'audiotrack',
        font: 'font_download',
        text: 'description',
        pdf: 'picture_as_pdf',
        document: 'description',
        zip: 'folder_zip',
        generic: 'more_horiz',
        lottie: 'interests',
        design_file: 'draw_abstract'
    },
    assetsListViewHeaderKeys: ['empty', 'title', 'fileType', 'fileSize', 'lastModified', 'empty'],
    acceptedFileTypes: [
        '.mp4',
        '.mov',
        '.jpg',
        '.jpeg',
        '.zip',
        '.pdf',
        '.html',
        '.gif',
        '.tif',
        '.png',
        '.eps',
        '.psd',
        '.ai',
        '.indd',
        '.raw',
        '.svg',
        '.webm',
        '.webp',
        '.bmp',
        '.heif',
        '.gz',
        '.ttf',
        '.otf',
        '.eot',
        '.woff',
        '.woff2',
        '.avi',
        '.f4v',
        '.mp3',
        '.wav',
        '.wma',
        '.aac',
        '.flac',
        '.m4a',
        '.doc',
        '.docx',
        '.htm',
        '.xls',
        '.xlsx',
        '.txt',
        '.ppt',
        '.pptx',
        '.mpa',
        '.aif',
        '.csv',
        '.m4v',
        '.js',
        '.css',
        '.json',
        '.less',
        '.srt',
        '.xml',
        '.mxf',
        '.avif'
    ]
};

export { MEDIA_MANAGEMENT_CONFIG, MEDIA_MANAGEMENT_DEFAULT_SETUP };
