import merge from 'lodash/merge';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import { isCustomTemplate, isTemplateDesignerTemplate } from 'components/template-management/utilities';
import { CreativeV2FormatHelpers } from 'components/creatives-v2/helpers/formats.helpers';
import { GridItem } from 'helpers/arrangeItemsInGrid';
import cloneDeep from 'helpers/cloneDeep';
import EditorData from 'components/editor-data/EditorData';
import { CreativeV2Template } from '../components/creative-editor/types/creativeV2.type';
import { CreativeV2Base } from './base-creative.class';
import { TemplateDisplayAdDesigned } from './template-types/display-ad-designed';
import { TemplateDynamicImageDesigned } from './template-types/dynamic-image-designed';
import { TemplateDynamicVideoDesigned } from './template-types/dynamic-video-designed';
import { TemplateDynamicImage } from './template-types/dynamic-image';
import { TemplateDynamicPDF } from './template-types/dynamic-pdf';
import { TemplateDynamicPDFDesigned } from './template-types/dynamic-pdf-designed';
import { TemplateDynamicAfterEffects } from './template-types/dynamic-after-effects';
import { TemplateDynamicIndesign } from './template-types/dynamic-indesign';
import { BASE_BLOCK_MODEL } from '../components/creative-editor/helpers/creative-editor-edit.helpers';
import { TemplateManager } from '../data/template-manager';
import { TemplateDynamicVideo } from './template-types/dynamic-video';
import { TemplateDisplayAd } from './template-types/display-ad';

export interface IframeSettings<T> {
    width: number;
    height: number;
    frameWidth?: number;
    frameHeight?: number;
    bottomSpacing?: number;
    url?: string;
    style?: any;
    assetUpdateModel?: string;
    data?: T;
}

export type IframeOverwrites<T> = Partial<IframeSettings<T>>;

export interface IframeData {
    editmode?: boolean;
    frameNr?: number;
    format?: string;
    language?: string;
    scale?: number;
    autoplay?: boolean;
    showPlaybar?: boolean;
    market?: string;
    positionByLanguage?: boolean;
    version?: number;
    template?: any;
    frameNrV2?: number;
    [key: string]: any;
}

export class CreativeV2TemplateType extends CreativeV2Base<CreativeV2Template> {
    prepareForArrangement = (): GridItem<CreativeV2Template>[] => {
        const arrangedCreatives: GridItem<CreativeV2Template>[] = [];

        const activeFormats = CreativeV2FormatHelpers.getActiveFormats(this.creative as CreativeV2Template);
        if (!activeFormats || activeFormats.length === 0) return [];

        activeFormats.forEach((activeFormat) => {
            const clonedCreative = cloneDeep(this.creative) as CreativeV2Template;
            clonedCreative.title = `${this.creative.title || 'Format - '} ${activeFormat.width}x${activeFormat.height}`;

            arrangedCreatives.push({
                item: clonedCreative,
                itemKey: activeFormat.key,
                width: activeFormat.width,
                height: activeFormat.height
            });
        });

        return arrangedCreatives;
    };

    prepareIframeSettings = (activeFormatKey?: string, iframeOverwrites?: IframeOverwrites<IframeData>): IframeSettings<IframeData | null> => {
        if (!activeFormatKey) {
            const formats = CreativeV2FormatHelpers.getActiveFormats(this.creative);
            if (formats.length > 0) {
                activeFormatKey = formats[0].key;
            }
        }

        if (!activeFormatKey) {
            throw new Error('No active format key found for template creative');
        }

        const activeFormat = CreativeV2FormatHelpers.getActiveFormat(this.creative, activeFormatKey);

        if (!activeFormat) {
            throw new Error('No active format found for template creative');
        }

        if (this.creative.data?.templateType === 'dynamicAfterEffects' || this.creative.data?.templateType === 'dynamicInDesign') {
            return {
                width: activeFormat.width,
                height: activeFormat.height
            };
        }

        let inputData = this.creative.data?.templateInput;
        const overwrites = this.creative.data?.templateInput?.overwrites;

        if (overwrites && overwrites[activeFormatKey] && !isEmpty(overwrites[activeFormatKey])) {
            inputData = merge(inputData, overwrites[activeFormatKey]);
        }

        let templateUrl = process.env.TEMPLATES_URL;
        // templateUrl = 'http://localhost:3000';

        const template = TemplateManager.getTemplateByIdentifier(this.creative.data.templateIdentifier);

        if (template && isCustomTemplate(template)) {
            if (template.data.previewUrl) templateUrl = template.data.previewUrl;
        }

        const templateExport = (() => {
            if (!template) return undefined;

            if (isTemplateDesignerTemplate(template)) {
                return template.data.templateExport;
            }
        })();

        const defaultIframeData: IframeData = {
            ...inputData,
            editmode: true,
            format: activeFormatKey,
            language: EditorData.getLanguage() ?? 'EN',
            market: 'NL',
            scale: 1,
            positionByLanguage: false,
            version: 2,
            autoplay: false,
            showPlaybar: false,
            template: templateExport
        };

        const defaultIframeSettings: IframeSettings<IframeData> = {
            url: templateUrl,
            assetUpdateModel: BASE_BLOCK_MODEL,
            frameWidth: activeFormat.width,
            frameHeight: activeFormat.height,
            width: activeFormat.width,
            height: activeFormat.height,
            data: defaultIframeData
        };

        const iframeSettings: IframeSettings<IframeData> = merge(defaultIframeSettings, iframeOverwrites);

        return iframeSettings;
    };

    getFormatOverwrites(formatKey: string, creativeModel: string): undefined | { [key: string]: unknown } {
        const templateInput = cloneDeep(this.creative.data?.templateInput);

        if (!templateInput?.overwrites?.[formatKey]) return;

        const inputData = cloneDeep(get(templateInput, creativeModel, templateInput));

        // If there is no creative model (like frames.frame is) the overwrites are at the same level as the input data.
        // This is because the inputs on the right should show the overwrites and not the default input data.
        // We have to delete the overwrites for the isEqual check at the end
        if (!creativeModel) {
            delete inputData.overwrites;
        }

        // Lodash 'get' doesn't accept empty strings as a path, it will return undefined. CreativeModel can be an empty string, so we need to check for that and return the whole object if it is.
        const formatOverwrites = creativeModel ? get(templateInput.overwrites[formatKey], creativeModel) : templateInput.overwrites[formatKey];

        // Return undefined if there are no overwrites or if the only overwrite is the type (which is what we set temporary)
        if (!formatOverwrites || Object.keys(formatOverwrites).length === 0 || (Object.keys(formatOverwrites).length === 1 && formatOverwrites.type)) return;

        // If the two are equal, there are no overwrites
        if (isEqual(inputData, formatOverwrites)) return;

        // Only return the keys that are in formatOverwrites and not in inputData
        const strippedFormatOverwrites = Object.keys(formatOverwrites).reduce((acc, key) => {
            if (!isEqual(formatOverwrites[key], get(inputData, key))) {
                acc[key] = formatOverwrites[key];
            }
            return acc;
        }, {});

        return strippedFormatOverwrites;
    }

    private getTemplateInstance() {
        switch (this.creative.data?.templateType) {
            case 'displayAdDesigned':
                return new TemplateDisplayAdDesigned(this.creative);
            case 'dynamicImageDesigned':
                return new TemplateDynamicImageDesigned(this.creative);
            case 'dynamicVideoDesigned':
                return new TemplateDynamicVideoDesigned(this.creative);
            case 'dynamicPDFDesigned':
                return new TemplateDynamicPDFDesigned(this.creative);
            case 'displayAd':
                return new TemplateDisplayAd(this.creative);
            case 'dynamicImage':
                return new TemplateDynamicImage(this.creative);
            case 'dynamicVideo':
                return new TemplateDynamicVideo(this.creative);
            case 'dynamicPDF':
                return new TemplateDynamicPDF(this.creative);
            case 'dynamicAfterEffects':
                return new TemplateDynamicAfterEffects(this.creative);
            case 'dynamicInDesign':
                return new TemplateDynamicIndesign(this.creative);
            default:
                return null;
        }
    }

    getCreativeModel(activeFrame: string): string {
        const templateInstance = this.getTemplateInstance();
        if (templateInstance) {
            return templateInstance.getCreativeModel(activeFrame);
        }
        return ``;
    }

    showPlayBar(): boolean {
        const templateInstance = this.getTemplateInstance();
        if (templateInstance) {
            return templateInstance.showPlayBar();
        }
        return false;
    }

    isMultiFrame(): boolean {
        const templateInstance = this.getTemplateInstance();
        if (templateInstance) {
            return templateInstance.isMultiFrame();
        }
        return false;
    }

    hasOverlay(): boolean {
        const templateInstance = this.getTemplateInstance();
        if (templateInstance) {
            return templateInstance.hasOverlay();
        }
        return false;
    }
}
