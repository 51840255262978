import { StaticAsset } from '../types/template-management.type';
import { convertStaticAssetTypeV1ToV2 } from '.';

/**
 * Static assets are defined in the code in the format that wa used in the V1 version of Template Management and creative builder.
 * This function converts the V1 format to to something that resembles a AMV2 asset.
 */
const convertStaticV1ToStaticV2 = (item: any): StaticAsset => {
    const { id, title, identifier, ...other } = item;
    return {
        _id: id,
        title: title,
        subType: convertStaticAssetTypeV1ToV2(identifier),
        type: 'template',
        data: {
            identifier,
            ...other
        }
    };
};

export { convertStaticV1ToStaticV2 };
