import React from 'react';
import Accordion from 'components/bricks/components/shared/components/accordion';
import RadioList from 'components/input/RadioList';
import Translation from 'components/data/Translation';
import { CreativeV2 } from 'components/creatives-v2/components/creative-editor/types/creativeV2.type';
import { MODEL_CREATIVES } from 'components/bricks/constants';
import { useAdSetupContext } from 'components/bricks/components/shared/components/ad-setup/context/ad-setup-context';
import BricksComponentStoreHelper from 'components/bricks/helpers/bricks-component-store.helper';
import useBrick from 'components/bricks/hooks/useBrick';
import BrickHelpers from 'components/bricks/helpers/brick.helpers';
import { BrickSetup } from 'components/bricks/types/brick.type';
import { AdSetupFrameType } from 'components/creatives-v2/components/social-previews-v2/social-preview.type';
import cloneDeep from 'helpers/cloneDeep';
import { AdSetup } from '../types/AdSetup.type';

type FrameProps = {
    handleSelectCreatives: (creatives: CreativeV2[]) => void;
};

type FrameComponent = React.ComponentType<FrameProps>;

const AdSetupType = () => {
    const { brick } = useBrick();
    const { adSetup, selectedFrame, updateAdSetupProperty, updateAdSetupFull, updateSelectedFrame, updateFrameCreatives } = useAdSetupContext();

    if (!brick) return;

    // Get the frame components for this brick subType
    const SingleFrame = BrickHelpers.getBrickData<FrameComponent>(brick?.subType, 'adSetupSingleFrame');
    const MultiFrame = BrickHelpers.getBrickData<FrameComponent>(brick?.subType, 'adSetupMultiFrame');
    const brickSetup = BrickHelpers.getBrickData<BrickSetup | undefined>(brick.subType, 'setup');

    // Check if multi-frame setup is enabled
    const allowsMultiFrame = brickSetup?.config?.adSetup?.allowsMultiFrame;

    /**
     * Handles the updates of creatives for a frame (single and multi). This can be removing, adding or updating
     * The thing to keep in mind here is that we always have a list of all creatives (brick.data.creatives) and the adSetup items just reference those creatives by id
     * @param frameCreatives The updated list of creatives for this frame
     */
    const handleSelectCreatives = (frameCreatives: CreativeV2[]) => {
        const adSetupCopy: AdSetup = cloneDeep(adSetup);
        const allExistingCreatives: CreativeV2[] = cloneDeep(brick.data?.creatives) || [];
        let newTotalCreatives: CreativeV2[] = [...allExistingCreatives];
        let frameCreativeIds: string[] = adSetupCopy.items?.[selectedFrame].creativeIds || [];

        if (frameCreatives.length > frameCreativeIds.length) {
            // A new creative is added, add it to the frameCreativeIds and the total creatives
            const addedCreative = frameCreatives.filter((creative) => !frameCreativeIds.includes(creative.id))[0];
            frameCreativeIds.push(addedCreative.id);
            newTotalCreatives.push(addedCreative);
        } else if (frameCreatives.length === frameCreativeIds.length) {
            // A creative is updated, frameCreativeIds stay the same, but the new frameCreatives should overwrite the existing creatives
            newTotalCreatives = newTotalCreatives.map((creative) => {
                const updatedCreative = frameCreatives.find((frameCreative) => frameCreative.id === creative.id);
                return updatedCreative || creative;
            });
        } else {
            // A creative is removed, remove it from the frameCreativeIds and the total creatives
            const removedCreativeId = frameCreativeIds.filter(
                (frameCreativeId) => !frameCreatives.some((frameCreative) => frameCreative.id === frameCreativeId)
            )[0];
            frameCreativeIds = frameCreativeIds.filter((frameCreativeId) => frameCreativeId !== removedCreativeId);
            newTotalCreatives = newTotalCreatives.filter((creative) => creative.id !== removedCreativeId);
        }

        // Set the new list of creatives in the brick
        BricksComponentStoreHelper.setBrickModel(brick.id, MODEL_CREATIVES, newTotalCreatives.length ? newTotalCreatives : undefined);

        // Also update the context frame creatives
        updateFrameCreatives(frameCreatives);

        // Update the frameCreativeIds for this frame
        updateAdSetupProperty('creativeIds', frameCreativeIds.length ? frameCreativeIds : undefined, true);
    };

    /**
     * Changes the frame and if it is single it removes the unused frames
     * @param newValue AdSetupFrameType
     */
    const handleChangeFrameType = (newValue: AdSetupFrameType): void => {
        if (newValue === 'multi') return updateAdSetupProperty('type', newValue);

        const updatedAdSetup = {
            ...adSetup,
            type: newValue,
            items: adSetup.items?.slice(0, 1) // Keep only the first item
        };

        updateSelectedFrame(0);
        updateAdSetupFull(updatedAdSetup);
    };

    return (
        <>
            {allowsMultiFrame && (
                <Accordion
                    square
                    title={Translation.get('adSetup.adSetup', 'bricks')}
                    classes={{
                        root: 'ad-setup__accordion__root',
                        summaryRoot: 'ad-setup__accordion__summary__root'
                    }}>
                    <RadioList
                        onMutation={(newValue: AdSetupFrameType) => handleChangeFrameType(newValue)}
                        value={adSetup.type}
                        options={[
                            { key: 'single', value: Translation.get('adSetup.singleFrame', 'bricks') },
                            { key: 'multi', value: Translation.get('adSetup.multiFrame', 'bricks') }
                        ]}
                    />
                </Accordion>
            )}
            {(adSetup.type === 'single' || !allowsMultiFrame) && SingleFrame && <SingleFrame handleSelectCreatives={handleSelectCreatives} />}
            {adSetup.type === 'multi' && MultiFrame && <MultiFrame handleSelectCreatives={handleSelectCreatives} />}
        </>
    );
};

export default AdSetupType;
