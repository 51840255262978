import { Brick, BrickSetup } from 'components/bricks/types/brick.type';
import BrickHelpers from 'components/bricks/helpers/brick.helpers';
import cloneDeep from 'helpers/cloneDeep';
import { BrickPublishStatus } from 'components/bricks/types/publish.type';
import { BrickPublishJobData } from 'components/bricks/hooks/useBricksPublish';
import { PublishSummaryTabData } from '../types/tabs.types';
import { PublishResult } from '../../../types/PublishTab.type';

// Helpers for the PublishSummaryTab component
class PublishSummaryTabHelpers {
    /**
     * Get the tab structure with the linked bricks based on the brick that is active
     *
     * @param brick the brick to add to the tabs
     * @param tabs the tabs array where want to add the other tabs to
     * @returns a list of tabs that needs to be shown in the PublishSummaryTabs component
     */
    static getTabs(brick: Brick, tabs: PublishSummaryTabData[] = []): PublishSummaryTabData[] {
        const { subType } = brick;

        const children = BrickHelpers.getChildrenOfBrick(brick, true);

        let newTabs = [...tabs];

        // we want to show tabs based on the brick type
        // we are finding in the tabs array if there is already a tab with the same brick type
        const foundTabId = newTabs.findIndex((tab) => tab.subType === subType);

        // If the tab is not found we create a new tab with the brick type
        if (foundTabId === -1) {
            const setup: BrickSetup = BrickHelpers.getBrickData(subType, 'setup');
            newTabs = [
                ...newTabs,
                {
                    ids: [brick.id],
                    subType: subType,
                    title: setup.title
                }
            ];
        } else {
            const tab = newTabs[foundTabId];
            tab.ids = [...tab.ids, brick.id];
            newTabs[foundTabId] = tab;
        }

        // If the brick has children we want to add the children to the tabs as well
        // this also goes for the children of the children
        if (children && children.length) {
            for (const child of children) {
                newTabs = PublishSummaryTabHelpers.getTabs(child, cloneDeep(newTabs));
            }
        }

        return newTabs;
    }

    /**
     * This function will return the count of the products, publish errors or validations errors based on the status
     *
     * @param status publish status of a brick
     * @param publishJobs the publish jobs that is linked to the tab
     * @param validationErrors  the validation errors that is linked to the tab
     * @returns The count of the products, publish errors or validations errors based on the status
     */
    static getTabInfoCount(status: BrickPublishStatus, validationErrors: PublishResult[], publishJobs?: BrickPublishJobData[]): number | undefined {
        switch (status) {
            case 'info':
            case 'success':
                return publishJobs
                    ?.map((job) => job.products && Object.keys(job.products).length)
                    .reduce((acc, val) => {
                        if (!val) return acc;

                        return (acc || 0) + val;
                    }, 0);
            case 'error':
                return publishJobs
                    ?.map((job) => job.errors && Object.keys(job.errors).length)
                    .reduce((acc, val) => {
                        if (!val) return acc;

                        return (acc || 0) + val;
                    }, 0);
            case 'blocking':
                return validationErrors.length;
            default:
                return undefined;
        }
    }
}

export { PublishSummaryTabHelpers };
