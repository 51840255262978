import '../styles/main.scss';

import InputAdornment from '@mui/material/InputAdornment';
import { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import DOMPurify from 'dompurify';
import Icon from 'components/ui-components-v2/Icon';
import TextField from 'components/ui-components-v2/TextField';
import Button from 'components/ui-components-v2/Button';
import Setup from 'components/data/Setup';
import { RichTextEditor } from 'components/input/RichTextEditor';
import Validator from 'components/data/Validator';
import LanguageAssistant from 'components/ui-components/LanguageAssistant';
import { StringHelpers } from 'helpers/string.helpers';
import DictionaryDialog from './dictionary-dialog';
import SelectDialog from './select-dialog';

/**
 * Copy Multilanguage
 * Allows you to create richtext or regular text in several languages.
 * This also displays the translate button.
 */
export default class CopyMultiLanguage extends Component {
    static propTypes = {
        /** Equal to the output of the field, requiresTranslation and maxLength should be copied from this input to the output.*/
        value: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
        /** Function to call when the value has changed */
        onMutation: PropTypes.func,
        /** The language to use */
        language: PropTypes.string,
        /** The maximu length of the string */
        maxLength: PropTypes.any,
        /** Whether this is a richtext field */
        richText: PropTypes.bool,
        /** Whether this is readonly */
        readOnly: PropTypes.bool,
        /**  In case true, we do not show the English version */
        hideReferenceTranslation: PropTypes.bool,
        /** Whether we have a multiline field */
        multiline: PropTypes.bool,
        /** Wide display */
        wide: PropTypes.bool
    };

    static defaultProps = {
        value: {},
        maxLength: undefined,
        richText: false,
        readOnly: false,
        hideReferenceTranslation: false,
        multiline: false,
        wide: false,
        onMutation: () => {}
    };

    constructor(props) {
        super(props);

        this.state = {
            value: props.value ? props.value : {},
            selectDialogOpen: false,
            dictionaryOpen: false,
            warnings: [],
            errors: [],
            defaultLanguage: 'EN'
        };
        if (typeof this.state.value !== 'object') {
            this.state.value = {};
        }

        const defaultEnvironmentLanguage = Setup.getValueFromModel('defaultLanguage');
        this.state.defaultLanguage = defaultEnvironmentLanguage || 'EN';

        this.state.value.multilanguage = true;
    }

    static getDerivedStateFromProps(props, state) {
        if (!state.value[props.language]) {
            const value = { ...state.value };
            value.multilanguage = true;
            value[props.language] = { value: '', requiresTranslation: false, updated: true };
            return { value: value };
        } else {
            return { ...state };
        }
    }

    /**
     * Component updated.
     * Check whether the props require a new state.
     */
    componentDidUpdate = (prevProps) => {
        const value = this.props.value;
        // Check for executing options to prevent this in the input
        if (typeof value === 'string' && (value.includes('{{{') || value.includes('[[[' || value.substring(0, 2) == '[['))) {
            return;
        }

        // The model location changed, set the new data
        if (this.props.fullModel !== prevProps.fullModel) {
            this.setState({ value: this.props.value });
        }
        // We have a new value which isn't equal to the
        else if (this.props.value !== prevProps.value && !isEqual(this.props.value, this.state.value)) {
            this.setState({ value: this.props.value });
        }
        // Empty in case it was externally cleared
        else if (!this.props.value && prevProps.value && this.state.value) {
            this.setState({ value: {} });
        }
    };

    /**
     * When changing the actual data
     * Waits for 500 ms and starts pushing it to the above model
     */
    processChange = (newValue) => {
        const { language } = this.props;
        const value = { ...this.state.value };

        // Set new value
        value.multilanguage = true;
        value[language]['updated'] = true;
        value[language]['value'] = newValue;
        value[language]['maxLength'] = this.props.maxLength;
        this.setState({ value: value });

        // After 500 ms no changes, set in store
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
        this.timeout = setTimeout(this.updateModel, 500);
    };

    /**
     * Text changes, start processing
     */
    handleTextChange = (value) => {
        this.processChange(value);
    };

    /**
     * Text in the richtext changed, start processing
     */
    handleRichTextChange = (newValue) => {
        this.processChange(newValue);
    };

    /**
     * Update the model value
     */
    updateModel = () => {
        const { onMutation, validators } = this.props;
        const { value } = this.state;
        onMutation(value);

        if (validators) {
            this.validate();
        }
    };

    /**
     * Field validator
     */
    validate = () => {
        const { value } = this.state;
        const { validators, language } = this.props;

        const warnings = [];
        const errors = [];
        // Validate the value on change
        if (value[language] && value[language].value && validators) {
            validators.forEach((item) => {
                if (!Validator.validateValue(value[language].value, item)) {
                    if (item.severity && item.severity === 'warning') {
                        warnings.push(item.message);
                    } else {
                        errors.push(item.message);
                    }
                }
            });
        }
        this.setState({
            warnings: warnings,
            errors: errors
        });
    };

    /**
     * Select box for multi language options
     */
    handleSelectDialogOpen = () => {
        this.setState({ selectDialogOpen: true });
    };

    /**
     * Select box for multiple language options closed
     */
    handleSelectDialogClose = () => {
        this.setState({ selectDialogOpen: false });
    };

    /**
     * We use the select dialog
     * When selecting
     */
    handleDialogChange = (newValue, closeDialog) => {
        const { language } = this.props;
        const value = { ...this.state.value };

        // Set language
        value[language] = { ...value[language], ...newValue };
        if (newValue.value) {
            value[language]['updated'] = true;
            value[language]['maxLength'] = this.props.maxLength;
        }

        // Change state and mutate
        if (closeDialog) {
            this.setState({ value: value, selectDialogOpen: false });
        } else {
            this.setState({ value: value });
        }
        this.props.onMutation(value);
    };

    /**
     * Handle dictionary change
     * This is called when clicking an item in the dictionary
     */
    handleDictionaryChange = (newValue) => {
        let value = { ...this.state.value };
        value = { ...value, ...newValue };
        this.props.onMutation(value);
        this.setState({ value: value, dictionaryOpen: false });
    };

    /**
     * Handle action menu.
     * When clicking the button behind the textfield you see an action dialog.
     */
    handleActionMenuOpen = (e, activeValue) => {
        // More options, show the
        if (Setup.hasModule('translateAuto') && !activeValue.requiresTranslation) {
            this.setState({ actionMenuOpen: true, anchorEl: e.currentTarget });
        }
        // Only check the checkbox
        else {
            this.handleCheckboxChange(!activeValue.requiresTranslation);
        }
    };

    /**
     * Close the action menu
     */
    handleActionMenuClose = () => {
        this.setState({ actionMenuOpen: false });
    };

    /**
     * Close the dictionary
     */
    handleDictionaryClose = () => {
        this.setState({ dictionaryOpen: false });
    };

    /**
     * Show dictionary
     */
    handleDictionaryOpen = () => {
        this.setState({ dictionaryOpen: true });
    };

    /**
     * Handle the paste event.
     * Remove special characters and update the value of the input.
     * @param event - The paste event.
     */
    handlePaste = (event) => {
        event.preventDefault();

        // Get the pasted text.
        const pastedText = event.clipboardData.getData('text');

        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = pastedText;
        const decodedText = tempDiv.innerText || tempDiv.textContent;

        // Remove non-breaking spaces.
        const cleanText = StringHelpers.removeNonBreakingSpaces(decodedText || '');

        // Update the value of the input.
        event.target.value = cleanText;

        // Update the state.
        this.handleTextChange(cleanText);
    };

    /**
     * Render a textfield
     */
    renderTextField = (referenceTranslation, activeValue) => {
        const { maxLength, hideReferenceTranslation, multiline, startAdornment, placeholder, startIcon, wide } = this.props;

        let adornment;
        if (startAdornment) {
            adornment = <InputAdornment position="start">{startAdornment}</InputAdornment>;
        } else if (startIcon) {
            adornment = (
                <InputAdornment position="start">
                    <Icon style={{ color: '#707070' }}>{startIcon}</Icon>
                </InputAdornment>
            );
        }

        // Alert in case it's too long
        let lengthLeft = 0;
        let error = 0;

        if (maxLength) {
            lengthLeft = maxLength - (activeValue.value && activeValue.value.length ? activeValue.value.length : 0);
        }
        if (lengthLeft < 0) {
            error = 1;
        }

        return (
            <React.Fragment>
                {referenceTranslation && !hideReferenceTranslation && (
                    <TextField
                        className="input__copy-multi-language__flex"
                        margin="dense"
                        variant="outlined"
                        size="medium"
                        fullWidth={wide}
                        value={referenceTranslation}
                        style={{ marginTop: 0 }}
                        bordercolor="#EFEFEF"
                        multiline={multiline}
                        slotProps={{
                            input: {
                                style: {
                                    background: '#EFEFEF'
                                }
                            }
                        }}
                        disabled
                    />
                )}

                <TextField
                    className="input__copy-multi-language__flex"
                    value={activeValue.value ? activeValue.value : ''}
                    onChange={(event) => this.handleTextChange(event.target.value)}
                    onPaste={this.handlePaste}
                    margin="dense"
                    size="medium"
                    fullWidth={wide}
                    error={error > 0}
                    onInput={(e) => {
                        if (e.target.value && maxLength && maxLength > 0) {
                            e.target.value = e.target.value.slice(0, maxLength);
                        }
                    }}
                    variant="outlined"
                    multiline={multiline}
                    style={{ marginTop: 0 }}
                    slotProps={{
                        input: {
                            maxLength: maxLength ? maxLength : 10000,
                            startAdornment: adornment,
                            endAdornment: <InputAdornment position="end">{maxLength ? lengthLeft : ''}</InputAdornment>
                        }
                    }}
                    helperText={error > 0 ? 'Text is too long' : ''}
                    placeholder={placeholder}
                />
            </React.Fragment>
        );
    };

    /**
     * Handle assistant mutation
     * This is called when the language assistant is used
     * @param {*} newValue
     */
    handleAssistantMutation = (newValue) => {
        let value = { ...this.state.value };
        value = { ...value, ...newValue };
        this.props.onMutation(value);
        this.setState({ value: value });
    };

    /**
     * Render the fields as a richtext option
     */
    renderRichText = (referenceTranslation, activeValue) => {
        const { minLength, maxLength, hideReferenceTranslation = false, richTextOptions = {} } = this.props;

        return (
            <React.Fragment>
                {referenceTranslation && !hideReferenceTranslation && (
                    <div className="input__copy-multi-language__reference">
                        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(referenceTranslation) }} />
                    </div>
                )}

                <RichTextEditor
                    className="input__copy-multi-language__richtext"
                    value={activeValue.value}
                    onMutation={this.handleRichTextChange}
                    onInput={(e) => {
                        if (e.target.value && maxLength && maxLength > 0) {
                            e.target.value = e.target.value.slice(0, maxLength);
                        }
                    }}
                    editorConfig={richTextOptions.editorConfig}
                    includedOptions={richTextOptions.includedOptions}
                    excludedOptions={richTextOptions.excludedOptions}
                    minLength={minLength}
                    maxLength={maxLength}
                />
            </React.Fragment>
        );
    };

    render() {
        const { richText = false, language, selectDialogUse, selectDialogEditUserLevel = 0, readOnly, dictionaryUse, wide, maxLength } = this.props;
        const { value, selectDialogOpen, dictionaryOpen, warnings, errors, defaultLanguage } = this.state;

        if (!this.state.value[language]) {
            return <React.Fragment />;
        }

        // Find reference translation
        let referenceTranslation = false;
        if (language != defaultLanguage) {
            try {
                referenceTranslation = value[defaultLanguage].value;
            } catch (e) {}
        }

        // Find active element
        const activeValue = value[language];

        if (readOnly) {
            return <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(activeValue.value) }} />;
        } else {
            return (
                <div className={'input__copy-multi-language'}>
                    <div
                        className={
                            'input__copy-multi-language__input ' +
                            (richText ? 'input__copy-multi-language__input--richtext' : 'input__copy-multi-language__input--text') +
                            (wide ? 'input__copy-multi-language__input--wide' : '')
                        }>
                        {richText ? this.renderRichText(referenceTranslation, activeValue) : this.renderTextField(referenceTranslation, activeValue)}

                        <LanguageAssistant
                            type={'multiLanguage'}
                            language={language}
                            value={value}
                            onMutation={this.handleAssistantMutation}
                            maxLength={maxLength}
                        />
                    </div>

                    {selectDialogUse && (
                        <div className="input__copy-multi-language__select-dialog-button">
                            <Button color="primary" onClick={this.handleSelectDialogOpen}>
                                Select copy from presets
                            </Button>
                        </div>
                    )}
                    {dictionaryUse && (
                        <div className="input__copy-multi-language__select-dialog-button">
                            <Button color="primary" onClick={this.handleDictionaryOpen}>
                                Select copy from dictionary
                            </Button>
                        </div>
                    )}

                    {selectDialogUse && selectDialogOpen && (
                        <SelectDialog
                            open={selectDialogOpen}
                            data={activeValue}
                            onChange={this.handleDialogChange}
                            onClose={this.handleSelectDialogClose}
                            selectDialogEditUserLevel={selectDialogEditUserLevel}
                        />
                    )}

                    {dictionaryUse && dictionaryOpen && (
                        <DictionaryDialog open={dictionaryOpen} onChange={this.handleDictionaryChange} onClose={this.handleDictionaryClose} />
                    )}

                    {warnings &&
                        warnings.map((warning, i) => (
                            <div key={'warning-' + i} className="input-field-set__helper-text input-field-set__helper-text--warning">
                                {warning}
                            </div>
                        ))}

                    {errors &&
                        errors.map((error, i) => (
                            <div key={'error-' + i} className="input-field-set__helper-text input-field-set__helper-text--error">
                                {error}
                            </div>
                        ))}
                </div>
            );
        }
    }
}
