import React from 'react';
import Button from 'components/ui-components-v2/Button';
import Icon from 'components/ui-components-v2/Icon';
import RouteHelper from '../../RouteHelper';

import '../styles/actions.scss';

/**
 * Diplay button(s) that trigger functions outside the filter component.
 * @param {*} param0
 * @returns
 */
const GenericFilterActions = ({ actions }) => {
    /**
     * Handle action
     * This is used for handling clicks on a button
     * @param {*} item the clicked item
     */
    const handleAction = (item) => {
        // In case we have a link, handle this
        if (item.link) {
            RouteHelper.openLink(item.link);
        }
        // In case it's a function, use the functionm
        else if (item.onClick) {
            item.onClick(item);
        }
    };

    return (
        <React.Fragment>
            {actions &&
                actions.map((item, i) => (
                    <Button
                        key={'button' + i}
                        data-cy={`cd-${item['data-cy']}GenericFilter-button`}
                        onClick={() => handleAction(item)}
                        variant="contained"
                        color="primary"
                        className="generic-filter__actions__button"
                        endIcon={item.icon && <Icon>{item.icon}</Icon>}>
                        {item.title}
                    </Button>
                ))}
        </React.Fragment>
    );
};

export default GenericFilterActions;
