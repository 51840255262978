import React from 'react';
import IconButton from '@mui/material/IconButton';
import Icon from 'components/ui-components-v2/Icon';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import Tooltip from 'components/ui-components-v2/Tooltip';
import Translation from 'components/data/Translation';
// import { FacebookDetailedTargetingMetaData } from '../types/FacebookDemographicTypes';
// import InputTooltipPopper from './input-tooltip-popper';
import { MetadataSearchOption } from '../types/types';

interface Props {
    value: MetadataSearchOption[] | string[];
    showName?: (item: MetadataSearchOption) => ReactJSXElement;
    onMutation: (value: unknown, type?: string) => void;
    tooltipPopper?: (item: MetadataSearchOption) => ReactJSXElement;
}

const ValueBlock = ({
    value,
    onMutation,
    tooltipPopper,
    showName = (val) => {
        return <span>{val.name}</span>;
    }
}: Props) => {
    /**
     * Handle remove selected item
     * @param {*} index
     */
    const handleRemove = (index: number) => {
        const newValue = [...value];
        newValue.splice(index, 1);
        onMutation(newValue);
    };

    return (
        <div className="input__metadata-search__list">
            <div className="input__metadata-search__list__title"> {Translation.get('input.metadata.facebook.detailedTargeting.selectedItems', 'common')}</div>

            {value.map((item, i) => (
                <Tooltip
                    placement="right"
                    key={i}
                    arrow
                    disableHoverListener={!tooltipPopper}
                    className="input__metadata-search__input-tooltip"
                    title={tooltipPopper ? tooltipPopper(item) : ''}>
                    <div className="input__metadata-search__list__item" key={'list' + i}>
                        <div className="input__metadata-search__list__item__name">{typeof item === 'object' ? showName(item) : item}</div>{' '}
                        <div className="input__metadata-search__list__item__actions">
                            <div className="input__metadata-search__list__item__actions__remove" onClick={() => handleRemove(i)}>
                                <IconButton size="small">
                                    <Icon fontSize="small">delete</Icon>
                                </IconButton>
                            </div>
                        </div>
                    </div>
                </Tooltip>
            ))}
        </div>
    );
};

export default ValueBlock;
