import React, { useState } from 'react';
import classNames from 'classnames';
import { MediaAsset } from '../../../types/media-management.type';

import '../styles/preview-image.scss';

interface Props {
    asset: MediaAsset;
}

const MediaManagementAssetTilePreviewImage = ({ asset }: Props) => {
    const [imageLoaded, setImageLoaded] = useState(false);
    return (
        <div className={classNames('media-management-asset-tile-preview-image', { 'media-management-asset-tile-preview-image--loaded': imageLoaded })}>
            <img
                src={asset.data.files?.[0]?.thumbnail?.url}
                className="media-management-asset-tile-preview-image__image"
                alt={asset.title}
                onLoad={() => setImageLoaded(true)}
            />
        </div>
    );
};

export default MediaManagementAssetTilePreviewImage;
