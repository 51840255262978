import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import CircularProgress from 'components/ui-components-v2/CircularProgress';
import Icon from 'components/ui-components-v2/Icon';
import Translation from 'components/data/Translation';
import Button from 'components/ui-components-v2/Button';
import Request from 'components/data/Request/index.js';
import SnackbarUtils from 'components/ui-base/SnackbarUtils';
import { AprimoOptions, SourceData } from 'components/assets/AssetGalleryDialog/interfaces/AssetGalleryDialogState.js';
import Setup from 'components/data/Setup';
import SelectorGrid from '../../grid';
import '../../../../styles/asset-finder.scss'; // -------------- asset-finder

interface Props {
    onSelect: (item: SourceData, skipCropper?: boolean) => void;
    list: SourceData[];
    onRemove: (item: SourceData) => void;
    onAdd: (item: SourceData) => void;
    tenant: string;
    outputFormat: string;
    options?: AprimoOptions;
}

/**
 * Upload images using the Aprimo Selector
 * this page is the upload
 */
const SelectorAprimo: React.FC<Props> = ({ onSelect, list = [], onRemove, onAdd, tenant: propTenant, outputFormat, options }) => {
    const [loading, setLoading] = useState(false);

    // If the tenant is not passed as a prop, get it from the setup
    const tenant =
        propTenant ||
        (() => {
            const setup = Setup.get();
            return setup?.aprimo?.tenant ? setup.aprimo.tenant : '';
        })();

    const tenantUrl = `https://${tenant || 'no-tenant-selected'}.dam.aprimo.com`;

    useEffect(() => {
        return () => {
            window.removeEventListener('message', handleMessageEvent, true);
        };
    }, []);

    /**
     * Open the Aprimo Selector in a new tab
     */
    const openSelector = () => {
        // Remove previous event listener
        window.removeEventListener('message', handleMessageEvent, true);

        const encodedOptions = window.btoa(JSON.stringify(options));
        const aprimoContentSelectorUrl = `${tenantUrl}/dam/selectcontent#options=${encodedOptions}`;

        window.open(aprimoContentSelectorUrl, 'selector');
        window.addEventListener('message', handleMessageEvent, true);
    };

    /**
     * Handle the event messages from the Aprimo Selector
     * @param {*} event The eventListener event
     */
    const handleMessageEvent = useCallback(async (event) => {
        // Ensure only messages from the Aprimo Content Selector are handled.
        if (event.origin !== tenantUrl) return;

        if (!event || !event.data || event.data.result === 'cancel') return;

        const { recordId, title } = (() => {
            if (event && event.data && event.data.selection) {
                return {
                    recordId: event.data.selection[0].id,
                    title: event.data.selection[0].title
                };
            }
            return { recordId: null, title: null };
        })();

        if (!recordId) {
            SnackbarUtils.error('No record id');
            return;
        }

        try {
            setLoading(true);

            const recordData = await getRecordData(recordId);

            if (recordData) {
                onAdd({
                    ...recordData.data,
                    title,
                    type: recordData.data.fileType,
                    subtitle: `${recordData.data.width} x ${recordData.data.height} | ${recordData.data.humanSize}`,
                    aprimo: true
                });
            }

            setLoading(false);
        } catch (error: any) {
            setLoading(false);
            if (error && error.response && error.response.data) {
                SnackbarUtils.error(error.response.data.message);
            }
        }
    }, []);

    const getToken = async () => {
        try {
            const {
                data: { token }
            } = await Request.post('/media/getClientMicroservicesToken', {});

            if (token) return token;
        } catch (error) {
            SnackbarUtils.error('There was an error retrieving the token');
        }
    };

    /**
     * Get the data of the asset from Aprimo
     * @param {*} recordId The id of the asset to retrieve
     * @returns An object with the data of the asset
     */
    const getRecordData = async (recordId: string) => {
        const token = await getToken();
        const aprimoMicroserviceUrl = `${process.env.CLIENT_MICROSERVICES_URL_V1}clients/aprimo`;

        return await axios.get(aprimoMicroserviceUrl, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: {
                url: tenant,
                recordId,
                outputFormat
            }
        });
    };

    // Only show images from Aprimo
    list = list.filter((item: SourceData) => item?.aprimo);

    return (
        <div className="asset-finder">
            {loading && (
                <div className="asset-finder__aprimo-loading">
                    <div className="asset-finder__aprimo-loading__copy">
                        {Translation.get('assetGallerySelector.selectorAprimo.retrievingAsset', 'content-space')}
                    </div>
                    <CircularProgress />
                </div>
            )}
            {!loading && (
                <>
                    <div className="asset-finder__aprimo-topbar">
                        <Button variant="contained" onClick={() => openSelector()} endIcon={<Icon>open_in_new</Icon>}>
                            {Translation.get('assetGallerySelector.selectorAprimo.openSelector', 'content-space')}
                        </Button>
                    </div>
                    <div className="asset-finder__list">
                        {list && list.length > 0 && <SelectorGrid isLoading={false} isRefresh={false} onRemove={onRemove} list={list} onSelect={onSelect} />}
                    </div>
                </>
            )}
        </div>
    );
};

export default SelectorAprimo;
