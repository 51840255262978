import React, { useState, useEffect } from 'react';
import Icon from 'components/ui-components-v2/Icon';
import Button from 'components/ui-components-v2/Button';
import { withResources } from 'components/data/Resources';
import WithContentSpaceHandler from 'components/asset-library/DataHandler/';
import AssetsSort from 'components/asset-library/Assets/components/sort';
import GenericFilterWrapper from 'components/asset-library/GenericFilterWrapper';
import Translation from 'components/data/Translation';
import { CollectionViewCount } from 'components/ui-components/CollectionView';
import AssetHelpers from 'components/data/AssetHelpers';
import SelectorAssetsGrid from './selector-assets-grid';
import SelectorCollectionsGrid from './selector-collections-grid';
import SelectorCollection from './selector-collection';

import '../../../../styles/asset-finder.scss';

/**
 * This uses the contentspace data in the selector
 * @param {*} param0
 */
function SelectorContentSpace({
    onSelect,
    acceptedTypes,
    assetsFetched,
    assetsFiltered,
    assetSorting,
    setup_contentspace,
    setResources,
    fetchFilters,
    fetchCollections,
    fetchMoreCollections,
    fetchAssets,
    fetchCollectionItems,
    setLocationView,
    collections,
    collectionsFetched,
    collectionsFiltered,
    collectionsNext,
    collectionItems,
    collectionItemsFiltered,
    collectionItemsSorting,
    contentSpaceCollection,
    contentSpaceCollectionQuery,
    setCollectionReferenceId,
    resetSearchQuery,
    onClearAllFilterSelection,
    sortAssets,
    token
}) {
    const [tab, setTab] = useState('overview');
    const [openCollection, setOpenCollection] = useState(null);

    const getLocationView = (tab) => {
        return tab === 'collections' ? 'collections' : 'assets';
    };

    /**
     * Determine whether assets should be fetched and if so, fetch them.
     * Are assets fetched allready and not filtered? Don't fetch.
     */
    const conditionallyFetchAssets = async () => {
        if (!assetsFetched || assetsFiltered) {
            if (!acceptedTypes?.length) {
                await fetchFilters(true);
                await fetchAssets();
            }
        }
    };

    /**
     * Determine whether collection items should be fetched and if so, fetch them.
     * Are collections items fetched allready? Don't fetch.
     * Are accepted filetypes specified? Don't fetch, the Generic Filter will do a search call.
     */
    const conditionallyFetchCollectionItems = (collectionReferenceId) => {
        if ((!Array.isArray(collectionItems[collectionReferenceId]) || collectionItemsFiltered.includes(collectionReferenceId)) && !acceptedTypes) {
            fetchCollectionItems(collectionReferenceId);
        }
    };

    const changeTab = (newTab) => {
        setLocationView(getLocationView(newTab));
        resetSearchQuery();
        if (newTab === 'collections') {
            setCollectionReferenceId(null);
            if (!collectionsFetched || collectionsFiltered) {
                if (collectionsFiltered) onClearAllFilterSelection('collections');
                fetchCollections();
            }
        } else {
            setCollectionReferenceId(null);
            onClearAllFilterSelection('assets');
            conditionallyFetchAssets();
        }
        setTab(newTab);
    };

    /**
     * Display the detail view of a collection.
     * @param {object} collection
     */
    const getCollection = async (collectionReferenceId) => {
        let collection = null;
        if (collectionReferenceId) {
            const found = collections.find((c) => c.referenceId === collectionReferenceId);
            if (!found) {
                collection = await AssetHelpers.getCollection(collectionReferenceId, token);
            } else {
                collection = found;
            }
        }

        if (collection && collectionReferenceId) {
            setLocationView('collectionItems');
            setCollectionReferenceId(collectionReferenceId);
            onClearAllFilterSelection('assets');
            conditionallyFetchCollectionItems(collectionReferenceId);
        } else {
            setCollectionReferenceId(null);
        }
        setOpenCollection(collection);
    };

    /**
     * On load, start loading new data
     */
    useEffect(() => {
        if (setup_contentspace) {
            setResources(setup_contentspace);
        }
        setLocationView(getLocationView(tab));

        // Clear the filterselection on mount in case acceptedTypes where previously set.
        onClearAllFilterSelection('assets', false);

        if (contentSpaceCollection || contentSpaceCollectionQuery) {
            if (!collectionsFetched) fetchCollections();
            setTab('collections');
        } else {
            conditionallyFetchAssets();
        }
    }, []);

    /**
     * Once the collections are fetched, see if a predefined collection should be loaded, and load it into detail view if it exists.
     */
    useEffect(() => {
        if (collectionsFetched) {
            let thisCollection = undefined;
            if (contentSpaceCollectionQuery) {
                thisCollection = collections.find((col) => col.name.toLowerCase().includes(contentSpaceCollectionQuery.toLowerCase()));
            }
            if (!thisCollection && contentSpaceCollection) {
                thisCollection = collections.find((col) => col.referenceId === contentSpaceCollection);
            }

            if (thisCollection) {
                getCollection(thisCollection.referenceId);
            }
        }
    }, [collectionsFetched]);

    return (
        <div className="asset-finder">
            <div className="asset-finder__content-space__navigation">
                {!openCollection && (
                    <React.Fragment>
                        <div
                            className={`asset-finder__content-space__navigation__${tab === 'overview' ? 'tab-active' : 'tab'}`}
                            onClick={() => changeTab('overview')}>
                            {Translation.get('assetGallerySelector.selectorContentSpace.overview', 'content-space')}
                        </div>
                        <div
                            className={`asset-finder__content-space__navigation__${tab === 'collections' ? 'tab-active' : 'tab'}`}
                            onClick={() => changeTab('collections')}>
                            {Translation.get('assetGallerySelector.selectorContentSpace.collections', 'content-space')}
                        </div>
                    </React.Fragment>
                )}

                {openCollection && (
                    <React.Fragment>
                        <div
                            className="asset-finder__content-space__navigation__back"
                            onClick={() =>
                                getCollection(
                                    openCollection.parentCollections && openCollection.parentCollections.length ? openCollection.parentCollections[0] : null
                                )
                            }>
                            <Button color="primary">
                                <Icon>arrow_back</Icon> <b style={{ marginLeft: 6 }}>{Translation.get('actions.back', 'common')}</b>
                            </Button>
                        </div>
                        <div
                            className="asset-finder__content-space__navigation__collection-info"
                            onClick={() =>
                                getCollection(
                                    openCollection.parentCollections && openCollection.parentCollections.length ? openCollection.parentCollections[0] : null
                                )
                            }>
                            <div className="asset-finder__content-space__navigation__collection-info__name">{openCollection.name}</div>
                            <div className="asset-finder__content-space__navigation__collection-info__assets">
                                <CollectionViewCount acceptedTypes={acceptedTypes} categories={openCollection.categories} count={openCollection.count} />
                            </div>
                        </div>
                    </React.Fragment>
                )}
            </div>

            {tab === 'overview' && (
                <React.Fragment>
                    <GenericFilterWrapper filterType="assets" inDialog acceptedTypes={acceptedTypes} className="asset-finder__content-space__generic-filter">
                        <AssetsSort sorting={assetSorting} sortAssets={sortAssets} />
                    </GenericFilterWrapper>
                    <div className="asset-finder__list">
                        <SelectorAssetsGrid hideDeleteButton={true} onSelect={onSelect} />
                    </div>
                </React.Fragment>
            )}

            {tab === 'collections' && (
                <React.Fragment>
                    {!openCollection && (
                        <React.Fragment>
                            <GenericFilterWrapper filterType="collections" inDialog className="asset-finder__content-space__generic-filter" />
                            <div className="asset-finder__content-space__collections-list">
                                <SelectorCollectionsGrid
                                    collections={collections}
                                    acceptedTypes={acceptedTypes}
                                    onSelect={onSelect}
                                    collectionsFetched={collectionsFetched}
                                    collectionsNext={collectionsNext}
                                    fetchMoreCollections={fetchMoreCollections}
                                    setOpenCollection={getCollection}
                                />
                            </div>
                        </React.Fragment>
                    )}
                    {openCollection && (
                        <React.Fragment>
                            <GenericFilterWrapper
                                filterType="assets"
                                inDialog
                                acceptedTypes={acceptedTypes}
                                className="asset-finder__content-space__generic-filter">
                                <AssetsSort sorting={collectionItemsSorting[openCollection.referenceId]} sortAssets={sortAssets} />
                            </GenericFilterWrapper>
                            <div className="asset-finder__content-space__collection">
                                <SelectorCollection onSelect={onSelect} acceptedTypes={acceptedTypes} setOpenCollection={getCollection} />
                            </div>
                        </React.Fragment>
                    )}
                </React.Fragment>
            )}
        </div>
    );
}

export default WithContentSpaceHandler(withResources(SelectorContentSpace, ['setup_contentspace']), {
    type: 'overview'
});
