import { useEffect, useMemo } from 'react';
import React from 'react';
import CreativePreviewHelper from 'components/bricks/components/shared/components/creatives-preview/helpers/creatives-preview.helpers';
import { Brick } from 'components/bricks/types/brick.type';
import SocialPreview from 'components/creatives-v2/components/social-previews-v2';

interface Props {
    brick: Brick;
    activeFormat?: string;
    handleFormats: (formats: string[]) => void;
}

const OutputDialogSocialPreview: React.FC<Props> = ({ brick, handleFormats, activeFormat }) => {
    const creatives = brick.data?.creatives;

    const getSocialPreviewData = () => {
        if (!brick || !activeFormat || !creatives) return;

        const activePlacementObject = CreativePreviewHelper.getPlacementObject(brick.subType, activeFormat);
        return CreativePreviewHelper.generateSocialPreviewData(brick, creatives, activePlacementObject);
    };

    const socialPreviewData = useMemo(() => getSocialPreviewData(), [brick, activeFormat, creatives]);

    const handleAvailablePlacements = () => {
        if (!brick) return;

        const availablePreviewPlacements = CreativePreviewHelper.getAvailablePreviewPlacements(brick, brick?.data?.adSetup?.type);
        const formats = availablePreviewPlacements.map((placement) => placement.value);
        handleFormats(formats);
    };

    useEffect(() => {
        handleAvailablePlacements();
    }, [brick]);

    if (!socialPreviewData) return;

    return <SocialPreview socialPreview={socialPreviewData} />;
};

export default OutputDialogSocialPreview;
