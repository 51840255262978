import React from 'react';
import Icon from 'components/ui-components-v2/Icon';
import Button from 'components/ui-components-v2/Button';
import Translation from 'components/data/Translation';
import { SourceData } from 'components/assets/AssetGalleryDialog/interfaces/AssetGalleryDialogState';
import SelectorGridItemVideo from './selector-grid-item-video';

import '../styles/selector-grid-item.scss';

export interface SelectorGridItemSourceData extends SourceData {
    base64?: string;
    thumbnail?: string;
}

interface Props {
    item: SelectorGridItemSourceData;
    onSelect: (item: SourceData) => void;
    onRemove?: (item: SourceData) => void;
    cropMedia?: boolean;
    hideDeleteButton?: boolean;
    dataCyPrefix?: string;
}

/**
 * This displays an individual item in the grid
 * @param {*} param0
 */
const SelectorGridItem: React.FC<Props> = ({ item, onSelect, onRemove, cropMedia = false, hideDeleteButton, dataCyPrefix }) => {
    const videoRef = React.createRef<HTMLVideoElement>();

    const src = (() => {
        if (item.base64) {
            return item.base64;
        } else if (item.thumbnail && (item.fileType === 'image' || item.fileType === 'document' || item.fileType === 'audio')) {
            if (item.extension === 'svg') return item.url; // We do not want a pixelated version of a svg as preview.
            return item.thumbnail;
        }

        return item.url;
    })();

    const isPortrait = !item?.width || !item?.height ? false : item.width / item.height < 1;

    /**
     * When removing, send data to upper props.
     * @param {*} e
     */
    const removeItem = (e) => {
        e.stopPropagation();

        if (onRemove) {
            onRemove(item);
        }
    };

    const openExternal = (e) => {
        window.open(item.url);
        e.stopPropagation();
    };

    const playVideo = async () => {
        if (item.fileType !== 'video') return;

        try {
            await videoRef.current?.play();
        } catch (_) {
            // Catch the "The play() request was interrupted by a call to pause()" error so the page doesn't crash
        }
    };

    const stopVideo = () => {
        if (item.fileType !== 'video') return;
        const videoElement = videoRef.current;

        if (!videoElement) {
            return;
        }

        videoElement.pause();
        videoElement.currentTime = 0;
    };

    return (
        <div
            data-cy={`${dataCyPrefix}-displayGridItem-${item.title}-div`}
            className="selector-grid-item"
            onClick={() => onSelect(item)}
            onMouseEnter={playVideo}
            onMouseLeave={stopVideo}>
            <div className="selector-grid-item__content">
                <div className={`selector-grid-item__image ${cropMedia && isPortrait ? 'cropped' : 'full'}`}>
                    {!hideDeleteButton && (
                        <div className="selector-grid-item__remove-button" onClick={removeItem}>
                            <Icon fontSize="small">delete</Icon>
                        </div>
                    )}

                    <div className="selector-grid-item__overlay">
                        <div className="selector-grid-item__actions">
                            <Button size="small" style={{ border: '1px solid white' }} variant="contained" color="primary">
                                {Translation.get('actions.select', 'common')}
                            </Button>
                        </div>
                    </div>
                    {item.extension && <div className="selector-grid-item__type-flag">{item.extension}</div>}
                    {item.fileType === 'image' && item.url && <img key={item.url} src={src} />}
                    {item.fileType === 'audio' && !item.thumbnail && (
                        <div className="selector-grid-item__type-audio">
                            <Icon color="primary" style={{ fontSize: 40 }}>
                                graphic_eq
                            </Icon>
                        </div>
                    )}
                    {item.fileType === 'audio' && item.thumbnail && <img key={item.url} src={src} />}
                    {item.fileType === 'video' && <SelectorGridItemVideo key={item.url} item={item} src={src} ref={videoRef} />}
                </div>

                <div className="selector-grid-item__meta">
                    <div className="selector-grid-item__title">{item.title}</div>
                    {item.fileType !== 'video' && item.subtitle && <div className="selector-grid-item__subtitle">{item.subtitle}</div>}
                    {item.fileType !== 'video' && !item.subtitle && !item.width && item.humanSize && (
                        <div className="selector-grid-item__subtitle">
                            {item.fileType} - {item.humanSize}
                        </div>
                    )}
                    {item.fileType !== 'video' && !item.subtitle && item.width && item.height && (
                        <div className="selector-grid-item__subtitle">
                            {item.width && item.height ? `${item.width.toFixed()} x ${item.height.toFixed()}` : ''}{' '}
                            {item.humanSize ? `| ${item.humanSize}` : ''}
                        </div>
                    )}
                    {item.fileType === 'video' && item.humanSize && <div className="selector-grid-item__subtitle">{item.humanSize}</div>}
                    {item.url && !item.url.includes('cape') && (
                        <div className="selector-grid-item__external-link" onClick={openExternal}>
                            <Icon>link</Icon>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default SelectorGridItem;
