import Request from 'components/data/Request';
import store from '../../../../store';

export default class Resources {
    /**
     * Get previously loaded item
     * @param resource
     */
    static get(resource) {
        return store.getState().resources[resource];
    }

    /**
     * Get item. If not loaded, load it and
     * @param resource Resource name
     * @param path Path name to load the URL from
     */
    static async asyncGetOrLoad(resource, path = 'resources/load', params = false) {
        // Check existing item
        if (store.getState().resources[resource]) {
            return store.getState().resources[resource];
        }
        // If not exists, request
        else {
            if (!params) {
                params = { resource: resource };
            }

            const data = await Request.post(path, { resource: resource });

            // Also send to store
            store.dispatch({
                type: 'RESOURCES_LOAD_SUCCESS',
                payload: {
                    resource: resource,
                    loading: false,
                    data: data.data
                }
            });

            return data.data;
        }
    }

    /**
     * Get  all items
     * @param items Array of all items
     */
    static load(resource, path = 'resources/load', params = false, force = false) {
        // Create promises
        return new Promise((resolve, reject) => {
            // Check existing item
            if (!force && store.getState().resources[resource]) {
                resolve(store.getState().resources[resource]);
            }
            // If not exists, request
            else {
                if (!params) {
                    params = { resource: resource };
                }

                Request.post(path, params)
                    .then((result) => {
                        const outputData = result.data;
                        // Also send to store
                        store.dispatch({
                            type: 'RESOURCES_LOAD_SUCCESS',
                            payload: {
                                resource: resource,
                                loading: false,
                                data: outputData
                            }
                        });

                        resolve(outputData);
                    })
                    .catch(() => {
                        reject(undefined);
                    });
            }
        });
    }

    /**
     * Set the data
     * @param {*} resource
     * @param {*} data
     */
    static set(resource, data) {
        store.dispatch({
            type: 'RESOURCES_LOAD_SUCCESS',
            payload: {
                resource: resource,
                loading: false,
                data: data
            }
        });
    }

    /**
     * Get  all items
     * @param items Array of all items
     */
    static loadAll(items) {
        const promises = items.map((item) => {
            const { resource, path = 'resources/load', params = false } = item;
            return new Promise((resolve, reject) => {
                Resources.load(resource, path, params).then((result) => {
                    resolve({ resource: result });
                });
            });
        });

        return Promise.all(promises);
    }

    /**
     * Filter resource data
     * @param resource Resource name
     * @param filter Object filter filter fields
     **/
    static filterFromResource(resource, filterFields) {
        const data = store.getState().resources[resource];

        // Check whether we have the resource
        if (!data || !data.filter) {
            return {};
        }

        // Filter the result
        const results = data.filter((item) => {
            let result = true;
            Object.keys(filterFields).forEach((fieldKey) => {
                const fieldValue = filterFields[fieldKey];

                if (!item[fieldKey] || item[fieldKey] != fieldValue) {
                    result = false;
                }
            });

            return result;
        });
        return results[0];
    }
}
