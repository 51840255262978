import get from 'lodash/get';
import BrickHelpers, { IAdditionalVars } from 'components/bricks/helpers/brick.helpers';
import { Brick, BrickSetup } from 'components/bricks/types/brick.type';
import { UnifiedTab } from 'components/bricks/types/bricksComponentStore.type';
import { IEditorBlock } from 'components/bricks/types/editorBlock.type';
import { FeedRequest } from 'components/data/FeedManagementHelpers';
import EditorData from 'components/editor-data/EditorData';
import { DynamicValueOption } from 'components/input/DynamicData/types/dynamic-value.type';

export class DynamicValueHelper {
    static getAllDynamicValueOptionsForBrick = (brick: Brick, tab: UnifiedTab, additionalVars: IAdditionalVars, model = 'brick'): DynamicValueOption[] => {
        let editorBlocks: IEditorBlock[] = BrickHelpers.getBrickData(brick.subType, 'settingsInputs');
        if (!editorBlocks) editorBlocks = BrickHelpers.getBrickData(brick.subType, 'settings');
        if (!editorBlocks) return [];

        const brickSetup = BrickHelpers.getBrickData(brick.subType, 'setup') as BrickSetup;
        const label = brickSetup.title;

        let optionsCopy: DynamicValueOption[] = [];

        editorBlocks.forEach((editorBlock) => {
            const blockModel = editorBlock.blockModel;
            const editorBlockItems = editorBlock.items || [];

            for (const item of editorBlockItems) {
                // Select only the inputs which has value which can be build as dynamic input
                if (!item.type || !['text', 'dynamicData', 'select', 'number', 'switch'].includes(item.type)) continue;

                // Build the model
                let itemModel = model;

                // Continue if the item's condition is not valid
                if (item.condition) {
                    const brickData = brick.data;
                    const blockData = get(brickData, `${tab.key}.${blockModel}`);

                    if (!EditorData.validateCondition(item, blockData, undefined, additionalVars)) continue;
                }

                if (!item.model) continue;
                else if (item.fixedModel) itemModel = `${itemModel}.${item.model}`;
                else {
                    // Puts the data under "data" and the tab key
                    itemModel = itemModel + `.data.${tab.key}`;

                    // Adds block model
                    if (blockModel) {
                        itemModel = `${itemModel}${blockModel}`;
                    }

                    // Adds the item's model
                    if (item.model) itemModel = `${itemModel}.${item.model}`;
                }

                const option: DynamicValueOption = {
                    value: itemModel,
                    label: `${label}: ${item.label}`,
                    identifier: item.identifier || ''
                };

                optionsCopy.push(option);
            }
        });

        const parent = BrickHelpers.getBrickById(brick.parentId);
        if (parent) {
            const nextModel = this.getNextModelForBrickDynamicOption(model);
            const parentDynamicValueOptions = this.getAllDynamicValueOptionsForBrick(parent, tab, additionalVars, nextModel);
            optionsCopy = [...optionsCopy, ...parentDynamicValueOptions];
        }

        return optionsCopy;
    };

    static getAllDynamicValueOptions = async (
        brick: Brick,
        tab: UnifiedTab,
        additionalVars: IAdditionalVars,
        model = 'brick'
    ): Promise<DynamicValueOption[]> => {
        const brickTypeDynamicValueOptions = this.getAllDynamicValueOptionsForBrick(brick, tab, additionalVars, model);

        let options = [...brickTypeDynamicValueOptions];

        if (additionalVars.datasetId) {
            const feedColumnOptions = (await this.loadFeedColumnsAsDynamicValueOptions(additionalVars.datasetId as string)) || [];
            options = [...feedColumnOptions, ...options];
        }

        return options;
    };

    static getNextModelForBrickDynamicOption = (currentModel: string): string => {
        switch (currentModel) {
            case 'brick':
                return 'parent';
            case 'parent':
                return 'parent.parent';
            default:
                return '';
        }
    };

    static loadFeedColumnsAsDynamicValueOptions = async (datasetId?: string): Promise<DynamicValueOption[] | undefined> => {
        if (!datasetId) return;
        const response = await FeedRequest.get(`dataset/${datasetId}`);
        const data = response.data;
        if (data && data.structure) {
            return Object.keys(data.structure).map((column) => {
                return {
                    value: `item.${column}`,
                    label: `Feed: ${column}`
                };
            });
        }
    };
}
