import React from 'react';
import Translation from 'components/data/Translation';
import { SocialChannelItem, TemplateAsset } from 'components/template-management/types/template-management.type';
import { useAssetManagementConfigContext } from 'components/asset-management/context/asset-management-config.context';
import Button from 'components/ui-components-v2/Button';
import { SelectorSocialChannelItem, SelectorTemplate } from 'components/asset-management/components/selector/types/selector-asset.type';
import { getSelectorUseCase, getSelectorUseCaseId } from 'components/asset-management/utilities';
import AssetManagementService from 'components/asset-management/services/asset-management.service';
import { isTemplateManagementTemplate } from 'components/template-management/utilities/isTemplate';
import TemplateManagementAssetTileInfo from '../../asset-tile/components/info';
import TemplateManagementAssetTilePreview from '../../asset-tile/components/preview';

import '../styles/tile.scss';

interface Props {
    item?: SelectorTemplate | SelectorSocialChannelItem;
    onSelect?: (item: TemplateAsset | SocialChannelItem) => void;
}

const TemplateManagementSelectorTile = ({ item, onSelect }: Props) => {
    const { subtypeIcons } = useAssetManagementConfigContext();

    if (!item?.originalRecord) return null;
    if (!onSelect) return null;

    // Select the asset and make sure we notify the usage endpoint of this selection.
    const handleSelect = (item: SelectorTemplate | SelectorSocialChannelItem) => {
        if (isTemplateManagementTemplate(item.originalRecord as TemplateAsset)) {
            const useCase = getSelectorUseCase();
            if (!useCase) return onSelect(item.originalRecord);

            const useCaseId = getSelectorUseCaseId(useCase);
            if (!useCaseId) return onSelect(item.originalRecord);

            AssetManagementService.addUsage({ assetId: item.id as string, objectId: useCaseId, objectName: useCase });
        }

        onSelect(item.originalRecord);
    };

    return (
        <div onClick={() => handleSelect(item)} className="template-management-selector-tile">
            <TemplateManagementAssetTilePreview
                template={item.originalRecord as TemplateAsset}
                delayBeforeStart={1000}
                className="template-management-selector-tile__preview">
                <div className="template-management-selector-tile__overlay">
                    <Button variant="contained" color="primary" className="template-management-selector-tile__button">
                        {Translation.get('actions.select', 'common')}
                    </Button>
                </div>
            </TemplateManagementAssetTilePreview>
            <TemplateManagementAssetTileInfo
                icon={subtypeIcons[item.originalRecord.subType]}
                iconUrl={item.originalRecord.data?.icon as string | undefined}
                title={item.title || ''}
                subtitle={Translation.get(`sub_types.${item.originalRecord.subType}`, 'template-management')}
                small
            />
        </div>
    );
};

export default TemplateManagementSelectorTile;
