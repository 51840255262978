import React, { useEffect, useRef } from 'react';
import lottie, { AnimationItem } from 'lottie-web';
import { MediaAsset } from '../../../types/media-management.type';

import '../styles/preview-lottie.scss';
import classNames from 'classnames';

interface Props {
    asset: MediaAsset;
}

const MediaManagementAssetTilePreviewLottie = ({ asset }: Props) => {
    const [configLoaded, setConfigLoaded] = React.useState(false);
    const containerRef = useRef<HTMLDivElement | null>(null);
    const lottieRef = useRef<AnimationItem | null>(null);

    const url = asset.data.files?.[0]?.url;

    useEffect(() => {
        if (!url || !containerRef.current) return;

        const anim = lottie.loadAnimation({
            name: asset.title,
            container: containerRef.current,
            renderer: 'svg',
            loop: true,
            autoplay: false,
            path: url,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid meet',
                className: 'media-management-asset-tile-preview-lottie__svg'
            }
        });
        lottieRef.current = anim;
        lottieRef.current.addEventListener('config_ready', setupLottie);

        return () => {
            if (lottieRef.current) {
                lottieRef.current.removeEventListener('config_ready', setupLottie);
                lottieRef.current.destroy();
            }
        };
    }, [url]);

    const setupLottie = () => {
        if (!lottieRef.current) return;
        setConfigLoaded(true);
    };

    // Function to toggle animation playback
    const togglePlay = () => {
        if (lottieRef.current) {
            if (lottieRef.current.isPaused) {
                lottieRef.current.play();
            } else {
                lottieRef.current.pause();
            }
        }
    };

    return (
        <div
            className={classNames('media-management-asset-tile-preview-lottie', { 'media-management-asset-tile-preview-lottie--config-loaded': configLoaded })}>
            <div className="media-management-asset-tile-preview-lottie__animation" ref={containerRef} onMouseEnter={togglePlay} onMouseLeave={togglePlay} />
        </div>
    );
};

export default MediaManagementAssetTilePreviewLottie;
