import { TemplateAsset } from '../types/template-management.type';

const getTemplatePreviewImage = (template: TemplateAsset): string | undefined => {
    if (template.data.image && template.data.image.length) return template.data.image;
    if (Array.isArray(template.data.thumbnail)) return template.data.thumbnail[0]?.url;
    if (template.data.thumbnail?.url) return template.data.thumbnail.url;
    return;
};

export { getTemplatePreviewImage };
