import axios from 'axios';
import * as Sentry from '@sentry/react';
import User from 'components/data/User';
import { CreativeV2 } from 'components/creatives-v2/components/creative-editor/types/creativeV2.type';

/**
 * The service is responsible for handling campaign assets related functions.
 */
class CampaignAssetsService {
    /**
     * The function is responsible for fetching campaign assets based on provided the campaign id.
     * @param campaignId The campaign id to fetch assets for.
     * @param title Filter assets by title.
     * @param assetType Filter assets by type.
     * @returns The list of campaign assets.
     */
    static getCampaignAssets = async (campaignId: string, title?: string, assetType?: string): Promise<CreativeV2[]> => {
        try {
            const uploadResult = await axios.get(process.env.APP_V2_API_HOST + 'bricks/creatives', {
                params: { campaignId, title, type: assetType },
                headers: { Authorization: `Bearer ${User.get('apiToken')}` }
            });

            return uploadResult.data;
        } catch (error) {
            Sentry.captureException(error);
            return [];
        }
    };
}

export default CampaignAssetsService;
