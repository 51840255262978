import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import { useComponentSize } from 'hooks/useComponentSize';
import ColumnHelpers from 'components/data/ColumnHelpers';

import './styles/main.scss';

interface Props {
    children: JSX.Element | JSX.Element[];
    className?: string;
    targetSize?: number;
    onGetColumnCount?: (columnCount: number) => void;
}

const GridWrapper = ({ children, targetSize, className, onGetColumnCount }: Props) => {
    const componentRef = useRef<HTMLDivElement>(null);
    const { width: componentWidth } = useComponentSize(componentRef);

    const columnCount = ColumnHelpers.getColumnCount(componentWidth, targetSize);

    useEffect(() => {
        if (onGetColumnCount) onGetColumnCount(columnCount);
    }, [columnCount]);

    if (!columnCount) return null;

    return (
        <div ref={componentRef} className={classNames('grid-wrapper', `grid-wrapper--${columnCount}`, className)}>
            {componentWidth > 0 && columnCount > 0 && children}
        </div>
    );
};

export default GridWrapper;
