import React from 'react';
import { connect } from 'react-redux';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import IconButton from '@mui/material/IconButton';
import Dropzone from 'react-dropzone';
import Icon from 'components/ui-components-v2/Icon';
import Menu from 'components/ui-components-v2/Menu';
import MenuItem from 'components/ui-components-v2/MenuItem';
import Setup from 'components/data/Setup';
import EditorData from 'components/editor-data/EditorData';
import Request from 'components/data/Request';
import actions from 'reducers/Editor/actions';
import LanguageData from 'components/editor-data/LanguageData';
import SnackbarUtils from 'components/ui-base/SnackbarUtils';
import Translation from 'components/data/Translation';
import '../styles/main.scss';

/**
 * Language Selector
 * Show a language bar, with the different languages in a dropdown, and allows you to import and export.
 * This component is used throughout the application to manage these kind of things.
 */
class LanguageSelector extends React.Component {
    constructor(props) {
        super(props);

        // Get the current active languages from the settings, or from props
        let languagesSelected;
        if (props.languages) {
            languagesSelected = props.languages;
        } else {
            languagesSelected = EditorData.getValueFromModel('settings.languages');
        }
        if (!languagesSelected) {
            languagesSelected = {};
        }

        this.state = {
            languagesSelected: languagesSelected,
            anchorEl: null
        };

        //Change the language to the first one if the current language if the current language does not exist in the setup
        if (!languagesSelected[props.language] && languagesSelected.length) {
            const defaultLanguage = Setup.get('defaultLanguage');
            EditorData.setLanguage(defaultLanguage || Object.keys(languagesSelected)[0]);
        }
        this.dropzoneRef = false;
    }

    static mapStateToProps(state) {
        return {
            language: state.editor.language,
            itemsToTranslate: state.editor.itemsToTranslate
        };
    }

    /**
     * Change the language
     * Send the new language to redux to push to all components
     */
    onChangeLanguage = (event) => {
        this.props.changeLanguage(event.target.value);
    };

    /**
     * Export all translations to the Excel file
     */
    exportTranslationsToExcel = () => {
        LanguageData.exportTranslationsToExcel(this.props.language, true);
        this.handleClose();
    };

    /**
     * Export translations to JSON
     */
    exportTranslations = () => {
        const found = LanguageData.exportTranslations(this.props.language, false);
        if (!found) {
            SnackbarUtils.error('No copy was found for translation.');
        }
        this.handleClose();
    };

    /**
     * Export selected translations to JSON
     */
    exportTranslationsSelected = () => {
        const found = LanguageData.exportTranslations(this.props.language, true);
        if (!found) {
            SnackbarUtils.error('No elements were selected for translation.');
        }

        this.handleClose();
    };

    /**
     * Auto translate the selected options via Google Translate
     */
    autoTranslate = (selected) => {
        const translations = LanguageData.translations(this.props.language, selected);

        if (Object.keys(translations).length == 0) {
            SnackbarUtils.info('No translations are selected. Please use the checkbox behind the fields to translate. ');
            return;
        }

        Request.post('translations/autoTranslate', { translate: translations, toLanguage: this.props.language, fromLanguage: 'en' }).then((data) => {
            SnackbarUtils.success('We are importing your translations');

            if (data.success) {
                LanguageData.importTranslations(this.props.language, data.data);
                SnackbarUtils.success('The translations are imported');
            } else {
                SnackbarUtils.error('The translation import failed');
            }
        });
        this.handleClose();
    };

    /**
     * Import a file, show the dialog using dropzone
     */
    openImportFileDialog = () => {
        if (this.dropzoneRef) {
            this.dropzoneRef.open();
        }
    };

    /**
     * Drop the imported file
     * Accepts the file and read the JSON data to import.
     */
    onImportFileDrop = (acceptedFiles) => {
        const language = this.props.language;

        const reader = new FileReader();

        // File reader results
        reader.onabort = () => console.log('file reading was aborted');
        reader.onerror = () => console.log('file reading has failed');
        reader.onload = () => {
            // Do whatever you want with the file contents
            const binaryStr = reader.result;
            let outputData = {};

            try {
                outputData = JSON.parse(binaryStr);
            } catch (e) {
                SnackbarUtils.error('There was an error reading the file');
                return;
            }
            LanguageData.importTranslations(language, outputData);

            SnackbarUtils.success('The language file is imported');
        };

        // Read the actual files
        acceptedFiles.forEach((file) => reader.readAsText(file, 'UTF-8'));
    };

    /**
     * Open the menu
     */
    openMenu = (event) => {
        this.setState({ anchorEl: event.currentTarget });
    };

    /**
     * Close the menu
     */
    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    render() {
        const { language, style = {}, extended, languages, itemsToTranslate, hideLanguageSelectorLabel } = this.props;
        const { languagesSelected, anchorEl } = this.state;
        const displayLanguages = languages ? languages : languagesSelected;

        return (
            <div className="language-selector" style={style}>
                {displayLanguages && (
                    <React.Fragment>
                        {!hideLanguageSelectorLabel && <div className="language-selector__field-title">{Translation.get('misc.language', 'common')}</div>}
                        <div className="language-selector__select-container">
                            <FormControl variant="outlined">
                                <NativeSelect
                                    style={{ fontSize: '0.8em' }}
                                    disableUnderline
                                    value={language}
                                    onChange={this.onChangeLanguage}
                                    inputProps={{ id: 'outlined-language', 'aria-label': 'Language' }}>
                                    {Object.keys(displayLanguages).map((code, i) => (
                                        <option value={code} key={i}>
                                            {languagesSelected[code]}
                                        </option>
                                    ))}
                                </NativeSelect>
                            </FormControl>
                        </div>
                    </React.Fragment>
                )}

                {!!itemsToTranslate && itemsToTranslate > 0 && (
                    <div className="language-selector__count" onClick={this.openMenu}>
                        {itemsToTranslate}
                    </div>
                )}

                {extended && (
                    <div className="language-selector__export">
                        <Dropzone
                            ref={(node) => {
                                this.dropzoneRef = node;
                            }}
                            noClick
                            noKeyboard
                            onDrop={this.onImportFileDrop}>
                            {({ getRootProps, getInputProps }) => {
                                return (
                                    <React.Fragment>
                                        <div {...getRootProps({ className: 'dropzone' })}>
                                            <input {...getInputProps()} />
                                        </div>
                                    </React.Fragment>
                                );
                            }}
                        </Dropzone>

                        <IconButton onClick={this.openMenu} size="small">
                            <Icon fontSize="inherit">more_vert</Icon>
                        </IconButton>

                        <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={this.handleClose}>
                            <MenuItem onClick={this.exportTranslations}>{Translation.get('actions.exportAllTranslations', 'common')}</MenuItem>
                            <MenuItem onClick={this.exportTranslationsSelected}>{Translation.get('actions.exportSelected', 'common')}</MenuItem>
                            <MenuItem onClick={() => this.autoTranslate(true)}>{Translation.get('actions.autotranslateSelected', 'common')}</MenuItem>
                            <MenuItem onClick={() => this.autoTranslate(false)}>{Translation.get('actions.autotranslateAll', 'common')}</MenuItem>
                            <MenuItem onClick={this.exportTranslationsToExcel}>{Translation.get('actions.exportTranslationsToExcel', 'common')}</MenuItem>
                            <MenuItem onClick={this.openImportFileDialog}>{Translation.get('actions.import', 'common')}</MenuItem>
                        </Menu>
                    </div>
                )}
            </div>
        );
    }
}

export default connect(LanguageSelector.mapStateToProps, actions)(LanguageSelector);
