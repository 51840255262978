import React, { useContext } from 'react';
import classNames from 'classnames';
import { IconButton } from '@mui/material';
import Icon from 'components/ui-components-v2/Icon';
import Dark, { DarkContext } from 'components/ui-components/Dark';
import '../styles/toolbar.scss';

interface Props {
    title: string;
    description?: string;
    leftButtons?: React.ReactNode | React.ReactNode[];
    centerButtons?: React.ReactNode | React.ReactNode[];
    rightButtons?: React.ReactNode | React.ReactNode[];
    tourData?: string;
    onClose?: () => void;
}

const FullScreenOverlayToolbar = ({ title, description, leftButtons, centerButtons = null, rightButtons = null, tourData, onClose }: Props) => {
    const dark = useContext(DarkContext);

    return (
        <Dark dark={dark}>
            <div
                data-mui-color-scheme={dark ? 'dark' : 'light'}
                // eslint-disable-next-line react/no-unknown-property
                tour-data={tourData}
                className={classNames('full-screen-overlay-toolbar', { 'full-screen-overlay-toolbar--dark': dark })}>
                <div className="full-screen-overlay-toolbar__left">
                    <IconButton
                        onClick={onClose}
                        size="small"
                        className={classNames('full-screen-overlay-toolbar__close', {
                            'full-screen-overlay-toolbar__close--dark': dark
                        })}
                        data-tour-selector="fso-back">
                        <Icon>arrow_back</Icon>
                    </IconButton>
                    <div>
                        <div className="full-screen-overlay-toolbar__title">{title}</div>
                        {description && description.length && <div className="full-screen-overlay-toolbar__desc">{description}</div>}
                    </div>
                    {leftButtons && <div>{leftButtons}</div>}
                </div>
                {centerButtons && (
                    <div className="full-screen-overlay-toolbar__center" data-tour-selector="fso-center-buttons">
                        {centerButtons}
                    </div>
                )}
                <div className="full-screen-overlay-toolbar__right">{rightButtons}</div>
            </div>
        </Dark>
    );
};

export default FullScreenOverlayToolbar;
