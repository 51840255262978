import React from 'react';
import classNames from 'classnames';
import Typography from 'components/ui-components-v2/Typography';
import Button from 'components/ui-components-v2/Button';
import './styles/main.scss';

interface EmptyStateProps {
    className?: string;
    illustration?: React.ReactElement;
    primaryText?: string;
    secondaryText?: string;
    primaryButton?: {
        label: string;
        dataCy?: string;
        className?: string;
        onClick: () => void;
    };
    secondaryButton?: {
        label: string;
        onClick: () => void;
    };
    size?: 'small' | 'medium' | 'large';
    dataCyPrefix?: string;
}

/**
 * Renders a Empty state (placeholder) component. An Empty State is used when there is no content or data to show, e.g. when the comment section is empty.
 *
 * - [EmptyState documentation](https://bycape.atlassian.net/wiki/spaces/DD/pages/468680715/EmptyState)
 */
const EmptyState = ({
    className,
    primaryButton,
    secondaryButton,
    primaryText,
    secondaryText,
    illustration,
    size = 'medium',
    dataCyPrefix
}: EmptyStateProps) => {
    return (
        <div data-cy={`${dataCyPrefix}-div`} className={classNames('ui-v2-empty-state', { [`${className}`]: className })}>
            {illustration}
            {primaryText && (
                <Typography className="ui-v2-empty-state__primary-text" variant={size === 'small' ? 'h4' : 'h2'}>
                    {primaryText}
                </Typography>
            )}
            {secondaryText && (
                <Typography
                    component="div"
                    className={classNames('ui-v2-empty-state__secondary-text', {
                        'ui-v2-empty-state__secondary-text--small': size === 'small'
                    })}
                    variant={size === 'small' ? 'caption' : 'body1'}
                    color="text.secondary">
                    {secondaryText}
                </Typography>
            )}
            {primaryButton && (
                <Button
                    size={size === 'large' ? 'medium' : size}
                    variant="contained"
                    className={primaryButton.className || undefined}
                    onClick={primaryButton.onClick}
                    data-cy={primaryButton.dataCy}>
                    {primaryButton.label}
                </Button>
            )}
            {secondaryButton && (
                <Button
                    size={size === 'large' ? 'medium' : size}
                    className="ui-v2-empty-state__secondary-btn"
                    onClick={secondaryButton.onClick}
                    variant="outlined">
                    {secondaryButton.label}
                </Button>
            )}
        </div>
    );
};

export default EmptyState;
