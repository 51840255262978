import Icon from 'components/ui-components-v2/Icon';
import PropTypes from 'prop-types';
import React from 'react';
import DynamicAsset from 'components/assets/DynamicAsset';
import TemplateHelpers from 'components/data/TemplateHelpers';

class FacebookCarouselAd extends React.Component {
    static propTypes = {
        frames: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.arrayOf(
                PropTypes.shape({
                    message: PropTypes.string,
                    name: PropTypes.string,
                    description: PropTypes.string,
                    link: PropTypes.string,
                    image: PropTypes.string,
                    cta: PropTypes.string
                })
            )
        ])
    };

    static defaultProps = {};

    constructor(props) {
        super(props);

        this.state = {
            scrollLeft: 0
        };
        this.facebookCarouselRef = React.createRef();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.frameNr !== this.props.frameNr) {
            if (this.props.frameNr > 0) {
                this.swipeOnFrameNr(this.props.frameNr);
            }
        }
    }

    componentDidMount() {
        if (this.facebookCarouselRef.current) {
            this.swipeOnFrameNr(this.props.frameNr);
        }
    }

    swipe = (direction) => {
        const { scrollLeft } = this.state;

        const element = this.facebookCarouselRef.current;
        const distance = 240;
        let newScrollLeft = isNaN(scrollLeft) ? 0 : scrollLeft;

        if (direction === 'right') {
            newScrollLeft += distance;
        } else {
            if (scrollLeft % distance === 0) {
                newScrollLeft -= distance;
            } else {
                newScrollLeft -= scrollLeft % distance;
            }
        }

        // Scroll the element to the new position
        element.scrollLeft = newScrollLeft;

        // setTimeout because of the animation time (smooth scroll)
        setTimeout(() => {
            this.setState({ scrollLeft: newScrollLeft });
        }, 300);
    };

    swipeOnFrameNr = (frameNr) => {
        const element = this.facebookCarouselRef.current;
        const distance = 240;

        this.setState({ scrollLeft: (frameNr - 1) * distance }, () => (element.scrollLeft = (frameNr - 1) * distance));
    };

    getAsset = (index, asset, image, video) => {
        const { assetComponents } = this.props;

        // Existing prop for assets
        if (assetComponents) {
            // Dynamic asset
            if (assetComponents[index] && assetComponents[index].length > 1) {
                for (let i = 0; i < assetComponents[index].length; i++) {
                    const formatResult = TemplateHelpers.getFormatDimension(
                        assetComponents[index][i].props.data,
                        assetComponents[index][i].props.originalWidth,
                        assetComponents[index][i].props.originalHeight
                    );
                    if (formatResult === '4x5' || formatResult === '1x1') {
                        return assetComponents[index][i];
                    }
                }

                return assetComponents[index] && assetComponents[index][0] ? assetComponents[index][0] : assetComponents[0];
            }
            // Single format
            else if (assetComponents[index] && assetComponents[index][0]) {
                return assetComponents[index][0];
            }
            // Bricks gives the direct asset
            else if (assetComponents[index]) {
                return assetComponents[index];
            }

            return <React.Fragment />;
        }
        // Create in component
        else {
            return (
                <DynamicAsset
                    {...asset}
                    image={image}
                    video={video}
                    frameWidth={asset && asset.frameWidth ? asset.frameWidth : 1200}
                    frameHeight={asset && asset.frameHeight ? asset.frameHeight : 1200}
                    width={230}
                />
            );
        }
    };

    checkMultiLanguageVideo = (framesRestructured, language) => {
        // If there are different video's on multiple languages, only return the correct video in the specific language
        framesRestructured = framesRestructured.map((item) => {
            if (item.video && item.video[language]) {
                return { ...item, video: item.video[language] };
            } else if (item.video && item.video.url === undefined) {
                // If the video is dependent on a language, but there is no video available in that language, return video:null
                // item.video.url will be undefined, because the url will be inside of the language object (item.video.EN.url)
                return { ...item, video: null };
            } else {
                return { ...item };
            }
        });

        // If the hasVideo property is true, always show the video. However, if the hasVideo property exists, but is false, remove the video.
        framesRestructured = framesRestructured.map((item) => {
            if (item.hasVideo) {
                return { ...item, asset: null, image: null };
            } else if (typeof item.hasVideo !== 'undefined' && !item.hasVideo) {
                return { ...item, video: null };
            } else {
                return { ...item };
            }
        });

        return framesRestructured;
    };

    render() {
        const { facebook_cta, cta, frames = [], adType, items, language, name, description } = this.props;
        let { frameCount = 0 } = this.props;
        let { scrollLeft } = this.state;
        let framesRestructured = [];

        // Framecount based items
        if (frameCount > 0) {
            for (let i = 1; i <= frameCount; i++) {
                framesRestructured.push(frames['frame' + i] ? frames['frame' + i] : {});
            }
        }
        // Support object based (
        else if (items && items.frame1) {
            framesRestructured = Object.values(items);
        }
        // Support array based (items)
        else if (Array.isArray(items)) {
            framesRestructured = items;
        }
        // Support object based
        else if (frames && frames.frame1) {
            framesRestructured = Object.values(frames);
        }
        // Support array based
        else if (Array.isArray(frames)) {
            framesRestructured = frames;
        }

        framesRestructured = this.checkMultiLanguageVideo(framesRestructured, language);

        // Also accept the old structure
        framesRestructured = framesRestructured.map((item) => {
            return {
                ...item,
                description: item.description || item.linkDescription || description,
                link: item.link || item.websiteUrl || item.displayUrl,
                name: item.name || item.headline || name
            };
        });

        if (frameCount === 0) {
            frameCount = framesRestructured.length;
        }

        if (isNaN(scrollLeft)) {
            scrollLeft = 0;
        }

        return (
            <div className="carousel-ad">
                <div className="carousel-ad__carousel" ref={this.facebookCarouselRef}>
                    {framesRestructured.map(({ name, description, link, asset, image, video }, index) => (
                        <div key={index} className="carousel-ad__carousel__item">
                            <div className="carousel-ad__carousel__item__post">
                                <div className="carousel-ad__carousel__item__asset-wrapper">
                                    <div className="carousel-ad__carousel__item__asset-center">{this.getAsset(index, asset, image, video)}</div>{' '}
                                </div>

                                <div className="carousel-ad__carousel__item__info">
                                    <div className="carousel-ad__carousel__item__text-wrapper">
                                        <div className="carousel-ad__carousel__item__name">{name}</div>
                                        <div className="carousel-ad__carousel__item__description">{description}</div>
                                    </div>

                                    {cta && cta !== '' && cta !== 'NO_BUTTON' && facebook_cta[cta] && adType !== 'carouselPost' && (
                                        <div className="carousel-ad__carousel__item__cta-wrapper">
                                            <a className="carousel-ad__carousel__item__link" target="_blank" href={link}>
                                                <div className="carousel-ad__carousel__item__cta">{facebook_cta[cta]}</div>
                                            </a>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                {scrollLeft > 0 && (
                    <div className="carousel-ad__arrow-left" onClick={() => this.swipe('left')}>
                        <Icon fontSize="large" className="carousel-ad__arrow-left__arrow-icon">
                            chevron_left
                        </Icon>
                    </div>
                )}

                {scrollLeft % 240 === 0 && framesRestructured.length > 1 && scrollLeft / 240 < frameCount - 1 && (
                    <div className="carousel-ad__arrow-right" onClick={() => this.swipe('right')}>
                        <Icon fontSize="large" className="carousel-ad__arrow-left__arrow-icon">
                            chevron_right
                        </Icon>
                    </div>
                )}
            </div>
        );
    }
}

export default FacebookCarouselAd;
