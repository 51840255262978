import React from 'react';
import Chip, { ChipProps } from 'components/ui-components-v2/Chip';
import campaignStatus from 'helpers/campaignStatus';

interface StatusChipProps {
    status: string;
    chipProps?: Pick<ChipProps, 'size' | 'onClick'>;
}

const error = [campaignStatus.removed, campaignStatus.declined, campaignStatus.inactive, campaignStatus.request];
const warning = [campaignStatus.offline, campaignStatus.unpublished, campaignStatus.inreview];
const info = [campaignStatus.upcoming, campaignStatus.pending, campaignStatus.create, campaignStatus.draft, 'New'];
const success = [
    campaignStatus.online,
    campaignStatus.live,
    campaignStatus.published,
    campaignStatus.submitted,
    campaignStatus.scheduled,
    campaignStatus.active,
    campaignStatus.inputReady
];
//All possible statuses for campaigns including the right color for the Chip
const allStatuses = [
    { color: 'error', statuses: error },
    { color: 'warning', statuses: warning },
    { color: 'info', statuses: info },
    { color: 'success', statuses: success }
];

//TODO: check the input that can switch statuses in the campaign settings,
// there is a 'selected' prop in: frontend\src\components\editor-blocks\CampaignStatus\components\index.js
// This component seems to be deprecated need to check if is still in use for a customer

/**
 * Renders an Campaign Status Chip component.
 *
 * @param status - The status of the campaign
 * @param chipProps - Object to configure the size or onCLick of the Chip component
 */
const StatusChip: React.FC<StatusChipProps> = ({ status, chipProps }) => {
    //Checks if the status is part of the allStatuses Object and picks the right Chip color
    const getColor = (status) => {
        let color = 'default';
        allStatuses.forEach((statusObject) => {
            if (statusObject.statuses.includes(campaignStatus[status])) {
                color = statusObject.color;
            }
        });
        return color as ChipProps['color'];
    };
    return <Chip color={getColor(status)} variant="outlined" label={campaignStatus[status]} {...chipProps} />;
};

export default StatusChip;
