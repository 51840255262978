import React from 'react';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import EditorData from 'components/editor-data/EditorData';
import cloneDeep from 'helpers/cloneDeep';

const AVAILABLE_TYPES = ['iframe', 'dynamicImage', 'dynamicImageDesigned', 'dynamicVideoDesigned', 'dynamicPDFDesigned', 'dynamicPDF'];

/**
 * iFrame
 * A dynamic iframe field
 */
class Iframe extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            uuid: uuidv4(),
            loaded: false
        };
    }

    static defaultProps = {
        width: '100%',
        height: 'auto',
        frameWidth: 0,
        frameHeight: 0,
        type: 'iframe',
        className: 'dynamic-asset',
        style: {}
    };

    static propTypes = {
        url: PropTypes.string.isRequired,
        type: PropTypes.string,
        /** Set visual width */
        width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        /** Set visual height */
        height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        /** Set iframe internal width */
        frameWidth: PropTypes.number,
        /** Set iframe internal height */
        frameHeight: PropTypes.number,
        /** Data to postMessage to iframe */
        data: PropTypes.object,
        style: PropTypes.object,
        className: PropTypes.string,
        /* Model to update in case the asset contains draggable */
        assetUpdateModel: PropTypes.string
    };

    /**
     * Send frame data
     */
    componentDidMount() {
        if (AVAILABLE_TYPES.includes(this.props.type)) {
            // Start listening for iFrame events
            window.addEventListener('message', this.handleFrameMessage);

            const _this = this;
            // Send data to iframe for first init
            this.ifr.onload = () => {
                window.setTimeout(() => {
                    _this.sendFrameMessages(this.props.data);
                }, 1);
            };
        }
    }

    // We can send certain actions to the iframe, e.g. play, pause, seek, etc.
    handleIframeAction = (message) => {
        if (message?.action) {
            this.sendFrameMessages({ action: message.action });
        }
    };

    /**
     * Remove listener on unmount
     */
    componentWillUnmount() {
        if (AVAILABLE_TYPES.includes(this.props.type)) {
            window.removeEventListener('message', this.handleFrameMessage);
        }
    }

    /**
     * On update send frame message
     */
    componentDidUpdate(prevProps) {
        const { data } = this.props;

        // If the template changed, we need to set loaded to false, so the template can be sent to the iframe.ed;
        if (!isEqual(prevProps?.data?.template, data?.template)) {
            this.setState({ loaded: false }, () => {
                this.sendFrameMessages(data);
            });
        } else {
            this.sendFrameMessages(data);
        }
    }

    /**
     * Send messages to frame
     */
    sendFrameMessages(data) {
        // We need to clone the data as the template gets deleted when it is loaded. So then the equal check is always different.
        const newData = cloneDeep(data);

        let url = this.props.url;
        if (window.location.hostname === 'localhost') {
            /**
             * We're doing this on a specific host, because the template designer host, 'templates.bycape.io' has a 301 redirect to https.
             * So for that host the url should remain unchanged.
             */
            url = url.replace('https://templates.campaigndesigner.io', 'http://templates.campaigndesigner.io');
        }

        // When there is no scale in data, add it. Scale is being used to drag layers in Template Designer templates.
        if (!newData.scale && !newData.action) newData.scale = this.props.width / this.props.frameWidth;

        if (this.ifr) {
            if (this.state.loaded) {
                delete newData.template;
                newData.renderTemplate = false;
            } else {
                newData.renderTemplate = true;
            }
            this.ifr.contentWindow.postMessage({ origin: window.location.origin, uuid: this.state.uuid, data: newData }, url);
        }
    }

    /**
     *  Handle messages coming from the iframe, e.g. position updates
     **/
    handleFrameMessage = (e) => {
        // Data is not from frame
        if (e.origin === window.location.origin) {
            return false;
        }

        // No data provided or no callback provided
        if (!e.data) {
            return false;
        }

        const data = e.data;
        if (data.uuid === this.state.uuid) {
            // TD template repo send a status update when its fully loaded when this happens we set it loaded true
            if (e.data.type === 'status') {
                //Only update state if the loaded state is different
                if (e.data.loaded !== this.state.loaded) return this.setState({ loaded: e.data.loaded });
                return;
            }

            this.onAssetUpdate(data.model, data.value);
        }
    };

    /**
     * Save to store
     **/
    onAssetUpdate = (model, value) => {
        if (this.props.assetUpdateModel) {
            EditorData.setModel(this.props.assetUpdateModel + '.' + model, value);
        }
    };

    render() {
        const { width, url, className, frameWidth, frameHeight, bottomSpacing = 0 } = this.props;
        const style = this.props.style;

        let parentStyle = {};
        let frameStyle = { ...style };
        let scaledBottomSpacing = 0;

        // Frame width is send
        if (frameWidth) {
            let left, top;
            const factor = width / frameWidth;

            parentStyle = {
                width: frameWidth * factor,
                height: frameHeight * factor - 1,
                position: 'relative',
                margin: 0,
                fontSize: 0,
                lineHeight: 0
            };

            // Items with type 'displayAdDesigned' or 'dynamicVideoDesigned' items have a playbar at the bottom, wihtin the iframe.
            // Therefore, we have to add some extra space on the bottom of the frame as well (dependent on the scale).
            scaledBottomSpacing = bottomSpacing / factor;
            const updatedFrameHeight = frameHeight + scaledBottomSpacing;

            // Position item
            if (factor < 1) {
                left = '-' + (frameWidth - frameWidth * factor) / 2;
                top = '-' + (updatedFrameHeight - updatedFrameHeight * factor) / 2;
            } else {
                left = Math.abs((frameWidth - frameWidth * factor) / 2);
                top = Math.abs((updatedFrameHeight - updatedFrameHeight * factor) / 2);
            }

            frameStyle = {
                ...frameStyle,
                position: 'relative',
                transform: `scale(${factor})`,
                left: `${left}px`,
                top: `${top}px`
            };
        }

        // Create url
        let urlNew = '';
        if (this.props.urlNavigation) {
            urlNew =
                url +
                '#' +
                encodeURIComponent(
                    JSON.stringify({
                        url: window.location.origin,
                        origin: window.location.origin,
                        uuid: this.state.uuid,
                        ...this.props.data
                    })
                );
        } else {
            urlNew = url;
            urlNew = urlNew.replace('http:', '').replace('https:', '');
        }

        return (
            <div style={parentStyle}>
                <iframe
                    className={`${className} iframe`}
                    scrolling="no"
                    frameBorder="0"
                    sandbox="allow-same-origin allow-scripts"
                    ref={(f) => (this.ifr = f)}
                    width={frameWidth}
                    height={frameHeight + scaledBottomSpacing}
                    src={urlNew}
                    style={frameStyle}
                    title={'title'}
                />
            </div>
        );
    }
}

export default Iframe;
