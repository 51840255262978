import React, { CSSProperties, useMemo, useRef } from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import classNames from 'classnames';
import IconButton from 'components/ui-components-v2/IconButton';
import TextField from 'components/ui-components-v2/TextField';
import Tooltip from 'components/ui-components-v2/Tooltip';
import Button from 'components/ui-components-v2/Button';
import Icon from 'components/ui-components-v2/Icon';
import { Preset } from '../../../../../types/preset';
import { getAssetTypeName, getFileExtensionName } from '../utils/table-utils';

import '../styles/presets-table-row.scss';

interface Props {
    preset: Preset;
    onChange: (preset: Preset) => void;
    modifyQuantityOfPreset: (preset: Preset, quantity: number) => void;
    selectedPresets: Preset[];
    focusedPreset: Preset | undefined;
    selected: boolean;
    multiple: boolean;
    handleMoreInfoButtonClick: (event, preset: Preset) => void;
    handleDeletePreset: (preset: Preset) => void;
    handleEditPreset: (event, preset: Preset) => void;
}

const PRESET_OVERVIEW_MAX_PX = 24;
const MAX_PRESETS_QUANTITY = 100; // Maximum quantity of a preset, because of performance reasons.

/**
 * Table row for the presets table
 */
const PresetsTableRow = ({
    preset,
    onChange,
    selected,
    selectedPresets,
    modifyQuantityOfPreset,
    focusedPreset,
    multiple,
    handleMoreInfoButtonClick,
    handleDeletePreset,
    handleEditPreset
}: Props) => {
    // Quantity of the preset which is selected
    const quantity = useMemo(() => {
        const selectedPresetsCopy = [...selectedPresets];
        const selectedCurrentPreset = selectedPresetsCopy.filter((selectedPreset: Preset) => selectedPreset.identifier === preset.identifier);

        return selectedCurrentPreset.length;
    }, [selectedPresets]);

    // Title and description ref
    const titleRef = useRef<HTMLParagraphElement>(null);
    const descriptionRef = useRef<HTMLParagraphElement>(null);

    // Is title truncated so we can show tooltip
    const isTitleTruncated = useMemo(() => {
        const element = titleRef.current;

        return element ? element.offsetWidth < element.scrollWidth || element.offsetHeight < element.scrollHeight : false;
    }, [titleRef.current]);

    // Is description truncated so we can show tooltip
    const isDescriptionTruncated = useMemo(() => {
        const element = descriptionRef.current;

        return element ? element.offsetWidth < element.scrollWidth || element.offsetHeight < element.scrollHeight : false;
    }, [descriptionRef.current]);

    const getSizeRestrictions = () => {
        const restrictions = preset.restrictions;

        // Return recommendation restrictions if there are no required size restrictions.
        if (
            !restrictions ||
            (!restrictions.minWidth &&
                !restrictions.minHeight &&
                !restrictions.maxWidth &&
                !restrictions.maxHeight &&
                !restrictions.width &&
                !restrictions.height)
        ) {
            return preset.recommendations;
        }

        return restrictions;
    };

    // Get the display name of the recommended size of the asset
    const getSizeName = () => {
        const restrictions = getSizeRestrictions();

        if (restrictions?.minWidth && restrictions?.minHeight && restrictions?.maxWidth && restrictions?.maxHeight) {
            return `${restrictions?.minWidth}x${restrictions?.minHeight} - ${restrictions?.maxWidth}x${restrictions?.maxHeight}`;
        }

        if (restrictions?.minWidth && restrictions?.minHeight) {
            return `Min size: ${restrictions.minWidth}x${restrictions.minHeight}`;
        } else if (restrictions?.minWidth) {
            return `Min width: ${restrictions.minWidth}`;
        } else if (restrictions?.minHeight) {
            return `Min height: ${restrictions.minHeight}`;
        }

        if (restrictions?.maxWidth && restrictions?.maxHeight) {
            return `Max size: ${restrictions.maxWidth}x${restrictions.maxHeight}`;
        } else if (restrictions?.maxWidth) {
            return `Max width: ${restrictions.maxWidth}`;
        } else if (restrictions?.maxHeight) {
            return `Max height: ${restrictions.maxHeight}`;
        }

        if (restrictions?.width && restrictions?.height) {
            return `${restrictions.width}x${restrictions.height}`;
        } else if (restrictions?.width) {
            return `Width: ${restrictions.width}`;
        } else if (restrictions?.height) {
            return `Height: ${restrictions.height}`;
        }

        return '';
    };

    // Gets preset dimensions for the overview column
    const getStyleContainer = (): CSSProperties => {
        /**
         * Calculate the thumbnail size based on the width and height.
         */
        const previewSize: CSSProperties = {
            width: PRESET_OVERVIEW_MAX_PX,
            height: PRESET_OVERVIEW_MAX_PX
        };

        const width = preset.restrictions?.width || preset.restrictions?.minWidth;
        const height = preset.restrictions?.height || preset.restrictions?.minHeight;
        if (!width || !height) return previewSize;
        if (width > height) {
            previewSize.height = (height / width) * PRESET_OVERVIEW_MAX_PX;
        } else {
            previewSize.width = (width / height) * PRESET_OVERVIEW_MAX_PX;
        }

        return previewSize;
    };

    const handlePresetQuantityChange = (value: string) => {
        let presetQuantity = Number(value);

        if (isNaN(presetQuantity)) return;

        if (presetQuantity > MAX_PRESETS_QUANTITY) presetQuantity = MAX_PRESETS_QUANTITY;
        if (presetQuantity < 0) presetQuantity = 0;

        modifyQuantityOfPreset(preset, presetQuantity);
    };

    return (
        <TableRow
            key={preset.title}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            className={classNames('presets-table-row', {
                'presets-table-row--single-preset': !multiple
            })}
            onClick={() => onChange(preset)}>
            {multiple && (
                <TableCell padding="none" className="presets-table-row__quantity-cell">
                    <div
                        className={classNames('presets-table-row__quantity-cell__content', {
                            'presets-table-row__quantity-cell__content--focused': focusedPreset && focusedPreset.identifier === preset.identifier && quantity
                        })}>
                        {((focusedPreset && focusedPreset.identifier === preset.identifier) || !quantity) && (
                            <IconButton
                                className="presets-table-row__quantity-cell__content__add-icon"
                                onClick={() => modifyQuantityOfPreset(preset, quantity + 1)}>
                                <Icon>add_circle</Icon>
                            </IconButton>
                        )}
                        {!!quantity && (
                            <TextField
                                variant="outlined"
                                className="presets-table-row__quantity-cell__content__input"
                                value={quantity}
                                onChange={(event) => handlePresetQuantityChange(event.target.value)}
                                slotProps={{
                                    htmlInput: {
                                        min: 0,
                                        style: { textAlign: 'center' }
                                    }
                                }}
                            />
                        )}
                        {!!quantity && focusedPreset && focusedPreset.identifier === preset.identifier && (
                            <IconButton
                                className="presets-table-row__quantity-cell__content__minus-icon"
                                onClick={() => modifyQuantityOfPreset(preset, quantity - 1)}>
                                <Icon>remove_circle</Icon>
                            </IconButton>
                        )}
                    </div>
                </TableCell>
            )}
            <TableCell align="left" component="th" scope="row">
                <div className="presets-table-row__overview-cell">
                    <span className="presets-table-row__overview-cell__placement" style={getStyleContainer()} />
                </div>
            </TableCell>
            <TableCell align="left" component="th" scope="row">
                <div className="presets-table-row__title-cell">
                    <span className="presets-table-row__title-cell__title" ref={titleRef}>
                        <Tooltip disableHoverListener={!isTitleTruncated} title={preset.title} placement="top-start">
                            <span>{preset.title}</span>
                        </Tooltip>
                    </span>

                    <span className="presets-table-row__title-cell__description" ref={descriptionRef}>
                        <Tooltip disableHoverListener={!isDescriptionTruncated} title={preset.description} placement="top-start">
                            <span> {preset.description}</span>
                        </Tooltip>
                    </span>
                </div>
            </TableCell>
            <TableCell align="left" component="th" scope="row" className="presets-table-row__small">
                {getAssetTypeName(preset.assetType)}
            </TableCell>
            <TableCell align="left" component="th" scope="row" className="presets-table-row__small">
                {getFileExtensionName(preset.fileExtension)}
            </TableCell>
            <TableCell align="left" component="th" scope="row" className="presets-table-row__small">
                {getSizeName()}
            </TableCell>
            <TableCell align="left" component="th" scope="row" className="presets-table-row__small">
                {preset.custom ? (
                    <div className="presets-table-row__icon-container">
                        <IconButton className="presets-table-row__edit-button" onClick={(event) => handleEditPreset(event, preset)} aria-label="edit">
                            <Icon>edit</Icon>
                        </IconButton>

                        <IconButton
                            className="presets-table-row__icon-container__delete"
                            onClick={(e) => {
                                e.stopPropagation();
                                handleDeletePreset(preset);
                            }}
                            aria-label="delete">
                            <Icon>delete</Icon>
                        </IconButton>
                    </div>
                ) : (
                    <Button
                        variant="text"
                        size="small"
                        className="presets-table-row__more-info-button"
                        onClick={(event) => handleMoreInfoButtonClick(event, preset)}>
                        More
                    </Button>
                )}
            </TableCell>
        </TableRow>
    );
};

export { PresetsTableRow };
