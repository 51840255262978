import * as Sentry from '@sentry/react';
import Request from 'components/data/Request';
import User from 'components/data/User';
import { UserProfile } from '../types/user-profile.type';

class UserProfileService {
    /**
     * get a list of profiles from the core api. It returns a list of user profiles that is linked to the current customer environment
     * @returns a list of profiles
     */
    public static async getUserProfiles(): Promise<UserProfile[]> {
        if (!User.get('id')) {
            return [];
        }
        try {
            const response = await Request.post('profiles/userlist');
            return response.data;
        } catch (error) {
            Sentry.captureException(error);
            return [];
        }
    }
}

export { UserProfileService };
