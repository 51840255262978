import { CreativeV2Template } from 'components/creatives-v2/components/creative-editor/types/creativeV2.type';
import { TemplateManager } from 'components/creatives-v2/data/template-manager';
import { CustomTemplateAsset } from 'components/template-management/types/template-management.type';
import { TemplateTypeBase } from './base-template';

class TemplateDisplayAd extends TemplateTypeBase {
    constructor(creative: CreativeV2Template) {
        super(creative);
    }

    getCreativeModel(activeFrame: string): string {
        const template = TemplateManager.getTemplateByIdentifier(this.creative.data.templateIdentifier) as CustomTemplateAsset;
        if (!template?.data?.settings?.dynamicFrames) return `frames.frame`;

        return `frames.${activeFrame}`;
    }

    showPlayBar(): boolean {
        const template = TemplateManager.getTemplateByIdentifier(this.creative.data.templateIdentifier) as CustomTemplateAsset;
        if (!template) return true;
        if (template.data.settings?.dynamicFrames === undefined || template.data.settings.dynamicFrames === null) return true;

        return template.data.settings.dynamicFrames;
    }

    isMultiFrame(): boolean {
        const template = TemplateManager.getTemplateByIdentifier(this.creative.data.templateIdentifier) as CustomTemplateAsset;
        return !!template?.data.settings?.dynamicFrames;
    }

    hasOverlay(): boolean {
        return true;
    }
}

export { TemplateDisplayAd };
