import React, { useEffect } from 'react';
import { Brick } from 'components/bricks/types/brick.type';
import SocialPreview from 'components/creatives-v2/components/social-previews-v2';
import { Placement } from 'components/bricks/types/placement.type';
import { CreativeV2 } from 'components/creatives-v2/components/creative-editor/types/creativeV2.type';
import { CreativeV2Helpers } from 'components/creatives-v2/helpers/creatives-v2.helpers';
import CircularProgress from 'components/ui-components-v2/CircularProgress';
import CreativePreviewHelper from '../helpers/creatives-preview.helpers';

import '../styles/content.scss';
// TODO Check these imports
// import useValidations from 'components/bricks/hooks/useValidations';
// import { useAdSetupContext } from '../../ad-setup/context/ad-setup-context';
// import PreviewWarning from './preview-warning';

interface Props {
    brick: Brick;
    activePlacement: Placement | null;
    activeTabKey?: string;
}

const BricksCreativesPreviewContent: React.FC<Props> = ({ brick, activePlacement, activeTabKey }) => {
    const [loading, setLoading] = React.useState(true);
    // const { selectedFrame } = useAdSetupContext();
    // const { warnings } = useValidations([brick.id], activeTabKey, `${brick.subType}-ad_setup-placements-${activePlacement?.key}-${selectedFrame}`);

    useEffect(() => {
        const fetchCreatives = async () => {
            const promises: Promise<CreativeV2>[] = [];

            try {
                const brickCreatives = brick?.data?.creatives || [];
                for (const creative of brickCreatives) {
                    promises.push(CreativeV2Helpers.loadTemplateForCreative(creative));
                }

                await Promise.all(promises);

                setLoading(false);
            } catch (error) {
                console.error('Error fetching creatives:', error);
            }
        };

        fetchCreatives();
    }, [brick?.data?.creatives?.length, activePlacement?.key]);

    const socialPreviewData = CreativePreviewHelper.generateSocialPreviewData(brick, brick.data?.creatives, activePlacement);

    // if (warnings?.length) return <PreviewWarning />; //we might use the warnings display in the future

    if (loading)
        return (
            <div className="bricks-creatives-preview-content">
                <CircularProgress />
            </div>
        );

    return <div className="bricks-creatives-preview-content">{socialPreviewData && <SocialPreview socialPreview={socialPreviewData} />}</div>;
};

export default BricksCreativesPreviewContent;
