import { Brick } from 'components/bricks/types/brick.type';
import Translation from 'components/data/Translation';
import ComponentStore from 'components/data/ComponentStore';
import BrickHelpers from 'components/bricks/helpers/brick.helpers';
import { ConfirmationDetails, PredefinedValidationType, Workflow, WorkflowStatus, WorkflowStatusTransition } from '../workflow.type';
import ValidatorUtils from './validator.utils';
import { WorkflowManager } from '../data/workflow-manager';

/**
 * Class for brick transition validation logic.
 */
class WorkflowValidator {
    /**
     * Finds a transition in the workflow for a given source status and target status.
     * @param workflow - The workflow to search.
     * @param sourceStatus - The source status of the transition.
     * @param targetStatus - The target status of the transition.
     * @returns The matching transition object or null if not found.
     */
    private static findTransition(workflow: Workflow, sourceStatus: string, targetStatus: string): WorkflowStatusTransition | undefined {
        return workflow.transitions.find((transition) => transition.source === sourceStatus && transition.target.includes(targetStatus)) || undefined;
    }

    /**
     * Validates if all selected bricks have the same workflow.
     * Ensures consistency for multi-brick operations.
     * @param bricksToUpdate - An array of brick IDs to validate.
     * @returns {boolean} - `true` if all bricks have the same workflow; otherwise, `false`.
     */
    static consistentWorkflowsCheckedBricks(bricksToUpdate: Brick['id'][]): boolean {
        const workflows = new Set(
            bricksToUpdate.map((brickId) => {
                const brickSubType = BrickHelpers.getBrickById(brickId)?.subType;
                return brickSubType ? WorkflowManager.getWorkflowForSubtype(brickSubType) : null;
            })
        );

        // Remove null values and check if only one unique workflow exists
        const validWorkflows = Array.from(workflows).filter((workflow) => workflow !== null);
        return validWorkflows.length <= 1;
    }

    /**
     * Validates if a single brick can transition to any of the specified target statuses.
     * @param brick - The brick object to validate.
     * @param targetStatus - The target status to which the brick is being transitioned.
     * @returns {string[]} - An array of validation error messages. If empty, the brick can transition.
     */
    static validateBrickTransition(brick: Brick, targetStatus: string): string[] {
        const errors: string[] = [];

        // Fetch workflow and validate its existence
        const workflow = WorkflowManager.getWorkflowForSubtype(brick.subType);
        if (!workflow) {
            return [Translation.get('workflow.error.noWorkflowDefined', 'bricks')];
        }

        // Fetch current status and validate
        const currentStatus = workflow.statuses.find((status) => status.id === brick.metadata?.status);
        if (!brick.metadata?.status || brick.metadata?.status === targetStatus) {
            return []; // No validation needed if already at the target status
        }

        if (!currentStatus) {
            return [Translation.get('workflow.error.invalidCurrentStatus', 'bricks')];
        }

        // Check if the target status is allowed
        if (currentStatus.allowedToStatuses && !currentStatus.allowedToStatuses.includes(targetStatus)) {
            return [
                Translation.get(`workflow.error.invalidTransitionNoTargetStatus`, 'bricks', { currentStatus: currentStatus.status, targetStatus: targetStatus })
            ];
        }

        // Validate transition using predefined validations
        const transition = this.findTransition(workflow, brick.metadata?.status, targetStatus);
        if (transition) {
            const validationErrors = this.checkPredefinedValidations(transition, brick);
            errors.push(...validationErrors);
        }

        return errors;
    }

    /**
     * Validates multiple bricks for a specified transition.
     * Checks if each brick can transition to the target status.
     * @param bricksToUpdate - An array of brick IDs to validate.
     * @param targetStatus - The target status to validate against.
     * @returns {Record<string, string[]>} - A record of brick IDs mapped to validation error messages.
     */
    static validateBricksForTransition(
        bricksToUpdate: Brick['id'][],
        targetStatus: string
    ): { failedValidations: Record<string, string[]>; hasMultipleBricksSelected: boolean } {
        const failedValidations: Record<string, string[]> = {};

        for (const brickId of bricksToUpdate) {
            const brick = ComponentStore.get('Bricks')?.bricks?.[`i_${brickId}`];
            if (!brick) continue;

            const brickValidations = this.validateBrickTransition(brick, targetStatus);
            if (brickValidations.length > 0) {
                failedValidations[brickId] = brickValidations;
            }
        }

        return {
            failedValidations,
            hasMultipleBricksSelected: bricksToUpdate.length > 1
        };
    }

    /**
     * Determines if a transition requires confirmation and retrieves the message.
     * @param bricksToUpdate - Array of brick IDs to check.
     * @param targetStatus - The target status for the transition.
     * @returns { requiresConfirmation: boolean, confirmMessage?: string }
     */
    static checkWorkflowShouldConfirmAndMessage(bricksToUpdate: Brick['id'][], targetStatus: string): ConfirmationDetails {
        let isRequired = false;
        let message = '';

        bricksToUpdate.forEach((brickId) => {
            const brick = ComponentStore.get('Bricks')?.bricks?.[`i_${brickId}`];
            if (!brick) return;

            const workflow = WorkflowManager.getWorkflowForSubtype(brick.subType);
            if (!workflow) return;

            // Find the transition for any of the target statuses
            const transition = this.findTransition(workflow, brick.metadata?.status, targetStatus);

            if (transition?.shouldConfirm) {
                isRequired = true;
                message = transition.confirmMessage || '';
            }
        });

        return { isRequired, message };
    }

    /**
     * Runs predefined validations on a brick based on the provided transition.
     * @param transition - The transition object containing source, target, and predefined validations.
     * @param brick - The brick to validate.
     * @returns Array of failed validation messages.
     */
    static checkPredefinedValidations(transition: WorkflowStatusTransition, brick: Brick): string[] {
        const failedValidations: string[] = [];

        // Iterate over predefined validations and execute them
        for (const validationType of transition.predefinedValidations || []) {
            let validationFn: ((brick: Brick) => boolean) | undefined;

            // Special case: Check if all children are already in one of the target statuses
            if (validationType === 'areAllChildrenInTargetStatus') {
                validationFn = () => !transition.target.some((targetStatus) => ValidatorUtils.areAllChildrenInTargetStatus(brick, targetStatus));
            } else {
                validationFn = ValidatorUtils[validationType];
            }

            // If the validation fails, add the corresponding message
            if (validationFn && validationFn(brick)) {
                const validationMessage = this.getPredefinedValidationMessage(validationType);
                if (validationMessage) {
                    failedValidations.push(validationMessage);
                }
            } else if (!validationFn) {
                console.warn(`Unknown validation type: ${validationType}`);
            }
        }

        return failedValidations;
    }

    /**
     * Retrieves the error message for a specific validation type.
     * @param validationType - The validation type.
     * @returns The error message.
     */
    private static getPredefinedValidationMessage(validationType: PredefinedValidationType): string {
        const messages: Record<PredefinedValidationType, string> = {
            hasAssignee: Translation.get('workflow.validation.message.noAssignee', 'bricks'),
            hasReviewer: Translation.get('workflow.validation.message.noReviewer', 'bricks'),
            hasDeadline: Translation.get('workflow.validation.message.noDeadline', 'bricks'),
            isAfterDeadline: Translation.get('workflow.validation.message.pastDeadline', 'bricks'),
            hasBriefingDescription: Translation.get('workflow.validation.message.noBriefingDescription', 'bricks'),
            hasBriefingAttachments: Translation.get('workflow.validation.message.noBriefingAttachments', 'bricks'),
            hasDateRange: Translation.get('workflow.validation.message.noDateRange', 'bricks'),
            isInDateRange: Translation.get('workflow.validation.message.outsideDateRange', 'bricks'),
            hasCreatives: Translation.get('workflow.validation.message.noCreatives', 'bricks'),
            hasCorrectMedia: Translation.get('workflow.validation.message.incorrectMedia', 'bricks'),
            areAllChildrenInTargetStatus: Translation.get('workflow.validation.message.childrenNotInTargetStatus', 'bricks')
        };

        return messages[validationType];
    }
}

export default WorkflowValidator;
