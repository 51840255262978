import React, { useMemo, useState } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import Icon from 'components/ui-components-v2/Icon';
import Button from 'components/ui-components-v2/Button';
import Translation from 'components/data/Translation';
import { CreativeV2, CreativeV2Template } from 'components/creatives-v2/components/creative-editor/types/creativeV2.type';
import FormatSelector from 'components/ui-base/FormatSelector';
import cloneDeep from 'helpers/cloneDeep';
import SnackbarUtils from 'components/ui-base/SnackbarUtils';
import { isCustomUploadCreative, isMediaCreative, isTemplateCreative } from 'components/creatives-v2/guards/creative-type-guards';
import ConfirmDialog from 'components/ui-components/ConfirmDialog';
import '../styles/single-selector.scss';
import Illustration from 'components/ui-components-cape/Illustration';

interface Props {
    creative?: CreativeV2;
    isButton: boolean;
    customButton?: React.ReactNode;
    openAssetGalleryDialog: () => void;
    onChange: (creatives: CreativeV2[]) => void;
    editCreative: (creative: CreativeV2) => void;
}

const SingleCreativeSelector = ({ creative, isButton, customButton, openAssetGalleryDialog, onChange, editCreative }: Props) => {
    const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);

    const onFormatsChange = (formats: string[]) => {
        if (!creative) return;

        const newCreative = cloneDeep(creative) as CreativeV2;
        newCreative.data.settings.activeFormats = formats;

        onChange([newCreative]);
    };

    const removeCreative = () => {
        setConfirmDialogOpen(false);
        if (!creative) return;

        onChange([]);
        SnackbarUtils.success('Creative removed');
    };

    const thumbnail = (creative as CreativeV2Template)?.thumbnail || creative?.data?.thumbnail?.url;

    const activeFormats = useMemo(() => {
        return creative?.data?.settings?.activeFormats || [];
    }, [creative?.data?.settings?.activeFormats]);

    return (
        <div className="creative-selector__single">
            {!creative && isButton && (
                <Button onClick={() => openAssetGalleryDialog()} variant="contained">
                    Select template
                </Button>
            )}
            {!creative && !!customButton && <div onClick={() => openAssetGalleryDialog()}>{customButton}</div>}
            {!creative && !isButton && !customButton && (
                <div className="creative-selector__single__container" onClick={() => openAssetGalleryDialog()}>
                    <div className="creative-selector__single__container__thumbnail">
                        <Illustration illustration="fallback" color="disabled" />
                    </div>
                    <div className="creative-selector__single__container__details">
                        <div className="creative-selector__single__container__details__title">No template selected</div>
                    </div>
                </div>
            )}
            {creative && (
                <>
                    <div className="creative-selector__single__container" onClick={() => editCreative(creative)}>
                        <div className="creative-selector__single__container__thumbnail">
                            {thumbnail && <img className="creative-selector__single__container__thumbnail__image" src={thumbnail} alt="thumbnail" />}
                            {!thumbnail && <Illustration illustration="fallback" />}
                        </div>
                        <div className="creative-selector__single__container__details">
                            <div>
                                <div className="creative-selector__single__container__details__title--filled">{creative.title}</div>
                                {isTemplateCreative(creative) && (
                                    <div className="creative-selector__single__container__details__subtitle">
                                        {Translation.get(`template.types.${creative.data.templateType}`, 'common')}
                                    </div>
                                )}
                                {isMediaCreative(creative) && (
                                    <div className="creative-selector__single__container__details__subtitle">
                                        {creative.data.width}x{creative.data.height}
                                    </div>
                                )}
                                {isCustomUploadCreative(creative) && (
                                    <div className="creative-selector__single__container__details__subtitle">
                                        {creative.data.width}x{creative.data.height}
                                    </div>
                                )}
                            </div>
                            <div>
                                <Tooltip title="Open creative editor" placement="top">
                                    <IconButton
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            editCreative(creative);
                                        }}
                                        size="small"
                                        className="creative-selector__single__container__details__edit">
                                        <Icon>design_services</Icon>
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Remove creative" placement="top">
                                    <IconButton
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            setConfirmDialogOpen(true);
                                        }}
                                        size="small"
                                        className="creative-selector__single__container__details__delete">
                                        <Icon>delete</Icon>
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                    {isTemplateCreative(creative) && (
                        <FormatSelector templateIdentifier={creative.data.templateIdentifier} selectedFormats={activeFormats} onChange={onFormatsChange} />
                    )}
                </>
            )}
            <ConfirmDialog
                open={confirmDialogOpen}
                title={Translation.get('confirmDialog.title', 'common', { item: 'this creative' })}
                description={Translation.get('confirmDialog.description', 'common')}
                onConfirm={() => removeCreative()}
                onClose={() => setConfirmDialogOpen(false)}
                confirmColor="error"
            />
        </div>
    );
};

export { SingleCreativeSelector };
