import { brickNameMapping, BrickSetup } from 'components/bricks/types/brick.type';

const setup: BrickSetup = {
    subType: 'peachconnect_campaign',
    title: brickNameMapping.peachconnect_campaign,
    channel: 'convergedtv',
    platform: 'peachconnect',
    description: 'Create a Peach campaign',
    defaultTab: 'settings',
    addBrickText: 'Add campaign',
    availableParentBricks: [],
    outputAction: 'publish',
    availableChildBricks: ['peachconnect_ad'],
    additionalVars: ['multiSocial_campaign', 'feed_custom', 'peachconnect_campaign'],
    tabs: [
        {
            key: 'settings',
            title: 'Settings',
            type: 'multiInput'
        },
        {
            key: 'briefing',
            title: 'Briefing',
            type: 'component',
            component: 'briefing'
        },
        {
            key: 'publish',
            title: 'Publish',
            type: 'component',
            component: 'output',
            rights: ['brickManagement', 'brickPublish']
        }
    ],
    config: {
        general: {
            publish: {
                previewType: 'settings'
            }
        }
    }
};

export default setup;
