import React, { useMemo } from 'react';
import Translation from 'components/data/Translation';
import AssetGalleryDialog from 'components/assets/AssetGalleryDialog';
import { CREATIVE_TEMPLATE_TYPES, STATIC_ASSET_TYPES } from 'components/template-management/constants';
import { convertStaticAssetTypeV2ToV1 } from 'components/template-management/utilities';
import { StaticAssetSelectorProps } from 'components/template-management/types/static-asset-selector-props.type';
import { SocialSelectorProps } from 'components/template-management/types/social-selector-props.type';
import { TemplateSelectorProps } from 'components/template-management/types/template-selector-props.type';
import { CreativeSelectorProps } from 'components/creative-management-v2/types/creative-selector-props.type';
import AssetGalleryDialogSelectorType from 'components/assets/AssetGalleryDialog/interfaces/AssetGalleryDialogSelectorType';
import { SocialChannelItem, StaticAsset, StaticAssetType, TemplateAsset } from 'components/template-management/types/template-management.type';
import Setup from 'components/data/Setup';
import { CreativeAsset } from 'components/creative-management-v2/types/creative-management.type';

interface Props {
    open: boolean;
    showCreatives?: boolean;
    templateTypes?: string[];
    itemIncludes?: string[];
    groupKey?: string;
    onClose: () => void;
    onSelectItem: (item: TemplateAsset | CreativeAsset | StaticAsset | SocialChannelItem) => void;
}

// Setup the template props for the AMV2.
const setupTemplateProps = (templateTypes, itemIncludes, groupKey, onSelectItem): TemplateSelectorProps | undefined => {
    // Setup Creative Templates if required.
    const creativeTemplateSubTypes = (() => {
        if (templateTypes) return templateTypes.filter((type) => !STATIC_ASSET_TYPES.includes(type) && type !== 'staticAsset' && type !== 'socialChannelItem');
        return CREATIVE_TEMPLATE_TYPES.filter((type: string) => type !== 'staticAsset' && type !== 'socialChannelItem');
    })();

    if (!creativeTemplateSubTypes || creativeTemplateSubTypes.length === 0) return;

    const dataFilters: TemplateSelectorProps['dataFilters'] = {};
    if (itemIncludes && itemIncludes.length > 0) {
        dataFilters.identifier = itemIncludes;
    }
    if (groupKey) dataFilters.groupKey = groupKey;
    return { subType: creativeTemplateSubTypes, dataFilters, onSelectItem };
};

const setupStaticAssetProps = (templateTypes, onSelectItem): StaticAssetSelectorProps | undefined => {
    // Setup Static Assets if required.
    const staticAssetTypes = (() => {
        if (!templateTypes) return STATIC_ASSET_TYPES.map((type) => convertStaticAssetTypeV2ToV1(type));
        return templateTypes
            .filter((type: string): type is StaticAssetType => STATIC_ASSET_TYPES.includes(type as StaticAssetType))
            .map((type: string) => convertStaticAssetTypeV2ToV1(type as StaticAssetType));
    })();

    if (!staticAssetTypes || staticAssetTypes.length === 0) return;

    return {
        staticAssetType: staticAssetTypes,
        onSelectItem
    };
};

const setupSocialChannelItemProps = (templateTypes, groupKey, itemIncludes, onSelectItem): SocialSelectorProps | undefined => {
    // Setup Social Channel Items if required.
    const socialChanneltemTypes = (() => {
        if (!templateTypes) return ['socialChannelItem'];
        return templateTypes.filter((type: string) => type === 'socialChannelItem');
    })();

    if (!socialChanneltemTypes || socialChanneltemTypes.length === 0) return;

    return {
        groupKey,
        itemIncludes,
        onSelectItem
    };
};
// Setup the creative selector props in AMV2.
const setupCreativeProps = (showCreatives, templateTypes, itemIncludes, onSelectItem): CreativeSelectorProps | undefined => {
    if (!showCreatives) return;
    if (!Setup.hasModule('creativeManagement')) return;

    const subType = (() => {
        if (templateTypes) return templateTypes;
        return null;
    })();

    const dataFilters: CreativeSelectorProps['dataFilters'] = {};
    if (itemIncludes && itemIncludes.length > 0) {
        dataFilters.templateIdentifier = itemIncludes;
    }

    return { subType, dataFilters, onSelectItem };
};

const CreativeBuilderTemplateDialogV2 = ({ open, templateTypes, itemIncludes, groupKey, showCreatives, onClose, onSelectItem }: Props) => {
    const templateProps = useMemo(
        () => setupTemplateProps(templateTypes, itemIncludes, groupKey, onSelectItem),
        [templateTypes, itemIncludes, groupKey, onSelectItem]
    );

    const staticAssetProps = useMemo(() => setupStaticAssetProps(templateTypes, onSelectItem), [templateTypes, onSelectItem]);

    const socialChannelItemProps = useMemo(
        () => setupSocialChannelItemProps(templateTypes, groupKey, itemIncludes, onSelectItem),
        [templateTypes, groupKey, itemIncludes, onSelectItem]
    );

    const creativeProps = useMemo(
        () => setupCreativeProps(showCreatives, templateTypes, itemIncludes, onSelectItem),
        [showCreatives, templateTypes, itemIncludes, onSelectItem]
    );

    const selectors = useMemo(() => {
        const selectors: AssetGalleryDialogSelectorType[] = [];
        if (templateProps) selectors.push('template');
        if (staticAssetProps) selectors.push('staticAsset');
        if (socialChannelItemProps) selectors.push('socialChannelItem');
        if (creativeProps) selectors.push('creative');

        return selectors;
    }, [templateProps, staticAssetProps, socialChannelItemProps, creativeProps]);

    return (
        <AssetGalleryDialog
            title={Translation.get('formflow.JSON.addItem', 'common')}
            onMutation={() => null}
            open={open}
            fixedHeightPaperScrollPaper
            onClose={onClose}
            selectors={selectors}
            selectorsProps={{ templateProps, staticAssetProps, socialChannelItemProps, creativeProps }}
        />
    );
};

export default CreativeBuilderTemplateDialogV2;
