import '../styles/main.scss';
import React, { Component } from 'react';
import Icon from 'components/ui-components-v2/Icon';
import { cloneDeep } from 'lodash';
import TextField from 'components/ui-components-v2/TextField';
import Button from 'components/ui-components-v2/Button';
import EditorData from 'components/editor-data/EditorData';
import Request from 'components/data/Request';

/**
 * Social review function result
 * Show the results of the edits by the subaccounts.
 */
class SocialCustomizerReview extends Component {
    static defaultProps = {};

    constructor(props) {
        super(props);

        this.state = {
            itemOpen: false,
            reviewData: [],
            reviewDataFiltered: [],
            subAccounts: {}
        };

        // Get array and sort
        let reviewData = EditorData.getValueFromModel('review');
        if (!reviewData) {
            reviewData = {};
        }

        const reviewDataArray = [];
        Object.keys(reviewData).forEach((key) => {
            const item = reviewData[key];
            if (item.subAccountId) {
                if (item.status === 'approveAll') {
                    item.statusMessage = 'All approved';
                } else if (item.status === 'declineAll') {
                    item.statusMessage = 'All declined';
                } else {
                    item.statusMessage = 'Customized';
                }
                reviewDataArray.push(item);
            }
        });
        reviewDataArray.sort((a, b) => Date.parse(a) - Date.parse(b));
        this.state.reviewData = reviewDataArray;
        this.state.reviewDataFiltered = reviewDataArray;
    }

    componentDidMount() {
        this.loadList();
    }

    loadList() {
        const { reviewData } = this.state;

        // Fetch a token to connect to the publish api
        Request.post('subAccounts/listAll', {}).then((result) => {
            const subAccounts = {};
            if (result.data) {
                result.data.forEach((item) => {
                    subAccounts['subAccount_' + item.id] = item.title;
                });
            }
            reviewData.forEach((item) => {
                item.title = subAccounts['subAccount_' + item.subAccountId] ? subAccounts['subAccount_' + item.subAccountId] : 'Test environment';
            });

            this.setState({ subAccounts: subAccounts, reviewData: reviewData, reviewDataFiltered: reviewData });
        });
    }

    handleMessageChange = (subAccountId, uuid, message, index) => {
        const { itemDisplayResults } = this.state;
        EditorData.setModel('review.account_' + subAccountId + '.item_' + uuid + '.message', message);
        const itemDisplayResultsNew = itemDisplayResults;

        itemDisplayResultsNew[index].message = message;
        this.setState({
            itemDisplayResults: itemDisplayResultsNew
        });
    };

    handleOpen = (i) => {
        if (this.state.itemOpen === i) {
            this.setState({ itemOpen: -1 });
            return;
        }
        const { list, dataSet } = this.props;
        const { reviewData } = this.state;
        const displayResults = [];
        const subAccountReview = reviewData[i];
        list.forEach((item) => {
            let reviewDataItem = subAccountReview['item_' + item.uuid];
            if (!reviewDataItem) {
                reviewDataItem = {};
            }

            const originalMessage = EditorData.getValueFromModel(
                'creativeBuilderSocial.data.' + item.uuid + '.data.main.channelData.message.' + EditorData.get('language') + '.value'
            );

            const insertObject = {
                title: dataSet[item.uuid].title,
                status: reviewDataItem.status,
                message: reviewDataItem.message,
                messageUpdated: originalMessage != reviewDataItem.message,
                uuid: item.uuid
            };
            displayResults.push(insertObject);
        });

        this.setState({ itemOpen: i, itemDisplayResults: displayResults });
    };

    handleSearch = (e) => {
        const searchTerm = e.target.value;
        const reviewDataFiltered = this.state.reviewData.filter((item) => {
            return item.title.toLowerCase().includes(searchTerm.toLowerCase());
        });
        this.setState({ reviewDataFiltered: reviewDataFiltered });
    };

    handleExport = () => {
        const { list, dataSet } = this.props;

        const reviewData = cloneDeep(this.state.reviewData);

        const csvRows = [];
        const headers = ['title', 'editTime', 'status', 'statusMessage'];

        list.forEach((item) => {
            headers.push(dataSet[item.uuid].title + ' status');
            headers.push(dataSet[item.uuid].title + ' message');
        });

        // Create header row
        const headerRow = headers.join(',');
        csvRows.push(headerRow);

        // Create rows
        reviewData.forEach((item) => {
            let row = [];
            row.push(item.title);
            row.push(item.editTime);
            row.push(item.status);
            row.push(item.statusMessage);

            list.forEach((item2) => {
                const reviewDataItem = item['item_' + item2.uuid];
                if (reviewDataItem) {
                    row.push(reviewDataItem.status);
                    row.push(reviewDataItem.message);
                } else {
                    row.push('');
                    row.push('');
                }
            });

            row = row.map((value) => {
                if (value.includes(',') || value.includes('"')) {
                    // Replace linebreaks
                    value = value.replace(/\n/g, ' ');
                    return `"${value.replace(/"/g, '""')}"`; // Enclose in quotes and double any existing quotes
                } else {
                    return value;
                }
            });

            csvRows.push(row.join(','));
        });

        // Join rows with line breaks
        const fileData = csvRows.join('\n');

        const blob = new Blob([fileData], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.href = url;
        link.setAttribute('download', 'data.csv');
        link.click();
    };

    /**
     * Render
     */
    render() {
        const { reviewDataFiltered, itemOpen, itemDisplayResults } = this.state;

        return (
            <div className="social-customizer__results">
                <div className="social-customizer__results__introduction">The overview below displays the results from the reviews of the social posts. </div>
                <div className="social-customizer__results__bar">
                    <div style={{ display: 'flex', flexGrow: 1 }}>
                        <TextField label="Search" variant="outlined" onChange={this.handleSearch} />
                    </div>
                    <Button startIcon={<Icon>cloud_download</Icon>} variant="contained" onClick={this.handleExport}>
                        Export
                    </Button>
                </div>

                {reviewDataFiltered.map((item, i) => (
                    <div key={'item-' + i}>
                        <div key={'itemsub-' + i}>
                            <div
                                className="social-customizer__results__item"
                                onClick={() => item.status !== 'declineAll' && item.status !== 'approveAll' && this.handleOpen(i)}>
                                <div className={'social-customizer__results__item__status social-customizer__results__item__status--' + item.status}>
                                    {item.statusMessage}
                                </div>
                                <div className="social-customizer__results__item__name">{item.title}</div>
                                <div className="social-customizer__results__item__date">{item.editTime}</div>
                                <div className="social-customizer__results__item__arrow">
                                    {item.status !== 'declineAll' && item.status !== 'approveAll' && <Icon>expand_more</Icon>}
                                </div>
                            </div>
                        </div>

                        {itemOpen === i && (
                            <div className="social-customizer__results__details">
                                {itemDisplayResults.map((postData, j) => (
                                    <div className="social-customizer__results__details__item" key={'subitem-' + i}>
                                        <div className="social-customizer__results__details__title">{postData.title}</div>
                                        <div
                                            className={
                                                'social-customizer__results__details__status ' +
                                                'social-customizer__results__details__status--' +
                                                postData.status
                                            }>
                                            {postData.status}
                                        </div>
                                        {postData.status === 'approve' && postData.message && postData.messageUpdated && (
                                            <div className="social-customizer__results__details__message">
                                                <TextField
                                                    margin="dense"
                                                    multiline
                                                    rows={2}
                                                    value={postData.message}
                                                    fullWidth
                                                    onChange={(e) => this.handleMessageChange(item.subAccountId, postData.uuid, e.target.value, j)}
                                                />
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                ))}
            </div>
        );
    }
}

export default SocialCustomizerReview;
