import ComponentStoreHelpers from 'components/data/ComponentStore';
import EditorData from 'components/editor-data/EditorData';
import { MetricsUsageUseCase } from '../types/asset-management.service.type';

// Determine the use case in which a select action talkes place.
const getSelectorUseCase = (): MetricsUsageUseCase | null => {
    if (ComponentStoreHelpers.get('CreativeEditor')) return 'creative';
    if (ComponentStoreHelpers.get('TemplateDesigner')) return 'template';
    if (EditorData.get('type') === 'concept') return 'campaignconcept';
    if (EditorData.get('type') === 'campaign') return 'campaign';
    return null;
};

export { getSelectorUseCase };
