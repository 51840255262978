import React, { useMemo } from 'react';
import Tooltip from 'components/ui-components-v2/Tooltip';
import Checkbox from 'components/ui-components-v2/Checkbox';
import Translation from 'components/data/Translation';
import ComponentStore from 'components/data/ComponentStore';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import cloneDeep from 'helpers/cloneDeep';
import { CreativeEditorV2 } from '../types/creativeEditorV2.type';
import { CreativeEditorV2FormatActions } from './creative-editor-format-actions';
import '../styles/creative-editor-format-header.scss';

interface ComponentStoreProps {
    creative: CreativeEditorV2['creative'];
    activeFrame: CreativeEditorV2['activeFrame'];
    disabledFeatures: CreativeEditorV2['disabledFeatures'];
}

interface Props {
    title?: string;
    formatKey?: string;
    scale?: number;
    selectedFormats?: CreativeEditorV2['selectedFormats'];
}

const CreativeEditorV2FormatHeader = ({ title, formatKey, scale, selectedFormats }: Props) => {
    const { activeFrame, creative, disabledFeatures } = useComponentStore<ComponentStoreProps>('CreativeEditorV2', {
        fields: {
            activeFrame: 'activeFrame',
            creative: 'creative',
            disabledFeatures: 'disabledFeatures'
        }
    });

    const activeFormatsLength = useMemo(() => {
        return creative?.data?.settings?.activeFormats?.length || 0;
    }, [creative?.data?.settings?.activeFormats]);

    if (!activeFrame || !formatKey) return null;

    const toggleFormat = () => {
        if (!formatKey) return;

        const clonedSelectedFormats = cloneDeep(selectedFormats);

        if (clonedSelectedFormats.includes(formatKey)) {
            clonedSelectedFormats.splice(clonedSelectedFormats.indexOf(formatKey), 1);
        } else {
            clonedSelectedFormats.push(formatKey);
        }

        ComponentStore.setModel('CreativeEditorV2', 'selectedFormats', clonedSelectedFormats);
    };

    return (
        <div className="creative-editor-format-header">
            {activeFormatsLength > 1 && !disabledFeatures.includes('overwrites') && (
                <div className="creative-editor-format-header__checkbox">
                    <Checkbox size="small" checked={selectedFormats?.includes(formatKey)} onClick={() => toggleFormat()} />
                </div>
            )}
            <div className="creative-editor-format-header__title" onClick={() => toggleFormat()}>
                <Tooltip title={title} placement="top">
                    <span>{title}</span>
                </Tooltip>
            </div>
            {scale && scale < 1 && (
                <Tooltip
                    title={Translation.get('formatsList.tooltips.formatAutomaticallyScaled', 'creatives-v2', {
                        scale: `${scale * 100}%`
                    })}>
                    <div className="creative-editor-format-header__scale">{`${Math.round(scale * 100)}%`}</div>
                </Tooltip>
            )}
            <div className="creative-editor-format-header__actions-right">
                <CreativeEditorV2FormatActions formatKey={formatKey} />
            </div>
        </div>
    );
};

export { CreativeEditorV2FormatHeader };
