import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker as MuiTimePicker } from '@mui/x-date-pickers/TimePicker';
import Icon from 'components/ui-components-v2/Icon';

/**
 * Time picker
 * Select a specific time
 **/
export class TimePicker extends Component {
    static propTypes = {
        onMutation: PropTypes.func,
        value: PropTypes.any
    };

    static defaultProps = {
        onMutation: () => {},
        value: undefined
    };

    constructor(props) {
        super(props);
        const { value } = props;

        this.state = {
            selectedDate: value ? moment(value, 'HH:mm:SS') : moment()
        };
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.value !== this.props.value) {
            this.setState({ selectedDate: moment(this.props.value, 'HH:mm:SS') });
        }
    };

    /**
     * Change the data
     */
    handleDateChange = (date) => {
        this.setState({ selectedDate: moment(date) }, this.onDataChanged);
    };

    /**
     * Data changed, mutate
     */
    onDataChanged = () => {
        const { onMutation } = this.props;
        const { selectedDate } = this.state;

        if (selectedDate) onMutation(selectedDate.format('HH:mm:SS'));
    };

    render() {
        const { selectedDate } = this.state;

        return (
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <MuiTimePicker
                    slots={{
                        openPickerIcon: () => <Icon>schedule</Icon>
                    }}
                    inputFormat="HH:mm"
                    value={selectedDate}
                    onChange={this.handleDateChange}
                />
            </LocalizationProvider>
        );
    }
}

export default TimePicker;
