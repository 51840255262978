import React from 'react';
import PropTypes from 'prop-types';
import DynamicAsset from 'components/assets/DynamicAsset';
import './../styles/demand-gen.scss';

/**
 * Youtube Video Ad
 * This shows a preview of the video ads
 */
class GoogleAdsDemandGen extends React.Component {
    static propTypes = {
        adType: PropTypes.string,
        headline: PropTypes.string,
        description: PropTypes.string,
        displayUrl: PropTypes.string,
        asset: PropTypes.shape({
            type: PropTypes.string,
            url: PropTypes.string
        })
    };

    static defaultProps = {
        adType: 'videoAd'
    };

    constructor(props) {
        super(props);

        this.state = {};
    }

    /**
     * Get the assets from Youtube
     */
    getAsset = () => {
        const { assetComponents, asset, image, video } = this.props;
        // existing prop
        if (assetComponents) {
            return assetComponents[0] && assetComponents[0][0] ? assetComponents[0][0] : assetComponents[0];
        }
        // Create in component
        else {
            return (
                <DynamicAsset
                    {...asset}
                    image={image}
                    video={video}
                    frameWidth={asset && asset.frameWidth ? asset.frameWidth : 1200}
                    frameHeight={asset && asset.frameHeight ? asset.frameHeight : 620}
                    width={320}
                    controls={false}
                />
            );
        }
    };

    render() {
        const { headline, description, logo } = this.props;
        return (
            <div>
                <div className="social-preview__google-ads__demand-gen">
                    <div className="social-preview__google-ads__demand-gen__bar"></div>
                    <div className="social-preview__google-ads__demand-gen__asset">{this.getAsset()}</div>
                    <div className="social-preview__google-ads__demand-gen__context">
                        <div className="social-preview__google-ads__demand-gen__logo" style={{ backgroundImage: `url(${logo?.url})` }} />
                        <div className="social-preview__google-ads__demand-gen__top">
                            <div className="social-preview__google-ads__demand-gen__headline">{headline}</div>
                            <div className="social-preview__google-ads__demand-gen__description">{description}</div>
                            <div className="social-preview__google-ads__demand-gen__sponsored">Sponsored</div>
                        </div>
                    </div>
                    <div className="social-preview__google-ads__demand-gen__cta">Learn more</div>
                </div>
            </div>
        );
    }
}

export default GoogleAdsDemandGen;
