import React, { useEffect, useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Select,
    InputLabel,
    FormControl,
    IconButton,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    MenuItem
} from '@mui/material';
import Icon from 'components/ui-components-v2/Icon';
import SearchField from 'components/ui-components/SearchField';
import Translation from 'components/data/Translation';

/**
 *  Type definition for voice labels.
 */
interface VoiceLabels {
    accent: string;
    description: string;
    age: string;
    gender: string;
    'use case': string;
}

/**
 * Type definition for a single voices.
 */
export interface Voice {
    voice_id: string;
    name: string;
    samples: any;
    preview_url: string;
    labels: VoiceLabels;
}

/**
 *  Props for the SelectorPopUp component.
 */
interface SelectorPopUpProps {
    voices: Voice[];
    selectedVoiceId?: string;
    onVoiceSelect: (voice: Voice) => void;
}

/**
 * State for the currently playing audio.
 */
interface CurrentAudioState {
    audio: HTMLAudioElement | null;
    playingVoiceId: string | null; // Allow null for when no audio is playing
}

/**
 * A popup component for selecting a voice.
 *
 * @component
 * @param {SelectorPopUpProps} props - The props for the component.
 */

const SelectorPopUp = ({ voices, onVoiceSelect, selectedVoiceId }: SelectorPopUpProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedVoice, setSelectedVoice] = useState('');
    const [currentAudio, setCurrentAudio] = useState<CurrentAudioState>({ audio: null, playingVoiceId: null });

    useEffect(() => {
        setSelectedVoice(voices.find((voice) => voice.voice_id === selectedVoiceId)?.name || '');
    }, [voices, selectedVoiceId]);

    /**
     * Plays the audio sample for a given voice.
     *
     * @param {string} voiceId - The ID of the voice to play.
     * @param {string} url - The URL of the audio sample to play.
     */
    const playAudio = (voiceId: string, url: string) => {
        // Stop the current audio if it's playing
        if (currentAudio.audio) {
            currentAudio.audio.pause();
        }

        if (currentAudio.playingVoiceId === voiceId) {
            // If the clicked audio is the same as the currently playing, just toggle the pause/play state
            setCurrentAudio({ audio: null, playingVoiceId: null });
        } else {
            // Play the new audio
            const newAudio = new Audio(url);
            newAudio.play();
            setCurrentAudio({ audio: newAudio, playingVoiceId: voiceId });

            // When the audio is finished, reset the state
            newAudio.onended = () => {
                setCurrentAudio({ audio: null, playingVoiceId: null });
            };
        }
    };

    /**
     * Toggles the visibility of the popup.
     */
    const togglePopup = () => {
        setIsOpen(!isOpen);
    };

    /**
     * Handles the selection of a voice.
     *
     * @param {Voice} voice - The voice that has been selected.
     */
    const selectVoice = (voice: Voice) => {
        setSelectedVoice(voice.name);
        setIsOpen(false);
        onVoiceSelect(voice);
    };

    /**
     * Capitalizes the first letter of a string.
     *
     * @param {string} str - The string to capitalize.
     * @returns {string} The capitalized string.
     */
    const capitalizeFirstLetter = (str: string): string => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    const [searchTerm, setSearchTerm] = useState('');

    const filteredVoices = voices.filter((voice) => voice.name.toLowerCase().includes(searchTerm.toLowerCase()));

    return (
        <div className="voice-popup">
            <FormControl fullWidth>
                <div className="selector-voice-over__select-contents">
                    <InputLabel id="demo-simple-select-label" shrink={true}>
                        {Translation.get('assetGallerySelector.selectorPopup.select', 'content-space')}
                    </InputLabel>
                </div>
                <div>
                    <Select
                        className={'voice-popup__voice-select'}
                        labelId="demo-simple-select-label"
                        value={selectedVoice || Translation.get('assetGallerySelector.selectorPopup.selectVoice', 'content-space')}
                        label={Translation.get('assetGallerySelector.selectorPopup.voices', 'content-space')}
                        displayEmpty
                        renderValue={(selected) => {
                            return selected;
                        }}>
                        <MenuItem value={selectedVoice || Translation.get('assetGallerySelector.selectorPopup.selectVoice', 'content-space')}>
                            {selectedVoice || Translation.get('assetGallerySelector.selectorPopup.selectVoice', 'content-space')}
                        </MenuItem>
                    </Select>
                    <div className="voice-popup__hitBox" onClick={togglePopup}></div>
                </div>
            </FormControl>
            <Dialog open={isOpen} onClose={togglePopup} className="voice-popup__dialog">
                <DialogTitle className="voice-popup__dialog__title">
                    <span>{Translation.get('assetGallerySelector.selectorPopup.title', 'content-space')}</span>
                    <IconButton onClick={togglePopup}>
                        <Icon className="voice-popup__dialog__title__icon" fontSize="inherit">
                            close
                        </Icon>
                    </IconButton>
                </DialogTitle>
                <DialogContent className="voice-popup__dialog__content">
                    <div className="voice-popup__dialog__content__search-header">
                        <SearchField className="voice-popup__search-field" onChange={(_, value) => setSearchTerm(value)} />
                    </div>
                    <div className="voice-popup__dialog__content__rows">
                        <Table stickyHeader sx={{ maxWidth: 560 }} size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell>{Translation.get('assetGallerySelector.selectorPopup.name', 'content-space')}</TableCell>
                                    <TableCell>{Translation.get('assetGallerySelector.selectorPopup.gender', 'content-space')}</TableCell>
                                    <TableCell>{Translation.get('assetGallerySelector.selectorPopup.accent', 'content-space')}</TableCell>
                                    <TableCell>{Translation.get('assetGallerySelector.selectorPopup.language', 'content-space')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredVoices.map((voice) => (
                                    <TableRow key={voice.voice_id} onClick={() => selectVoice(voice)} className="voice-popup__dialog__content__rows__item">
                                        <TableCell>
                                            <Icon
                                                className="voice-popup__dialog__content__rows__item-button"
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    playAudio(voice.voice_id, voice.preview_url);
                                                }}>
                                                {currentAudio.playingVoiceId === voice.voice_id ? <Icon>pause_circle</Icon> : <Icon>play_circle</Icon>}
                                            </Icon>
                                        </TableCell>
                                        <TableCell>{voice.name}</TableCell>
                                        <TableCell>{voice.labels && voice.labels.gender ? capitalizeFirstLetter(voice.labels.gender) : 'N/A'}</TableCell>
                                        <TableCell>{voice.labels && voice.labels.accent ? capitalizeFirstLetter(voice.labels.accent) : 'N/A'}</TableCell>
                                        <TableCell>{Translation.get('assetGallerySelector.selectorPopup.english', 'content-space')}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default SelectorPopUp;
