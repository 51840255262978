import React from 'react';
import FormControl from '@mui/material/FormControl';
import Icon from 'components/ui-components-v2/Icon';
import Tooltip from '@mui/material/Tooltip';
import IconButton from 'components/ui-components-v2/IconButton';
import MenuItem from 'components/ui-components-v2/MenuItem';
import Select from 'components/ui-components-v2/Select';
import useValidations from 'components/bricks/hooks/useValidations';
import { Brick } from 'components/bricks/types/brick.type';
import Translation from 'components/data/Translation';
import { Direction } from '..';
import { useAdSetupContext } from '../../ad-setup/context/ad-setup-context';
import '../styles/header.scss';

interface Props {
    activePlacement: string;
    placements: any;
    onSelectChange: (newPlacement: string) => void;
    onSwipe: (direction: Direction) => void;
    brick: Brick;
    activeTabKey?: string;
}

const BricksCreativesPreviewHeader: React.FC<Props> = ({ activePlacement, placements, onSelectChange, onSwipe, brick, activeTabKey }) => {
    const { selectedFrame } = useAdSetupContext();
    const { warnings } = useValidations([brick.id], activeTabKey, `${brick.subType}-ad_setup-placements-${activePlacement}-${selectedFrame}`);

    return (
        <div className="bricks-creatives-preview-header">
            <div className="bricks-creatives-preview-header__main">
                <div className="bricks-creatives-preview-header__main__left">
                    <IconButton onClick={() => onSwipe('previous')}>
                        <Icon>arrow_back</Icon>
                    </IconButton>
                </div>
                <div className="bricks-creatives-preview-header__main__select">
                    <Tooltip
                        title={Translation.get('adSetup.creativesPreview.noAssignedCreativeToPlacements', 'bricks')}
                        disableHoverListener={!warnings?.length}>
                        <div>{!!warnings?.length && <Icon className="bricks-creatives-preview-header__main__select__warning-icon">warning_amber</Icon>}</div>
                    </Tooltip>
                    <FormControl>
                        <Select
                            value={activePlacement}
                            variant="standard"
                            onChange={(e) => onSelectChange(e.target.value as string)}
                            displayEmpty
                            disableUnderline
                            style={{ fontWeight: 'bold' }}>
                            {placements.map((placement) => (
                                <MenuItem
                                    key={placement.value}
                                    value={placement.value}
                                    style={{
                                        fontWeight: activePlacement === placement.value ? 'bold' : 'normal',
                                        textTransform: 'capitalize'
                                    }}>
                                    {placement.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>

                <div className="bricks-creatives-preview-header__main__right">
                    <IconButton onClick={() => onSwipe('next')}>
                        <Icon>arrow_forward</Icon>
                    </IconButton>
                </div>
            </div>
            <div className="bricks-creatives-preview-header__actions"></div>
        </div>
    );
};

export default BricksCreativesPreviewHeader;
