import React from 'react';
import { DEFAULT_INDENTATION, INITIAL_INDENTATION } from '../data/indentations';

import '../styles/vertical-line.scss';

interface Props {
    level: number;
    className?: string;
}

/**
 * This component is responsible for rendering the vertical line in the tree view list.
 */
const TreeViewVerticalLine: React.FC<Props> = ({ level, className }) => {
    const createVerticalLines = (currentLevel: number, cumulativeIndentation = 0) => {
        if (currentLevel === 0) return [];

        const indentation = currentLevel === level ? INITIAL_INDENTATION : DEFAULT_INDENTATION;
        cumulativeIndentation += indentation;

        return [
            <div className={`tree-view-vertical-line__line ${className || ''}`} key={currentLevel} style={{ left: `${cumulativeIndentation}px` }}></div>,
            ...createVerticalLines(currentLevel - 1, cumulativeIndentation)
        ];
    };

    return <>{createVerticalLines(level)}</>;
};

export default TreeViewVerticalLine;
