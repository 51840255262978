import ComponentStore from 'components/data/ComponentStore';
import { MultiModel } from 'components/template-designer/data/template-designer-store';
import BrickHelpers from './brick.helpers';
import { BricksComponentStore, BricksObject } from '../types/bricksComponentStore.type';
import { MODEL_TEMP_BRICK, MODEL_UPDATED_AT, MODEL_UPDATED_BRICKS } from '../constants';
import { Brick } from '../types/brick.type';
import WorkflowValidator from '../components/workflow/helpers/validator.helpers';
import CustomBrickHelpers from './custom-brick-helper';
import { StatusChangeState } from '../components/workflow/workflow.type';

interface SetBrickModelOptions {
    disableMultiEdit?: boolean;
    isStatusChange?: boolean;
    statusChangeConfirmed?: boolean;
}

type Callbacks = {
    onValidationError?: (statusChangeState: StatusChangeState) => void;
    onWorkflowMismatch?: (bricksToUpdate: Brick['id'][], model: string, value: unknown) => void;
};

/** Helper class for the bricks component store. */
class BricksComponentStoreHelper {
    /**
     * Delete a brick from the brick component store based on the provided brick id.
     * @param brickId The brick id to be delete.
     * @returns The updated bricks object or undefined if the brick is not found.
     */
    static deleteBrick = (brickId: Brick['id']): boolean => {
        return BricksComponentStoreHelper.deleteMultipleBricks([brickId]);
    };

    /**
     * Delete multiple bricks and their nested children from the brick component store based on the provided brick ids.
     * @param brickIds The brick ids to delete.
     */
    static deleteMultipleBricks = (brickIds: Brick['id'][]): boolean => {
        const bricksComponentStore: BricksComponentStore | undefined = ComponentStore.get('Bricks');
        const bricks = bricksComponentStore?.bricks;

        if (!bricks) return false;

        const bricksModels = brickIds.flatMap((brickId) => {
            const brickChildren = BrickHelpers.getBrickAndInnerChildren(bricks, brickId);
            return brickChildren.map((brickId) => BrickHelpers.getBrickPrefix(brickId));
        });

        bricksModels.forEach((brickModel) => ComponentStore.removeItem('Bricks', brickModel));
        return true;
    };

    /**
     * Get the checked bricks from the brick component store that are none custom bricks.
     */
    private static getCheckedBricks = (): Brick['id'][] => {
        const checkedBricksObject: undefined | BricksObject = ComponentStore.get('Bricks')?.checkedBricks;

        return checkedBricksObject
            ? Object.keys(checkedBricksObject).filter((brickId) => {
                  const isChecked = checkedBricksObject[brickId];
                  const brick = BrickHelpers.getBrickById(brickId);
                  const isCustom = brick && CustomBrickHelpers.isCustomBrick(brick);
                  return !isCustom && isChecked; // Checked bricks should not be custom bricks and should be checked.
              })
            : [];
    };

    /**
     * Update a model in the brick component store based on the provided brick id and model key.
     * Keep in mind the bricks prefix is already set, so there is no need to add the ("bricks.i_" + "brickId") prefix to your model.
     * @param brickId The id of the brick, the brick id will be added to the prefix, so there is no need to the brick id to your model.
     * @param model The key of the component model. e.g. "roles.assignees". Keep in mind the bricks prefix is already set, so there is no need to add the "bricks.i_" prefix to your model.
     * @param value The value to set for the given model key.
     * @param options Additional options for the setBrickModel function.
     * @param callbacks Callbacks for handling errors or workflow mismatches.
     */
    static setBrickModel = (brickId: string, model: string, value: unknown, options?: SetBrickModelOptions, callbacks?: Callbacks): void => {
        // If there is no brickId, that means we are working with a temporary brick in the request flow.
        if (!brickId) {
            ComponentStore.setModel('Bricks', `${MODEL_TEMP_BRICK}.${model}`, value);
            return;
        }

        const models: MultiModel = [];
        const checkedBricks: Brick['id'][] = this.getCheckedBricks();
        const bricksToUpdate = this.determineBricksToUpdate(brickId, model, checkedBricks, options);

        // This is in case a few bricks are selected but you update a value in another brick
        if (!bricksToUpdate.includes(brickId)) bricksToUpdate.push(brickId);

        // Skip validation if the status change is already confirmed
        if (options?.statusChangeConfirmed) {
            this.applyBrickModelUpdates(bricksToUpdate, model, value, models);
            return;
        }

        // Handle status change workflow validation
        if (!this.validateWorkflow(bricksToUpdate, value as string, options, callbacks)) {
            // Stop if workflow validation fails
            return;
        }

        // Apply the updates to the component store
        this.applyBrickModelUpdates(bricksToUpdate, model, value, models);
    };

    /**
     * Prepare updates for the given bricks, model, and value.
     * @param bricksToUpdate Array of brick IDs to update.
     * @param model The model key to update (e.g., "roles.assignees").
     * @param value The value to set for the given model key.
     * @param models The MultiModel array to be populated with the updates.
     */
    private static applyBrickModelUpdates = (bricksToUpdate: Brick['id'][], model: string, value: unknown, models: MultiModel): void => {
        bricksToUpdate.forEach((brickId: Brick['id']) => {
            const brickPrefix = BrickHelpers.getBrickPrefix(brickId); // Get brick prefix together with the brick id, e.g. bricks.i_3423423.
            const brickIdPrefix = BrickHelpers.getBrickIdPrefix(brickId); // Get brick id prefix, e.g. i_3423423.
            const updatedBrickModelPath = `${MODEL_UPDATED_BRICKS}.${brickIdPrefix}`; // Path for saving the updated brick id to the component store.
            let modelPath = `${brickPrefix}.${model}`; // Path for saving the value to the component store.

            if (!model) {
                modelPath = brickPrefix;
            }

            // Add updates to the models array
            models.push([modelPath, value]);
            models.push([updatedBrickModelPath, brickId]);
            models.push([`${brickPrefix}.${MODEL_UPDATED_AT}`, new Date().toISOString()]); // Temporary solution for updating the updated at date
        });

        // Apply the updates to the store
        ComponentStore.setMultiModels('Bricks', models);
    };

    /*
     * Determines the list of checked brick IDs that need to be updated
     */
    private static determineBricksToUpdate = (brickId: string, model: string, checkedBricks: Brick['id'][], options?: SetBrickModelOptions): Brick['id'][] => {
        if (model === 'title') return [brickId]; // Don't update the title for multiple bricks
        if (checkedBricks.length && !options?.disableMultiEdit) return [...checkedBricks, brickId];
        return [brickId];
    };

    /**
     * Validates the workflow transitions and returns whether the process should continue or stop.
     * @param bricksToUpdate Array of brick IDs to validate.
     * @param targetStatus The target status for the transition.
     * @param options Additional options for the setBrickModel function.
     * @param callbacks Callbacks for handling validation errors or mismatches.
     * @returns A boolean indicating whether the validation passed (true to continue, false to stop).
     */
    private static validateWorkflow(bricksToUpdate: Brick['id'][], targetStatus: string, options?: SetBrickModelOptions, callbacks?: Callbacks): boolean {
        // If it's not a status change or value is missing, skip validation
        if (!options?.isStatusChange || !targetStatus) {
            return true;
        }

        // Check for workflow mismatches
        if (!WorkflowValidator.consistentWorkflowsCheckedBricks(bricksToUpdate)) {
            callbacks?.onWorkflowMismatch?.(bricksToUpdate, 'status', targetStatus);
            return false;
        }

        // Validate transitions for all bricks
        const validationData = WorkflowValidator.validateBricksForTransition(bricksToUpdate, targetStatus);
        const hasFailedValidations = Object.keys(validationData.failedValidations).length > 0;

        // Build the statusChangeState object
        const statusChangeState: StatusChangeState = {
            validationData: {
                failedValidations: validationData.failedValidations,
                hasMultipleBricksSelected: validationData.hasMultipleBricksSelected
            },
            targetStatus,
            pendingStatus: '',
            confirmationDetails: {
                isRequired: false,
                message: ''
            },
            hasMismatchedWorkflows: false
        };

        // Handle failed validations
        if (hasFailedValidations) {
            callbacks?.onValidationError?.(statusChangeState);
            return false;
        }

        // Check if the transition requires confirmation
        const { isRequired, message } = WorkflowValidator.checkWorkflowShouldConfirmAndMessage(bricksToUpdate, targetStatus);
        if (isRequired) {
            statusChangeState.pendingStatus = targetStatus;
            statusChangeState.confirmationDetails = {
                isRequired: true,
                message: message || ''
            };
            callbacks?.onValidationError?.(statusChangeState);
            return false;
        }
        // Validation passed
        return true;
    }
}

export default BricksComponentStoreHelper;
