import Translation from 'components/data/Translation';
import AssetGalleryDialogSelectorType from 'components/assets/AssetGalleryDialog/interfaces/AssetGalleryDialogSelectorType';
import AssetGalleryDialogSidebarSelector from '../interfaces/AssetGalleryDialogSidebarSelector';

/**
 * An array of objects representing the available selectors in the asset gallery dialog sidebar.
 * Each object contains a type, icon, title, and description.
 */
const assetGalleryDialogSidebarSelectors: AssetGalleryDialogSidebarSelector[] = [
    {
        type: 'upload',
        icon: 'upload',
        title: Translation.get('labels.upload', 'common'),
        description: Translation.get('assetGalleryDialog.assetSelectView.uploadMediaFromDevice', 'content-space')
    },
    {
        type: 'reuseImage',
        icon: 'view_carousel',
        title: Translation.get('assetGalleryDialog.assetSelectView.reuseImage', 'content-space'),
        description: Translation.get('assetGalleryDialog.assetSelectView.reduceFilesize', 'content-space')
    },
    {
        type: 'media',
        icon: 'folder',
        title: Translation.get('selector.sidebarTitle', 'media-management'),
        description: Translation.get('selector.sidebarDescription', 'media-management')
    },
    {
        type: 'contentSpace',
        icon: 'folder',
        title: Translation.get('assetGalleryDialog.assetSelectView.assetLibrary', 'content-space'),
        description: Translation.get('assetGalleryDialog.assetSelectView.selectAssetsFromTheAssetLibrary', 'content-space')
    },
    {
        type: 'unsplash',
        icon: 'unsplash',
        isCustomSvg: true,
        title: Translation.get('assetGalleryDialog.assetSelectView.unsplash', 'content-space'),
        description: Translation.get('assetGalleryDialog.assetSelectView.selectImagesFromUnsplash', 'content-space')
    },
    {
        type: 'url',
        icon: 'link',
        title: Translation.get('assetGalleryDialog.assetSelectView.url', 'content-space'),
        description: Translation.get('assetGalleryDialog.assetSelectView.uploadAssetsFromURL', 'content-space')
    },
    {
        type: 'aprimo',
        icon: 'link',
        title: Translation.get('assetGalleryDialog.assetSelectView.aprimo', 'content-space'),
        description: Translation.get('assetGalleryDialog.assetSelectView.selectImagesFromAprimo', 'content-space')
    },
    {
        type: 'aiContent',
        icon: 'auto_awesome',
        title: 'AI content (beta)',
        description: 'Generate AI content'
    },
    {
        type: 'voiceOverGenerator',
        icon: 'record_voice_over',
        title: Translation.get('assetGalleryDialog.assetSelectView.voiceOver', 'content-space'),
        description: Translation.get('assetGalleryDialog.assetSelectView.generateAudio', 'content-space')
    },
    {
        type: 'template',
        icon: 'design_services',
        title: Translation.get('templatesDialog.templates.title', 'template-designer'),
        description: Translation.get('templatesDialog.templates.description', 'template-designer')
    },
    {
        type: 'templateLibrary',
        icon: 'photo_library',
        title: Translation.get('selector.templateLibrary.title', 'template-management'),
        description: Translation.get('selector.templateLibrary.description', 'template-management')
    },
    {
        type: 'socialChannelItem',
        icon: 'groups',
        title: Translation.get('selector.socialChannelItems.title', 'template-management'),
        description: Translation.get('selector.socialChannelItems.desc', 'template-management')
    },
    {
        type: 'staticAsset',
        icon: 'upload_2',
        title: Translation.get('selector.staticAssets.title', 'template-management'),
        description: Translation.get('selector.staticAssets.desc', 'template-management')
    },
    {
        type: 'creative',
        icon: 'star_outline',
        title: Translation.get('templatesDialog.creatives.title', 'template-designer'),
        description: Translation.get('templatesDialog.creatives.description', 'template-designer')
    },
    {
        type: 'imageToVideo',
        icon: 'auto_awesome',
        title: Translation.get('selectors.imageToVideo.sidebarTitle', 'asset-gallery-dialog'),
        description: Translation.get('selectors.imageToVideo.sidebarDescription', 'asset-gallery-dialog')
    },
    {
        type: 'frontify',
        icon: 'folder',
        title: Translation.get('selectors.frontify.sidebarTitle', 'asset-gallery-dialog'),
        description: Translation.get('selectors.frontify.sidebarDescription', 'asset-gallery-dialog')
    },
    {
        type: 'campaignAssets',
        icon: 'folder',
        title: Translation.get('selectors.campaignAssets.sidebarTitle', 'asset-gallery-dialog'),
        description: Translation.get('selectors.campaignAssets.sidebarDescription', 'asset-gallery-dialog')
    }
];

export default assetGalleryDialogSidebarSelectors;

/**
 * These are the default selectors that has there own fixed conditions in the Asset Gallery Dialog.
 * Which means that these selectors will always be available in the Asset Gallery Dialog even no selectors are passed in the props of the Asset Gallery Dialog.
 */
export const defaultSelectorTypes: AssetGalleryDialogSelectorType[] = [
    'upload',
    'reuseImage',
    'media',
    'contentSpace',
    'unsplash',
    'url',
    'aprimo',
    'aiContent',
    'voiceOverGenerator',
    'imageToVideo',
    'frontify'
];
