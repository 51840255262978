import React from 'react';
import '../styles/header.scss';
import moment from 'moment';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ListItemButton from '@mui/material/ListItemButton';
import Icon from 'components/ui-components-v2/Icon';
import ListItem from 'components/ui-components-v2/ListItem';
import List from 'components/ui-components-v2/List';
import Button from 'components/ui-components-v2/Button';
import IconDropdown from 'components/ui-components/IconDropdown/components';
import Translation from 'components/data/Translation';
import ToggleButtonGroup from 'components/ui-components-v2/ToggleGroup';
import ToggleButton from 'components/ui-components-v2/ToggleButton';

/**
 * Class CalendarHeader
 * Adds the header interface of the calendar
 * A user can change the active date, the timeframe etc
 */
class CalendarHeader extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            format: this.getFormat(props.timeframe)
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.timeframe !== this.props.timeframe) {
            this.setState({ format: this.getFormat(this.props.timeframe) });
        }
    }

    getFormat = (timeframe) => {
        if (timeframe === 'week') return 'DD MMMM YYYY';
        if (timeframe === 'month') return 'MMMM YYYY';
        if (timeframe === 'quarterly') return 'MMMM YYYY';
        if (timeframe === 'year') return 'YYYY';
    };

    render() {
        const { currentDate, setDateToday, prevTimeRange, nextTimeRange, setTimeframe, downloadImage, downloadExcel, timeframe, onChangeDate } = this.props;
        const { format } = this.state;

        let views = ['day'];

        if (timeframe === 'month' || timeframe === 'quarterly' || timeframe === 'year') views = ['month'];

        return (
            <div className="calendar-header">
                <div className="calendar-header__toolbar">
                    <Button variant="contained" color="primary" onClick={() => setDateToday()}>
                        Today
                    </Button>

                    <Button variant="outlined" color="primary" onClick={() => prevTimeRange()} className="calendar-header__toolbar__arrow-button">
                        <Icon className="calendar-header__toolbar__arrow-icon">arrow_back</Icon>
                    </Button>

                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                            views={views}
                            format={format}
                            slots={{
                                openPickerIcon: () => <Icon>today</Icon>
                            }}
                            value={moment(currentDate)}
                            onChange={(e) => onChangeDate(e)}
                        />
                    </LocalizationProvider>

                    <Button variant="outlined" color="primary" onClick={() => nextTimeRange()} className="calendar-header__toolbar__arrow-button">
                        <Icon className="calendar-header__toolbar__arrow-icon">arrow_forward</Icon>
                    </Button>

                    <div className="calendar-header__toolbar__right">
                        <ToggleButtonGroup
                            className="calendar-header__toolbar__right__timeframes"
                            color="primary"
                            exclusive
                            value={timeframe}
                            onChange={(_event, value) => {
                                if (!value) return;
                                setTimeframe(value);
                            }}>
                            <ToggleButton value="week">Week</ToggleButton>
                            <ToggleButton value="month">Month</ToggleButton>
                            <ToggleButton value="quarterly">Quarterly</ToggleButton>
                            <ToggleButton value="year"> Half Year</ToggleButton>
                        </ToggleButtonGroup>

                        <IconDropdown className="calendar-header__toolbar__dropdown" icon={'more_horiz'}>
                            <List>
                                <ListItem disablePadding>
                                    <ListItemButton onClick={downloadImage}>{Translation.get('calendar.downloadAsImage', 'campaigns')}</ListItemButton>
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemButton onClick={downloadExcel}>{Translation.get('calendar.exportToExcel', 'campaigns')}</ListItemButton>
                                </ListItem>
                            </List>
                        </IconDropdown>
                    </div>
                </div>
            </div>
        );
    }
}

export default CalendarHeader;
