import React, { useState, useMemo } from 'react';
import { FormControlLabel, FormGroup } from '@mui/material';
import Checkbox from 'components/ui-components-v2/Checkbox';
import { CreativeV2FormatHelpers } from 'components/creatives-v2/helpers/formats.helpers';
import { CreativeV2Template } from 'components/creatives-v2/components/creative-editor/types/creativeV2.type';
import cloneDeep from 'helpers/cloneDeep';
import SearchField from 'components/ui-components/SearchField';
import { SearchFieldInputKey } from 'components/ui-components/SearchField/components';
import { TemplateManager } from 'components/creatives-v2/data/template-manager';
import '../styles/main.scss';

interface Props {
    templateIdentifier: CreativeV2Template['data']['templateIdentifier'];
    selectedFormats: string[];
    onChange: (formats: string[]) => void;
}

const FormatSelector = ({ onChange, templateIdentifier, selectedFormats }: Props) => {
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [selectedFilters, setSelectedFilters] = useState<string[]>([]);

    const allFormats = useMemo(() => {
        return CreativeV2FormatHelpers.getFormats(templateIdentifier);
    }, [templateIdentifier]);

    // Filters the formats based on the search query and the selected filters
    const filteredFormats = useMemo(() => {
        return allFormats.filter((format) => {
            if (searchQuery) {
                return format.title.toLowerCase().includes(searchQuery.toLowerCase());
            }

            if (selectedFilters.length) {
                if (!format.sets) return false;
                return format.sets.some((set) => selectedFilters.includes(set));
            }

            return true;
        });
    }, [allFormats, searchQuery, selectedFilters.length]);

    const selectAllChecked = filteredFormats.every((format) => selectedFormats.includes(format.key));

    const isIndeterminate = (() => {
        const checkedCount = filteredFormats.filter((filteredFormat) => selectedFormats.includes(filteredFormat.key)).length;
        return checkedCount > 0 && checkedCount < filteredFormats.length;
    })();

    const isChecked = (format: string) => {
        return selectedFormats.includes(format);
    };

    const onCheck = (format: string) => {
        let newSelectedFormats = cloneDeep(selectedFormats);
        if (isChecked(format)) {
            newSelectedFormats = newSelectedFormats.filter((item) => item !== format);
            onChange(newSelectedFormats);
            return;
        }

        newSelectedFormats = [...newSelectedFormats, format];
        onChange(newSelectedFormats);
    };

    const onSelectAllCheck = () => {
        if (selectAllChecked || isIndeterminate) {
            // uncheck the all selected items that are in the filtered formats
            const newSelectedFormats = selectedFormats.filter((selectedFormat) => !filteredFormats.map((format) => format.key).includes(selectedFormat));
            onChange(newSelectedFormats);
            return;
        }

        // check all the filtered formats
        const newSelectedFormats = filteredFormats.map((item) => item.key);
        onChange(newSelectedFormats);
    };

    // Handles the changes of the searchQuery and the filters
    const handleChange = (_key: SearchFieldInputKey, searchTerm = '', _isSearchCanceled: boolean | undefined, filters?: string[]) => {
        setSearchQuery(searchTerm);

        if (filters) {
            setSelectedFilters(filters);
        }
    };

    // todo helper (creative editor header)
    const getFilters = (): string[] | undefined => {
        const formatSets = TemplateManager.getTemplateByIdentifier(templateIdentifier)?.data?.settings?.formatSets;

        if (!formatSets || !Array.isArray(formatSets)) return;

        return formatSets.map((format) => format.title).filter((set) => set !== 'Unset');
    };

    const getToggleAllLabel = () => {
        let label = filteredFormats.length === selectedFormats.length || isIndeterminate ? 'Deselect all' : 'Select all';
        if (searchQuery || selectedFilters.length) {
            label += ' results';
        }

        return label;
    };

    return (
        <div className="format-selector">
            {filteredFormats.length > 6 ||
                (!!getFilters() && (
                    <div className="format-selector__search">
                        <SearchField onChange={handleChange} filters={getFilters()} />
                    </div>
                ))}
            <FormGroup className="format-selector__container">
                {allFormats.length > 3 && (
                    <FormControlLabel
                        control={<Checkbox checked={selectAllChecked} indeterminate={isIndeterminate} size="small" onChange={onSelectAllCheck} />}
                        label={getToggleAllLabel()}
                    />
                )}
                {filteredFormats.length > 0 &&
                    filteredFormats.map((item, ib) => (
                        <FormControlLabel
                            key={'checkbox-' + ib}
                            control={<Checkbox checked={isChecked(item.key)} onChange={() => onCheck(item.key)} name={item.key} size="small" />}
                            label={item.title}
                        />
                    ))}
            </FormGroup>
        </div>
    );
};

export default FormatSelector;
