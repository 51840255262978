import React, { Component } from 'react';
import WaveSurfer from 'wavesurfer.js';
import Stack from '@mui/material/Stack';
import Slider from 'components/ui-components-v2/Slider';
import Icon from 'components/ui-components-v2/Icon';
import './../styles/audio-player.scss';
import CircularProgress from 'components/ui-components-v2/CircularProgress';

export class AudioPlayer extends Component {
    constructor(props) {
        super(props);

        this.wavesurfer = null;

        this.state = {
            isPlaying: false,
            loading: true
        };
    }

    componentDidMount = () => {
        const { url } = this.props;

        //Create an instance of wavesurfer
        this.wavesurfer = WaveSurfer.create({
            container: '#audio-player__waveform',
            progressColor: 'rgb(41, 113, 235, 0.75)',
            cursorColor: 'rgb(41, 113, 235)',
            waveColor: 'rgb(41, 113, 235, 0.5)',
            fillParent: true,
            plugins: []
        });

        //load the provided url into wavesurfer
        this.wavesurfer.load(url);

        //when wavesurfer is ready
        this.wavesurfer.on('ready', () => {
            // calcute the duration in HMS
            const duration = this.convertHMS(this.wavesurfer.getDuration());
            // get the current volume
            const volume = this.wavesurfer.getVolume() * 100;
            //save data to the state and set loading to false
            this.setState({ duration, timeElapsed: '00:00', volume, loading: false });
        });

        // While audio is playing
        this.wavesurfer.on('audioprocess', () => {
            // Calculate the elapsed time
            const timeElapsed = this.convertHMS(this.wavesurfer.getCurrentTime());
            // Save the elapsed time to the state
            this.setState({ timeElapsed });
        });
    };

    componentDidUpdate = (prevProps, prevState) => {
        // If the component is done loading and the wave form container is available
        if (this.state.loading !== prevState.loading && document.getElementById('audio-player__waveform')) {
            // Draw the waveform in the conatiner
            this.wavesurfer.drawBuffer();
        }
    };

    componentWillUnmount = () => {
        // Unsubscribes from all events
        this.wavesurfer.unAll();
    };

    /**
     * Convert seconds into a Hours, Minutes, Seconds format
     * @param {Number} value the value (in seconds) you want convert to a HMS format
     * @returns a string with the time in HMS format
     */
    convertHMS = (value) => {
        const sec = parseInt(value, 10); // convert value to number if it's string
        let hours = Math.floor(sec / 3600); // get hours
        let minutes = Math.floor((sec - hours * 3600) / 60); // get minutes
        let seconds = sec - hours * 3600 - minutes * 60; //  get seconds
        // add 0 if value < 10; Example: 2 => 02
        if (hours < 10) {
            hours = '0' + hours;
        }
        if (minutes < 10) {
            minutes = '0' + minutes;
        }
        if (seconds < 10) {
            seconds = '0' + seconds;
        }
        return `${hours > 0 ? hours + ':' : ''} ${minutes}:${seconds}`; // Return is HH : MM : SS
    };

    /**
     * Handle play and pause audio
     */
    playPauseAudio = () => {
        const { isPlaying } = this.state;
        // Switch the 'isPlaying' state between true an false
        this.setState({ isPlaying: !isPlaying });
        // Play or pause the audio using wavesurfer
        this.wavesurfer.playPause();
    };

    /**
     * Update the volume
     * @param {*} e the event.
     * @param {*} newVolume the number representation of the desired volume.
     */
    onVolumeChange = (e, newVolume) => {
        // Set the playback volume using wavesurfer
        this.wavesurfer.setVolume(newVolume / 100);
        // Save the new volume to the state (for the volume slider)
        this.setState({ volume: newVolume });
    };

    render() {
        const { isPlaying, duration, timeElapsed, volume, loading } = this.state;

        return (
            <div className="audio-player">
                {!loading && (
                    <div className="audio-player__play-pause-icon">
                        {isPlaying ? (
                            <Icon fontSize="large" color="primary" onClick={this.playPauseAudio}>
                                pause_circle
                            </Icon>
                        ) : (
                            <Icon fontSize="large" color="primary" onClick={this.playPauseAudio}>
                                play_circle
                            </Icon>
                        )}
                    </div>
                )}
                {/* The container always has to be present for wavesurfer to work */}
                <div style={{ display: `${loading ? 'none' : 'block'}` }} id="audio-player__waveform"></div>
                {duration && timeElapsed && volume && !loading && (
                    <div className="audio-player__info">
                        <div className="audio-player__info__duration">{timeElapsed + '/' + duration}</div>
                        <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
                            <Icon fontSize="small">volume_down</Icon>
                            <Slider min={1} aria-label="Volume" value={volume} onChange={this.onVolumeChange} size="small" />
                            <Icon fontSize="small">volume_up</Icon>
                        </Stack>
                    </div>
                )}
                {loading && (
                    <div className="audio-player__loader">
                        <CircularProgress />
                    </div>
                )}
            </div>
        );
    }
}

export default AudioPlayer;
