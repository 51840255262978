// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../images/Youtube-video-top.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "/* Units are deprecated, use the $s* variables instead */\n/* Sizes using rem. This is based on a default 16px */\n/* This is to provide better control and consistency in managing stacking components */\n/* Custom sizes */\n.social-preview__google-ads__demand-gen {\n  width: 320px;\n  line-height: 1.2;\n  overflow: hidden;\n}\n.social-preview__google-ads__demand-gen__bar {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  height: 40px;\n  background-size: 100% 100%;\n}\n.social-preview__google-ads__demand-gen__logo {\n  height: 30px;\n  width: 30px;\n  margin: 8px;\n  background-size: cover;\n  background-position: center center;\n}\n.social-preview__google-ads__demand-gen__headline {\n  color: #030303;\n  font-size: 12px;\n  font-family: Roboto, Arial, sans-serif;\n  font-weight: bold;\n}\n.social-preview__google-ads__demand-gen__description {\n  font-size: 12px;\n  font-family: Roboto, Arial, sans-serif;\n  color: rgba(0, 0, 0, 0.57);\n}\n.social-preview__google-ads__demand-gen__cta {\n  margin: 8px 0;\n  background: #065fd4;\n  border-radius: 2px;\n  font-size: 13px;\n  color: #fff;\n  display: inline-flex;\n  justify-content: center;\n  padding: 5px 10px;\n  text-transform: uppercase;\n  align-items: center;\n  max-width: 223px;\n  width: 100%;\n}\n.social-preview__google-ads__demand-gen__sponsored {\n  font-family: Roboto, Helvetica, sans-serif;\n  font-size: 12px;\n  line-height: 14px;\n  font-weight: 500;\n  letter-spacing: 0.3px;\n  color: #202124;\n  align-items: center;\n  display: flex;\n  justify-content: center;\n}\n.social-preview__google-ads__demand-gen__context {\n  display: flex;\n}\n.social-preview__google-ads__demand-gen__top {\n  padding: 8px;\n  padding-left: 0px;\n}", ""]);
// Exports
module.exports = exports;
