import React, { useState } from 'react';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { AssetV2 } from 'types/asset.type';
import Icon from 'components/ui-components-v2/Icon';
import MenuItem from 'components/ui-components-v2/MenuItem';
import Translation from 'components/data/Translation';
import AssetManagementService from 'components/asset-management/services/asset-management.service';
import { useAMData, useAMFetch, useAMAssetSelection } from 'components/asset-management/hooks';
import ConfirmDialog from 'components/ui-components/ConfirmDialog';
import { useAssetManagementConfigContext } from 'components/asset-management/context/asset-management-config.context';

interface Props {
    asset: AssetV2<unknown, unknown>;
    onClose: () => void;
    onSetWaiting: (waiting: boolean) => void;
}

const AssetManagementAssetMenuItemsSetToDraft = ({ asset, onClose, onSetWaiting }: Props) => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const { userHasRight, versioning, languageNameSpace } = useAssetManagementConfigContext();
    const { setSelectedAssets } = useAMAssetSelection();
    const { refreshLists } = useAMFetch();
    const { data } = useAMData<{ selectedAssetIds: string[] }>({
        selectedAssetIds: 'state.selectedAssetIds'
    });
    const { selectedAssetIds } = data;

    const setToDraft = async (asset: AssetV2<unknown, unknown>) => {
        onSetWaiting(true);
        const newAsset: AssetV2<unknown, unknown> = { ...asset, status: 'draft' };
        const response = await AssetManagementService.patchAsset(newAsset, { updateOnlyMetadata: true });
        if (response) {
            setSelectedAssets(selectedAssetIds.filter((id: string) => id !== asset._id));
            await refreshLists('available');
            await refreshLists('draft');
            onSetWaiting(false);
        }
        onClose();
    };

    if (!userHasRight('management')) return null;
    if (asset.status !== 'available') return null;

    return (
        <React.Fragment>
            <MenuItem
                onClick={(e) => {
                    e.stopPropagation();
                    setDialogOpen(true);
                }}>
                <ListItemIcon>
                    <Icon>edit_note</Icon>
                </ListItemIcon>
                <ListItemText>{Translation.get('actions.setToDraft', 'asset-management')}</ListItemText>
            </MenuItem>
            <ConfirmDialog
                open={dialogOpen}
                title={Translation.get('titles.sure', 'common')}
                description={versioning ? Translation.get('dialogs.retract', languageNameSpace) : Translation.get('tooltips.statusDraft', languageNameSpace)}
                onClose={(e) => {
                    e.stopPropagation();
                    setDialogOpen(false);
                    onClose();
                }}
                onConfirm={(e) => {
                    e.stopPropagation();
                    setToDraft(asset);
                }}
            />
        </React.Fragment>
    );
};

export default AssetManagementAssetMenuItemsSetToDraft;
