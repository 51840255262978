import React from 'react';
import { InputAttribute } from 'components/template-designer/components/dynamic-layers/types/attribute.type';
import Translation from 'components/data/Translation';
import { NumberSettings, SliderSettings } from 'components/template-designer/types/dynamicLayer.type';
import Layer from 'components/template-designer/types/layer.type';
import { LayerPropertiesHelpers } from 'components/template-designer/helpers/layer-properties.helpers';
import { NumberSettingsForm } from '../forms/number-settings-form';
import { baseSettings } from './base-settings';

const positionX: InputAttribute<SliderSettings | NumberSettings> = {
    label: Translation.get('dynamicLayers.labels.positionX', 'template-designer'),
    description: Translation.get('dynamicLayers.descriptions.positionX', 'template-designer'),
    disabledDescription: Translation.get('dynamicLayers.disabledDescriptions.position', 'template-designer'),
    attribute: 'positionX',
    defaultSetting: 'slider',
    settings: {
        slider: {
            ...baseSettings,
            type: 'slider',
            min: -100,
            max: 100,
            defaultValue: 0,
            step: 1,
            inputFieldDisplay: true,
            hideMarks: true,
            marks: false,
            endAdornment: 'px',
            allowZero: true
        },
        number: {
            ...baseSettings,
            type: 'number',
            placeholder: Translation.get('dynamicLayers.labels.positionX', 'template-designer'),
            min: -100,
            max: 100,
            step: 1
        }
    },
    editForm: () => (
        <>
            <NumberSettingsForm unit="px" allowNegative />
        </>
    ),
    isDisabled: (layer: Layer): boolean => {
        const draggable = LayerPropertiesHelpers.getLayerProperty(layer.key, 'canEdit.draggable');
        const draggableX = LayerPropertiesHelpers.getLayerProperty(layer.key, 'canEdit.draggableX');
        const draggableY = LayerPropertiesHelpers.getLayerProperty(layer.key, 'canEdit.draggableY');

        return (
            Object.keys(draggable).some((format) => draggable[format] === true) ||
            Object.keys(draggableX).some((format) => draggableX[format] === true) ||
            Object.keys(draggableY).some((format) => draggableY[format] === true)
        );
    }
};

export { positionX };
