import React from 'react';
import GridWrapper from 'components/ui-components/GridWrapper';
import { SelectorStaticAsset } from 'components/asset-management/components/selector/types/selector-asset.type';
import { StaticAsset, StaticAssetType } from 'components/template-management/types/template-management.type';
import { STATIC_ASSETS } from 'components/template-management/constants';
import { convertStaticV1ToStaticV2 } from 'components/template-management/utilities';
import { TemplateManagementSelectorStaticAssetTile } from '../';

import '../styles/static-assets.scss';

interface Props {
    staticAssetType: StaticAssetType[];
    onSelectItem: (item: StaticAsset) => void;
}

const TemplateManagementSelectorStaticAssets = ({ staticAssetType, onSelectItem }: Props) => {
    const filteredTypes = STATIC_ASSETS.filter((item) => staticAssetType.includes(item.identifier as StaticAssetType));

    const selectorItems: SelectorStaticAsset[] = filteredTypes.map((item) => ({
        id: item.id,
        title: item.title,
        subtitle: item.description,
        originalRecord: convertStaticV1ToStaticV2(item)
    }));
    return (
        <div className="template-management-selector-static-assets">
            <GridWrapper targetSize={220}>
                {selectorItems.map((item) => (
                    <TemplateManagementSelectorStaticAssetTile key={item.id} item={item} onSelect={() => onSelectItem(item.originalRecord)} />
                ))}
            </GridWrapper>
        </div>
    );
};

export default TemplateManagementSelectorStaticAssets;
