import React, { useState } from 'react';
import FormControl from '@mui/material/FormControl';
import { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from 'components/ui-components-v2/MenuItem';
import Translation from 'components/data/Translation';
import Tooltip from 'components/ui-components-v2/Tooltip';
import Icon from 'components/ui-components-v2/Icon';
import Select from 'components/ui-components-v2/Select';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import Button from 'components/ui-components-v2/Button';
import ComponentStore from 'components/data/ComponentStore';
import ToggleButtonGroup from 'components/ui-components-v2/ToggleGroup';
import ToggleButton from 'components/ui-components-v2/ToggleButton';
import { CreativeViewType, ICreativeOverview } from '../types/creative-overview.type';
import { CreativeEditorV2 } from '../../creative-editor';
import { CreativeV2Template } from '../../creative-editor/types/creativeV2.type';
import '../styles/zoom-bar.scss';

interface Props {
    availableViews?: CreativeViewType[];
    showEditCreativeButton?: boolean;
    onSaveCreative?: (creative: CreativeV2Template) => void;
}

interface ComponentStoreProps {
    view: ICreativeOverview['view'];
    canvasZoom: ICreativeOverview['canvasZoom'];
    creatives: ICreativeOverview['creatives'];
}

const CreativeOverviewZoomBar: React.FC<Props> = ({ availableViews = [], showEditCreativeButton, onSaveCreative }) => {
    const [creativeEditorOpen, setCreativeEditorOpen] = useState(false);
    const { view, canvasZoom, creatives } = useComponentStore<ComponentStoreProps>('CreativeOverview', {
        fields: {
            view: 'view',
            canvasZoom: 'canvasZoom',
            creatives: 'creatives'
        }
    });

    const handleChange = (event: SelectChangeEvent<unknown>) => {
        ComponentStore.setModel('CreativeOverview', 'canvasZoom', Number(event.target.value));
    };

    const handleChangeView = (view: CreativeViewType) => {
        ComponentStore.setModel('CreativeOverview', 'view', view);
    };

    const defaultZoomLevels = [
        { label: '100%', value: 1 },
        { label: '75%', value: 0.75 },
        { label: '50%', value: 0.5 },
        { label: '25%', value: 0.25 }
    ];

    // Get the zoom levels from the template. If the canvas is zoomed in, add the current zoom level to the list
    const zoomLevels = (() => {
        if (canvasZoom && defaultZoomLevels.map((zoomLevel) => zoomLevel.value).indexOf(Number(canvasZoom)) === -1) {
            return [...defaultZoomLevels, { label: `${Math.round(canvasZoom * 100)}%`, value: canvasZoom }].sort((a, b) => a.value - b.value);
        } else {
            return defaultZoomLevels.sort((a, b) => a.value - b.value);
        }
    })();

    const getButtons = () => {
        // Only return the canvasFixed button if the creative has no active formats
        if (creatives?.length && !creatives[0]?.data?.settings?.activeFormats?.length) {
            return [
                {
                    value: 'canvasFixed',
                    icon: <Icon>view_quilt</Icon>
                }
            ];
        }

        const allButtons = [
            {
                value: 'canvasFixed',
                icon: <Icon>view_quilt</Icon>,
                tooltip: Translation.get('canvasFixed', 'bricks')
            },
            {
                value: 'canvasFree',
                icon: <Icon>pinch</Icon>,
                tooltip: Translation.get('canvasFree', 'bricks')
            },
            {
                value: 'single',
                icon: <Icon>list</Icon>,
                tooltip: Translation.get('canvasSingle', 'bricks')
            }
        ];

        return allButtons.filter((button) => availableViews.includes(button.value as CreativeViewType));
    };

    return (
        <div className="creative-overview-zoom-bar">
            <div className="creative-overview-zoom-bar__canvas-free-mode">
                <ToggleButtonGroup
                    exclusive
                    color="primary"
                    value={view}
                    onChange={(_event, value) => {
                        if (!value) return;
                        handleChangeView(value as CreativeViewType);
                    }}>
                    {getButtons().map((button) => (
                        <Tooltip key={button.value} title={button.tooltip} placement="top" enterDelay={400}>
                            <ToggleButton value={button.value}>{button.icon}</ToggleButton>
                        </Tooltip>
                    ))}
                </ToggleButtonGroup>
            </div>
            <div className="creative-overview-zoom-bar__right">
                {(view === 'canvasFree' || view === 'single') && (
                    <FormControl sx={{ minWidth: 65 }}>
                        <Select
                            labelId="zoom-select-label"
                            id="zoom-select"
                            variant="standard"
                            value={String(canvasZoom)}
                            disableUnderline={true}
                            label="Zoom"
                            onChange={handleChange}>
                            {zoomLevels.map((zoomLevel) => (
                                <MenuItem key={zoomLevel.value} value={String(zoomLevel.value)}>
                                    {zoomLevel.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )}
                {showEditCreativeButton && (
                    <Button onClick={() => setCreativeEditorOpen(true)} variant="contained">
                        {Translation.get('editCreative', 'bricks')}
                    </Button>
                )}
            </div>

            {!!creatives?.length && creativeEditorOpen && (
                <CreativeEditorV2
                    creativeV2Template={creatives[0] as CreativeV2Template}
                    onClose={() => setCreativeEditorOpen(false)}
                    onSaveCreative={onSaveCreative}
                />
            )}
        </div>
    );
};

export default CreativeOverviewZoomBar;
