import React, { useState } from 'react';
import axios from 'axios';
import Icon from 'components/ui-components-v2/Icon';
import { cloneDeep } from 'lodash';
import Translation from 'components/data/Translation';
import Button from 'components/ui-components-v2/Button';
import AudioTrimmer from 'components/assets/AudioTrimmer';
import User from 'components/data/User';

import '../../../../styles/asset-cropper-view.scss';

interface Region {
    id: string;
    start: number;
    end: number;
    transcript: string;
}

export interface Audio {
    fileType: string;
    text: string;
    title: string;
    url: string;
    voiceId: string;
    originalUrl: string;
    selectedRegion: Region;
}

interface Props {
    audio: Audio;
    onSkipTrimmer: () => void;
    goBack: () => void;
    canSkipTrimmer: boolean | undefined;
    maximumDuration: number | undefined;
    minimumDuration: number | undefined;
    duration: string | number | undefined;
    onMutation: (data: Audio) => void;
}

/**
 * Image cropper view
 * This displays the cropped component with a specific image in it.
 */
const AssetAudioTrimmerView: React.FC<Props> = ({ audio, onSkipTrimmer, goBack, canSkipTrimmer, maximumDuration, minimumDuration, duration, onMutation }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [selectedAudio, setSelectedAudio] = useState(audio);
    const [timeData, setTimeData] = useState({
        timeElapsed: '',
        totalDuration: ''
    });

    /**
     * send segment data to backend for cutting
     */
    const cutSelectedAudio = () => {
        const { selectedRegion, originalUrl } = selectedAudio;
        const selectedAudioClone = cloneDeep(selectedAudio);
        const token = User.get('mediaServicesApiToken');

        setIsLoading(true);

        const requestData = {
            url: originalUrl,
            segments: [
                {
                    id: selectedRegion.id,
                    startTime: selectedRegion.start,
                    endTime: selectedRegion.end,
                    labelText: selectedRegion.transcript ? selectedRegion.transcript : ''
                }
            ]
        };

        axios
            .post(process.env.APP_MEDIA_URL + 'media/audiocutsegments', requestData, {
                headers: { Authorization: `Bearer ${token}` }
            })
            .then((res) => {
                selectedAudioClone['isTrimmed'] = true;
                selectedAudioClone.url = res.data[0].url;
                setSelectedAudio(selectedAudioClone);
                onMutation(selectedAudioClone);
            });
    };

    /**
     * Update the selected audio
     * @param {Object} selectedAudio the updated audio
     */
    const onUpdateSelectedAudio = (selectedAudio: Audio) => {
        setSelectedAudio(selectedAudio);
    };

    return (
        <div className="asset-cropper-view">
            <div className="asset-cropper-view__header audio">
                <div className="asset-cropper-view__header--action button">
                    <Button color="primary" onClick={goBack}>
                        <Icon style={{ marginRight: 8 }}>arrow_back</Icon>
                        {Translation.get('assetGalleryDialog.assetAudioTrimmer.back', 'content-space')}
                    </Button>
                </div>
                <div>{timeData.timeElapsed + '/' + timeData.totalDuration}</div>
            </div>
            <div className="asset-cropper-view__content">
                <div className="asset-cropper-view__children">
                    <AudioTrimmer
                        selectedAudio={audio}
                        minimumDuration={minimumDuration}
                        maximumDuration={maximumDuration}
                        duration={duration}
                        setTimeData={setTimeData}
                        timeData={timeData}
                        onMutation={onUpdateSelectedAudio}
                    />
                </div>
            </div>
            <div className="asset-cropper-view__footer" style={{ justifyContent: 'end' }}>
                <div className="asset-cropper-view__actions">
                    {canSkipTrimmer && (
                        <Button onClick={onSkipTrimmer} variant="outlined" color="primary" style={{ marginRight: 12 }} data-cy="assetEditor-skip-button">
                            {Translation.get('assetGalleryDialog.assetAudioTrimmer.skip', 'content-space')}
                        </Button>
                    )}
                    <Button variant="contained" color="primary" onClick={cutSelectedAudio} disabled={isLoading}>
                        {isLoading
                            ? Translation.get('assetGalleryDialog.assetAudioTrimmer.processing', 'content-space')
                            : Translation.get('assetGalleryDialog.assetAudioTrimmer.trimAndSave', 'content-space')}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default AssetAudioTrimmerView;
