import output from 'components/bricks/components/shared/components/output-tab';
import briefing from 'components/bricks/components/shared/components/briefing-content/components';

import settings from './settings';
import setup from './setup';
import defaultData from './deafult-data';
import validators from './validators';
import creatives from './creatives';

export default { settings, setup, output, briefing, defaultData, validators, creatives };
