import { generateKey } from 'components/template-designer/utils/generateKey';
import Translation from 'components/data/Translation';
import { PredefinedPositionAnimation } from 'components/template-designer/types/animation.type';
import { PredefinedAnimationSetup } from '.';

const defaultValue: { value: PredefinedPositionAnimation['value']; easing: PredefinedPositionAnimation['easing'] } = {
    value: {
        position: {
            y: { value: 100, unit: 'px' },
            direction: 'down',
            directionDegrees: 0
        }
    },
    easing: {
        type: 'ease-out',
        value: 'ease-out'
    }
};

const bungee: PredefinedAnimationSetup = {
    label: Translation.get('animation.predefinedAnimations.animations.bungee', 'template-designer'),
    key: 'bungee',
    duration: 1.5,
    defaultValue: defaultValue,
    reset: { fadeIn: false, fadeOut: true },
    stamps: {
        position: [
            {
                value: {
                    y: {
                        value: -100,
                        unit: '%'
                    }
                },
                stamp: 0.1,
                id: generateKey(),
                easing: {
                    type: 'ease-out',
                    value: 'ease-out'
                }
            },
            {
                value: {
                    y: {
                        value: 0,
                        unit: '%'
                    }
                },
                stamp: 0.45,
                id: generateKey(),
                easing: {
                    type: 'ease-out',
                    value: 'ease-out'
                }
            },
            {
                value: {
                    y: {
                        value: -20,
                        unit: '%'
                    }
                },
                stamp: 0.55,
                id: generateKey(),
                easing: {
                    type: 'ease-out',
                    value: 'ease-out'
                }
            },
            {
                value: {
                    y: {
                        value: 0,
                        unit: '%'
                    }
                },
                stamp: 0.65,
                id: generateKey(),
                easing: {
                    type: 'ease-out',
                    value: 'ease-out'
                }
            },
            {
                value: {
                    y: {
                        value: -6,
                        unit: '%'
                    }
                },
                stamp: 0.75,
                id: generateKey(),
                easing: {
                    type: 'ease-out',
                    value: 'ease-out'
                }
            },
            {
                value: {
                    y: {
                        value: 0,
                        unit: '%'
                    }
                },
                stamp: 0.85,
                id: generateKey(),
                easing: {
                    type: 'ease-out',
                    value: 'ease-out'
                }
            }
        ],
        size: [],
        scale: [],
        rotation: [],
        opacity: [
            {
                value: 0,
                stamp: 0,
                id: generateKey(),
                easing: {
                    type: 'ease-out',
                    value: 'ease-out'
                }
            },
            {
                value: 1,
                stamp: 0.35,
                id: generateKey(),
                easing: {
                    type: 'ease-out',
                    value: 'ease-out'
                }
            }
        ],
        backgroundColor: [],
        color: [],
        shadow: [],
        rotationX: [],
        rotationY: []
    }
};

export default bungee;
