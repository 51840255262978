import { IEditorBlock } from 'components/bricks/types/editorBlock.type';

const settings: IEditorBlock[] = [
    {
        title: 'General',
        itemType: 'block',
        type: 'multiInput',
        opened: true,
        items: [
            {
                type: 'text',
                title: 'Block title',
                identifier: 'peachconnect-title',
                model: 'title',
                itemType: 'input',
                label: 'Title',
                defaultValue: 'PeachConnect campaign',
                maxLength: 150,
                dynamicValueActive: true,
                fixedModel: true,
                useValueObject: false
            }
        ]
    },
    {
        title: 'General',
        itemType: 'block',
        type: 'multiInput',
        opened: true,
        items: [
            {
                type: 'text',
                identifier: 'peachconnect_campaign-advertiser',
                model: 'advertiser',
                itemType: 'input',
                label: 'Advertiser',
                maxLength: 150,
                useValueObject: false
            },
            {
                type: 'text',
                identifier: 'peachconnect_campaign-reference',
                model: 'reference',
                itemType: 'input',
                label: 'Reference',
                maxLength: 150,
                useValueObject: false
            },
            {
                type: 'text',
                identifier: 'peachconnect_campaign-brand',
                model: 'brand',
                itemType: 'input',
                label: 'Brand',
                maxLength: 150,
                useValueObject: false
            }
        ]
    }
];

export default settings;
