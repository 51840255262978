import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/ui-components-v2/Icon';
import LinearProgress from 'components/ui-components-v2/LinearProgress';
import DynamicAsset from 'components/assets/DynamicAsset';
import TemplateHelpers from 'components/data/TemplateHelpers';
import iconArrowUp from '../images/icon_arrow_up.svg';
import iconClose from '../images/icon_close.png';
import iconLike from '../images/icon_facebook_like.svg';
import iconHeart from '../images/icon_facebook_heart.svg';
import { checkMultiLanguageVideo, getAssetsWithTimeline } from '../../helpers';

class FacebookStoriesAd extends React.Component {
    static propTypes = {
        adType: PropTypes.string,
        advertiser: PropTypes.shape({
            logo: PropTypes.string,
            name: PropTypes.string
        }),
        frames: PropTypes.oneOfType([
            PropTypes.arrayOf(
                PropTypes.shape({
                    name: PropTypes.string,
                    link: PropTypes.string,
                    cta: PropTypes.string,
                    asset: PropTypes.shape({
                        type: PropTypes.string,
                        url: PropTypes.string
                    })
                })
            ),
            PropTypes.objectOf(
                PropTypes.shape({
                    name: PropTypes.string,
                    link: PropTypes.string,
                    cta: PropTypes.string,
                    asset: PropTypes.shape({
                        type: PropTypes.string,
                        url: PropTypes.string
                    })
                })
            )
        ])
    };

    static defaultProps = {
        frames: [{}]
    };

    constructor(props) {
        super(props);

        this.facebookStoriesRef = React.createRef(null);
        this.state = {
            carouselIndex: 0
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.frameNr !== this.props.frameNr) {
            if (this.props.frameNr > 0) {
                this.swipeOnFrameNr(this.props.frameNr);
            }
        }
    }

    componentDidMount() {
        if (this.facebookStoriesRef.current) {
            this.swipeOnFrameNr(this.props.frameNr);
        }
    }

    swipe = (direction) => {
        const element = this.facebookStoriesRef.current;
        const distance = 320;
        if (direction === 'right') {
            element.scrollLeft += distance;
        } else {
            if (element.scrollLeft % distance === 0) {
                element.scrollLeft += -distance;
            } else {
                element.scrollLeft += -(element.scrollLeft % distance);
            }
        }

        setTimeout(() => {
            const index = element.scrollLeft / distance;

            this.setState({
                carouselIndex: Math.round(index)
            });
        }, 340);
    };

    swipeOnFrameNr = (frameNr) => {
        const element = this.facebookStoriesRef.current;
        const distance = 320;
        this.setState({ carouselIndex: frameNr - 1 }, () => (element.scrollLeft = (frameNr - 1) * distance));
    };

    getAsset = (index, asset, image, video) => {
        const { assetComponents } = this.props;

        // existing prop
        if (assetComponents) {
            // Dynamic asset
            if (assetComponents[index] && assetComponents[index].length > 1) {
                for (let i = 0; i < assetComponents[index].length; i++) {
                    const formatResult = TemplateHelpers.getFormatDimension(
                        assetComponents[index][i].props.data,
                        assetComponents[index][i].props.originalWidth,
                        assetComponents[index][i].props.originalHeight
                    );
                    if (formatResult === '9x16') {
                        return assetComponents[index][i];
                    }
                }
            }
            // Single format
            else if (assetComponents[index] && assetComponents[index][0]) {
                return assetComponents[index][0];
            }
            // Bricks gives the direct asset
            else if (assetComponents[index]) {
                return assetComponents[index];
            }

            return <React.Fragment />;
        }
        // Create in component
        else {
            return (
                <DynamicAsset
                    {...asset}
                    image={image}
                    video={video}
                    frameWidth={asset && asset.frameWidth ? asset.frameWidth : 1080}
                    frameHeight={asset && asset.frameHeight ? asset.frameHeight : 1920}
                    width={320}
                />
            );
        }
    };

    render() {
        const { advertiser, facebook_cta, cta, frames = [], adType, frameCount = 0, language, items, assetComponents } = this.props;
        const { carouselIndex } = this.state;
        let framesRestructured = [];

        // Framecount based items
        if (frameCount > 0) {
            for (let i = 1; i <= frameCount; i++) {
                framesRestructured.push(frames['frame' + i] ? frames['frame' + i] : {});
            }
        }
        // Support object based (
        else if (items && items.frame1) {
            framesRestructured = Object.values(items);
        }
        // Support array based (items)
        else if (Array.isArray(items)) {
            framesRestructured = items;
        }
        // Support object based
        else if (frames && frames.frame1) {
            framesRestructured = Object.values(frames);
        }
        // Support array based
        else if (Array.isArray(frames)) {
            framesRestructured = frames;
        }

        framesRestructured = checkMultiLanguageVideo(framesRestructured, language);

        // Also accept the old structure
        framesRestructured = framesRestructured.map((item) => {
            let link = item.link;

            if (!item.link) {
                if (item.websiteUrl) {
                    link = item.websiteUrl;
                } else {
                    link = item.displayUrl;
                }
            }

            return {
                ...item,
                description: item.description ? item.description : item.linkDescription,
                link,
                name: item.name ? item.name : item.headline
            };
        });

        const assetsWithTimeline = getAssetsWithTimeline(assetComponents);
        const hasBottomSpacing = assetsWithTimeline[carouselIndex];

        const width = 100 / framesRestructured.length + '%';

        return (
            <div className={`stories-ad ${hasBottomSpacing ? 'stories-ad--bottom-spacing' : ''}`}>
                <div className="stories-ad__carousel" ref={this.facebookStoriesRef}>
                    {framesRestructured.map(({ link, asset, image, video }, index) => (
                        <div
                            className={`stories-ad__carousel__item ${hasBottomSpacing ? 'stories-ad__carousel__item--bottom-spacing' : ''}`}
                            key={`facebook_stories_carousel_${index}`}>
                            <div className="stories-ad__gradient-top"></div>
                            <div className="stories-ad__carousel__item__asset-wrapper">
                                <div className="stories-ad__carousel__item__asset-center">
                                    {React.cloneElement(this.getAsset(index, asset, image, video), { width: 320 })}
                                </div>
                            </div>

                            {cta && cta !== '' && cta !== 'NO_BUTTON' && facebook_cta[cta] && adType !== 'storiesPost' && (
                                <div className="stories-ad__carousel__item__swipeup">
                                    <a href={link} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                                        <img className="stories-ad__carousel__item__icon-arrow-up" src={iconArrowUp} />
                                        <div className="stories-ad__carousel__item__cta">{facebook_cta[cta]}</div>
                                    </a>
                                </div>
                            )}

                            {adType === 'storiesPost' && (
                                <>
                                    <div className="stories-ad__carousel__item__send-chat">Reply</div>
                                    <img className="stories-ad__carousel__item__icon-heart" src={iconHeart} />
                                    <img className="stories-ad__carousel__item__icon-like" src={iconLike} />
                                </>
                            )}
                        </div>
                    ))}
                </div>

                {carouselIndex !== 0 && framesRestructured.length > 1 && (
                    <div className="stories-ad__arrow-left" onClick={() => this.swipe('left')}>
                        <Icon fontSize="large" className="stories-ad__arrow-icon">
                            chevron_left
                        </Icon>
                    </div>
                )}

                {carouselIndex !== framesRestructured.length - 1 && framesRestructured.length > 1 && (
                    <div className="stories-ad__arrow-right" onClick={() => this.swipe('right')}>
                        <Icon fontSize="large" className="stories-ad__arrow-icon">
                            chevron_right
                        </Icon>
                    </div>
                )}

                <div className="stories-ad__progress-wrapper">
                    <div style={{ width: '100%' }}>
                        {framesRestructured.map((_, index) => {
                            if (index < carouselIndex) {
                                return (
                                    <div key={`facebook_stories_progressbar_${index}`} className="stories-ad__progress-wrapper__progressbar" style={{ width }}>
                                        <LinearProgress
                                            variant="determinate"
                                            value={100}
                                            classes={{
                                                bar: 'stories-ad__progress-wrapper__progressbar__bar',
                                                colorPrimary: 'stories-ad__progress-wrapper__progressbar__color-primary',
                                                barColorPrimary: 'stories-ad__progress-wrapper__progressbar__bar-color-primary'
                                            }}
                                        />
                                    </div>
                                );
                            } else if (index === carouselIndex) {
                                return (
                                    <div key={`facebook_stories_progressbar_${index}`} className="stories-ad__progress-wrapper__progressbar" style={{ width }}>
                                        <LinearProgress
                                            variant="determinate"
                                            value={50}
                                            classes={{
                                                bar: 'stories-ad__progress-wrapper__progressbar__bar',
                                                colorPrimary: 'stories-ad__progress-wrapper__progressbar__color-primary',
                                                barColorPrimary: 'stories-ad__progress-wrapper__progressbar__bar-color-primary'
                                            }}
                                        />
                                    </div>
                                );
                            } else {
                                return (
                                    <div key={`facebook_stories_progressbar_${index}`} className="stories-ad__progress-wrapper__progressbar" style={{ width }}>
                                        <LinearProgress
                                            variant="determinate"
                                            value={0}
                                            classes={{
                                                bar: 'stories-ad__progress-wrapper__progressbar__bar',
                                                colorPrimary: 'stories-ad__progress-wrapper__progressbar__color-primary',
                                                barColorPrimary: 'stories-ad__progress-wrapper__progressbar__bar-color-primary'
                                            }}
                                        />
                                    </div>
                                );
                            }
                        })}
                    </div>
                </div>

                <div className="stories-ad__advertiser">
                    <div className="stories-ad__advertiser__logo" style={{ backgroundImage: `url(${advertiser.logo})` }} />

                    <div className="stories-ad__advertiser__text">
                        {adType === 'storiesAd' && (
                            <div>
                                <div className="stories-ad__advertiser__name">{advertiser.name}</div>
                                <div className="stories-ad__advertiser__sponsored">Sponsored</div>
                            </div>
                        )}
                        {adType === 'storiesPost' && <div className="stories-ad__advertiser__name-post">{advertiser.name}</div>}
                    </div>

                    <div className="stories-ad__advertiser__close">
                        <img className="stories-ad__advertiser__icon-close" src={iconClose} alt={'icon-close'} />
                    </div>
                </div>
            </div>
        );
    }
}

export default FacebookStoriesAd;
