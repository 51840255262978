import React from 'react';
import FeedHelpers from 'components/data/FeedManagementHelpers';
import FeedForm from 'components/feed-management/FeedForm';
import Button from 'components/ui-components-v2/Button';
import Translation from 'components/data/Translation';
import DataSetManagerEmpty from './empty';

/**
 * Show different kind of empry states if the item list is empty.
 * @param {*} param0
 * @returns
 */
const DataSetManagerNoItems = ({ dataSet = {}, list, onHandleOpenManualRecordDialog }) => {
    if (list.length > 0) {
        // The list is not empty, don't show an empty state.
        return null;
    } else if (dataSet.metadata && dataSet.metadata.itemCount > 0) {
        // An empty list for a data set wich has rows, means a serch has been done with no results.
        return <DataSetManagerEmpty placeholder={true} title={Translation.get('dataSet.noItems.noResults', 'feed-management')} />;
    } else if (dataSet.latestUpdate && dataSet.latestUpdate.isUpdating) {
        // For an empty list that is importing, show the spinning placeholder.
        return <DataSetManagerEmpty spinner title={Translation.get('feedback.processing.processing', 'common')} />;
    } else if (dataSet.customData && dataSet.customData.isManual) {
        // An empty list for a manual data set means we have to add a record.
        return (
            <DataSetManagerEmpty placeholder={true} title={Translation.get('dataSet.noItems.noManualRecords', 'feed-management')}>
                <Button variant="contained" color="primary" onClick={() => onHandleOpenManualRecordDialog()}>
                    {Translation.get('dataSet.noItems.addManualRecords', 'feed-management')}
                </Button>
            </DataSetManagerEmpty>
        );
    } else if (dataSet.feeds && dataSet.feeds.length === 0) {
        // For a data set that is not manual and has no feeds, we can start from scratch. Add a manual record or a feed.
        return (
            <DataSetManagerEmpty placeholder={true} title={Translation.get('dataSet.noFeedConnected', 'feed-management')}>
                <React.Fragment>
                    <FeedForm
                        dataSetId={dataSet._id}
                        structure={dataSet.structure ? Object.keys(dataSet.structure) : []}
                        callback={() => FeedHelpers.reloadDataSet(dataSet._id)}>
                        <Button variant="contained" color="primary" data-cy="feedManagement-emptyAddFeed-button">
                            {Translation.get('dataSet.toolbar.addFeed', 'feed-management')}
                        </Button>
                    </FeedForm>
                    <Button
                        variant="outlined"
                        color="primary"
                        style={{ marginLeft: 16 }}
                        onClick={() => onHandleOpenManualRecordDialog()}
                        data-cy="feedManagement-emptyAddManualRecord-button">
                        {Translation.get('dataSet.noItems.addManualRecords', 'feed-management')}
                    </Button>
                </React.Fragment>
            </DataSetManagerEmpty>
        );
    } else return <DataSetManagerEmpty placeholder={true} title={Translation.get('dataSet.noItems.noItemsFound', 'feed-management')} />;
};

export default DataSetManagerNoItems;
