import React, { useEffect, useMemo } from 'react';
import { OnChangeFn, RowSelectionState, ExpandedState, TableOptions, getCoreRowModel, getExpandedRowModel, Row } from '@tanstack/react-table';
import ComponentStore from 'components/data/ComponentStore';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import { BricksObject } from 'components/bricks/types/bricksComponentStore.type';
import { Brick, BrickTree } from 'components/bricks/types/brick.type';
import BrickExpandedRowsHelper from 'components/bricks/helpers/brick-expanded-rows.helper';
import TreeHelpers from 'components/bricks/helpers/tree.helpers';
import ValidateHelpers from 'components/bricks/helpers/validate.helpers';
import TreeViewList from '../../../../../shared/components/tree-view-list';
import outputDialogColumnsSetup from './data/column-setup';
import OutputTreeViewItem from './components/item';
import OutputTreeViewHelper from './helpers/output-tree-view.helper';

import './styles/main.scss';

interface Props {
    bricks: BricksObject;
}

interface ComponentStoreProps {
    previewItemId?: string;
    checkedBricks?: RowSelectionState;
    expandedBricks?: ExpandedState;
}

/**
 * This component is responsible for rendering the tree view list of publishable bricks.
 */
const OutputTreeView: React.FC<Props> = ({ bricks }) => {
    const bricksArray: Brick[] = useMemo(() => Object.values(bricks), [bricks]);
    const defaultExpandedBricks = useMemo(() => BrickExpandedRowsHelper.expandedAllBricks(bricksArray), [bricksArray]);
    const defaultCheckedBricks = useMemo(() => OutputTreeViewHelper.getDefaultCheckedBricks(bricksArray), [bricksArray]);

    const { expandedBricks = defaultExpandedBricks, checkedBricks = defaultCheckedBricks } = useComponentStore<ComponentStoreProps>('Bricks', {
        fields: { checkedBricks: 'publishDialog.checkedBricks', expandedBricks: 'publishDialog.expandedBricks' }
    });

    const brickTree = useMemo(() => TreeHelpers.createTreeV2(bricks), [bricks]);

    useEffect(() => {
        ComponentStore.setModel('Bricks', 'publishDialog.checkedBricks', defaultCheckedBricks); // Selected all the bricks that doesn't have validation errors
        bricksArray.length > 0 && ComponentStore.setModel('Bricks', 'publishDialog.previewItemId', bricksArray[0].id); // Set the first brick as the preview item
    }, []);

    const handleSelectionChange: OnChangeFn<RowSelectionState> = (newCheckedBricks) => {
        const updatedSelection = typeof newCheckedBricks === 'function' ? newCheckedBricks(checkedBricks) : newCheckedBricks;
        ComponentStore.setModel('Bricks', 'publishDialog.checkedBricks', updatedSelection);
    };

    const handleExpandedState: OnChangeFn<ExpandedState> = (newExpandedBricks) => {
        const updatedExpanded = typeof newExpandedBricks === 'function' ? newExpandedBricks(expandedBricks) : newExpandedBricks;
        ComponentStore.setModel('Bricks', 'publishDialog.expandedBricks', updatedExpanded);
    };

    const renderItem = (item: Row<BrickTree>) => {
        return <OutputTreeViewItem item={item} />;
    };

    const tableOptions: TableOptions<BrickTree> = {
        data: brickTree,
        columns: outputDialogColumnsSetup,
        state: { expanded: expandedBricks, rowSelection: checkedBricks },
        autoResetExpanded: true,
        getRowId: (row) => row.id,
        getSubRows: (row) => row.children,
        getCoreRowModel: getCoreRowModel(),
        getExpandedRowModel: getExpandedRowModel(),
        onExpandedChange: handleExpandedState,
        onRowSelectionChange: handleSelectionChange,
        enableRowSelection: (row) => !ValidateHelpers.isBrickWithValidationErrors(row.id)
    };

    return <TreeViewList className="output-dialog-tree-view" options={tableOptions} renderItem={renderItem} />;
};

export default OutputTreeView;
