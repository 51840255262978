import Icon from 'components/ui-components-v2/Icon';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Select from 'components/ui-components-v2/Select';
import classNames from 'classnames';
import Chip from 'components/ui-components-v2/Chip';
import { withResources } from 'components/data/Resources';
import '../styles/main.scss';

/**
 * Decision tree options
 * Input field to allow you to set up all the decision tree paths
 */
export class DecisionTreeOptions extends Component {
    static propTypes = {
        onMutation: PropTypes.func,
        value: PropTypes.any,
        readOnly: PropTypes.bool
    };

    static defaultProps = {
        onMutation: () => {},
        value: [['']],
        readOnly: false
    };

    constructor(props) {
        super(props);

        const value = Array.isArray(this.props.value) ? [...this.props.value, ['']] : [['']];
        const titles = props.decisionTree.metadata.levels.map((x) => x.title);

        this.state = {
            value: value,
            titles: titles
        };
    }

    /**
     * Remove a path from the decision tree
     */
    onRemoveClicked = (index) => {
        const { value } = this.state;
        value.splice(index, 1);
        this.setState({ value: [...value] }, () => {
            const { onMutation } = this.props;
            onMutation(this.state.value.filter((x) => x[0] !== ''));
        });
    };

    /**
     * Change the decision tree select at a specific location
     */
    handleChange = (event, listIndex, itemIndex) => {
        const value = [...this.state.value];
        value[listIndex][itemIndex] = event.target.value;
        value[listIndex] = value[listIndex].filter((x, i) => i <= itemIndex);

        let foundCount = 0;
        value.forEach((item) => {
            if (item.length === 1 && item[0] === '') {
                foundCount++;
            }
        });
        if (foundCount === 0) {
            value.push(['']);
        }

        this.setState({ value: [...value] }, () => {
            const { onMutation } = this.props;
            onMutation(this.state.value.filter((x) => x[0] !== ''));
        });
    };

    /**
     * Render an individual decision tree row
     * This can be either read only or as an input
     */
    renderItem = (item, listIndex) => {
        const { decisionTree, readOnly } = this.props;
        const { titles } = this.state;

        let found = true;
        let itemIndex = 0;
        let lastObject = decisionTree.tree;
        const result = [];

        while (found) {
            const val = item[itemIndex];
            const _itemIndex = itemIndex;

            let resultElement;

            // Read only mode, display chip
            if (readOnly) {
                if (item[itemIndex] && lastObject[item[itemIndex]]) {
                    resultElement = <Chip label={lastObject[item[itemIndex]].title} key={listIndex + '_' + itemIndex} style={{ marginRight: 5 }} />;
                }
            }

            // Select box display
            else {
                resultElement = (
                    <div className="input__decision-tree-options__item__select" key={listIndex + '_' + itemIndex}>
                        <Select
                            native
                            value={item[itemIndex]}
                            onChange={(e) => this.handleChange(e, listIndex, _itemIndex)}
                            className={classNames({
                                ['input__decision-tree-options__item__select__active']: val,
                                ['input__decision-tree-options__item__select__passive']: !val
                            })}>
                            <option value="" className="input__decision-tree-options__item__select__passive">
                                Select {titles[itemIndex]}
                            </option>
                            {Object.keys(lastObject).map((key) => (
                                <option
                                    value={key}
                                    key={listIndex + '_' + itemIndex + '_' + key}
                                    className="input__decision-tree-options__item__select__active">
                                    {lastObject[key].title}
                                </option>
                            ))}
                        </Select>
                    </div>
                );
            }

            if (resultElement) {
                result.push(resultElement);
            }

            // We can dive deeper, continue displaying
            if (val && lastObject[val] && lastObject[val].options) {
                itemIndex++;
                lastObject = lastObject[val].options;
            } else {
                found = false;
            }
        }

        return result;
    };

    /**
     * Render the decision tree
     */
    render() {
        const { value } = this.state;
        const { readOnly } = this.props;

        return (
            <div className="input__decision-tree-options">
                {value.map((item, index) => (
                    <span className="input__decision-tree-options__item" key={'list_' + index}>
                        {this.renderItem(item, index)}
                        {index > 0 && !readOnly && (
                            <Icon className="input__decision-tree-options__item__icon-close" onClick={() => this.onRemoveClicked(index)}>
                                close
                            </Icon>
                        )}
                    </span>
                ))}
            </div>
        );
    }
}

export default withResources(DecisionTreeOptions, ['decisionTree']);
