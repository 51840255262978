import { GridItem } from 'helpers/arrangeItemsInGrid';
import { CreativeV2CustomUpload } from '../components/creative-editor/types/creativeV2.type';
import { CreativeV2Base } from './base-creative.class';

export class CreativeV2CustomUploadType extends CreativeV2Base<CreativeV2CustomUpload> {
    prepareForArrangement = (): GridItem<CreativeV2CustomUpload>[] => {
        const { fileType, width: creativeWidth, height: creativeHeight } = this.creative.data;
        let width = fileType === 'audio' ? 400 : creativeWidth || 0;
        let height = fileType === 'audio' ? 60 : creativeHeight || 0;

        // Fallback to 200x200
        if (width === 0 || height === 0) {
            width = 200;
            height = 200;
        }

        return [
            {
                height,
                width,
                item: this.creative,
                itemKey: this.creative.id
            }
        ];
    };

    getFormatOverwrites(): undefined {
        return;
    }

    getCreativeModel(): string {
        return `frames.frame`;
    }

    showPlayBar(): boolean {
        return false;
    }

    isMultiFrame(): boolean {
        return false;
    }

    hasOverlay(): boolean {
        return false;
    }
}
