import React from 'react';
import moment from 'moment';
import Icon from 'components/ui-components-v2/Icon';
import Button from 'components/ui-components-v2/Button';
import Typography from 'components/ui-components-v2/Typography';
import '../styles/grid.scss';

/**
 * Class CalendarGridHeaderSidebar
 * The part of the header that's in the sidebar
 */
class CalendarGridHeaderSidebar extends React.Component {
    /**
     * Calculates the total amount of campaigns in the list
     */
    getTotalCampaignsCount = () => {
        const { filteredCampaigns } = this.props;

        let totalCampaigns = 0;

        if (filteredCampaigns) {
            filteredCampaigns.forEach((campaignType) => {
                totalCampaigns += campaignType.totalCampaigns;
            });
        }

        return totalCampaigns;
    };

    render() {
        const { currentDate, filteredCampaigns, name, compactView, prevTimeRange, nextTimeRange } = this.props;

        return (
            <div className="calendar-grid-header__sidebar">
                <div className={`calendar-grid-header__sidebar__title calendar-grid-header__sidebar__title${compactView ? '--compact' : ''}`}>
                    {compactView ? (
                        <React.Fragment>
                            <Button size="small" variant="outlined" onClick={() => prevTimeRange()} className="calendar-grid-header__sidebar__arrow-button">
                                <Icon>keyboard_arrow_left</Icon>
                            </Button>
                            <Button size="small" variant="outlined" onClick={() => nextTimeRange()} className="calendar-grid-header__sidebar__arrow-button">
                                <Icon>keyboard_arrow_right</Icon>
                            </Button>
                            <span style={{ marginLeft: 16 }}>{moment(currentDate).format('MMMM YYYY')}</span>
                        </React.Fragment>
                    ) : (
                        <Typography
                            variant="h5"
                            component="span"
                            style={{
                                textTransform: 'capitalize'
                            }}>{`${name} (${this.getTotalCampaignsCount()})`}</Typography>
                    )}
                </div>

                {compactView && (
                    <div className="calendar-grid-header__sidebar__subtitle">
                        <React.Fragment>
                            <Icon
                                style={{ fontSize: 10, margin: 0 }}
                                className={`calendar-grid-rows__row__sidebar__subheader__icon--${filteredCampaigns[0] ? filteredCampaigns[0].type : ''}`}>
                                fiber_manual_record
                            </Icon>
                            <span style={{ marginLeft: 8 }}>{`${filteredCampaigns[0] ? filteredCampaigns[0].type : 'No'} campaigns ${
                                filteredCampaigns[0] && filteredCampaigns[0].filteredCampaigns ? '(' + filteredCampaigns[0].totalCampaigns + ')' : ''
                            }`}</span>
                        </React.Fragment>
                    </div>
                )}
            </div>
        );
    }
}

export default CalendarGridHeaderSidebar;
