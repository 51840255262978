import { AssetV2 } from 'types/asset.type';
import { Collection } from 'types/collection.type';
import Setup from 'components/data/Setup';
import { MULTIS } from '../constants';

// This function checks if an item has labels and if any of them has content.
const hasLabels = (item: Collection | AssetV2<unknown, unknown>): boolean => {
    if (!item) return false;
    if (!item.labels) return false;

    // if one the multis are enabled we show the labels
    const hasMultiEnabled = Object.keys(MULTIS).some((key) => Setup.hasModule(MULTIS[key]));
    if (hasMultiEnabled) return true;

    return Object.values(item.labels).some((label) => label.length > 0);
};

export { hasLabels };
