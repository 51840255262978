import React, { useEffect } from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import { useColorScheme } from '@mui/material/styles';
import { history } from './store';
import Preview from './controllers/Preview';

export default function AppRoutes() {
    useEffect(() => {
        document.getElementById('core-loader').style.display = 'none';
    });
    //Highest functional component to force color mode to light
    const { mode, setMode } = useColorScheme();
    useEffect(() => {
        if (mode !== 'light') {
            setMode('light'); // Set mode to 'light' after render
        }
    }, [mode, setMode]); // Dependencies ensure this effect runs when mode changes

    if (!mode) return null; // Wait until mode is available

    return (
        <div>
            <Router history={history}>
                <Switch>
                    <Route exact path="/:type/:campaignId/:token" component={Preview} />
                </Switch>
            </Router>
        </div>
    );
}
