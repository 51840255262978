import React, { useState } from 'react';
import { DialogTitle, DialogContent, DialogActions, TextField, Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Tooltip from '@mui/material/Tooltip';
import Icon from 'components/ui-components-v2/Icon';
import Dialog from 'components/ui-components-v2/Dialog';
import Chip from 'components/ui-components-v2/Chip';
import ButtonGroup from 'components/ui-components-v2/ButtonGroup';
import Select from 'components/ui-components-v2/Select';
import Button from 'components/ui-components-v2/Button';
import MenuItem from 'components/ui-components-v2/MenuItem';
import Translation from 'components/data/Translation';
import { DynamicValueOption } from '../types/dynamic-value.type';
import './../styles/dialog.scss';
import { DynamicDataHelper } from '../helpers/dynamic-data-helper';

interface Props {
    open: boolean;
    onClose: () => void;
    onChange: (value: any) => void;
    value: any;
    options: DynamicValueOption[];
}

const DynamicDataDialog: React.FC<Props> = ({ open, onClose, onChange, value, options = [] }) => {
    // Select proper window of the dialog
    const [type, setType] = useState<string>(value && DynamicDataHelper.customSplitOfString(value).length > 1 ? 'custom' : 'field');
    const [customTextFieldValue, setCustomTextFieldValue] = useState<string>('');

    /**
     * Input change handler
     * @param event
     */
    const handleInputChangeField = (event): void => {
        const newValue = event.target.value;
        if (!newValue) return;
        onChange(`%[${newValue}]%`);
    };

    /**
     * Inserts the value into the brackets for dynamic value
     * @param codeToAdd
     */
    const insertCode = (codeToAdd) => {
        let code = getCustom(value);
        if (code) {
            code = code + '+' + codeToAdd;
        } else {
            code = codeToAdd;
        }
        onChange('%[' + code + ']%');
    };

    /**
     * Changes the type of the dialog
     * @param newType
     * @returns
     */
    const handleChangeType = (newType): void => {
        const customValue = getCustom(value);
        if (newType === type) {
            return;
        } else if (newType === 'field') {
            const valueParts = customValue?.split('+');
            if (valueParts && valueParts.length !== 1) onChange(`%[${options[0].value}]%`);
        } else if (newType === 'custom') {
            if (customValue?.length) onChange(value);
        }
        setType(newType);
    };

    /**
     * Checks if value is from feed field
     * @param value
     * @returns if value is feed field
     */
    const getFieldValue = (value: string): string | undefined => {
        if (value === null) return;
        const regexField = /%\[([^']+)\]%/;
        const match = value?.match(regexField);
        if (match && match.length > 0) {
            return match[1];
        } else return '';
    };

    /**
     * Get dynamic value
     * @param value
     * @returns value
     */
    const getCustom = (value) => {
        if (value === null) return;
        return value?.replace('%[', '').replace(']%', '');
    };

    /**
     * Adds value from the text field to the internal value
     * @returns
     */
    const addCustomValueFromInput = () => {
        console.log({ customTextFieldValue });
        if (!customTextFieldValue.length) return;
        let code = getCustom(value);

        if (code) {
            code = code + '+' + customTextFieldValue;
        } else {
            code = customTextFieldValue;
        }
        onChange('%[' + code + ']%');

        setCustomTextFieldValue('');
    };

    /**
     * Handles the delete of an item in the input
     * @param index
     */
    const handleDeleteItemFromCustomValue = (index: number): void => {
        addCustomValueFromInput();
        const code = getCustom(value);

        const newValue = DynamicDataHelper.handleDeleteValueByIndex(code, index);

        onChange('%[' + newValue + ']%');

        setCustomTextFieldValue('');
    };

    /**
     * Gets icon for chip
     * @param value
     * @returns icon
     */
    const getTagIcon = (value) => {
        if (options.find((option) => option.value === value)) return <Icon>link</Icon>;
    };

    /**
     * Gets the label of the option if item is from options
     * @param value
     * @returns label
     */
    const getTagLabel = (value): string => {
        const foundOption = options.find((option) => option.value === value);
        if (!foundOption) return value;
        return foundOption.label;
    };

    /**
     * Gets value for the autocomplete
     * @returns autocomplete value
     */
    const getAutocompleteValue = () => {
        const customValue = getCustom(value);
        if (!value || !customValue) return [];
        const valueParts = DynamicDataHelper.customSplitOfString(customValue || '');

        console.log({ valueParts });
        return valueParts;
    };

    return (
        <Dialog open={open} onClose={() => onClose()}>
            <DialogTitle>{Translation.get('input.dynamicData.dialog.title', 'common')}</DialogTitle>
            <DialogContent dividers>
                <ButtonGroup variant="outlined">
                    <Button variant={type === 'field' ? 'contained' : 'outlined'} onClick={() => handleChangeType('field')}>
                        {Translation.get('input.dynamicData.dialog.singleValue', 'common')}
                    </Button>
                    <Button variant={type === 'custom' ? 'contained' : 'outlined'} onClick={() => handleChangeType('custom')}>
                        {Translation.get('input.dynamicData.dialog.custom', 'common')}
                    </Button>
                </ButtonGroup>
                <Typography className="dynamic-data-dialog__description">{Translation.get('input.dynamicData.dialog.description', 'common')}</Typography>
                {type === 'field' && !!options.length && (
                    <Select defaultValue={options[0].value} value={getFieldValue(value)} onChange={(event) => handleInputChangeField(event)} fullWidth>
                        {options.map((option, index) => {
                            return (
                                <MenuItem key={index} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            );
                        })}
                    </Select>
                )}

                {type === 'custom' && (
                    <Autocomplete
                        multiple
                        options={[]}
                        value={getAutocompleteValue()}
                        disableClearable
                        freeSolo
                        inputValue={customTextFieldValue}
                        onChange={() => {
                            addCustomValueFromInput();
                        }}
                        renderTags={(tagValue) => {
                            return tagValue.map((option, index) => {
                                if (option === '') return '';
                                return (
                                    <Tooltip placement="top" key={index} title={option}>
                                        <Chip
                                            icon={getTagIcon(option)}
                                            key={index}
                                            label={getTagLabel(option)}
                                            className="dynamic-data-dialog__custom-input__chip"
                                            onDelete={() => handleDeleteItemFromCustomValue(index)}
                                        />
                                    </Tooltip>
                                );
                            });
                        }}
                        renderInput={(params) => (
                            <TextField {...params} onChange={(event) => setCustomTextFieldValue(event.target.value)} onBlur={() => addCustomValueFromInput()} />
                        )}
                    />
                )}

                {type === 'custom' && (
                    <div className="dynamic-data-dialog__options">
                        {options.map((option, id) => {
                            return (
                                <Tooltip placement="top" key={id} title={option.value}>
                                    <Chip
                                        label={option.label}
                                        onClick={() => insertCode(option.value)}
                                        className="dynamic-data-dialog__options__option"
                                        variant="outlined"
                                    />
                                </Tooltip>
                            );
                        })}
                    </div>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onClose()} color="primary">
                    {Translation.get('input.dynamicData.dialog.update', 'common')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DynamicDataDialog;
