import React from 'react';
import FormControl from '@mui/material/FormControl';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Icon from 'components/ui-components-v2/Icon';
import TextField from 'components/ui-components-v2/TextField';
import Translation from 'components/data/Translation';
import GenericFilterDelete from './delete';

import '../styles/type-date-picker.scss';

/**
 * Display a filter of type date picker
 * @param {*} param0
 * @returns
 */

const GenericFilterTypeDatePicker = ({ filter, value = [], onChange }) => {
    const handleChange = (value) => {
        onChange(filter.name, moment(value).format('YYYY-MM-DD'));
    };

    return (
        <div>
            <FormControl component="fieldset" className="generic-filter__type-date-picker" fullWidth>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                        className="generic-filter__type-date-picker__input"
                        format="DD/MM/YYYY"
                        value={value.length ? moment(value) : undefined}
                        onAccept={handleChange}
                        slots={{
                            openPickerIcon: () => <Icon>today</Icon>,
                            textField: (params) => (
                                <TextField
                                    {...params}
                                    slotProps={{
                                        htmlInput: { ...params.inputProps, value: value, placeholder: Translation.get('filters.selectDate', 'ui-base') }
                                    }}
                                    fullWidth
                                />
                            )
                        }}
                        slotProps={{ popper: { className: 'generic-filter__type-date-picker__input__popper' } }}
                        autoFocus={false}
                        onChange={(value) => value}
                        views={['year', 'month', 'day']}
                    />
                </LocalizationProvider>
            </FormControl>
            <GenericFilterDelete onDelete={() => onChange(filter.name, '')} active={value.length > 0} />
        </div>
    );
};

export default GenericFilterTypeDatePicker;
