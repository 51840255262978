import { StaticAssetType, StaticAssetTypeOriginal } from '../types/template-management.type';

enum StaticAssetSubType {
    image = 'staticAssetImage',
    video = 'staticAssetVideo',
    audio = 'staticAssetAudio',
    display = 'staticAssetDisplay',
    pdf = 'staticAssetPDF'
}

const convertStaticAssetTypeV1ToV2 = (originalType: StaticAssetTypeOriginal): StaticAssetType => {
    return StaticAssetSubType[originalType];
};

export { convertStaticAssetTypeV1ToV2 };
