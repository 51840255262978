import { StaticAssetType, StaticAssetTypeOriginal } from '../types/template-management.type';

enum StaticAssetSubType {
    staticAssetImage = 'image',
    staticAssetVideo = 'video',
    staticAssetAudio = 'audio',
    staticAssetDisplay = 'display',
    staticAssetPDF = 'pdf'
}

const convertStaticAssetTypeV2ToV1 = (V2Type: StaticAssetType): StaticAssetTypeOriginal => {
    return StaticAssetSubType[V2Type];
};

export { convertStaticAssetTypeV2ToV1 };
