import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker as MuiDateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import Icon from 'components/ui-components-v2/Icon';

// Make sure the datepicker starts the week on a Monday instead of a Sunday
moment.updateLocale('en', {
    week: {
        dow: 1
    }
});

/**
 * DateTime picker
 * Displays a field where you can select the date and time
 */
export class DateTimePicker extends Component {
    static propTypes = {
        onMutation: PropTypes.func,
        value: PropTypes.any,
        daysInFuture: PropTypes.any, // Number of days in the future to set the date to, in case nothing is set
        readOnly: PropTypes.bool,
        outputUTC: PropTypes.bool // If true, the date will be output in UTC format
    };

    static defaultProps = {
        onMutation: () => {},
        value: undefined,
        daysInFuture: 0,
        readOnly: false,
        outputUTC: false
    };

    constructor(props) {
        super(props);
        const { value } = props;

        let selectedDate;

        if (props.outputUTC && value) {
            const utcMoment = moment.utc(value, 'YYYY-MM-DD HH:mm:ss');
            const localTime = utcMoment.local();
            selectedDate = localTime;
        } else if (value) {
            selectedDate = moment(value, 'YYYY-MM-DD HH:mm:SS');
        } else if (props.daysInFuture) {
            selectedDate = moment().add(props.daysInFuture, 'd');
        } else {
            selectedDate = moment();
        }

        this.state = {
            selectedDate
        };
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.value !== this.props.value && !this.props.outputUTC) {
            this.setState({ selectedDate: moment(this.props.value) });
        }
    };

    handleDateChange = (date) => {
        this.setState({ selectedDate: date }, this.onDataChanged);
    };

    onDataChanged = () => {
        const { onMutation, outputUTC } = this.props;
        const { selectedDate } = this.state;
        if (selectedDate && outputUTC) {
            const dateString = selectedDate.format('YYYY-MM-DD HH:mm:SS');
            const localMoment = moment(dateString, 'YYYY-MM-DD HH:mm:ss');
            onMutation(localMoment.utc().format('YYYY-MM-DD HH:mm:SS'));
        } else if (selectedDate) onMutation(selectedDate.format('YYYY-MM-DD HH:mm:SS'));
    };

    render() {
        const { readOnly } = this.props;
        const { selectedDate } = this.state;

        if (readOnly) {
            return <div>{selectedDate.format('DD/MM/YYYY HH:mm')}</div>;
        } else {
            return (
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <MuiDateTimePicker
                        slots={{
                            openPickerIcon: () => <Icon>today</Icon>
                        }}
                        format="DD/MM/YYYY HH:mm"
                        value={selectedDate}
                        onChange={this.handleDateChange}
                    />
                </LocalizationProvider>
            );
        }
    }
}

export default DateTimePicker;
