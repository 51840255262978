import React from 'react';
import { DataSetFeed } from 'types/dataset.type';
import { BrickFeedData } from 'components/bricks/types/brick.type';
import { MODEL_DATASET_DETAILS, MODEL_SETTINGS } from 'components/bricks/constants';
import AddFeedDialog from 'components/feed-management/AddFeedDialog';
import BricksComponentStoreHelper from 'components/bricks/helpers/bricks-component-store.helper';
import BrickHelpers from 'components/bricks/helpers/brick.helpers';
import BrickFeedHelpers from 'components/bricks/helpers/brick-feed-helpers';

interface Props {
    onClose: () => void;
    brickId: string;
}

const BrickFeedDialog: React.FC<Props> = ({ onClose, brickId }) => {
    /**
     * Selects feed
     * @param feedData
     */
    const handleSelectFeed = async (feedData: BrickFeedData): Promise<void> => {
        const brick = BrickHelpers.getBrickById(brickId);
        if (!brick) return;

        BricksComponentStoreHelper.setBrickModel(brickId, MODEL_DATASET_DETAILS, feedData); // Save the datasetId and the feedId

        const dataset = await BrickFeedHelpers.loadBrickDataset(brickId);
        const feed: DataSetFeed = dataset?.feeds?.[0];
        const brickSettings = {
            type: feed?.type,
            url: feed?.url,
            updateAction: feed?.updateAction
        };

        BricksComponentStoreHelper.setBrickModel(brickId, MODEL_SETTINGS, brickSettings); // Save the datasetId and the feedId
    };

    return <AddFeedDialog onSelectFeed={(feedData: BrickFeedData) => handleSelectFeed(feedData)} onClose={() => onClose()} />;
};

export default BrickFeedDialog;
