import React from 'react';
import { SelectorAssetSourceData } from 'components/asset-management/components/selector/types/selector-asset-source-data.type';
import Button from 'components/ui-components-v2/Button';
import Translation from 'components/data/Translation';
import { getSelectorUseCase, getSelectorUseCaseId } from 'components/asset-management/utilities';
import AssetManagementService from 'components/asset-management/services/asset-management.service';
import { MediaAsset } from 'components/media-management/types/media-management.type';
import { MediaManagementAssetTilePreview, MediaManagementAssetTileInfo } from '../../asset-tile';

import '../styles/tile.scss';

interface Props {
    item?: SelectorAssetSourceData;
    onSelect?: (item: SelectorAssetSourceData) => void;
}

const MediaManagementSelectorTile = ({ item, onSelect }: Props) => {
    if (!item?.originalRecord) return null;
    if (!onSelect) return null;

    // Select the asset and make sure we notify the usage endpoint of this selection.
    const handleSelect = (item: SelectorAssetSourceData) => {
        const useCase = getSelectorUseCase();
        if (!useCase) return onSelect(item);

        const useCaseId = getSelectorUseCaseId(useCase);
        if (!useCaseId) return onSelect(item);

        AssetManagementService.addUsage({ assetId: item.id as string, objectId: useCaseId, objectName: useCase });
        onSelect(item);
    };

    return (
        <div onClick={() => handleSelect(item)} className="media-management-selector-tile" data-cy="mediaManagement-selector-title-div">
            <div className="media-management-selector-tile__preview">
                <MediaManagementAssetTilePreview asset={item.originalRecord as MediaAsset} />
                <div className="media-management-selector-tile__overlay">
                    <div className="media-management-selector-tile__extension">{(item.originalRecord as MediaAsset).data.files?.[0]?.extension}</div>
                    <Button variant="contained" color="primary" className="media-management-selector-tile__button">
                        {Translation.get('actions.select', 'common')}
                    </Button>
                </div>
            </div>
            <MediaManagementAssetTileInfo title={item.title || ''} subtitle={item.subtitle || ''} small />
        </div>
    );
};

export default MediaManagementSelectorTile;
