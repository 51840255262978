import { Color, ColorOptions, SimpleColor } from 'types/color.type';

const defaultColorSimple: SimpleColor = { hex: '#515a6a', rgb: { r: 81, g: 90, b: 106, a: 1 } };
const defaultColorSimple2: SimpleColor = { hex: '#2971eb', rgb: { r: 41, g: 113, b: 235, a: 1 } };
const defaultBlackColorSimple: SimpleColor = { hex: '#000000', rgb: { r: 0, g: 0, b: 0, a: 1.0 } };

const defaultColor: Color = {
    type: ColorOptions.Solid,
    rotation: 0,
    points: [
        { location: 0, color: defaultColorSimple },
        { location: 1, color: defaultColorSimple2 }
    ]
};

const defaultTransparent: Color = {
    type: ColorOptions.Transparent,
    rotation: 90,
    points: [
        { location: 0, color: { hex: '#ffffff', rgb: { r: 255, g: 255, b: 255, a: 1.0 } } },
        { location: 1, color: { hex: '#000000', rgb: { r: 0, g: 0, b: 0, a: 1.0 } } }
    ]
};

const defaultWhiteColor: Color = {
    type: ColorOptions.Solid,
    rotation: 90,
    points: [
        { location: 0, color: { hex: '#ffffff', rgb: { r: 255, g: 255, b: 255, a: 1.0 } } },
        { location: 1, color: { hex: '#000000', rgb: { r: 0, g: 0, b: 0, a: 1.0 } } }
    ]
};

const defaultBlackColor: Color = {
    type: ColorOptions.Solid,
    rotation: 90,
    points: [
        { location: 1, color: { hex: '#ffffff', rgb: { r: 0, g: 0, b: 0, a: 1.0 } } },
        { location: 1, color: { hex: '#000000', rgb: { r: 0, g: 0, b: 0, a: 1.0 } } }
    ]
};

export { defaultColor, defaultTransparent, defaultWhiteColor, defaultBlackColor, defaultColorSimple, defaultBlackColorSimple };
