import { ValidationObject } from 'components/bricks/types/validator.type';

const validators: ValidationObject = {
    validators: [
        {
            condition: '!brick.data?.settings?.adType',
            message: 'Ad type is required',
            inputIdentifiers: ['peachconnect_ad-ad_type'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition: '!brick.data?.settings?.regionCode',
            message: 'Region is required',
            inputIdentifiers: ['peachconnect_ad-region_code'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition: '!brick.data?.settings?.destination',
            message: 'Destination is required',
            inputIdentifiers: ['peachconnect_ad-destination'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition: '!brick.data?.settings?.reference.id',
            message: 'Reference id is required',
            inputIdentifiers: ['peachconnect_ad-reference_id'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition: '!brick.data?.settings?.reference.type',
            message: 'Reference type is required',
            inputIdentifiers: ['peachconnect_ad-reference_type'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition: '!brick.data?.creatives?.length',
            message: 'Please select an asset',
            inputIdentifiers: ['peachconnect_ad-creatives'],
            tabKey: 'settings',
            severity: 'error'
        }
    ]
};

export default validators;
