import staticAssetZipImage from 'components/creative-builder/CreativeBuilderTemplateDialog/images/zip.png';
import staticAssetImgImage from 'components/creative-builder/CreativeBuilderTemplateDialog/images/image.png';
import staticAssetVideoImage from 'components/creative-builder/CreativeBuilderTemplateDialog/images/video.png';
import staticAssetAudioImage from 'components/creative-builder/CreativeBuilderTemplateDialog/images/audio.png';
import Translation from 'components/data/Translation';
import { StaticAssetType, StaticAssetTypeOriginal } from '../types/template-management.type';

const STATIC_ASSETS = [
    {
        id: 'static_asset_display',
        title: Translation.get('selector.staticAssets.display.title', 'template-management'),
        description: Translation.get('selector.staticAssets.display.desc', 'template-management'),
        identifier: 'display',
        type: 'staticAsset',
        image: staticAssetZipImage
    },
    {
        id: 'static_asset_video',
        title: Translation.get('selector.staticAssets.video.title', 'template-management'),
        description: Translation.get('selector.staticAssets.video.desc', 'template-management'),
        identifier: 'video',
        type: 'staticAsset',
        image: staticAssetVideoImage,
        interfaceSetup: [
            {
                title: 'Asset',
                path: 'asset',
                items: [
                    {
                        type: 'alert',
                        alertType: 'info',
                        message: 'Publish requirements. Max size Meta: 80MB; Max size Snapchat: 32MB. Crop & Trip video to stay in limits'
                    },
                    {
                        type: 'switch',
                        label: 'Different asset per language',
                        model: 'multiLanguageAsset'
                    },
                    {
                        type: 'assetGalleryInput',
                        model: 'video',
                        sourceDataModel: 'videoGallery',
                        label: 'Video file',
                        useCropper: true,
                        canSkipCropper: true,
                        fileType: 'video',
                        condition: '!blockData || !blockData.multiLanguageAsset'
                    },
                    {
                        type: 'assetGalleryInput',
                        model: 'videoByLanguage.[[language]]',
                        sourceDataModel: 'videoGallery',
                        label: 'Video',
                        useCropper: true,
                        canSkipCropper: true,
                        fileType: 'video',
                        condition: 'blockData.multiLanguageAsset'
                    }
                ]
            }
        ]
    },
    {
        id: 'static_asset_image',
        title: Translation.get('selector.staticAssets.image.title', 'template-management'),
        description: Translation.get('selector.staticAssets.image.desc', 'template-management'),
        identifier: 'image',
        type: 'staticAsset',
        image: staticAssetImgImage,
        interfaceSetup: [
            {
                title: 'Asset',
                path: 'asset',
                items: [
                    {
                        type: 'switch',
                        label: 'Different asset per language',
                        model: 'multiLanguageAsset'
                    },
                    {
                        type: 'assetGalleryInput',
                        model: 'image',
                        sourceDataModel: 'imageGallery',
                        label: 'Image',
                        useCropper: true,
                        fileType: 'image',
                        condition: '!blockData || !blockData.multiLanguageAsset'
                    },
                    {
                        type: 'assetGalleryInput',
                        model: 'imageByLanguage.[[language]]',
                        sourceDataModel: 'imageGallery',
                        label: 'Image',
                        useCropper: true,
                        fileType: 'image',
                        condition: 'blockData.multiLanguageAsset'
                    }
                ]
            }
        ]
    },
    {
        id: 'static_asset_pdf',
        title: Translation.get('selector.staticAssets.pdf.title', 'template-management'),
        description: Translation.get('selector.staticAssets.pdf.desc', 'template-management'),
        identifier: 'pdf',
        type: 'staticAsset',
        image: staticAssetImgImage
    },
    {
        id: 'static_asset_audio',
        title: Translation.get('selector.staticAssets.audio.title', 'template-management'),
        description: Translation.get('selector.staticAssets.audio.desc', 'template-management'),
        identifier: 'audio',
        type: 'staticAsset',
        image: staticAssetAudioImage
    }
];

const STATIC_ASSET_TYPES: StaticAssetType[] = ['staticAssetDisplay', 'staticAssetVideo', 'staticAssetImage', 'staticAssetPDF', 'staticAssetAudio'];

const STATIC_ASSET_TYPES_ORIGINAL: StaticAssetTypeOriginal[] = ['display', 'video', 'image', 'pdf', 'audio'];

export { STATIC_ASSETS, STATIC_ASSET_TYPES, STATIC_ASSET_TYPES_ORIGINAL };
