import React, { useEffect, useRef } from 'react';
import CircularProgress from 'components/ui-components-v2/CircularProgress';

import './styles/main.scss';

interface Props {
    children: React.ReactNode | React.ReactNode[]; // List of items as children, or a component wrapping the list.
    loadMore: () => void; // Function to load more items
    hasMore: boolean; // Whether there are more items to load
    loader?: React.ReactNode; // Optional loader element
    loading: boolean; // Whether a loadMore is in progress.
    threshold?: number; // Intersection threshold (default is 0.1)
    root?: HTMLElement | null; // Optional root element for the observer
    rootMargin?: string; // Root margin for the observer (default is "0px")
}

const AssetManagementInfiniteScroll = ({
    children,
    loadMore,
    hasMore,
    loader = <CircularProgress />, // Default loader
    loading = false,
    threshold = 0.1,
    root = null,
    rootMargin = '0px 0px 300px 0px' // Apply margin only to the bottom
}: Props) => {
    const observerRef = useRef<IntersectionObserver | null>(null); // IntersectionObserver instance
    const loaderRef = useRef<HTMLDivElement | null>(null); // Ref for the loader element

    useEffect(() => {
        if (!hasMore || loading) return;

        // Create a new IntersectionObserver instance
        observerRef.current = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting && !loading) {
                    loadMore();
                }
            },
            {
                root, // Custom scrollable container or viewport
                rootMargin,
                threshold
            }
        );

        // Observe the loader element
        if (loaderRef.current) {
            observerRef.current.observe(loaderRef.current);
        }

        return () => {
            observerRef.current?.disconnect(); // Cleanup observer
        };
    }, [hasMore, loading, loadMore, root, rootMargin, threshold]);

    return (
        <React.Fragment>
            {children}
            {hasMore && (
                <div ref={loaderRef} className="asset-management-infinite-scroll">
                    {loader}
                </div>
            )}
        </React.Fragment>
    );
};

export default AssetManagementInfiniteScroll;
