import Format from 'types/format.type';
import { CSSProperties } from 'react';
import { isCustomTemplate } from 'components/template-management/utilities';
import ComponentStore from 'components/data/ComponentStore';
import CreativeBuilderHelpers from 'components/creative-builder/CreativeBuilderHelpers';
import Resources from 'components/data/Resources/components';
import { CreativeV2Template } from '../components/creative-editor/types/creativeV2.type';
import { ACTIVE_FORMATS } from '../constants';
import { TemplateManager } from '../data/template-manager';

const PLACEMENT_MAX_SIZE = 24;
class CreativeV2FormatHelpers {
    static getActiveFormat = (creative: CreativeV2Template, formatKey: string): Format | undefined => {
        const template = TemplateManager.getTemplateByIdentifier(creative.data.templateIdentifier);

        if (!template) return;

        if (isCustomTemplate(template)) {
            return CreativeBuilderHelpers.createAssetFormatsArray(template.subType, [formatKey])[0];
        }

        return template.data.templateSetup?.formats.find((format) => format.key === formatKey);
    };

    static getActiveFormats = (creative: CreativeV2Template): Format[] => {
        const activeFormats = creative.data?.settings?.activeFormats;

        if (!activeFormats) return [];

        return this.getFormats(creative.data.templateIdentifier, activeFormats);
    };

    static getFormats = (templateIdentifier: CreativeV2Template['data']['templateIdentifier'], formatKeys?: string[]): Format[] => {
        const template = TemplateManager.getTemplateByIdentifier(templateIdentifier);

        // If the template is not loaded yet and we have specific formatKeys, we try to get them from the resources
        if (!template && !!formatKeys) {
            const templateFormats = Resources.get('templateFormats');
            if (!templateFormats) return [];

            const formatsArray: Format[] = [];

            formatKeys.forEach((formatKey) => {
                Object.keys(templateFormats).forEach((templateTypeKey) => {
                    const format: Format | undefined = templateFormats[templateTypeKey][formatKey] as Format | undefined;
                    if (format) formatsArray.push({ ...format, key: formatKey });
                });
            });

            return formatsArray;
        }

        if (!template) return [];

        if (!formatKeys) {
            if (isCustomTemplate(template)) {
                return CreativeBuilderHelpers.createAssetFormatsArray(template.subType, template.data.settings?.formats).map((format) => {
                    return {
                        ...format,
                        key: format.format
                    };
                });
            }

            return template.data.templateSetup?.formats || [];
        }

        if (isCustomTemplate(template)) {
            return CreativeBuilderHelpers.createAssetFormatsArray(template.subType, formatKeys).map((format) => {
                return {
                    ...format,
                    key: format.format
                };
            });
        }

        return formatKeys
            .map((activeFormat) => template?.data.templateSetup?.formats.find((format) => format.key === activeFormat))
            .filter((format) => format !== undefined) as Format[];
    };

    // Update the active formats in the creative
    static updateActiveFormats = (newActiveFormats: string[]): void => {
        ComponentStore.setModel('CreativeEditorV2', `creative.${ACTIVE_FORMATS}`, newActiveFormats);
    };

    // Get the closest format to a square aspect ratio between 1:1 and 1.3:1, or the closest to square if none are found
    static getOptimalScreenshotFormat = (formats: Format[]): Format => {
        // Only consider formats that are visible in the editor
        const visibleFormats = Array.from(document.querySelectorAll('[data-visible-format]'))
            .map((element) => element.getAttribute('data-visible-format'))
            .filter(Boolean);

        // Filter formats that have an aspect ratio between 1:1 (1) and 1.3:1 (1.3)
        const validFormats = formats.filter((format) => {
            const aspectRatio = format.width / format.height;
            return aspectRatio >= 1 && aspectRatio <= 1.3 && visibleFormats.includes(format.key);
        });

        // If valid formats are found, find the one with the smallest height
        if (validFormats.length > 0) {
            let closestFormat = validFormats[0];
            for (let i = 1; i < validFormats.length; i++) {
                if (validFormats[i].height < closestFormat.height) {
                    closestFormat = validFormats[i];
                }
            }
            return closestFormat;
        }

        // If no valid formats, fallback to finding the closest to a square aspect ratio
        let closestFormat = formats[0];
        let closestDifference = Math.abs(1 - closestFormat.width / closestFormat.height);

        for (let i = 1; i < formats.length; i++) {
            const aspectRatio = formats[i].width / formats[i].height;
            const difference = Math.abs(1 - aspectRatio);

            if (difference < closestDifference) {
                closestDifference = difference;
                closestFormat = formats[i];
            }
        }

        return closestFormat;
    };

    /**
     * Get the style for the preview of the format.
     **/
    static getFormatStyles = (format?: Format): CSSProperties => {
        // Calculate the thumbnail size based on the width and height.
        const previewSize: CSSProperties = {
            width: PLACEMENT_MAX_SIZE,
            height: PLACEMENT_MAX_SIZE
        };

        const width = format?.width;
        const height = format?.height;

        if (!width || !height) return previewSize;

        if (width > height) {
            previewSize.height = (height / width) * PLACEMENT_MAX_SIZE;
        } else {
            previewSize.width = (width / height) * PLACEMENT_MAX_SIZE;
        }

        return previewSize;
    };
}

export { CreativeV2FormatHelpers };
