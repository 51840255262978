import React, { useEffect, useState } from 'react';
import { DataSetStructure } from 'types/dataset.type';
import User from 'components/data/User';
import FeedHelpers, { FeedSubscription } from 'components/data/FeedManagementHelpers';
import ComponentStoreHelpers from 'components/data/ComponentStore';
import DataSetManager from './';

import '../styles/in-campaign.scss';

interface Props {
    dataSet: DataSetStructure;
    displayType: string;
}

/**
 * so the DataSetManger can be used stand-alone in the bricks.
 */
const DataSetManagerInBrick = ({ dataSet }: Props) => {
    const [subscription, setSubscription] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (User.hasRight('feedManagement')) {
            FeedHelpers.loadData();

            ComponentStoreHelpers.setData('FeedManager', { dataSet: dataSet });

            FeedSubscription.subscribe();
        }

        return () => closeWebSocket();
    }, []);

    const closeWebSocket = () => {
        if (User.hasRight('feedManagement')) {
            if (subscription) FeedSubscription.unsubscribe(subscription);
        }
    };

    if (!User.hasRight('feedManagement')) return null;

    return <DataSetManager displayType="list" showFeedItem={false} onSetHasContent={() => ''} />;
};

export default DataSetManagerInBrick;
